import React, { useState } from "react";
import Carousel from "nuka-carousel";

import { FlexCol } from "../../../FlexCol";
import { ThumbFileSelector } from "./ThumbFileSelector";

interface Props {
  thumbnails: string[];
  selected?: string;
  onSelectNewFile?: (file?: File) => void;
  onSelect?: (url: string) => void;
}

export const ThumbnailCarousel = ({
  selected,
  thumbnails = [],
  onSelectNewFile = () => {},
  onSelect = () => {},
}: Props) => {
  const [dragging, setDragging] = useState(false);

  const onAddNewFile = (file: File) => {
    if (!file) {
      return;
    }
    onSelectNewFile(file);
    onSelect(URL.createObjectURL(file));
  };

  const onRemoveNewFile = () => {
    onSelect(thumbnails?.[0]);
    onSelectNewFile(undefined);
  };

  return (
    <Carousel
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      renderBottomCenterControls={null}
      cellAlign="left"
      slideWidth="108px"
      onDragStart={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
    >
      <ThumbFileSelector
        onSelect={onSelect}
        onSelectNewFile={onAddNewFile}
        selected={selected}
        onRemoveNewFile={onRemoveNewFile}
      />
      {thumbnails.map((item, index) => (
        <FlexCol
          key={index}
          width={100}
          sx={{
            borderRadius: 1,
            overflow: "hidden",
            borderColor: "primary.main",
            borderWidth: 2,
            cursor: "pointer",
            borderStyle: selected === item ? "solid" : "none",
            aspectRatio: "16/9",
          }}
          onClick={() => !dragging && onSelect(item)}
        >
          <img alt="thumbnail" src={item} width="100%" height="100%" style={{ objectFit: "cover" }} />
        </FlexCol>
      ))}
    </Carousel>
  );
};
