import React, { useCallback, useEffect } from "react";
import { ButtonBase, Popover, PopoverProps } from "@mui/material";

import { reactionsPack, stringFromReaction } from "../helpers";
import { ReactionType } from "../types";
import { FlexRow } from "./FlexRow";

type Props = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  handleReaction: (reaction: ReactionType["type"]) => void;
} & Partial<PopoverProps>;

const ReactionPopover = ({ anchorEl, setAnchorEl, handleReaction, anchorOrigin, open, ...rest }: Props) => {

  useEffect(() => {
    const handler = () => setAnchorEl(null);

    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  const handleClose = useCallback((e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <Popover
      id="reactions"
      open={open || Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={
        anchorOrigin || {
          vertical: -40,
          horizontal: "left",
        }
      }
      disableScrollLock
      PaperProps={{
        sx: {
          background: "transparent",
          boxShadow: "none",
        },
      }}
      {...rest}
    >
      <FlexRow sx={{ borderRadius: 6.25, background: "#282C46", px: 1.25 }}>
        {Object.keys(reactionsPack).map((item, index) => {
          return (
            <ButtonBase
              key={item}
              onClick={(e) => {
                e.stopPropagation();
                handleClose(e);
                handleReaction(stringFromReaction(item));
              }}
              sx={{
                padding: 1.25,
                borderRadius: 30,
                fontSize: 16,
                ":active": {
                  background: "rgba(255, 255, 255, 0.1)",
                },
                ":hover": {
                  background: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              {item}
            </ButtonBase>
          );
        })}
      </FlexRow>
    </Popover>
  );
};

export default ReactionPopover;
