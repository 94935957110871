import { createAction } from "redux-actions";
import { START } from "../common";

export const GET_NFT_CREATOR = "GET_NFT_CREATOR";
export const FETCH_NFT_TWEETS = "FETCH_NFT_TWEETS";
export const FETCH_NFT_COMMUNITY_FEEDS = "FETCH_NFT_COMMUNITY_FEEDS";
export const FOLLOW_NFT = "FOLLOW_NFT";

export const getNftCreatorAction = createAction(GET_NFT_CREATOR + START);
export const fetchNftTweetsAction = createAction(FETCH_NFT_TWEETS + START);
export const fetchNftCommunityFeedsAction = createAction(FETCH_NFT_COMMUNITY_FEEDS + START);
export const followNFTAction = createAction(FOLLOW_NFT + START);
