import { IsArray, IsBoolean, IsNotEmpty, IsOptional, IsString } from "class-validator";
import { SubCategory } from "../types";

export class UpdateStreamRecordDto {
  @IsString()
  @IsNotEmpty()
  public title: string;

  @IsOptional()
  @IsString()
  public description: string;

  @IsString()
  public thumbnail: string;

  @IsBoolean()
  public private: boolean;

  @IsOptional()
  @IsString()
  public language: string;

  @IsOptional()
  @IsArray()
  public categories: SubCategory[];
}
