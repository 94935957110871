import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Avatar, Button, GlobalStyles, SxProps, Typography } from "@mui/material";
import { ContentState, convertToRaw, DraftHandleValue, EditorState } from "draft-js";
import Editor, { PluginEditorProps } from "@draft-js-plugins/editor";
import createMentionPlugin, { MentionData, Popover } from "@draft-js-plugins/mention";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";
import createHashtagPlugin from "@draft-js-plugins/hashtag";
import createLinkifyPlugin, { extractLinks } from "@draft-js-plugins/linkify";
import axios, { CancelTokenSource } from "axios";

import editorStyles from "./editor.module.css";
import hashtagStyles from "./hashtagStyles.module.css";
import { FlexCol } from "../FlexCol";
import { apiClient } from "../../config";

defaultTheme.emojiSuggestions += " emojiSuggestions";
defaultTheme.emojiSuggestionsEntry += " emojiSuggestionsEntry";
defaultTheme.emojiSuggestionsEntryFocused += " emojiSuggestionsEntryFocused";
defaultTheme.emojiSuggestionsEntryText += " emojiSuggestionsEntryText";
defaultTheme.emojiSelect += " emojiSelect";
defaultTheme.emojiSelectButton += " emojiSelectButton";
defaultTheme.emojiSelectButtonPressed += " emojiSelectButtonPressed";
defaultTheme.emojiSelectPopover += " emojiSelectPopover";

let cancelToken: CancelTokenSource;

interface Props extends Partial<PluginEditorProps> {
  resetCreator?: number;
  containerStyle?: SxProps;
  onChangeDraftContent?: (value: string) => void;
  onChangeText?: (value: string) => void;
  onEnterKey?: () => void; // used for chat function
  initialMultiline?: boolean;
  showGifIcon?: boolean;
  onClickGiphy?: () => void;
  //
  initialEditorState?: EditorState;
  onChangeEditorState?: (value: EditorState) => void;
}

function BoltMentionInput({
  resetCreator,
  containerStyle,
  onChangeDraftContent = () => {},
  onChangeText = () => {},
  onEnterKey,
  initialMultiline = false,
  showGifIcon = false,
  onClickGiphy = () => {},
  //
  initialEditorState,
  onChangeEditorState = () => {},
  ...rest
}: Props): ReactElement {
  const ref = useRef<Editor>(null);
  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorState, setEditorState] = useState(() => initialEditorState || EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);

  useEffect(() => {
    onChangeEditorState(editorState);
    const editorData = convertToRaw(editorState.getCurrentContent());
    onChangeDraftContent(JSON.stringify(editorData));
    onChangeText(editorData?.blocks?.map((item) => item.text)?.join("\n"));
  }, [editorState]);

  useEffect(() => {
    if (resetCreator && !initialEditorState) {
      const newState = EditorState.push(editorState, ContentState.createFromText(""), "insert-characters");
      setEditorState(EditorState.moveFocusToEnd(newState));
    }
  }, [resetCreator, initialEditorState]);

  const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(mentionProps) {
        return <Typography className={`${mentionProps.className} mention`}>{mentionProps.children}</Typography>;
      },
      mentionPrefix: "@",
    });
    const linkifyPlugin = createLinkifyPlugin({
      target: "_blank",
      customExtractLinks: (text) => extractLinks(text),
    });

    const hashtagPlugin = createHashtagPlugin({
      theme: hashtagStyles,
    });

    const emojiPlugin = createEmojiPlugin({
      useNativeArt: true,
      theme: defaultTheme,
    });
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

    const { MentionSuggestions } = mentionPlugin;
    const plugins = [linkifyPlugin, mentionPlugin, hashtagPlugin, emojiPlugin];
    return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(async ({ value, trigger }: { trigger: string; value: string }) => {
    try {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      const { data } = await apiClient.get(`/search?type=user&query=${value || "*"}&page=1&limit=10`, {
        cancelToken: cancelToken.token,
      });
      const suggestData = data?.data?.map(
        ({ photoUrl, username, firstName, lastName }: any) => ({
          name: username,
          fullName: `${firstName} ${lastName}`,
          username,
          avatar: photoUrl,
          link: "",
        }),
        trigger
      );
      setSuggestions(suggestData);
    } catch (error) {
      setSuggestions([]);
    }
  }, []);

  const handleKeyCommand = (command: string, state: EditorState, t: number): DraftHandleValue => {
    if (command === "split-block" && !!onEnterKey) {
      onEnterKey();
      return "handled";
    }
    return "not-handled";
  };

  return (
    <FlexCol
      className={initialMultiline ? editorStyles.multilineEditor : editorStyles.editor}
      role="none"
      sx={containerStyle}
      onClick={() => ref.current!.focus()}
    >
      <GlobalStyles
        styles={{
          ".public-DraftEditor-content::-webkit-scrollbar": {
            width: 3,
            marginBlock: 2,
          },
          ".public-DraftEditor-content::-webkit-scrollbar-thumb": {
            backgroundColor: "silver",
          },
          ".public-DraftEditor-content::-webkit-scrollbar-track": {
            backgroundColor: "dimgrey",
            marginBlock: 2,
          },
        }}
      />
      <Editor
        {...rest}
        editorKey="editor"
        editorState={editorState}
        onChange={setEditorState}
        handleKeyCommand={handleKeyCommand}
        plugins={plugins}
        ref={ref}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        entryComponent={({ mention, theme, ...rest }) => (
          <div {...rest} className={`${rest.className} entry-row`}>
            <div className={theme?.mentionSuggestionsEntryAvatar}>
              <Avatar sx={{ width: 20, height: 20 }} src={mention.avatar} />
            </div>
            <div className={theme?.mentionSuggestionsEntryContainer}>
              <Typography sx={{ ml: 0.5 }}>{mention.username}</Typography>
            </div>
          </div>
        )}
        popoverContainer={({ children, popperOptions, store, theme }) =>
          Popover({
            store,
            theme: {},
            popperOptions,
            children: (
              <FlexCol className="popover" sx={{ cursor: "pointer" }}>
                {children}
              </FlexCol>
            ),
          })
        }
      />
      <EmojiSuggestions />
      <EmojiSelect closeOnEmojiSelect />
      {showGifIcon && (
        <Button
          className="chat-gif-icon"
          size="small"
          onClick={onClickGiphy}
          sx={{
            borderStyle: "solid",
            height: 18,
            minWidth: 40,
            pl: 0,
            pr: 0,
            borderColor: "white",
            borderWidth: 1,
            color: "white",
            fontSize: 12,
            position: "absolute",
            right: 8,
          }}
        >
          GIF
        </Button>
      )}
    </FlexCol>
  );
}

export default BoltMentionInput;
