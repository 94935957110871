import React, { useEffect, useState } from "react";
import { ListItem, ListItemText, useTheme } from "@mui/material";

import { SettingsPanelLayout } from "../../layouts";
import { UserSettings } from "../../types";
import { SettingSwitch } from "./SettingSwitch";

type EmailSettings = {
  id: string;
  title: string;
  subTitle: string;
};

const emailSettings: EmailSettings[] = [
  { id: "emailFeedComment", title: "New Comments", subTitle: "New comments on your posts" },
  { id: "emailFeedPost", title: "New Posts", subTitle: "New posts by the people you follow" },
  { id: "emailFeedTags", title: "New Mentions/Tags", subTitle: "New mentions or tags of you in posts" },
  { id: "emailStreamingLive", title: "New Live Streams", subTitle: "New live streams by the people you follow" },
];

const emailSettingIDs = emailSettings.map((v) => v.id);

type Props = {
  settings: UserSettings | null;
  updateSettings: Function;
};

export const EmailSettingsPage = ({ settings, updateSettings }: Props) => {
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    if (settings) {
      const keys = Object.keys(settings);
      const activeSettings = keys.filter((k) => settings[k as keyof UserSettings] === true);
      setSelectedOptions(activeSettings);
    } else {
      setSelectedOptions(emailSettingIDs);
    }
  }, [settings]);

  const handleToggle = (value: string) => () => {
    const currentIndex = selectedOptions.indexOf(value);
    const newChecked = [...selectedOptions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedOptions(newChecked);

    const updatedSetting = {} as UserSettings;
    emailSettingIDs.forEach((id) => {
      updatedSetting[id as keyof UserSettings] = newChecked.includes(id);
    });
    updateSettings(updatedSetting);
  };

  return (
    <SettingsPanelLayout title="Send emails to be me about..">
      {emailSettings.map(({ id, title, subTitle }, index) => {
        return (
          <ListItem key={index}>
            <ListItemText
              id="switch-list-label-wifi"
              primary={title}
              primaryTypographyProps={{
                color: "#A3AED0",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
              }}
              secondary={subTitle}
              secondaryTypographyProps={{
                color: "rgba(163, 174, 208, 0.4)",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "18px",
                mt: "7px    ",
              }}
            />
            <SettingSwitch
              onChange={handleToggle(id)}
              checked={selectedOptions.includes(id)}
              inputProps={{
                "aria-labelledby": `switch-list - label - ${id}`,
              }}
            />
          </ListItem>
        );
      })}
    </SettingsPanelLayout>
  );
};
