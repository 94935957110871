import React, { useState } from "react";
import { GlobalStyles, Typography } from "@mui/material";

import { CustomTab, CustomTabs, FlexCol, FlexRow, ScrollToTopButton } from "../../../components";
import { NAV_BAR_HEIGHT } from "../../../constants";
import { ReactComponent as AnalyticColorIcon } from "../../../assets/svgs/users/analytic.svg";
import AnalyticsOverview from "./AnalyticsOverview";
import AnalyticsFollowers from "./AnalyticsFollowers";
import AnalyticsPost from "./AnalyticsPost";
import AnalyticsLiveStream from "./AnalyticsLiveStream";

enum AnalyticsType {
  "OVERVIEW" = "Overview",
  "FOLLOWERS" = "Followers",
  "POSTS" = "Posts",
  "LIVE_STREAM" = "Live Stream",
}

const tabMenus = [
  { title: "Overview", key: AnalyticsType.OVERVIEW },
  { title: "Followers", key: AnalyticsType.FOLLOWERS },
  { title: "Posts", key: AnalyticsType.POSTS },
  { title: "Live Stream", key: AnalyticsType.LIVE_STREAM },
];

export const AnalyticsPage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <FlexCol
      sx={{
        minWidth: "100%",
        py: { xs: 2, sm: 3, md: 4, lg: 5 },
        px: { xs: 1, sm: 3, md: 3, lg: 7 },
      }}
    >
      <GlobalStyles styles={{ ".css-6nevci-MuiTabs-indicator, .css-1lkv59c": { display: "none !important" } }} />
      <FlexCol
        sx={{
          alignItems: { xs: "flex-start", sm: "center" },
          flexDirection: { xs: "column", sm: "row" },
          mt: { xs: 2 },
        }}
      >
        <FlexRow sx={{ alignItems: "center", ml: { xs: 1, sm: 0 } }}>
          <AnalyticColorIcon width={26} height={14} />
          <Typography fontWeight="600" sx={{ ml: 2, fontSize: { xs: 20, sm: 24 }, mr: { xs: 0, sm: 5.5 } }}>
            Analytics
          </Typography>
        </FlexRow>
        <CustomTabs value={tabIndex} onChange={handleChange} sx={{ mt: 0, mb: 0 }}>
          {tabMenus.map((item, index) => (
            <CustomTab label={item.title} key={index} />
          ))}
        </CustomTabs>
      </FlexCol>
      {tabIndex === 0 && <AnalyticsOverview />}
      {tabIndex === 1 && <AnalyticsFollowers />}
      {tabIndex === 2 && <AnalyticsPost />}
      {tabIndex === 3 && <AnalyticsLiveStream />}
      <ScrollToTopButton />
    </FlexCol>
  );
};
