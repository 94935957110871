import React from "react";
import { Avatar, Box, IconButton, Paper, Typography } from "@mui/material";
import moment from "moment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import TwitterIcon from "@mui/icons-material/Twitter";
import ReplyIcon from "@mui/icons-material/Reply";
import TwitterText from "twitter-text";

import { FlexCol, FlexRow, LinkPreview } from "..";
import { DefaultAvatar } from "../DefaultAvatar";
import { TwitterFeed, TwitterUser } from "../../types";
import { Formatter } from "../../utils";

interface TweetCardProps {
  item: TwitterFeed;
  user: TwitterUser | undefined;
}

export const TweetCard = ({ item, user }: TweetCardProps) => {
  const { entities: { urls } = {} } = item;
  const url = urls && urls.length && urls[0] && urls[0].expanded_url;

  return (
    <FlexCol component={Paper} sx={{ borderRadius: 4, mb: 2 }}>
      <FlexRow sx={{ alignItems: "center", pt: 2, px: 3 }}>
        <Box
          sx={{ display: "flex", alignItems: "center", textDecoration: "none" }}
          component="a"
          href={`https://twitter.com/${user?.username}`}
          target="_blank"
        >
          <Avatar
            src={user?.profile_image_url}
            alt={user?.name}
            sx={{
              borderColor: "primary.main",
              borderWidth: 2,
              borderStyle: "solid",
              height: 32,
              width: 32,
            }}
          >
            <DefaultAvatar />
          </Avatar>
          <Typography variant="subtitle1" color="primary.contrastText" sx={{ ml: 1.5, fontWeight: 600 }}>@{user?.username}</Typography>
        </Box>
        <Typography variant="subtitle2" sx={{ ml: "auto", fontSize: 12 }}>{moment(item.created_at).fromNow()}</Typography>
      </FlexRow>
      <FlexRow sx={{ my: 2, px: 3 }}>
        <Typography
          component="p"
          sx={{ a: { color: "#1d9bf0" } }}
          dangerouslySetInnerHTML={{ __html: TwitterText.autoLink(item?.text) }}
        />
      </FlexRow>
      <LinkPreview url={url} />
      <FlexRow sx={{ justifyContent: "center", py: 2 }}>
        <IconButton size="small" color="secondary" sx={{ mr: 8 }}>
          <ThumbUpIcon sx={{ color: "white", fontSize: 16 }} />
          {item?.public_metrics?.like_count! > 0 && (
            <Typography
              fontWeight="600"
              variant="tiny"
              color="primary.contrastText"
              sx={{ position: "absolute", left: "100%", top: 10 }}
            >
              {Formatter.formatCount(item?.public_metrics?.like_count!)}
            </Typography>
          )}
        </IconButton>
        <IconButton size="small" color="secondary" sx={{ mr: 8 }}>
          <TwitterIcon sx={{ color: "white", fontSize: 18 }} />
          {item?.public_metrics?.retweet_count! > 0 && (
            <Typography
              fontWeight="600"
              variant="tiny"
              color="primary.contrastText"
              sx={{ position: "absolute", left: "100%", top: 10 }}
            >
              {Formatter.formatCount(item?.public_metrics?.retweet_count!)}
            </Typography>
          )}
        </IconButton>
        <IconButton size="small" color="secondary">
          <ReplyIcon sx={{ color: "white", fontSize: 20 }} />
          {item?.public_metrics?.reply_count! > 0 && (
            <Typography
              fontWeight="600"
              variant="tiny"
              color="primary.contrastText"
              sx={{ position: "absolute", left: "100%", top: 10 }}
            >
              {Formatter.formatCount(item?.public_metrics?.reply_count!)}
            </Typography>
          )}
        </IconButton>
      </FlexRow>
    </FlexCol>
  );
};
