import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";
import { StreamCategory, StreamSubCategory } from "../../types";

export interface CategoryState {
  categories: { items: StreamCategory[]; loading: boolean };
  subCategories: { [category: string]: { items: StreamSubCategory[]; loading: boolean } };
}

const initialState: CategoryState = {
  categories: { items: [], loading: false },
  subCategories: {},
};

const reducer = handleActions<CategoryState, any>(
  {
    [actions.INIT_STREAM_CATEGORIES]: (state) => initialState,
    [actions.FETCH_STREAM_CATEGORIES + START]: (state) =>
      produce(state, (draft) => {
        draft.categories.loading = true;
      }),
    [actions.FETCH_STREAM_CATEGORIES + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.categories.loading = false;
        draft.categories.items = payload;
      }),
    [actions.FETCH_STREAM_CATEGORIES + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.categories.loading = true;
      }),
    [actions.FETCH_STREAM_SUB_CATEGORIES + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.subCategories = {
          ...state.subCategories,
          [payload?.id]: { ...state.subCategories?.[payload?.id], loading: true },
        };
      }),
    [actions.FETCH_STREAM_SUB_CATEGORIES + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.subCategories = {
          ...state.subCategories,
          [payload?.id]: { items: payload?.data, loading: true },
        };
      }),
    [actions.FETCH_STREAM_SUB_CATEGORIES + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.subCategories = {
          ...state.subCategories,
          [payload?.id]: { loading: false },
        };
      }),
  },
  initialState
);

export default reducer;
