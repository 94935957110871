import { SVGProps } from "react";

export const ListenSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="currentColor"
        d="M10.002 8.961a1.924 1.924 0 00-1.924 1.924c0 .615.308 1.154.77 1.492v7.74h2.308v-7.74c.461-.354.77-.877.77-1.492a1.924 1.924 0 00-1.924-1.924z"
      ></path>
      <path
        fill="currentColor"
        d="M10.002 4.73a6.153 6.153 0 00-5.088 9.615c.358.529 1.1.527 1.552.076.449-.45.43-1.173.134-1.734a3.848 3.848 0 015.536-4.996 3.847 3.847 0 011.713 3.194c0 .646-.16 1.252-.44 1.784-.299.563-.319 1.29.132 1.74.45.45 1.191.454 1.55-.072a6.114 6.114 0 001.066-3.452c0-3.4-2.755-6.155-6.155-6.155z"
      ></path>
      <path
        fill="currentColor"
        d="M10.002.883A10.002 10.002 0 000 10.885c0 2.754 1.13 5.231 2.93 7.047 1.237.582 2.2-.059 1.625-1.6a7.727 7.727 0 01-2.247-5.447 7.696 7.696 0 017.694-7.694 7.697 7.697 0 017.693 7.694A7.654 7.654 0 0115.45 16.3c-.703 1.252.58 2.213 1.646 1.631A10.003 10.003 0 0010.002.882z"
      ></path>
    </svg>
  );
};
