import React, { useEffect, useState } from "react";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TranslateIcon from "@mui/icons-material/Translate";
import PublicIcon from "@mui/icons-material/Public";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LockIcon from "@mui/icons-material/Lock";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import ReactPlayer from "react-player";

import { ModalProps, Typography, Paper, IconButton, Button, Grid, Divider, Box, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { BaseModal } from "../BaseModal";
import { StreamVideo } from "../../../types";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { ThumbnailCarousel } from "./components";
import { CustomSelector } from "../../CustomSelector";
import { defaultlanguage, languages } from "../../../constants";
import { UpdateStreamRecordDto } from "../../../dtos";
import { updateStreamerRecordAction } from "../../../store/streamer/actions";
import { LoadingView } from "../../LoadingView";
import { ToastService } from "../../../services";
import { SubCategorySelector } from "../../SubCategorySelector";

const resolver = classValidatorResolver(UpdateStreamRecordDto);

interface Props extends Partial<ModalProps> {
  item?: StreamVideo;
  onDelete?: () => void;
  onClose?: () => void;
}

export const VideoRecordEditModal = ({ children, item, onDelete = () => {}, onClose = () => {}, ...rest }: Props) => {
  const dispatch = useDispatch();
  const [newFile, setNewFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateStreamRecordDto>({
    resolver,
    defaultValues: {
      title: item?.title || "",
      description: item?.description || "",
      language: item?.language || defaultlanguage.value,
      thumbnail: item?.thumbnail,
      private: item?.private,
      categories: item?.categories || [],
    },
  });

  const onSubmit = (data: UpdateStreamRecordDto) => {
    setLoading(true);
    dispatch(
      updateStreamerRecordAction({
        id: item?.id,
        data: { ...data, categories: data?.categories?.map((item) => item.id) || [] },
        file: item?.thumbnails?.includes(data.thumbnail) ? undefined : newFile,
        onSuccess: () => {
          setLoading(false);
          onClose();
        },
        onFail: () => {
          setLoading(false);
        },
      })
    );
  };

  const onDownload = () => {
    ToastService.showInfoMessage("Coming soon.");
  };

  useEffect(() => {
    if (rest.open) {
      reset({
        title: item?.title || "",
        description: item?.description || "",
        language: item?.language || defaultlanguage.value,
        thumbnail: item?.thumbnail,
        private: item?.private,
        categories: item?.categories || [],
      });
      setNewFile(undefined);
      setLoading(false);
    }
  }, [rest.open, item]);

  return (
    <BaseModal
      {...rest}
      open={!!rest.open}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ maxHeight: "100vh", overflow: "hidden", position: "relative" }}
      >
        <Grid
          component={Paper}
          container
          sx={{
            borderRadius: 4,
            maxWidth: 976,
            maxHeight: "inherit",
            overflow: "scroll",
            color: "#A3AED0",
          }}
        >
          <Grid container item xs={12} alignItems="center" p={1}>
            <Grid item>
              <IconButton onClick={onClose}>
                <ArrowBack sx={{ color: "#A3AED0" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6" fontWeight="600">
                Edit Rewind Details
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} p={3} sx={{ backgroundColor: "secondary.dark" }}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box>
                      <ReactPlayer
                        url={item?.url! || undefined}
                        width="100%"
                        height="auto"
                        controls
                        style={{ backgroundColor: "black" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="caption" component="p">
                      Thumbnail
                    </Typography>
                    <Typography sx={{ fontSize: 10, mt: 1 }}>
                      Upload your image here. Recommended size is 1280 x 720 px (16:9) and the file format should be
                      either jpg or png.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src={watch().thumbnail}
                      alt="thumbnail"
                      style={{ width: "60%", borderRadius: 8, aspectRatio: "16/9", objectFit: "cover" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" component="p">
                      Drag to scroll through thumbnails
                    </Typography>
                    <Typography sx={{ fontSize: 10 }}>or upload your own</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ThumbnailCarousel
                      thumbnails={item?.thumbnails?.length ? item.thumbnails : [item?.thumbnail!]}
                      selected={watch().thumbnail}
                      onSelect={(value) => setValue("thumbnail", value)}
                      onSelectNewFile={(file) => file && setNewFile(file)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="caption">Rewind Title</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ mt: 1, bgcolor: "#282C46" }}
                      InputProps={{ sx: { fontSize: 12 } }}
                      {...register("title")}
                      error={!!errors.title}
                      helperText={errors.title?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">Rewind Description</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      multiline
                      minRows={4}
                      sx={{ mt: 1, bgcolor: "#282C46" }}
                      InputProps={{ sx: { fontSize: 12 } }}
                      {...register("description")}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  </Grid>
                  <Grid item container rowSpacing={1}>
                    <Grid item xs={12} alignItems="center" display="flex">
                      <TranslateIcon sx={{ fontSize: 14, color: "white", mr: 0.5 }} />
                      <Typography variant="caption">Language</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomSelector
                        value={languages.find((vItem) => vItem.value === watch().language)}
                        options={languages}
                        onClick={(language) => setValue("language", language.value)}
                        sx={{ borderRadius: 1 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container rowSpacing={1}>
                    <Grid item xs={12} alignItems="center" display="flex">
                      <VisibilityOutlinedIcon sx={{ fontSize: 14, color: "white", mr: 0.5 }} />
                      <Typography variant="caption">Visibility</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        size="small"
                        color={!watch().private ? "primary" : "secondary"}
                        sx={{ bgcolor: !watch().private ? "" : "secondary.light", fontSize: 12 }}
                        startIcon={<PublicIcon fontSize="small" />}
                        onClick={() => setValue("private", false)}
                      >
                        Public
                      </Button>
                      <Button
                        variant="contained"
                        color={watch().private ? "primary" : "secondary"}
                        size="small"
                        sx={{ bgcolor: watch().private ? "" : "secondary.light", ml: 1, fontSize: 12 }}
                        startIcon={<LockIcon sx={{ fontSize: 10 }} />}
                        onClick={() => setValue("private", true)}
                      >
                        Private
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px", ml: 1 }}
                    >
                      Category
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SubCategorySelector
                      selectedItems={watch().categories}
                      mounted={!!rest?.open}
                      onChange={(values) => setValue("categories", values)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ width: "100%" }} />
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            sx={{ backgroundColor: "secondary.dark" }}
            p={2}
            pt={0}
            spacing={2}
            mt={0}
          >
            <Grid item xs={6} sm={3} md={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ borderRadius: 40, fontWeight: 600, lineHeight: 1.6, bgcolor: "secondary.light" }}
                startIcon={<DeleteIcon width={12} fill="#EB5757" />}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                startIcon={<SaveAltIcon width={12} />}
                sx={{ borderRadius: 40, fontWeight: 600, lineHeight: 1.6, bgcolor: "secondary.light" }}
                onClick={onDownload}
              >
                Download
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button
                fullWidth
                variant="contained"
                sx={{ borderRadius: 40, lineHeight: 1.6, fontWeight: 600 }}
                type="submit"
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <LoadingView visible={loading} sx={{ position: "absolute", zIndex: 9999999 }} />
      </Box>
    </BaseModal>
  );
};
