import axios, { CancelTokenSource } from "axios";
import { all, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../config";
import { RootState } from "../../types";
import { CANCEL, FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

let cancelSource = axios.CancelToken.source();

function* fetchSearch({ payload }: any) {
  const { query = "", page = 1 } = payload;
  if (!query) {
    yield put({
      type: actions.FETCH_SEARCH + CANCEL,
    });
    return;
  }
  const { search }: RootState = yield select();

  if (typeof cancelSource !== typeof undefined) {
    cancelSource.cancel("Operation canceled due to new request.");
  }
  cancelSource = axios.CancelToken.source();

  try {
    const { selectedType: type } = search;
    const { data } = yield apiClient.get(`/search?type=${type}&query=${query}&page=${page}&limit=50`, {
      cancelToken: cancelSource.token,
    });
    yield put({
      type: actions.FETCH_SEARCH + SUCCESS,
      payload: { ...data, type },
    });
  } catch (error: any) {
    yield put({
      type: actions.FETCH_SEARCH + FAIL,
      payload: error.response.data,
    });
  }
}

export default function* searchSaga() {
  yield all([takeLatest([actions.FETCH_SEARCH + START], fetchSearch)]);
}
