import React, { useState } from "react";
import moment from "moment";
import { Box, Button, IconButton, Tab, TabProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";

import { ReactComponent as DebitIcon } from "../../assets/svgs/studio/debit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgs/studio/delete.svg";
import { ReactComponent as PencilIcon } from "../../assets/svgs/studio/pencil.svg";
import { FlexCol, FlexRow } from "../../components";
import { StatusLabel } from "../user/EarningsPage/components";


const ActionTab = ({ label, sx, ...other }: TabProps) => (
  <Tab
    label={label}
    sx={{ minWidth: 0, color: "#a3aed080", textTransform: 'capitalize', '&.Mui-selected': { color: '#A3AED0', backgroundColor: '#1E2034' }, ...sx }}
    {...other}
  />
);

export const ActionControls = () => {

  const [typeIndex, setTypeIndex] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTypeIndex(newValue);
  };

  const getColorFlag = (index: number) => {
    const divided = index % 3;
    if (divided === 0) return "succeeded";
    if (divided === 1) return "pending";
    return "canceled";
  }

  const handlePoll = (index: number) => { }
  const editItem = (index: number) => { }
  const deleteItem = (index: number) => { }

  return (
    <FlexCol sx={{ width: "100%", height: "100%" }}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        sx={{ width: 'fit-content', minHeight: 20, '& .MuiTab-root': { px: { xs: 1.5, sm: 3 }, py: 0.5, minHeight: 20 } }}
        value={typeIndex}
        onChange={handleTabChange}
      >
        <ActionTab label="Polls" />
        <ActionTab label="Donate" />
      </Tabs>
      <FlexCol sx={{ width: "100%", flexGrow: 1 }} height='50px'>
        <FlexCol className="-webkit-chatbox-scrollbar" sx={{ display: typeIndex === 0 ? "flex" : "none", width: "100%", minHeight: 50, flexGrow: 1, overflowY: 'auto', backgroundColor: "#1E2034" }}>
          <Box sx={{ flexWrap: "wrap", display: "flex" }}>
            {[...Array(9)].map((row, index) => (
              <FlexCol
                key={index}
                sx={{ width: "50%", px: 1.5, pt: 1.5, borderRight: (theme) => `1px solid ${theme.palette.divider}`, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
              >
                <Typography sx={{ fontSize: 10, color: "#FFFFFF80", letterSpacing: 3, }}>QUESTION #{index + 1}</Typography>
                <Typography sx={{ fontSize: 14, color: "white", fontWeight: 600 }}>Do you wanna see more of this BAYC Bolties?</Typography>

                <FlexRow sx={{ mt: 1.5 }}>
                  <FlexCol sx={{ flex: 1, mr: 1, borderRadius: 2, overflow: "hidden", position: "relative" }}>
                    <FlexCol sx={{ background: "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)", width: "70%", height: 27 }} />
                    <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600, position: "absolute", left: 8, top: 4.5 }}>YESSSSS!!! 😍</Typography>
                  </FlexCol>
                  <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600 }}>70%</Typography>
                </FlexRow>

                <FlexRow sx={{ mt: 0.8 }}>
                  <FlexCol sx={{ flex: 1, mr: 1, borderRadius: 2, overflow: "hidden", position: "relative" }}>
                    <FlexCol sx={{ bgcolor: "#343648", width: "20%", height: 27 }} />
                    <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600, position: "absolute", left: 8, top: 4.5 }}>Yes 👍</Typography>
                  </FlexCol>
                  <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600 }}>20%</Typography>
                </FlexRow>

                <FlexRow sx={{ mt: 0.8 }}>
                  <FlexCol sx={{ flex: 1, mr: 1, borderRadius: 2, overflow: "hidden", position: "relative" }}>
                    <FlexCol sx={{ bgcolor: "#343648", width: "10%", height: 27 }} />
                    <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600, position: "absolute", left: 8, top: 4.5 }}>No 👎</Typography>
                  </FlexCol>
                  <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600 }}>10%</Typography>
                </FlexRow>

                <FlexRow sx={{ mt: 2, mb: 2, gap: 1 }}>
                  <Button sx={{ bgcolor: index % 2 === 0 ? "#3C436F" : "#6535E9", borderRadius: 20, color: index % 2 === 0 ? "#EB5757" : "white", fontWeight: 600, fontSize: 13, py: 0.2 }} variant="contained" onClick={() => handlePoll(index)}>
                    {index % 2 === 0 ? "Stop Poll" : "Start Poll"}
                  </Button>
                  <IconButton sx={{ bgcolor: "#3C436F", borderRadius: 20, color: "#EB5757", fontWeight: 600, fontSize: 14, py: 0.2, width: 50 }} onClick={() => editItem(index)}>
                    <PencilIcon />
                  </IconButton>
                  <IconButton sx={{ bgcolor: "#3C436F", borderRadius: 20, color: "#EB5757", fontWeight: 600, fontSize: 14, py: 0.2, width: 50 }} onClick={() => deleteItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </FlexRow>
              </FlexCol>
            ))}
          </Box>
        </FlexCol>
        <FlexCol sx={{ display: typeIndex === 1 ? "flex" : "none", pb: 0.5, width: "100%", minHeight: 50, flexGrow: 1, overflowY: 'auto', backgroundColor: "#1E2034" }}>
          <FlexRow sx={{ alignItems: "center", p: 1 }}>
            <DebitIcon />
            <Typography sx={{ color: "#A3AED0", fontSize: 16, fontWeight: 600, ml: 1 }}>Debit History</Typography>
          </FlexRow>
          <TableContainer className="-webkit-chatbox-scrollbar">
            <Table stickyHeader aria-label="Debit History table">
              <TableHead>
                <TableRow sx={{ '& th': { color: '#A3AED0', fontSize: 12, py: 1, bgcolor: "#2A2B3E" } }}>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">User</TableCell>
                  <TableCell>Bolts</TableCell>
                  <TableCell align="center">Payment Method</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(10)].map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th, & td': { py: 1.2, border: 0, borderTopWidth: index === 0 ? 0 : 0.5, borderTopColor: "#ffffff1a" } }}>
                    <TableCell width={160} sx={{ color: "#EFEFEFC0", fontSize: 12, minWidth: 120 }}>{moment(1680889385000).format("DD MMM, YYYY, h:mm A")}</TableCell>
                    <TableCell align="center">Zhang Tao</TableCell>
                    <TableCell width={80}>
                      <FlexRow sx={{ bgcolor: "#1E2034", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: 600 }}>$10</Typography>
                      </FlexRow>
                    </TableCell>
                    <TableCell align="center">your earning</TableCell>
                    <TableCell align="center" width={140}><StatusLabel status={getColorFlag(index)} sx={{ height: 22 }} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FlexCol>
      </FlexCol >
    </FlexCol >
  );
};