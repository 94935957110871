import React from "react";
import { Avatar, Button, SxProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import { motion, useAnimation, useSpring, Variants } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { debounce } from "lodash";
import { isMobile } from "react-device-detect";

import { FlexCol, FlexRow } from "../../../components";
import { Channel } from "../../../types";

type Props = {
  channel: Channel;
  setActiveChannel?: (channel: Channel) => void;
  onClick: (active: boolean, channel: Channel) => void;
  style?: SxProps;
};

const boxVariant: Variants = {
  max: { opacity: 1, scale: 1, originX: 0, originY: 0 },
  min: { opacity: 0.4, scale: 0.8, originX: 0.025, originY: 0.5 },
};

const titleVariants: Variants = {
  max: { opacity: 1, scaleX: 1, originX: 0 },
  min: { opacity: 0, scaleX: 0, originX: 0 },
};

const buttonVariants: Variants = {
  max: { opacity: 1 },
  min: { opacity: 0 },
};

const avatarVriants: Variants = {
  max: { borderColor: "white" },
  min: { borderColor: "transparent" },
};

export const ChannelRow = ({ channel, style, setActiveChannel = () => {}, onClick = () => {} }: Props) => {
  const theme = useTheme();
  const isSmallSize = useMediaQuery(theme.breakpoints.down("sm"));
  const opacityControl = useAnimation();
  const circleControl = useAnimation();
  const [ref, inView] = useInView({
    root: document.getElementById("scroll-element"),
    rootMargin: isMobile ? "-75% 0px -20% 0px" : "-85% 0px -15% 0px",
  });

  React.useEffect(() => {
    opacityControl.start(inView ? "max" : "min");
    circleControl.start(inView ? "max" : "min");
  }, [opacityControl, circleControl, inView]);

  const debuouncedSetActiveChannel = React.useCallback(
    debounce(
      () => {
        setActiveChannel(channel);
      },
      500,
      { leading: false, trailing: true }
    ),
    [channel]
  );

  React.useEffect(() => {
    if (inView) {
      debuouncedSetActiveChannel();
    }
  }, [debuouncedSetActiveChannel, inView]);

  return (
    <FlexRow
      ref={ref}
      sx={{
        cursor: "pointer",
        userSelect: "none",
        width: "100%",
        px: 4,
        justifyContent: "space-between",
        alignItems: "center",
        ...style,
      }}
      onClick={() => {
        onClick(false, channel);
      }}
    >
      <motion.div
        variants={boxVariant}
        initial="min"
        animate={opacityControl}
        style={{ display: "flex", position: "relative", flexShrink: 0 }}
      >
        <motion.div
          variants={avatarVriants}
          animate={circleControl}
          initial="min"
          style={{ borderRadius: "100%", padding: 1, borderWidth: 3, borderStyle: "solid" }}
        >
          <Avatar
            variant="circular"
            src={channel.logo}
            sx={{
              width: { xs: 50, sm: 90 },
              height: { xs: 50, sm: 90 },
              objectFit: "contain",
              pointerEvents: "none",
              backgroundColor: "white",
            }}
          />
        </motion.div>
        <FlexCol justifyContent="center" sx={{ ml: 2 }}>
          <motion.div variants={titleVariants} initial="min" animate={opacityControl}>
            <Typography sx={{ fontSize: { xs: 14, sm: 34 } }} fontWeight={600}>
              {channel.title}
            </Typography>
            <Typography sx={{ fontSize: { xs: 14, sm: 16 }, textTransform: "capitalize" }}>
              {channel.tags && channel.tags[0] ? channel.tags[0] : "Live"}
            </Typography>
          </motion.div>
        </FlexCol>
      </motion.div>
      <motion.div
        variants={buttonVariants}
        animate={opacityControl}
        initial="min"
        style={{ display: "flex", alignItems: "center", height: "100%", marginRight: isSmallSize ? 0 : 10 }}
      >
        <Button
          variant="contained"
          color="darker"
          startIcon={<ArrowOutwardIcon />}
          sx={{
            height: 40,
            backgroundColor: "#ffffffc0",
            color: "black",
            fontWeight: 600,
            fontSize: { xs: 10, sm: 14 },
          }}
          onClick={() => onClick(inView, channel)}
          onTouchEndCapture={() => onClick(inView, channel)}
        >
          Go to Channel
        </Button>
      </motion.div>
    </FlexRow>
  );
};
