import { PrivateNavbarContextProvider } from "./PrivateNavbarContext";
import { PrivateNavbarUi } from "./PrivateNavbarUi";

export const PrivateNavbar = () => {
  return (
    <PrivateNavbarContextProvider>
      <PrivateNavbarUi />
    </PrivateNavbarContextProvider>
  );
};
