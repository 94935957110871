import React from "react";
import { Button, Grid, ModalProps, Typography } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

import { useWithdraw } from "../../../hooks/WithdrawContext";
import { WithdrawModalType } from "../../../types";
import { BaseWithdrawModal } from "./BaseWithdrawModal";
import { FlexCol } from "../../FlexCol";
import { FlexRow } from "../../FlexRow";

interface Props extends Partial<ModalProps> {}

export const WithdrawSuccessModal = ({ ...rest }: Props) => {
  const { values, setValues, resetContext } = useWithdraw();

  return (
    <BaseWithdrawModal
      {...rest}
      showTitle={false}
      containerStyle={{ width: 375 }}
      open={values.modal === WithdrawModalType.WITHDRAW_SUCCESS}
      onClose={() => setValues({ modal: undefined })}
    >
      <FlexCol alignItems="center" sx={{ pb: 2, mt: -2 }}>
        <FlexCol sx={{ backgroundColor: "#27AE60", borderRadius: "100%" }}>
          <CheckRoundedIcon sx={{ color: "white", borderRadius: "100%", width: 50, height: 50 }} />
        </FlexCol>
        <Typography variant="h6" sx={{ color: "#A3AED0", fontWeight: 600, mt: 2 }}>
          Withdrawal Successful
        </Typography>
        <Grid container sx={{ px: 4, mt: 2 }} rowSpacing={1}>
          <Grid item container>
            <Grid item xs={6}>
              <Typography variant="caption" sx={{ color: "#ffffff50" }}>
                Receiver
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="caption" fontWeight={600}>
                {values.binanceInfo.value}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={6}>
              <Typography variant="caption" sx={{ color: "#ffffff50" }}>
                Withdrawn amount
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="caption" fontWeight={600}>
                ${values.withdrawAmount}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={6}>
              <Typography variant="caption" sx={{ color: "#ffffff50" }}>
                Txn no.
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="caption" fontWeight={600}>
                {" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <FlexRow justifyContent="space-between" alignItems="center" sx={{ mt: 3, px: 4, width: "100%" }}>
          <Button
            variant="contained"
            sx={{ borderRadius: 40, height: 30, flex: 0.6, fontSize: 12, fontWeight: 600 }}
            onClick={resetContext}
          >
            View Transaction
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 40,
              height: 30,
              flex: 0.3,
              fontSize: 12,
              backgroundColor: "#ffffff30",
              fontWeight: 600,
            }}
            onClick={() => {
              resetContext();
            }}
          >
            Close
          </Button>
        </FlexRow>
      </FlexCol>
    </BaseWithdrawModal>
  );
};
