import { Box } from "@mui/material";

import { usePrivateLayoutContext } from "../../layouts";

export const Overlay = () => {
  const { navbar__height, leftMenubar__width } = usePrivateLayoutContext();

  return (
    <Box
      sx={{
        position: "fixed",
        top: navbar__height,
        left: { xs: 0, md: leftMenubar__width },
        right: 0,
        bottom: 0,
        background: {
          xs: "linear-gradient(180deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.8) 30%, rgba(255,255,255,0) 100%)",
          md: "linear-gradient(90deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.8) 30%, rgba(255,255,255,0) 100%)",
        },
      }}
    />
  );
};
