import React from "react";
import { Grid, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WebsiteIcon from "@mui/icons-material/LanguageOutlined";

import { ReactComponent as DiscordIcon } from "../../assets/svgs/discord.svg";
import { FlexCol } from "../FlexCol";

type AboutItemProps = {
  label?: string;
  link?: string;
  icon: React.ReactNode;
};

const AboutItem = ({ label, link, icon }: AboutItemProps) => {
  if (label === "" || label === null || label === undefined) return null;

  return (
    <Grid item container columnSpacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item>
        <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
          <Typography variant="caption" sx={{ color: "#FFFFFF80" }}>
            {label}
          </Typography>
        </a>
      </Grid>
    </Grid>
  );
};

type Props = {
  content?: string;
  twitter?: string;
  instagram?: string;
  website?: string;
  discord?: string;
};

export const AboutTab = (props: Props) => {
  const { content, twitter, instagram, website, discord } = props;
  const websiteLink = `${website?.startsWith("http://") || website?.startsWith("https://") ? "" : "http://"}${website}`;

  return (
    <FlexCol sx={{ mb: 5, py: 2 }}>
      <Typography sx={{ mb: 3 }} variant="caption">
        {content}
      </Typography>
      <Grid container rowSpacing={2} sx={{ flexDirection: "column" }}>
        <AboutItem label={twitter} icon={<TwitterIcon />} link={`https://twitter.com/${twitter}`} />
        <AboutItem label={instagram} icon={<InstagramIcon />} link={`https://instagram.com/${instagram}`} />
        <AboutItem
          label={discord}
          icon={<DiscordIcon style={{ width: 25, height: 25 }} />}
          link={`https://discord.gg/${discord}`}
        />
        <AboutItem label={website} icon={<WebsiteIcon />} link={websiteLink} />
      </Grid>
    </FlexCol>
  );
};
