import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  IconButton,
  Grid,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import { StreamVideo } from "../../types";
import { ReactComponent as ShareIcon } from "../../assets/svgs/share2.svg";
import { ReactComponent as ThreeDotsIcon } from "../../assets/svgs/threedots.svg";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { ToastService } from "../../services";
import { userSelector } from "../../store/auth/selector";

interface Props {
  item: StreamVideo;
  onEdit?: () => void;
  onDelete?: () => void;
}

const allOptions = [
  { name: "Edit Details", Icon: () => <EditIcon fontSize="small" />, public: false },
  { name: "Share", Icon: () => <ShareIcon height={16} width={16} />, public: true },
  { name: "Delete", Icon: () => <DeleteIcon width={12} />, public: false },
];

export const VideoCard = ({ item, onEdit = () => {}, onDelete = () => {} }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);
  const [_, setSearchParams] = useSearchParams();

  const options = allOptions.filter((option) => (user?.id === item.user ? true : option.public));

  useEffect(() => {
    const handler = () => setAnchorEl(undefined);
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOtherMenu = (action: string) => {
    // Don't delete it, it will be integarate on API step

    switch (action) {
      case "Edit Details":
        onEdit();
        break;
      case "Share":
        navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}?rewind=${item?.id}`);
        ToastService.showInfoMessage("Copied the video link to clipboard!");
        break;
      case "Delete":
        onDelete();
        break;
      default:
        break;
    }

    setAnchorEl(undefined);
  };

  const playVideo = () => {
    setSearchParams({ rewind: item._id });
  };

  return (
    <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
      <Card variant="outlined" sx={{ backgroundColor: "transparent", borderWidth: 0 }}>
        <CardActionArea onClick={playVideo}>
          <CardMedia
            sx={{ borderRadius: 1, backgroundColor: "black" }}
            style={{ height: 0, paddingTop: "65%" }}
            image={item.thumbnail}
            title={item.title}
          >
            <Typography
              variant="body2"
              component="span"
              sx={{
                backgroundColor: "#282c4680",
                padding: "4px",
                right: "4px",
                bottom: "4px",
                position: "absolute",
                borderRadius: 0.5,
              }}
            >
              {moment.utc(item.duration * 1000).format(item.duration >= 3600 ? "HH:mm:ss" : "mm:ss")}
            </Typography>
          </CardMedia>
        </CardActionArea>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingLeft: 0.5,
            paddingRight: 0,
            paddingTop: 1,
          }}
          style={{ paddingBottom: 0 }}
        >
          <Box sx={{ flex: 1, overflow: "hidden" }}>
            <Typography variant="subtitle2" display="block" sx={{ fontWeight: 600, mb: 0.3 }} noWrap>
              {item.title}
            </Typography>
            <Typography variant="caption" display="block" sx={{ lineHeight: 0.8, marginBottom: 0 }}>
              {item.views} {item.views > 1 ? "Views" : "View"}
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ lineHeight: 0.8, marginBottom: 0 }}>
              {moment(item.createdAt).fromNow(false)}
            </Typography>
          </Box>
          <IconButton aria-label="menu" size="small" onClick={(e) => handleClick(e)}>
            <ThreeDotsIcon height={18} width={18} />
          </IconButton>
        </CardContent>
      </Card>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(undefined)}
        disableScrollLock
        onClick={(e) => e.stopPropagation()}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {options.map(({ name, Icon }, index) => (
          <MenuItem key={index} onClick={() => handleOtherMenu(name)}>
            {name}{" "}
            <ListItemIcon style={{ paddingLeft: "12px" }}>
              <Icon />
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};
