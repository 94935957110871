import React from "react";
import { Box, Paper, Skeleton, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { FlexRow } from "../../components";
import CommentSkeleton from "./CommentSkeleton";

type Props = {};

const FeedSkeleton = (props: Props) => {
  return (
    <Stack>
      <Box sx={{ pb: 2 }}>
        <Box component={Paper} sx={{ borderRadius: 2.5, mb: { xs: 20, sm: 0 } }}>
          <Stack direction="column">
            <FlexRow sx={{ alignItems: "center", pt: 2, pl: 3, pr: 1.25 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                <Skeleton
                  variant="circular"
                  animation="wave"
                  sx={{
                    height: 32,
                    width: 32,
                  }}
                />
                <Skeleton variant="text" animation="wave" sx={{ width: 150, fontSize: "18px", ml: 1.5 }} />
              </Box>
              <Skeleton variant="text" animation="wave" sx={{ width: 50, fontSize: "10px", ml: "auto" }} />
              <FlexRow sx={{ p: 0.5, ml: 1.5 }}>
                <MoreVertIcon />
              </FlexRow>
            </FlexRow>
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{ height: 300, width: "100%", borderRadius: "16px", mt: 2.25, mb: 1.25 }}
            />
            <FlexRow sx={{ px: 3, py: 1, justifyContent: "space-between" }}>
              <FlexRow
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1.5,
                }}
              >
                <Skeleton variant="circular" animation="wave" style={{ height: 22, width: 22 }} />
                {["", "", ""].map((item, index) => {
                  return (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      animation="wave"
                      style={{ height: 22, width: 42, borderRadius: 42 }}
                    />
                  );
                })}
              </FlexRow>
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  height: 22,
                  width: 50,
                  px: 1.2,
                  py: 0.5625,
                  borderRadius: "4px",
                }}
              />
            </FlexRow>
            <FlexRow
              sx={{
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
                py: 0.5,
                borderTop: "0.25px solid rgba(255, 255, 255, 0.05)",
                borderBottom: "0.25px solid rgba(255, 255, 255, 0.05)",
              }}
            >
              {["", "", "", ""].map((item, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  animation="wave"
                  style={{ height: 28, width: 28, borderRadius: 8 }}
                />
              ))}
            </FlexRow>
            <Stack direction="column" sx={{ px: 2.875, marginTop: 3, marginBottom: 3, gap: 2.25 }}>
              <CommentSkeleton />
              <CommentSkeleton />
              <CommentSkeleton />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default FeedSkeleton;
