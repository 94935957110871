import { IconButton, useTheme } from "@mui/material";
import { TrendingUpRounded } from "@mui/icons-material";

import { usePrivateNavbarContext } from "../PrivateNavbarContext";

export const TrendsListButton = () => {
  const theme = useTheme();

  const { updateContext: updateNavbarContext } = usePrivateNavbarContext();

  return (
    <IconButton
      sx={{
        width: 36,
        height: 36,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: theme.palette.secondary.dark,

        "&:hover": {
          bgcolor: theme.palette.secondary.light,
        },
      }}
      onClick={() => {
        updateNavbarContext({ activeDrawer: "trends" });
      }}
    >
      <TrendingUpRounded />
    </IconButton>
  );
};
