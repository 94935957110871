import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { Avatar, Box, Button, Grid, IconButton, LinearProgress, ModalProps, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { BaseModal } from "./BaseModal";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";
import { ReactComponent as ImageIcon } from "../../assets/svgs/image.svg";
import { ReactComponent as BoltIcon } from "../../assets/svgs/boltIcon.svg";
import { userSelector } from "../../store/auth/selector";
import { createStreamFeed } from "../../store/feed/actions";
import { streamFeedSelector } from "../../store/feed/selector";
import { uploadProgressSelector } from "../../store/ui/selector";
import { getVideoCover } from "../../utils";
import { ToastService } from "../../services";
import { DefaultAvatar } from "../DefaultAvatar";
import BoltMentionInput from "../BoltMentionInput";
import { LoadingView } from "../LoadingView";

interface Props extends Partial<ModalProps> {
  open: boolean;
  onClose: () => void;
}

export const QuickPostCreateModal = ({ open, onClose, ...props }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const uploadProgress = useSelector(uploadProgressSelector);
  const {
    items,
    page,
    hasMore,
    action: { loading: creatingFeed },
  } = useSelector(streamFeedSelector);

  const [text, setText] = useState("");
  const [draftContent, setDraftContent] = useState("");
  const [focused, setFocused] = useState(false);
  const [dragMode, setDragMode] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileUris, setFileUris] = useState<string[] | undefined>();
  const [uploadId, setUploadId] = useState<number | undefined>();
  const [resetCreator, setResetCreator] = useState(0);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef<DropzoneRef>(null);

  const getThumbnails = useCallback(async (data: File[]) => {
    const uriArray: string[] = [];
    const promiseArray: Promise<unknown>[] = [];
    data.forEach((file) => {
      if (!file.type.includes("image")) {
        promiseArray.push(getVideoCover(file, 1));
      }
    });
    const results = await Promise.all(promiseArray);
    let videoIndex = results.length ? 0 : -1;
    data.forEach(async (file) => {
      let fileUrl = "";
      if (file.type.includes("image")) {
        fileUrl = URL.createObjectURL(file);
      } else if (videoIndex !== -1) {
        const blob = results[videoIndex];
        fileUrl = URL.createObjectURL(blob as Blob);
        videoIndex += 1;
      }
      if (fileUrl) {
        uriArray.push(fileUrl);
      }
    });
    setFileUris(uriArray);
  }, []);

  const initForm = () => {
    setText("");
    setDraftContent("");
    setFiles([]);
    setFileUris(undefined);
    setUploadId(undefined);
    setResetCreator(Date.now());
  };

  useEffect(() => {
    if (creatingFeed) {
      setLoading(creatingFeed);
    }
  }, [creatingFeed]);

  useEffect(() => {
    if (files.length) {
      getThumbnails(files);
    } else {
      setFileUris(undefined);
    }
  }, [files, getThumbnails]);

  useEffect(() => {
    initForm();
  }, [open]);

  const handlePaste = useCallback((files: Blob[]) => {
    if (files?.length) {
      const blob = files[0];
      setFiles((prev) => [...prev, blob as File]);
      return "handled";
    }
    return "not-handled";
  }, []);

  const onSubmit = (data: { files?: File[]; text?: string; draftContent?: string; uploadId?: number }) => {
    dispatch(
      createStreamFeed({
        ...data,
        onSuccess: () => {
          setLoading(false);
          onClose();
          initForm();
        },
      })
    );
  };

  const handleSubmit = useCallback(async () => {
    if ((!text.trim() && !files.length) || loading) {
      ToastService.showErrorMessage("Please write your content");
      return;
    }

    const problemIndex = files.findIndex((file) => {
      if (file?.size >= 100000000) {
        return true;
      }
      return false;
    });

    if (problemIndex !== -1) {
      ToastService.showErrorMessage(
        "One or more file(s) is too large and cannot be uploaded. Please reduce the size for the file and try again."
      );
      return;
    }

    setLoading(true);
    const uploadTempId = Date.now();
    if (files.length) {
      setUploadId(uploadTempId);
    }

    onSubmit({
      files,
      text: text || "",
      draftContent,
      uploadId: files.length ? uploadTempId : undefined,
    });
  }, [files, text, draftContent]);

  const onDrop = (items: any) => {
    if (items.length + files.length <= 4) {
      setFiles((prevItems) => [...prevItems, ...items]);
    } else {
      ToastService.showErrorMessage("Max 4 files can be selected!");
    }
  };

  return (
    <BaseModal
      {...props}
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(7px)",
        px: { xs: 4, md: 0 },
        mt: { xs: "64px" },
      }}
    >
      <Box
        component={Paper}
        className="-webkit-chatbox-scrollbar"
        sx={{
          px: { xs: 1, sm: 4 },
          pr: { xs: 2 },
          pt: 2,
          pb: { xs: 2, sm: 4 },
          borderRadius: 6,
          position: "relative",
          width: 450,
        }}
      >
        <LoadingView visible={loading} />
        <Grid container justifyContent="space-between" alignItems="center" sx={{ px: { xs: 2, sm: 0 }, py: 1 }}>
          <Typography sx={{ color: "#A3AED0", fontWeight: 600, fontSize: { xs: 18, md: 20 } }}>Create Post</Typography>
          <IconButton onClick={onClose} sx={{ p: 1, mr: -1.5 }}>
            <CloseIcon sx={{ cursor: "pointer", opacity: 0.5, width: { xs: 25, md: 25 } }} />
          </IconButton>
        </Grid>

        <Dropzone
          ref={inputRef}
          multiple
          maxSize={1024 * 1024 * 100} // 100MB limit
          accept={{ "image/*": [".mov", ".mp4", ".avi"] }}
          onDrop={onDrop}
          noClick
        >
          {({ getRootProps, getInputProps, isDragActive, open }) => (
            <Box
              sx={{
                mt: 0.5,
                mx: { xs: 1, sm: 0 },
                borderRadius: 4,
                borderStyle: isDragActive ? "dashed" : "none",
                borderWidth: 2,
                borderColor: "primary.main",
                position: "relative",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <FlexRow sx={{ flex: 1, flexDirection: "column" }}>
                <FlexRow sx={{ flex: 1, alignItems: "flex-start", zIndex: 1 }}>
                  <FlexRow sx={{ alignItems: "center", mr: 1, mt: 0.4 }}>
                    <Avatar
                      variant="circular"
                      src={user?.photoUrl}
                      alt=""
                      sx={{ width: 32, height: 32, borderColor: "primary.main", borderWidth: 1, borderStyle: "solid" }}
                    >
                      <DefaultAvatar />
                    </Avatar>
                  </FlexRow>
                  <FlexRow sx={{ flex: 1, alignItems: "center" }}>
                    <BoltMentionInput
                      placeholder="Your content here..."
                      resetCreator={resetCreator}
                      onFocus={() => setFocused(true)}
                      onBlur={() => {
                        setTimeout(() => {
                          if (!text.trim()) {
                            setText("");
                          }
                          setFocused(false);
                        }, 300);
                      }}
                      handlePastedFiles={handlePaste}
                      onChangeText={(value: string) => setText(value)}
                      onChangeDraftContent={setDraftContent}
                      initialMultiline
                    />
                  </FlexRow>
                </FlexRow>
                <FlexRow sx={{ mt: 2, ml: 5, alignItems: "center", justifyContent: "space-between" }}>
                  <FlexRow
                    sx={{
                      cursor: "pointer",
                      p: dragMode ? 2 : 0,
                      borderRadius: 4,
                      borderStyle: dragMode ? "dashed" : "none",
                      borderWidth: 2,
                      borderColor: "primary.main",
                      alignItems: "center",
                      flex: 1,
                    }}
                    onClick={() => {
                      if (dragMode) {
                        open();
                      } else {
                        setDragMode(true);
                      }
                    }}
                  >
                    {!files.length && (
                      <>
                        <ImageIcon width="20" height="20" />
                        {dragMode && (
                          <FlexCol sx={{ pl: 2 }}>
                            <Typography variant="subtitle2" fontWeight="600">
                              Click to add media
                            </Typography>
                            <Typography variant="caption" fontSize={10}>
                              or Drag & Drop
                            </Typography>
                          </FlexCol>
                        )}
                      </>
                    )}
                    {!!files.length && (
                      <FlexRow sx={{ gap: 1 }}>
                        {fileUris?.map((fileUri, index) => (
                          <Box key={fileUri} sx={{ position: "relative" }}>
                            <Avatar variant="rounded" src={fileUri} />
                            <FlexRow
                              sx={{
                                position: "absolute",
                                top: -8,
                                right: -8,
                                p: 0,
                                bgcolor: "primary.dark",
                                width: 20,
                                height: 20,
                                borderRadius: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setFiles((prevFiles) => {
                                  const newFiles = [...prevFiles];
                                  newFiles.splice(index, 1);
                                  return newFiles;
                                });
                              }}
                            >
                              <CloseIcon sx={{ width: 16, height: 16 }} />
                            </FlexRow>
                          </Box>
                        ))}
                      </FlexRow>
                    )}
                  </FlexRow>
                  <FlexCol>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ height: 26, px: 2, ml: 2, borderRadius: 13 }}
                      onClick={handleSubmit}
                    >
                      <Typography variant="caption" sx={{ fontWeight: "600", mr: 0.5 }}>
                        Bolt it
                      </Typography>
                      <BoltIcon width={13} height={13} />
                    </Button>
                  </FlexCol>
                </FlexRow>
              </FlexRow>
              {loading && (
                <FlexCol sx={{ alignItems: "center", justifyContent: "center", marginTop: 2 }}>
                  {uploadProgress && uploadId && uploadProgress[uploadId] && (
                    <>
                      <LinearProgress
                        variant="determinate"
                        color="warning"
                        value={uploadProgress[uploadId] || 0}
                        sx={{ width: "100%", borderRadius: 4 }}
                      />
                      <Typography sx={{ mt: 1 }}>Uploading file...</Typography>
                    </>
                  )}
                </FlexCol>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
    </BaseModal>
  );
};
