import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";
import { Channel } from "../../types";

export interface EntityState {
  loading: boolean;
  success: boolean;
  error: any;
  entity: any;
}

const initialState: EntityState = {
  loading: false,
  success: false,
  error: null,
  entity: null,
};

const reducer = handleActions<EntityState, any>(
  {
    [actions.INIT_ENTITY]: (state) =>
      produce(state, (draft) => {
        draft.loading = initialState.loading;
        draft.success = initialState.success;
        draft.error = initialState.error;
        draft.entity = initialState.entity;
      }),

    [actions.FETCH_ENTITY + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.entity = null;
        draft.error = null;
      }),
    [actions.FETCH_ENTITY + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.entity = payload;
      }),
    [actions.FETCH_ENTITY + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
        draft.entity = null;
      }),
  },
  initialState
);

export default reducer;
