import { Avatar, Box, Button, Collapse, IconButton, TextareaAutosize, useTheme } from "@mui/material";
import React, { memo, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FlexCol, FlexRow } from "../../components";
import { ReactComponent as SendIcon } from "../../assets/svgs/send.svg";
import { DefaultAvatar } from "../../components/DefaultAvatar";
import { userSelector } from "../../store/auth/selector";
import BoltMentionInput from "../../components/BoltMentionInput";
import { GiphyModal } from "../../components/modals/GiphyModal";
import { GiphyObject } from "../../types";

type Props = {
  showReplyBox: boolean;
  mainCommentReply?: boolean;
  onSend: (payload: { text: string; draftContent?: string; giphy?: GiphyObject }) => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  reply?: boolean;
};

const CommentInputBox = ({ showReplyBox, mainCommentReply, onSend, inputValue, setInputValue, reply }: Props) => {
  const theme = useTheme();
  const [draftContent, setDraftContent] = React.useState("");
  const [resetCreator, setResetCreator] = React.useState(0);
  const [showGiphyModal, setShowGiphyModal] = React.useState(false);

  const user = useSelector(userSelector);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (showReplyBox && !!inputRef.current && !mainCommentReply) {
      // inputRef.current.scrollIntoView({ behavior: "auto" });
      inputRef.current.focus();
    }
  }, [showReplyBox, mainCommentReply]);

  return (
    <Collapse in={showReplyBox}>
      <FlexRow
        sx={{
          alignItems: "stretch",
          pl: mainCommentReply ? 0 : 2.125,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <FlexCol sx={{ alignItems: "center", justifyContent: "flex-start", marginRight: 0.625 }}>
          {!mainCommentReply && (
            <Box sx={{ width: 0, height: "7px", mb: "5px", border: "1px solid rgba(255, 255, 255, 0.15)" }} />
          )}
          <Box
            component={Link}
            to={`/${user?.username}`}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textDecoration: "none",
              paddingBottom: 1,
              paddingTop: mainCommentReply ? "3px" : 0,
            }}
          >
            <Avatar
              src={user?.photoUrl}
              alt={user?.username}
              sx={{
                borderColor: "primary.main",
                borderWidth: 1,
                borderStyle: "solid",
                height: reply ? 25 : 30,
                width: reply ? 25 : 30,
              }}
            >
              <DefaultAvatar />
            </Avatar>
          </Box>
        </FlexCol>
        <FlexRow
          sx={{
            flex: 1,
            gap: 0.625,
            alignItems: "flex-end",
            paddingBottom: 1.25,
            paddingRight: mainCommentReply ? undefined : 2,
            marginTop: mainCommentReply ? 0 : "10px",
          }}
          className="comment-input"
        >
          <BoltMentionInput
            placeholder="Type your reply..."
            resetCreator={resetCreator}
            onChangeText={(value: string) => setInputValue(value)}
            onChangeDraftContent={setDraftContent}
            onClickGiphy={() => setShowGiphyModal(true)}
            showGifIcon
          />
          {/* <TextareaAutosize
            ref={inputRef}
            minRows={1}
            maxRows={4}
            style={{
              width: "100%",
              outline: "none",
              fontFamily: "Poppins",
              fontSize: 12,
              lineHeight: "100%",
              backgroundColor: mainCommentReply ? "#282C46" : theme.palette.background.paper,
              color: "white",
              borderRadius: 6,
              padding: mainCommentReply ? "12px 10px" : "7px 15px",
              fontWeight: 400,
              borderWidth: 0,
              borderColor: "#ffffff26",
              resize: "none",
            }}
            placeholder="Type your reply..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          /> */}
          <IconButton
            // disabled={loadingAddReply}
            onClick={() => {
              onSend({ text: inputValue, draftContent });
              setResetCreator(Date.now());
            }}
            sx={{ p: 0, mb: "2px" }}
          >
            <SendIcon />
          </IconButton>
        </FlexRow>
      </FlexRow>
      <GiphyModal
        open={showGiphyModal}
        inputPlaceholder="Type your reply..."
        onClose={() => setShowGiphyModal(false)}
        onSelectItem={(data) => {
          setShowGiphyModal(false);
          onSend({ ...data });
        }}
      />
    </Collapse>
  );
};

export default memo(CommentInputBox);
