import React from 'react';
import { Grid } from '@mui/material';
import { TableCell } from './TableCell';

export const NoHistory = () => {
  return (
    <Grid item container mt={3} alignItems="center">
      <TableCell xs={12} sx={{ fontSize: 14, color: '#efefef80' }}>No earning history</TableCell>
    </Grid>
  );
};