import React from "react";

import { ModalProps, Typography, Paper, Button, TextField, Box, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BaseModal } from "./BaseModal";

interface Props extends Partial<ModalProps> {
  open: boolean;
  onClose?: () => void;
  data?: any;
}

export const AddBankAccountModal = ({ open, onClose, ...props }: Props) => {
  return (
    <>
      <BaseModal
        {...props}
        open={open}
        onClose={onClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: "blur(7px)" }}
      >
        <Box
          component={Paper}
          sx={{
            px: 4,
            py: 4,
            borderRadius: 4,
            position: "relative",
            // flexDirection: "column",
            width: 500,
            // ...containerStyle,
          }}
        >

          <Grid container spacing={2} direction="column">
            <Grid item>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography sx={{ color: "#A3AED0", fontWeight: 600, fontSize: 20, lineHeight: "26.5px" }} align="center">
                    Add a Bank account
                  </Typography>
                </Grid>
                <Grid item sx={{ position: 'absolute', right: 40 }}>
                  <IconButton onClick={onClose}>
                    <CloseIcon sx={{ cursor: "pointer" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px" }}>
                    Bank Name
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    name="bankName"
                    inputProps={{
                      style: {
                        backgroundColor: "#282C46",
                        border: "0.5px solid rgba(255, 255, 255, 0.15)",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        height: "20px",
                        padding: "7px",
                        paddingLeft: "13px",
                        paddingRight: "40px",
                        borderRadius: "6px",
                      },
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px" }}>
                    SWIFT Code/ Wire number
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    name="code"
                    inputProps={{
                      style: {
                        backgroundColor: "#282C46",
                        border: "0.5px solid rgba(255, 255, 255, 0.15)",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        height: "20px",
                        padding: "7px",
                        paddingLeft: "13px",
                        paddingRight: "40px",
                        borderRadius: "6px",
                      },
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px" }}>
                    Account number
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    name="accountNumber"
                    inputProps={{
                      style: {
                        backgroundColor: "#282C46",
                        border: "0.5px solid rgba(255, 255, 255, 0.15)",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        height: "20px",
                        padding: "7px",
                        paddingLeft: "13px",
                        paddingRight: "40px",
                        borderRadius: "6px",
                      },
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={2} direction="column" >
                <Grid item>
                  <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", }}>
                    Nickname
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    name="nickname"
                    inputProps={{
                      style: {
                        backgroundColor: "#282C46",
                        border: "0.5px solid rgba(255, 255, 255, 0.15)",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        height: "20px",
                        padding: "7px",
                        paddingLeft: "13px",
                        paddingRight: "40px",
                        borderRadius: "6px",
                      },
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" sx={{ textTransform: "none", height: 34, borderRadius: 29 / 2, mt: 6, px: 4.5 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: 14, lineHeight: "18.55px" }}>
                  Add account
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </BaseModal>
      {/* <Modal
        {...props}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: "blur(7px)" }}
      >
        <FlexCol
          sx={{
            px: 5.5,
            py: 4.5,
            width: "470px",
            maxWidth: "90%",
            borderRadius: "20px",
            background: "rgba(28, 32, 57, 0.8)",
            position: "relative"
          }}>
          <CloseIcon sx={{ cursor: "pointer", position: "absolute", right: 32, top: 40 }} onClick={props.onClose} />
          <FlexRow
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              sx={{
                color: "#A3AED0",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 600,
                fontSize: 20,
                lineHeight: "26.5px"
              }}>
              Add a Bank account
            </Typography>
          </FlexRow>
          <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mt: 5, mb: 1 }}>
            Bank Name
          </Typography>
          <TextField
            name="bankName"
            inputProps={{
              style: {
                backgroundColor: "#282C46",
                border: "0.5px solid rgba(255, 255, 255, 0.15)",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "21px",
                height: "20px",
                padding: "7px",
                paddingLeft: "13px",
                paddingRight: "40px",
                borderRadius: "6px",
              },
            }}
          />
          <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mt: 2, mb: 1 }}>
            SWIFT Code/ Wire number
          </Typography>
          <TextField
            name="code"
            inputProps={{
              style: {
                backgroundColor: "#282C46",
                border: "0.5px solid rgba(255, 255, 255, 0.15)",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "21px",
                height: "20px",
                padding: "7px",
                paddingLeft: "13px",
                paddingRight: "40px",
                borderRadius: "6px",
              },
            }}
          />
          <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mt: 2, mb: 1 }}>
            Account number
          </Typography>
          <TextField
            name="accountNumber"
            inputProps={{
              style: {
                backgroundColor: "#282C46",
                border: "0.5px solid rgba(255, 255, 255, 0.15)",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "21px",
                height: "20px",
                padding: "7px",
                paddingLeft: "13px",
                paddingRight: "40px",
                borderRadius: "6px",
              },
            }}
          />
          <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mt: 2, mb: 1 }}>
            Nickname
          </Typography>
          <TextField
            name="nickname"
            inputProps={{
              style: {
                backgroundColor: "#282C46",
                border: "0.5px solid rgba(255, 255, 255, 0.15)",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "21px",
                height: "20px",
                padding: "7px",
                paddingLeft: "13px",
                paddingRight: "40px",
                borderRadius: "6px",
              },
            }}
          />
          <FlexRow
            sx={{
              justifyContent: "center",
              alignItems: "center",
              pt: "50px",
            }}>
            <Button variant="contained" sx={{ textTransform: "none", height: 34, borderRadius: 29 / 2, mt: 6, px: 4.5 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: 14, lineHeight: "18.55px" }}>
                Add account
              </Typography>
            </Button>
          </FlexRow>
        </FlexCol>
      </Modal> */}
    </>
  );
};