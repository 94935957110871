import { List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { FlexRow } from "../../components";

export const NotificationSettings = () => {
    return (<FlexRow sx={{ mt: '20px', ml: '40px' }}>
        <List sx={{ width: '100%', maxWidth: 360 }}>
            <ListItem>
                <ListItemText>
                    <Typography fontSize='20px' fontWeight='600'>Notification Settings</Typography>
                </ListItemText>
            </ListItem>
        </List>
    </FlexRow>);
};
