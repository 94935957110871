import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { ImmersivePanel } from "../../components";

const containerId = "immersive-pannel-container";

export const ChatWindowPopout = () => {
  const [searchParams] = useSearchParams();

  const [flexDirection, setFlexDirection] = useState("column-reverse");

  const chatProps = useMemo(() => {
    const channelId = searchParams.get("channelId");
    const channelType = searchParams.get("channelType");
    const receiverName = searchParams.get("receiverName");
    const receiverType = searchParams.get("receiverType");

    return {
      containerId,
      channelId,
      channelType,
      receiverName,
      receiverType,
    };
  }, [searchParams]);

  return (
    <Box
      id={containerId}
      sx={{
        position: "fixed",
        top: 0,
        height: "100%",
        width: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection,
      }}
    >
      <ImmersivePanel
        chatProps={chatProps as any}
        onChangeTab={(value) => {
          setFlexDirection(value === 0 ? "column-reverse" : "column");
        }}
      />
    </Box>
  );
};
