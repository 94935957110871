import React from "react";
import { Box, Button, IconButton, ModalProps, Paper, SxProps, TextField, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ShareIcon } from "../../assets/icons/ShareIcon";
import { ReactComponent as CopyIcon } from "../../assets/svgs/copy.svg";

import { BaseModal } from "./BaseModal";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";

interface Props extends Partial<ModalProps> {
  link: string;
  open: boolean;
  loading?: boolean;
  title?: string;
  subtitle?: string;
  containerStyle?: SxProps;
  onClose: () => void;
}

export const ShareLinkModal = ({
  link,
  open,
  loading = false,
  title = "",
  subtitle = "",
  onClose,
  containerStyle,
  children,
  ...rest
}: Props) => {
  return (
    <BaseModal
      {...rest}
      open={open}
      onClose={(_, reason) => {
        onClose();
      }}
    >
      <Box
        component={Paper}
        elevation={0}
        sx={{
          p: 3,
          pt: 2,
          borderRadius: 4,
          position: "relative",
          flexDirection: "column",
          minWidth: 310,
          ...containerStyle,
        }}
      >
        <FlexCol position="relative">
          <FlexRow sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <FlexRow sx={{ alignItems: "center" }}>
              <ShareIcon color="#A3AED0" />
              <Typography color="#A3AED0" fontWeight="600" sx={{ ml: 1 }}>
                Share
              </Typography>
            </FlexRow>
            <IconButton sx={{ ml: "auto", mr: -2 }} onClick={onClose}>
              <CloseRoundedIcon sx={{ color: "#A3AED0" }} />
            </IconButton>
          </FlexRow>
          <FlexRow component={Paper} sx={{ justifyContent: "space-between", bgcolor: "secondary.dark", p: 1, my: 1 }}>
            <Typography variant="caption" sx={{ mr: 1, mt: 0.5 }}>
              {link}
            </Typography>
            <Button
              startIcon={<CopyIcon />}
              sx={{ bgcolor: "#3C436F", color: "white", fontSize: 10, width: 80 }}
              onClick={() => navigator.clipboard.writeText(link)}
            >
              COPY
            </Button>
          </FlexRow>
        </FlexCol>
      </Box>
    </BaseModal>
  );
};
