import React, { createRef, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Socket, io } from "socket.io-client";

import { authSelector } from "../store/auth/selector";
import { fetchHomeStreamFeed } from "../store/feed/actions";
import { apiClient } from "../config";
import { NOTIFICATION_TYPE } from "../types";
import { logOutAction } from "../store/auth/actions";

export interface SocketContextProviderValues {
  loading: boolean;
  data: string;
  latestFeed?: {
    creator: string;
    feedId: string;
  };
}

const defaultValues: SocketContextProviderValues = {
  loading: false,
  data: "sample",
  latestFeed: undefined,
};

export interface SocketContextType {
  socket?: React.MutableRefObject<Socket<any, any> | undefined>;
  values: SocketContextProviderValues;
  setValues: (payload: Partial<SocketContextProviderValues>) => void;
}

const defaultSocketContextValue: SocketContextType = {
  values: defaultValues,
  setValues: () => {},
};

export const SocketContext = React.createContext(defaultSocketContextValue);

interface SocketContextProviderInterface {}

export const SocketContextProvider = ({ children }: React.PropsWithChildren<SocketContextProviderInterface>) => {
  const [values, setValues] = React.useState<SocketContextProviderValues>(defaultValues);
  const dispatch = useDispatch();
  const { user, token } = useSelector(authSelector);
  const socket = useRef<Socket>();

  const updateValues = React.useCallback(
    (payload: Partial<SocketContextProviderValues>) => setValues((prev) => ({ ...prev, ...payload })),
    []
  );

  const newFeedAvailableListener = useCallback(
    (payload: { creator: string; feedId: string }) => {
      if (payload.creator === user?.id) {
        updateValues({ latestFeed: undefined });
        dispatch(fetchHomeStreamFeed());
      } else {
        updateValues({ latestFeed: payload });
      }
    },
    [user?.id]
  );

  const suspendAccountListener = useCallback((payload: string) => {
    // logout user when suspension
    dispatch(logOutAction());
  }, []);

  useEffect(() => {
    socket.current = io(`${apiClient.defaults.baseURL}`, {
      auth: { token },
      transports: ["websocket"],
      withCredentials: true,
    });
    socket.current.on("connect", () => {});
    socket.current.on(NOTIFICATION_TYPE.NEW_FEED_AVAILABLE, newFeedAvailableListener);

    // subscribe account suspension event
    socket.current.on(NOTIFICATION_TYPE.SUSPEND_ACCOUNT, suspendAccountListener);
    return () => {
      socket.current?.disconnect();
    };
  }, [token]);

  const value = React.useMemo(
    () => ({
      socket,
      values,
      setValues: updateValues,
    }),
    [values]
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export const useSocketContext = () => {
  return React.useContext(SocketContext);
};
