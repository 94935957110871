import { EntityLayoutContextProvider } from "./EntityLayoutContext";
import { EntityLayoutUi } from "./EntityLayoutUi";

export const EntityLayout = () => {
  return (
    <EntityLayoutContextProvider>
      <EntityLayoutUi />
    </EntityLayoutContextProvider>
  );
};
