import { CollectionPageContextProvider } from "./context";
import { CollectionPageUi } from "./CollectionPageUi";

export const CollectionPage = () => {
  return (
    <CollectionPageContextProvider>
      <CollectionPageUi />
    </CollectionPageContextProvider>
  );
};
