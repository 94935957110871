import React from "react";
import { Button, Container, Typography, Paper, Link } from "@mui/material";
import { useDispatch } from "react-redux";

import { FlexCol } from "./FlexCol";
import { apiClient } from "../config";
import { BoltError, ToastService } from "../services";
import { LoadingView } from "./LoadingView";
import { logOutAction } from "../store/auth/actions";

interface Props {
  email: string;
}

export const EmailVerifyPendingForm = ({ email }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const resendEmail = async () => {
    try {
      setLoading(true);
      await apiClient.post("/auth/sendVerificationEmail", { email });
      ToastService.showSuccessMessage("We've sent verification link to your email!.");
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
    }
    setLoading(false);
  };
  return (
    <Container
      maxWidth="sm"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}
    >
      <FlexCol component={Paper} sx={{ justifyContent: "center", alignItems: "center", flex: 1, p: 4 }}>
        <Typography variant="subtitle1">Please verify your email address!</Typography>
        <Typography variant="caption" sx={{ mt: 2 }}>
          We've sent the verification link to your email - <strong>{email}</strong>
        </Typography>
        <Typography variant="caption" sx={{ mt: 2 }}>
          Didn't you get it yet?{" "}
          <Link component={Button} color="primary.light" onClick={resendEmail}>
            <strong>Resend</strong>
          </Link>
        </Typography>
        <Button variant="contained" sx={{ mt: 2 }} onClick={() => dispatch(logOutAction())}>
          Logout
        </Button>
      </FlexCol>
      <LoadingView visible={loading} />
    </Container>
  );
};
