import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import * as profileActions from "../profile/actions";
import { APPLE, EMAIL, FAIL, GOOGLE, START, SUCCESS, WALLET } from "../common";
import { SocialProvider, User } from "../../types";

export interface AuthState {
  isLoggedIn: boolean;
  authorizing: boolean;
  loading: boolean;
  error: any;
  user?: User;
  token: string;
  userAppData: any;
  ipInfo: any;
  profileUpdate: {
    loading: boolean;
    error: any;
  };
}

const initialState: AuthState = {
  isLoggedIn: false,
  authorizing: false,
  loading: false,
  error: undefined,
  user: undefined,
  token: "",
  userAppData: {},
  ipInfo: {},
  profileUpdate: {
    loading: false,
    error: null,
  },
};

const reducer = handleActions<AuthState, any>(
  {
    [actions.INIT_AUTH]: (state) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.authorizing = false;
        draft.error = undefined;
      }),
    [actions.FETCH_SIGNUP + EMAIL + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = true;
        draft.error = undefined;
      }),
    [actions.FETCH_SIGNUP + EMAIL + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = true;
        draft.loading = false;
        draft.error = undefined;
        draft.user = payload.user;
        draft.token = payload.token;
      }),
    [actions.FETCH_SIGNUP + EMAIL + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.FETCH_LOGIN + EMAIL + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = true;
        draft.error = undefined;
      }),
    [actions.FETCH_LOGIN + EMAIL + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = true;
        draft.loading = false;
        draft.error = undefined;
        draft.user = payload.user;
        draft.token = payload.token;
      }),
    [actions.FETCH_LOGIN + EMAIL + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.FETCH_ONBOARDING + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = true;
        draft.error = undefined;
      }),
    [actions.FETCH_ONBOARDING + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = true;
        draft.loading = false;
        draft.error = undefined;
        draft.user = payload.user;
        draft.token = payload.token;
      }),
    [actions.FETCH_ONBOARDING + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.FETCH_LOGIN + WALLET + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = true;
        draft.error = undefined;
      }),
    [actions.FETCH_LOGIN + WALLET + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = undefined;
        draft.user = payload.user;
        if (payload.token) {
          draft.isLoggedIn = true;
          draft.token = payload.token;
        }
      }),
    [actions.FETCH_LOGIN + WALLET + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.FETCH_LOGIN + GOOGLE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = true;
        draft.error = undefined;
      }),
    [actions.FETCH_LOGIN + GOOGLE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = undefined;
        draft.user = payload.user;
        if (payload.token) {
          draft.isLoggedIn = true;
          draft.token = payload.token;
        }
      }),
    [actions.FETCH_LOGIN + GOOGLE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.FETCH_LOGIN + APPLE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = true;
        draft.error = undefined;
      }),
    [actions.FETCH_LOGIN + APPLE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = undefined;
        draft.user = payload;
        if (payload.token) {
          draft.isLoggedIn = true;
          draft.token = payload.token;
        }
      }),
    [actions.FETCH_LOGIN + APPLE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.FETCH_USER + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.authorizing = true;
        draft.error = undefined;
      }),
    [actions.FETCH_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = true;
        draft.authorizing = false;
        draft.error = undefined;
        draft.user = payload.data;
        draft.userAppData = payload.userApp;
        draft.ipInfo = payload.ipInfo;
      }),
    [actions.FETCH_USER + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isLoggedIn = false;
        draft.authorizing = false;
        draft.error = payload.error;
        draft.ipInfo = payload.ipInfo;
      }),
    [actions.SYNC_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.user = payload.data;
      }),
    [actions.UPDATE_PROFILE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.profileUpdate.loading = true;
        draft.profileUpdate.error = null;
      }),
    [actions.UPDATE_PROFILE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.profileUpdate.loading = false;
        draft.user = payload;
      }),
    [actions.UPDATE_PROFILE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.profileUpdate.loading = false;
        draft.profileUpdate.error = payload;
      }),
    [profileActions.SET_STREAM_PROFILE]: (state, { payload }) =>
      produce(state, (draft) => {
        if (draft.user?.streamProfile) {
          draft.user = { ...state.user, streamProfile: { ...state.user?.streamProfile, ...payload } } as any;
        }
      }),
    [actions.UPDATE_USER_WALLET + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.profileUpdate.loading = false;
        if (draft.user) {
          draft.user.providers = [
            ...(state.user?.providers?.filter(({ providerId }) => providerId !== "wallet") || []),
            ...((payload?.providers as SocialProvider[]) || []),
          ];
        }
      }),
    [actions.UPDATE_USER_WALLET + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.profileUpdate.loading = false;
        draft.profileUpdate.error = payload;
      }),
    [actions.LOG_OUT]: (state) => {
      return { ...initialState, ipInfo: state.ipInfo };
    },
  },

  initialState
);

export default reducer;
