import { NAV_BAR_HEIGHT } from "../../../../constants";
import { PageLoader, StepScrollContainer } from "../../../../components";
import { useBloombergEpisodesPageContext } from "../episodes-context";
import { Background } from "./Background";
import { Overlay } from "./Overlay";
import { Content } from "./Content";
import { Episodes } from "./Episodes";

export const BloombergEpisodesPageUi = () => {
  const { isLoadingData } = useBloombergEpisodesPageContext();

  if (isLoadingData) {
    return <PageLoader />;
  }

  return (
    <>
      <Background />

      <Overlay />

      <StepScrollContainer
        containerSx={{
          height: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Content />

        <Episodes />
      </StepScrollContainer>
    </>
  );
};
