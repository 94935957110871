import { useState } from "react";
import { AmazonIVSBroadcastClient } from "amazon-ivs-web-broadcast";

// Keeps track of initialized audio devices.

export interface AudioDevice {
  name: string;
  deviceId?: string;
  track?: MediaStream;
  muted?: boolean;
}

const useMixer = (initialDevice: any) => {
  const [mixerDevices, setMixerDevices] = useState([initialDevice]);

  // Add track
  // Adds an audio track directly to the mixer
  const addAudioTrack = async (audioLayer: AudioDevice, client: AmazonIVSBroadcastClient) => {
    if (!client) return;
    try {
      await client.addAudioInputDevice(audioLayer.track!, audioLayer.name);
      setMixerDevices((prevState) => [...prevState, audioLayer]);
    } catch (err) {
      console.error(err);
    }
  };

  // Remove Device
  // Removes a device from the mixer
  const removeMixerDevice = async (mixerDevice: AudioDevice, client: AmazonIVSBroadcastClient) => {
    if (!mixerDevice) return;

    try {
      const { name } = mixerDevice;
      if (!name) return;
      if (client.getAudioInputDevice(name)) {
        client.removeAudioInputDevice(name);
      }

      setMixerDevices((prevState) => prevState.filter((item) => item.name !== name));
    } catch (err) {
      console.error(err);
    }
  };

  const muteMixerDevice = (microphoneTrack: MediaStreamTrack) => {
    microphoneTrack.enabled = false;
    return true;
  };

  const unmuteMixerDevice = (microphoneTrack: MediaStreamTrack) => {
    microphoneTrack.enabled = true;
    return false;
  };

  // Add Device
  // Adds an audio device to the mixer
  const addMixerDevice = async (mixerDevice: AudioDevice, client: AmazonIVSBroadcastClient) => {
    try {
      const { deviceId, name, muted } = mixerDevice;
      // If it exists, remove the previous device from the canvas
      if (client.getAudioInputDevice(name)) {
        await removeMixerDevice(mixerDevice, client);
      }

      // Get the audio stream
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId },
      });
      // Add the audio stream to the mixer
      await client.addAudioInputDevice(audioStream, name);
      // If the device is set to muted, mute the device
      if (muted) {
        const [microphoneTrack] = client.getAudioInputDevice(name).getAudioTracks();
        muteMixerDevice(microphoneTrack);
      }

      setMixerDevices((prevState) => [...prevState, mixerDevice]);
    } catch (err) {
      console.error(err);
    }
  };

  // Toggle Device Mute
  // Toggles mute for an audio device.
  // Returns TRUE if muted, FALSE if not.
  const toggleMixerDeviceMute = (mixerDevice: AudioDevice, client: AmazonIVSBroadcastClient, forceState?: string) => {
    const { name, muted } = mixerDevice;
    const [microphoneTrack] = client.getAudioInputDevice(name).getAudioTracks();

    switch (forceState) {
      case "MUTE":
        return muteMixerDevice(microphoneTrack);
      case "UNMUTE":
        return unmuteMixerDevice(microphoneTrack);
      default:
        return muted ? unmuteMixerDevice(microphoneTrack) : muteMixerDevice(microphoneTrack);
    }
  };

  // Removes all devices from the mixer and resets to default state
  const resetMixers = async (devices: any, client: AmazonIVSBroadcastClient) => {
    await Promise.all(
      devices.map(async (device: any) => {
        try {
          await removeMixerDevice(device, client);
          // setMixerDevices((prevState) =>
          //   prevState.filter((item) => item.name !== name)
          // );
        } catch (error) {
          console.error(error);
        }
      })
    );
  };

  return {
    mixerDevices,
    addMixerDevice,
    addAudioTrack,
    removeMixerDevice,
    toggleMixerDeviceMute,
    resetMixers,
  };
};

export default useMixer;
