import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, AvatarProps, Box, ButtonBase, alpha } from "@mui/material";
import { Image } from "@mui/icons-material";

export type EpisodeItemProps = {
  id: string;
  imageProps?: AvatarProps;
  active?: boolean;
  slug?: string;
};

export const EpisodeItem = ({ imageProps = {}, active = false, slug = "" }: EpisodeItemProps) => {
  const { categorySlug } = useParams();

  const navigate = useNavigate();

  const onItem = useCallback(() => {
    if (slug) {
      navigate(`/p/bloomberg/${categorySlug}/${slug}`);
    }
  }, [categorySlug, slug]);

  return (
    <Box sx={{ p: 1 }}>
      <ButtonBase
        onClick={onItem}
        sx={{
          borderRadius: 1.5,
        }}
      >
        <Avatar
          sx={{
            width: 260,
            height: 150,
            objectFit: "cover",
            borderRadius: 1.5,

            ...(active
              ? {
                  outline: "3px solid white",
                }
              : {}),

            "&:hover": {
              ...(!active ? { outline: `3px solid ${alpha("#fff", 0.2)}` } : {}),
            },
          }}
          {...imageProps}
        >
          <Image sx={{ fontSize: 48 }} />
        </Avatar>
      </ButtonBase>
    </Box>
  );
};
