import { Avatar, AvatarProps, Box, ButtonBase, Typography, alpha, useTheme } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useNavigate } from "react-router-dom";

import { useStreamsContext } from "./StreamsContext";

export const LiveChannels = () => {
  const { liveChannels } = useLiveChannels();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
      <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Live Channels</Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {liveChannels.map((item, index) => (
          <LiveChannelItem key={`live-channel-item-${index}`} {...item} />
        ))}
      </Box>
    </Box>
  );
};

const useLiveChannels = () => {
  const { channels } = useStreamsContext();

  const liveChannels: LiveChannelItemProps[] = channels.data.map((item) => {
    return {
      id: item.channelId,
      imageProps: {
        src: item.thumbnail,
      },
    };
  });

  return { liveChannels };
};

type LiveChannelItemProps = {
  id: string;
  imageProps?: AvatarProps;
};

const LiveChannelItem = ({ id, imageProps = {} }: LiveChannelItemProps) => {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <ButtonBase
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative",
        "&:hover": {
          outline: `3px solid ${alpha("#fff", 0.2)}`,
        },
      }}
      onClick={() => {
        navigate(`/${id}`);
      }}
    >
      <Avatar
        sx={{
          width: { xs: 160, md: 225 },
          height: { xs: 160 / 1.7, md: 225 / 1.7 },
          borderRadius: "10px",
          bgcolor: "rgb(35, 37, 51)",
          ".MuiAvatar-img": {
            scale: "1",
            transition: theme.transitions.create(["scale", "transform"]),
          },
          "&:hover": {
            ".MuiAvatar-img": {
              scale: "1.1",
            },
          },
        }}
        {...imageProps}
      >
        <ImageIcon sx={{ fontSize: 60, color: "#666" }} />
      </Avatar>

      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 12,
          bgcolor: theme.palette.error.main,
          borderRadius: 10,
          display: "flex",
          alignItems: "center",
          columnGap: 0.5,
          px: 0.6,
          py: 0.2,
        }}
      >
        <Box sx={{ width: 5, height: 5, bgcolor: "#fff", borderRadius: 5 }} />

        <Typography sx={{ color: "#fff", fontWeight: 500, fontSize: 10 }}>LIVE</Typography>
      </Box>
    </ButtonBase>
  );
};
