import { useBloombergEpisodesPageContext } from "../episodes-context";
import { EpisodeItemProps } from "./EpisodeItem";

export const useEpisodeItems = () => {
  const { data } = useBloombergEpisodesPageContext();

  const episodeItems: EpisodeItemProps[] = (data?.channels || []).map((item) => {
    return {
      id: item.id,
      imageProps: {
        src: item.thumbnail,
      },
      slug: item.slug,
    };
  });

  return { episodeItems };
};
