import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { DeviceUUID } from "device-uuid";
import packageJson from "../../package.json";
import { StorageService } from "../services";
import { store } from "../store";
import { logOutAction } from "../store/auth/actions";

const API_ENDPOINT = process.env.REACT_APP_API_URL;
const deviceUUID = new DeviceUUID().get();
const lastSession = new Date(`${localStorage.getItem("session")}`).toString();
const timeBeforeUnload = new Date(`${localStorage.getItem("timeBeforeUnload")}`).toString();
const fiveMins = 5 * 60 * 1000;

const setTimeBeforeUnload = () => {
  localStorage.setItem("timeBeforeUnload", `${new Date()}`);
};

const Client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "product-token": "330dbc49a5872166f13049629596fc088b26d885",
    boltsrc: "boltplus/webapp/package-version",
    device: deviceUUID,
    session: lastSession && lastSession !== "Invalid Date" ? lastSession : `${new Date()}`,
  },
});

Client.interceptors.request.use(
  async (request: AxiosRequestConfig) => {
    const token = await StorageService.getAuthToken();
    if (!lastSession || lastSession === "Invalid Date") {
      localStorage.setItem("session", `${new Date()}`);
    }
    const mountingTime = new Date();
    window.addEventListener("beforeunload", setTimeBeforeUnload);

    if (lastSession && lastSession !== "Invalid Date" && +mountingTime - +new Date(timeBeforeUnload) > fiveMins) {
      const tempTime = `${new Date()}`;
      localStorage.setItem("session", tempTime);
      request.headers = { ...request.headers, session: tempTime };
    }

    if (token) {
      request.headers = { ...request.headers, authorization: `Bearer ${token}` };
    } else {
      request.headers = { ...request.headers, authorization: request?.headers?.authorization! || "" };
    }

    const deviceUUID = new DeviceUUID().get();
    const session = new Date().getTime();
    const { name, version } = packageJson;
    const platform = new DeviceUUID().parse().platform.replace(/\s/g, "_").toLowerCase();
    const boltsrc = `${name}/${platform}/${version}`;

    request.headers = {
      ...request.headers,
      device: deviceUUID,
      session: session.toString(),
      boltsrc,
    };

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Client.interceptors.response.use(
  async (response: AxiosResponse<any, any>) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status && error.response?.status === 401) {
      store?.dispatch(logOutAction());
    }
    return Promise.reject(error);
  }
);

export default Client;
