import moment from "moment";
import { AnalyticsData, PeriodFilterType } from "../types";

export const LineChartOptions: any = {
  responsive: true,
  plugins: {
    legend: { display: false },
    tooltip: { displayColors: false }
  },
  scales: {
    y: {
      grid: { color: "rgba(255, 255, 255, 0.1)" },
      ticks: {
        color: "rgba(255, 255, 255, 0.5)",
        font: { size: 12 },
        callback: (value: any) => `${value}`,
      },
      beginAtZero: true,
    },
    x: {
      grid: { display: false },
      ticks: {
        color: "rgba(255, 255, 255, 0.5)",
        font: { size: 12 },
      },
      beginAtZero: true,
    },
  },
};

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const lastDateOfCurMonth = new Date(currentYear, currentMonth, 0).getDate();
const lastDateOfPreMonth = new Date(currentYear, currentMonth - 1, 0).getDate();

const getDaysArray = (startDate: Date, endDate: Date) => {
  const temp = new Date(startDate.getTime());
  const result = [];
  while (temp <= endDate) {
    result.push(new Date(temp));
    temp.setDate(temp.getDate() + 1);
  }
  return result;
}

export const getLabels = (period: PeriodFilterType) => {
  let labels: Date[] = [];

  if (period === PeriodFilterType.last_week) {
    const date = new Date();
    date.setDate(date.getDate() - 6);
    labels = getDaysArray(date, new Date());
  } else if (period === PeriodFilterType.last_month) {
    const date = new Date();
    date.setDate(date.getDate() - lastDateOfPreMonth + 1);
    labels = getDaysArray(date, new Date());
  } else if (period === PeriodFilterType.this_year) {

    if (currentMonth < 7) {
      const startDate = new Date(currentYear, 0, 1).getTime();
      const endDate = new Date().getTime();
      const increase = (endDate - startDate) / 9

      for (let index = startDate; index <= endDate; index += increase) {
        const temp = new Date(index);
        labels.push(temp);
      }
    } else {
      for (let index = 1; index <= currentMonth; index += 1) {
        const temp = new Date(currentYear, index, 0, 23, 59, 59);
        labels.push(temp);
      }
    }
  } else if (period === PeriodFilterType.last_year) {
    for (let index = 1; index <= 12; index += 1) {
      const temp = new Date(currentYear - 1, index, 0, 23, 59, 59);
      labels.push(temp);
    }
  }

  return labels;
};

const checkIsBetween = (from: Date, to: Date, value: Date) => {
  return new Date(value) > new Date(from) && new Date(value) <= new Date(to)
};

export const getCharData = (data: AnalyticsData[], period: PeriodFilterType) => {
  const temp: number[] = [];
  if (data.length === 0) return temp;

  const dateLabels = getLabels(period);
  if (period === PeriodFilterType.last_week || period === PeriodFilterType.last_month) {
    for (let index = 0; index < dateLabels.length; index += 1) {
      const found = data.find(one => moment(new Date(one.date!)).format("DD/MM") === moment(new Date(dateLabels[index])).format("DD/MM"));
      temp[index] = found ? found.count : 0;
    }
  }
  else if ((period === PeriodFilterType.this_year && currentMonth < 7)) {
    const totalCount = data.reduce((sum, one) => sum + (new Date(dateLabels[0]) === new Date(one.date!) ? one.count : 0), 0);
    temp[0] = totalCount;

    for (let index = 1; index < dateLabels.length; index += 1) {
      const totalCount = data.reduce((sum, one) => {
        return sum + (checkIsBetween(dateLabels[index - 1], dateLabels[index], one.date!) ? one.count : 0);
      }, 0);
      temp[index] = totalCount;
    }
  } else {
    for (let index = 0; index < dateLabels.length; index += 1) {
      const totalCount = data.reduce((sum, one) => sum + (moment(new Date(one.date!)).format("MM") === moment(new Date(dateLabels[index])).format("MM") ? one.count : 0), 0);
      temp[index] = totalCount;
    }
  }

  return temp;
}