import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, BoxProps, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, PointElement, LineElement, Legend, ScriptableContext } from "chart.js";

import { ReactComponent as CameraIcon } from "../../../assets/svgs/users/camera.svg";
import { ReactComponent as PeopleViewIcon } from "../../../assets/svgs/users/peopleview.svg";
import { ReactComponent as FaceIcon } from "../../../assets/svgs/users/face.svg";
import { ReactComponent as MessageIcon } from "../../../assets/svgs/users/message.svg";
import { ReactComponent as EyeWhiteIcon } from "../../../assets/svgs/users/eyeWhite.svg";
import { ReactComponent as BoltsIcon } from "../../../assets/svgs/users/bolts.svg";
import { fetchAnalyticsLiveStreamAction } from "../../../store/users/actions";
import { userStreamSelector } from "../../../store/users/selector";
import { ChartTab, ChartTabs, OptionButton, StatusBox } from "./components";
import { FlexCol, FlexRow } from "../../../components";
import { ChartType, DateUnit, FilterType } from "../../../types";
import { Formatter, LineChartOptions } from "../../../utils";

ChartJS.register(PointElement, LineElement, Legend);


const AnalyticsLiveStream = ({ sx, ...other }: BoxProps) => {
  const dispatch = useDispatch();
  const { livestream: { statistics, totalStreams, totalViews, totalMinutes, totalChats, totalImpressions, totalRecords, totalBolts } } = useSelector(userStreamSelector);

  const [dayIndex, setDayIndex] = useState<FilterType>(FilterType.ALL);
  const [typeIndex, setTypeIndex] = useState(0);
  const [typeLabel, setTypeLabel] = useState("");

  useEffect(() => {
    dispatch(fetchAnalyticsLiveStreamAction());
  }, [dispatch]);

  useEffect(() => {
    let startDate = 0;
    let dateUnit: DateUnit = "";
    let type: ChartType = ChartType.REACTIONS;
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    switch (dayIndex) {
      case FilterType.DAILY:
        dateUnit = "month";
        today.setDate(1);
        startDate = today.getTime();
        break;
      case FilterType.MONTHLY:
        dateUnit = "year";
        today.setDate(1);
        today.setMonth(0);
        startDate = today.getTime();
        break;
      default:
        break;
    }

    switch (typeIndex) {
      case 0:
        type = ChartType.REACTIONS;
        setTypeLabel(ChartType.REACTIONS);
        break;
      case 1:
        type = ChartType.IMPRESSIONS;
        setTypeLabel(ChartType.IMPRESSIONS);
        break;
      case 2:
        type = ChartType.COMMENTS;
        setTypeLabel("Replies");
        break;
      case 3:
        type = ChartType.REBOLTS;
        setTypeLabel(ChartType.REBOLTS);
        break;
      default:
        break;
    }

    // dispatch(fetchAnalyticsPostsStatistics({ type, startDate, dateUnit }));
  }, [dispatch, dayIndex, typeIndex]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTypeIndex(newValue);
  };

  const getTypeValue = () => {
    let value = 0;
    switch (typeIndex) {
      case 0: value = totalStreams; break;
      case 1: value = totalImpressions; break;
      case 2: value = totalViews; break;
      case 3: value = totalBolts; break;
      default: break;
    }

    return value;
  };

  const graphData = {
    labels: statistics.map(({ date }) => moment(date).format(Formatter.getDateFormatType(dayIndex))),
    datasets: [
      {
        label: "Followers",
        data: statistics.map(({ count }) => count),
        fill: true,
        tension: 0.5,
        borderColor: (context: ScriptableContext<"line">) => {
          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "#3A09FD");
          gradient.addColorStop(1, "#3BA2EE");
          return gradient;
        },
        pointBorderWidth: 0,
      },
    ],
  };

  return (
    <Box sx={{ pt: 3, ...sx }} {...other}>
      <FlexRow sx={{ ml: { xs: 2, sm: 0 }, flexWrap: 'wrap', display: { xs: 'block', sm: 'flex' }, justifyContent: { xs: 'center', sm: 'start' }, gap: "8px", maxWidth: 1000 }}>
        <StatusBox Icon={CameraIcon} label="Total Streams" value={totalStreams} />
        <StatusBox Icon={PeopleViewIcon} label="Total Views" value={totalViews} />
        <StatusBox Icon={FaceIcon} label="Total Minutes" value={totalMinutes} />
        <StatusBox Icon={MessageIcon} label="Total Chats" value={totalChats} />
        <StatusBox Icon={EyeWhiteIcon} label="Total Impressions" value={totalImpressions} />
        <StatusBox Icon={EyeWhiteIcon} label="Recorded Videos" value={totalRecords} />
        <StatusBox Icon={BoltsIcon} label="Total Bolts Received (Live)" value={totalBolts} sx={{ width: 260 }} />
      </FlexRow>

      <ChartTabs value={typeIndex} onChange={handleTabChange} sx={{ display: "none" }}>
        {Object.values(ChartType).map((item, index) => (
          <ChartTab key={index} label={item === ChartType.COMMENTS ? "Replies" : item} />
        ))}
      </ChartTabs>

      <FlexCol
        component={Paper}
        sx={{
          p: { xs: 1.5, sm: 3 },
          pt: { xs: 1, sm: 2 },
          width: { md: "100%", lg: "85%", xl: "70%" },
          borderTopLeftRadius: 0,
          display: "none"
        }}
      >
        <FlexRow sx={{ justifyContent: "space-between" }}>
          <FlexCol sx={{ backgroundColor: "#282C46", px: 2, py: 0.5, borderRadius: 1, display: "table" }}>
            <Typography sx={{ color: "#A3AED0", fontSize: 12, textTransform: "capitalize" }}>{typeLabel}</Typography>
            <Typography sx={{ color: "#219653", fontSize: 18, fontWeight: 600 }}>
              {getTypeValue() > 0 ? "+" : ""}
              {getTypeValue().toLocaleString("en-US")}
            </Typography>
          </FlexCol>
          <FlexRow>
            <OptionButton
              onClick={() => setDayIndex(FilterType.DAILY)}
              isSelected={dayIndex === FilterType.DAILY}
              label={FilterType.DAILY}
            />
            <OptionButton
              onClick={() => setDayIndex(FilterType.MONTHLY)}
              isSelected={dayIndex === FilterType.MONTHLY}
              label={FilterType.MONTHLY}
            />
            <OptionButton
              onClick={() => setDayIndex(FilterType.ALL)}
              isSelected={dayIndex === FilterType.ALL}
              label={FilterType.ALL}
            />
          </FlexRow>
        </FlexRow>

        <Line options={LineChartOptions} data={graphData} height={30} width={90} style={{ marginTop: 32 }} />
      </FlexCol>
    </Box>
  );
};

export default AnalyticsLiveStream;