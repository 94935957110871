import React from "react";
import { Button, ButtonProps } from "@mui/material";


type OptionButtonProps = { isSelected?: boolean, label?: string } & ButtonProps;

export const OptionButton = ({ isSelected = false, label, children, sx, ...other }: OptionButtonProps) => {
  return <Button
    size="small"
    sx={{
      ml: 0.5,
      px: 1.5,
      height: 25,
      minWidth: 45,
      borderRadius: 4,
      fontWeight: 600,
      color: isSelected ? "white" : "#ffffff40",
      backgroundColor: isSelected ? "#282C46" : "transparent",
      textTransform: 'capitalize',
      ...sx
    }}
    {...other}
  >
    {label || children}
  </Button>
}
