import { all, fork, put, take } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* fetchAnalyticsOverview() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_ANALYTICS_OVERVIEW + START);
    const { startDate = 0, endDate = 0 } = payload;

    let query = "";
    if (startDate > 0) query += `${query === "" ? "?" : "&"}startDate=${startDate}`;
    if (endDate > 0) query += `${query === "" ? "?" : "&"}endDate=${endDate}`;

    try {
      const { data } = yield apiClient.get(`users/analytics/overview${query}`);
      yield put({
        type: actions.FETCH_ANALYTICS_OVERVIEW + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_ANALYTICS_OVERVIEW + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchAnalyticsOverviewTopFeeds() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS + START);
    const { startDate = 0, endDate = 0 } = payload;

    let query = "";
    if (startDate > 0) query += `${query === "" ? "?" : "&"}startDate=${startDate}`;
    if (endDate > 0) query += `${query === "" ? "?" : "&"}endDate=${endDate}`;

    try {
      const { data } = yield apiClient.get(`users/analytics/topFeeds${query}`);
      yield put({
        type: actions.FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchAnalyticsFollowers() {
  while (true) {
    yield take(actions.FETCH_ANALYTICS_FOLLOWERS + START);
    try {
      const { data } = yield apiClient.get(`users/analytics/followers`);
      yield put({
        type: actions.FETCH_ANALYTICS_FOLLOWERS + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_ANALYTICS_FOLLOWERS + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchAnalyticsFollowersStatistics() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_ANALYTICS_FOLLOWERS_STATISTICS + START);
    const { startDate = 0, endDate = 0, dateUnit = '' } = payload;

    let query = "";
    const getJoinSymbol = () => query === "?" ? "" : "&";
    if (startDate > 0 || endDate > 0 || dateUnit !== '') query += "?";
    if (startDate > 0) query += `${getJoinSymbol()}startDate=${startDate}`;
    if (endDate > 0) query += `${getJoinSymbol()}endDate=${endDate}`;
    if (dateUnit !== "") query += `${getJoinSymbol()}dateUnit=${dateUnit}`;

    try {
      const { data } = yield apiClient.get(`/users/analytics/followers/chart${query}`);
      yield put({
        type: actions.FETCH_ANALYTICS_FOLLOWERS_STATISTICS + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_ANALYTICS_FOLLOWERS_STATISTICS + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchAnalyticsPosts() {
  while (true) {
    yield take(actions.FETCH_ANALYTICS_POSTS + START);
    try {
      const { data } = yield apiClient.get(`users/analytics/streamFeeds/metric`);
      yield put({
        type: actions.FETCH_ANALYTICS_POSTS + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_ANALYTICS_POSTS + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchAnalyticsPostsContent() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_ANALYTICS_POSTS_CONTENT + START);
    const { type = "", startDate = 0, endDate = 0, dateUnit = '' } = payload;

    let query = "";
    if (type !== "") query += `${query === "" ? "?" : "&"}type=${type}`;
    if (startDate > 0) query += `${query === "" ? "?" : "&"}startDate=${startDate}`;
    if (endDate > 0) query += `${query === "" ? "?" : "&"}endDate=${endDate}`;
    if (dateUnit !== "") query += `${query === "" ? "?" : "&"}dateUnit=${dateUnit}`;

    try {
      const { data } = yield apiClient.get(`users/analytics/streamFeeds${query}`);
      yield put({
        type: actions.FETCH_ANALYTICS_POSTS_CONTENT + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_ANALYTICS_POSTS_CONTENT + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchAnalyticsPostsStatistics() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_ANALYTICS_POSTS_STATISTICS + START);
    const { type = "", startDate = 0, endDate = 0, dateUnit = '' } = payload;

    let query = "";
    if (type !== "") query += `${query === "" ? "?" : "&"}type=${type}`;
    if (startDate > 0) query += `${query === "" ? "?" : "&"}startDate=${startDate}`;
    if (endDate > 0) query += `${query === "" ? "?" : "&"}endDate=${endDate}`;
    if (dateUnit !== "") query += `${query === "" ? "?" : "&"}dateUnit=${dateUnit}`;

    try {
      const { data } = yield apiClient.get(`users/analytics/streamFeeds/chart${query}`);
      yield put({
        type: actions.FETCH_ANALYTICS_POSTS_STATISTICS + SUCCESS,
        payload: type === "" ? data : data[type],
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_ANALYTICS_POSTS_STATISTICS + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchAnalyticsLiveStream() {
  while (true) {
    yield take(actions.FETCH_ANALYTICS_LIVESTREAM + START);
    try {
      const { data } = yield apiClient.get(`users/analytics/liveStreams`);
      yield put({
        type: actions.FETCH_ANALYTICS_LIVESTREAM + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_ANALYTICS_LIVESTREAM + FAIL,
        payload: error,
      });
    }
  }
}


function* fetchEarningTotal() {
  while (true) {
    yield take(actions.FETCH_EARNING_TOTAL + START);
    try {
      const { data } = yield apiClient.get("users/transactions/totalEarning");
      yield put({
        type: actions.FETCH_EARNING_TOTAL + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_EARNING_TOTAL + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchEarningList() {
  while (true) {
    const { payload } = yield take(actions.FETCH_EARNING_LIST + START);
    const { page = 1, type = "" } = payload;

    let query = `?page=${page}`;
    if (type !== "") query += `&type=${type}`;

    try {
      const { data } = yield apiClient.get(`users/transactions/earnings${query}`);
      yield put({
        type: actions.FETCH_EARNING_LIST + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_EARNING_LIST + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchEarningWithdrawTotal() {
  while (true) {
    yield take(actions.FETCH_EARNING_WITHDRAW_TOTAL + START);
    try {
      const { data } = yield apiClient.get(`/users/transactions/totalWithdraw`);
      yield put({
        type: actions.FETCH_EARNING_WITHDRAW_TOTAL + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_EARNING_WITHDRAW_TOTAL + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchEarningWithdrawList() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_EARNING_WITHDRAW_LIST + START);
    const { page = 1, type = "" } = payload;

    let query = `?page=${page}`;
    if (type !== "") query += `&type=${type}`;

    try {
      const { data } = yield apiClient.get(`users/transactions/withdraws${query}`);
      yield put({
        type: actions.FETCH_EARNING_WITHDRAW_LIST + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_EARNING_WITHDRAW_LIST + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchEarningBalanceTotal() {
  while (true) {
    yield take(actions.FETCH_EARNING_BALANCE_TOTAL + START);
    try {
      const { data } = yield apiClient.get(`users/transactions/totalBalance`);
      yield put({
        type: actions.FETCH_EARNING_BALANCE_TOTAL + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_EARNING_BALANCE_TOTAL + FAIL,
        payload: error,
      });
    }
  }
}


export default function* userSaga() {
  yield all([
    fork(fetchAnalyticsOverview),
    fork(fetchAnalyticsOverviewTopFeeds),
    fork(fetchAnalyticsFollowers),
    fork(fetchAnalyticsFollowersStatistics),
    fork(fetchAnalyticsPostsContent),
    fork(fetchAnalyticsPosts),
    fork(fetchAnalyticsPostsStatistics),
    fork(fetchAnalyticsLiveStream),

    fork(fetchEarningTotal),
    fork(fetchEarningList),
    fork(fetchEarningWithdrawTotal),
    fork(fetchEarningWithdrawList),
    fork(fetchEarningBalanceTotal),
  ]);
}