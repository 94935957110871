import { BloombergEpisodesPageProvider } from "../episodes-context";
import { BloombergEpisodesPageUi } from "./BloombergEpisodesPageUi";

export const BloombergEpisodesPage = () => {
  return (
    <BloombergEpisodesPageProvider>
      <BloombergEpisodesPageUi />
    </BloombergEpisodesPageProvider>
  );
};
