import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { Close, TrendingUpRounded } from "@mui/icons-material";

import { usePrivateLayoutContext } from "../../../../layouts";
import { TrendList } from "../../../../pages/home/trending-list";
import { FlexRow } from "../../../FlexRow";
import { FlexCol } from "../../../FlexCol";
import { usePrivateNavbarContext } from "../PrivateNavbarContext";

export const TrendsDrawer = () => {
  const { navbar__height } = usePrivateLayoutContext();

  const { activeDrawer, updateContext: updateNavbarContext } = usePrivateNavbarContext();

  return (
    <Drawer
      disableEnforceFocus
      anchor="right"
      open={activeDrawer === "trends"}
      onClose={() => {
        updateNavbarContext({
          activeDrawer: undefined,
        });
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: { md: 600 },
          pt: `${navbar__height}px`,
          borderLeft: `1px solid rgba(255, 255, 255, 0.05)`,
        },
      }}
    >
      <FlexCol sx={{ p: 2.5, rowGap: 2.5, position: "relative" }}>
        <FlexRow sx={{ justifyContent: "center", alignItems: "center", columnGap: 1, pt: 2.5 }}>
          <Box sx={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <TrendingUpRounded />
          </Box>

          <Typography variant="h6" sx={{ fontFamily: "NFUltra-Regular", fontSize: { xs: 14 } }}>
            TRENDS
          </Typography>
        </FlexRow>

        <TrendList />

        <Box
          sx={{
            position: "absolute",
            top: { xs: 12, sm: 20 },
            right: { xs: 12, sm: 40 },
          }}
        >
          <IconButton
            onClick={() => {
              updateNavbarContext({ activeDrawer: undefined });
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </FlexCol>
    </Drawer>
  );
};
