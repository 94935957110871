import { CollectionPageContextType } from "./types";

export const defaultCollectionPageContextValue: CollectionPageContextType = {
  isPageLoading: true,
  //
  mainInfo: undefined,
  isLoadingMainInfo: true,
  //
  channels: {
    data: [],
    page: 0,
    limit: 0,
    total: 0,
  },
  isLoadingChannels: true,
  //
  streamers: {
    data: [],
    page: 0,
    limit: 0,
    total: 0,
  },
  isLoadingStreamers: true,
  //
  rewinds: {
    data: [],
    page: 0,
    limit: 0,
    total: 0,
  },
  isLoadingRewinds: true,
  //
  bottomOffset: 100,
  itemHeight: 0,
  //
  noData: false,
  bgVideo: "",
  //
  updateContext: () => {},
  getCollectionChannels: (() => {}) as any,
  getCollectionStreamers: (() => {}) as any,
  getCollectionRewinds: (() => {}) as any,
};
