import {
  IsBoolean,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUrl,
  Matches,
  MaxLength,
  MinLength,
  ValidateIf,
} from "class-validator";
import { USER_GENDER } from "../types";
import { userNameRegex } from "../utils/formatter";

export class CreateUserDto {
  @IsString()
  @IsNotEmpty({ message: "Add First Name" })
  public firstName: string;

  @IsString()
  @IsNotEmpty({ message: "Add Last Name" })
  public lastName: string;

  @IsEmail({}, { message: "Invalid email address" })
  @IsNotEmpty({ message: "Add Email" })
  public email: string;

  @IsString()
  @MinLength(6, { message: "Weak password" })
  @IsNotEmpty({ message: "Add password" })
  public password: string;

  @IsString()
  @IsNotEmpty({ message: "Add re-enter password" })
  public confirmPassword: string;

  @IsString()
  @Matches(userNameRegex, { message: "Invalid username" })
  @MinLength(6, { message: "Invalid username at least 6 chars" })
  @MaxLength(32, { message: "Invalid username, max 32 chars" })
  @IsNotEmpty({ message: "Add Username" })
  public username: string;

  @IsOptional()
  @IsString()
  public phone: string;

  @IsOptional()
  @IsEnum(USER_GENDER)
  public gender: USER_GENDER;

  @IsOptional()
  @IsBoolean()
  public subscribeEmail: boolean;

  @IsOptional()
  @IsBoolean()
  public allowUserTracking: boolean;
}

export class UpdateProfileDto {
  @IsString()
  @IsNotEmpty({ message: "Add First Name" })
  public firstName: string;

  @IsString()
  @IsNotEmpty({ message: "Add Last Name" })
  public lastName: string;

  @IsString()
  @Matches(userNameRegex, { message: "Invalid username" })
  @MinLength(6, { message: "Invalid username at least 6 chars" })
  @MaxLength(32, { message: "Invalid username, max 32 chars" })
  @IsNotEmpty({ message: "Add Username" })
  public username: string;

  @IsOptional()
  @IsEnum(USER_GENDER)
  public gender: USER_GENDER;

  @IsOptional()
  @IsString()
  public discordId: string;

  @IsOptional()
  @IsString()
  @MinLength(4, { message: "Invalid twitter ID, at least 4 chars" })
  @MaxLength(15, { message: "Invalid twitter ID, max 15 chars" })
  @ValidateIf((e) => e.twitterId !== "")
  public twitterId: string;

  @IsOptional()
  @IsString()
  public instagramId: string;

  @IsOptional()
  @IsUrl()
  @ValidateIf((e) => e.website !== "")
  public website: string;

  @IsOptional()
  @IsString()
  public bio: string;

  @IsOptional()
  @ValidateIf((e) => e.photoUrl !== "")
  photoUrl?: string | File;

  @IsOptional()
  @ValidateIf((e) => e.coverImage !== "")
  coverImage?: string | File;
}
