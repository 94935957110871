export enum STRIPE_TRANSACTION_STATUS {
  NOT_INITILIZED = "NOT_INITILIZED",
  INITIALIZED = "INITIALIZED",
  PAY_SUCCESS = "PAY_SUCCESS",
  PAY_CLOSED = "PAY_CLOSED",
}

export enum DIRECT_TO_TYPE {
  STREAM_FEED = "stream_feed",
  NFT_FEED = "nft_feed",
}
