import { Avatar, Paper, SxProps } from "@mui/material";
import React from "react";

import { FlexCol } from "../../../components";
import { apiClient } from "../../../config";
import { Channel } from "../../../types";

type Props = {
  channel: Channel;
  enableClick: boolean;
  style?: SxProps;
  onClick: (channel: Channel) => void;
};

type OpenseaNft = {
  image_url: string;
  banner_image_url?: string;
  name: string;
};

type NftCollection = {
  loading: boolean;
  data: OpenseaNft | null;
  error: any;
};

export const NftCreatorCard = ({ channel, enableClick = false, style, onClick = () => {} }: Props) => {
  const [nftCollection, setNftCollection] = React.useState<NftCollection>({
    loading: false,
    data: null,
    error: null,
  });

  const fetchNFT = React.useCallback(async (slug: string) => {
    setNftCollection({ loading: true, data: null, error: null });
    try {
      const { data } = await apiClient.get(`/opensea/collection/${slug}`);
      setNftCollection({
        loading: false,
        data: data?.collection,
        error: null,
      });
    } catch (error) {
      setNftCollection({ loading: false, data: null, error });
    }
  }, []);

  React.useEffect(() => {
    fetchNFT(channel.slug);
  }, [fetchNFT, channel]);

  if (nftCollection.loading) {
    return null;
  }
  return (
    <FlexCol
      component={Paper}
      sx={{
        width: { xs: 164, md: 200 },
        minWidth: { xs: 164, md: 200 },
        height: { xs: 92, md: 112 },
        borderRadius: { xs: 2, md: 2.5 },
        bgcolor: "background.default",
        cursor: "pointer",
        userSelect: "none",
        justifyContent: "flex-end",
        overflow: "hidden",
        mr: 1,
        ":hover": {
          transform: { md: "scale(1.25)" },
          borderStyle: { md: "solid" },
          borderColor: { md: "primary.main" },
          zIndex: 2,
        },
        transition: "transform 0.25s ease-in-out",
        ...style,
      }}
      onClick={() => (enableClick ? onClick(channel) : () => {})}
    >
      <Avatar
        variant="square"
        src={nftCollection.data?.banner_image_url || nftCollection.data?.image_url}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          inset: 0,
          pointerEvents: "none",
        }}
      />
    </FlexCol>
  );
};
