import {
  Autocomplete,
  FormHelperText,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce } from "lodash";

import { SubCategory } from "../types";
import { apiClient } from "../config";

interface Props {
  mounted?: boolean;
  selectedItems: SubCategory[];
  onChange?: (items: SubCategory[]) => void;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: "#00000040",
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export const SubCategorySelector = ({ mounted = false, selectedItems = [], onChange = () => {} }: Props) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<SubCategory[]>([]);
  const [error, setError] = useState(false);

  const fetchSubCategories = useCallback(async (search: string = "") => {
    try {
      if (search?.trim()?.length < 3) {
        return;
      }
      setLoading(true);
      const { data } = await apiClient.get(`/search?type=category&query=${search}`);
      setItems(data?.data || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedHandlder = React.useMemo(() => debounce(fetchSubCategories, 500), []);

  return (
    <>
      <Autocomplete
        disablePortal
        options={items}
        value={selectedItems}
        getOptionLabel={(item) => item.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(e, value) => {
          setError(value?.length > 4);
          if (value?.length > 4) {
            return;
          }
          onChange(value);
        }}
        filterOptions={(x) => x}
        fullWidth
        multiple
        loading={loading}
        onInputChange={(e, value) => debouncedHandlder(value)}
        size="small"
        noOptionsText="No categories"
        sx={{
          ".MuiOutlinedInput-root": {
            borderRadius: 4,
            borderWidth: 0,
            padding: "14px !important",
            bgcolor: "#282C46",
          },
          fieldset: { border: "none" },
        }}
        PopperComponent={StyledPopper}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <img src={option.icon} style={{ width: 20, height: 20 }} alt="" />
            <Typography sx={{ flex: 1, ml: 1 }}>{option.name}</Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: 12 },
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {/* {params.inputProps.endAdornment} */}
                </>
              ),
            }}
          />
        )}
      />
      {error && <FormHelperText error>You can select up to 4 categories</FormHelperText>}
    </>
  );
};
