import React from "react";
import { Box, IconButton, ModalProps } from "@mui/material";
import { SearchContextManager } from "@giphy/react-components";
import { EditorState } from "draft-js";

import { BaseModal } from "../BaseModal";
import { GiphyComponent } from "./GiphyComponent";
import { GiphyObject } from "../../../types";
import BoltMentionInput from "../../BoltMentionInput";
import { FeedGiphyContent } from "../../FeedGiphyContent";
import { FlexRow } from "../../FlexRow";
import { ReactComponent as SendIcon } from "../../../assets/svgs/send.svg";

interface Props extends Omit<ModalProps, "children"> {
  inputPlaceholder?: string;
  initialEditorState?: EditorState;
  onSelectItem?: (payload: { text: string; draftContent: string; giphy: GiphyObject }) => void;
}

export const GiphyModal = ({ inputPlaceholder, initialEditorState, onSelectItem = () => {}, ...props }: Props) => {
  const [selectedGiphy, setSelectedGiphy] = React.useState<GiphyObject>();
  const [text, setText] = React.useState("");
  const [draftContent, setDraftContent] = React.useState("");
  const [resetCreator, setResetCreator] = React.useState(0);

  React.useEffect(() => {
    if (props.open) {
      setSelectedGiphy(undefined);
    }
  }, [props.open]);

  return (
    <BaseModal {...props}>
      <Box>
        {!selectedGiphy && (
          <Box>
            <SearchContextManager apiKey={process.env.REACT_APP_GIPHY_API_KEY!} theme={{ darkMode: true }}>
              <GiphyComponent onSelectItem={setSelectedGiphy} />
            </SearchContextManager>
          </Box>
        )}
        {!!selectedGiphy && (
          <Box sx={{ minWidth: 400, minHeight: 200, backgroundColor: "black" }}>
            <FeedGiphyContent data={selectedGiphy} />
            <FlexRow
              sx={{
                flex: 1,
                gap: 0.625,
                alignItems: "flex-end",
                p: 1.25,
                backgroundColor: "#282C46",
                borderRadius: 1,
                width: 1,
                maxWidth: 400,
                // paddingRight: mainCommentReply ? undefined : 2,
                // marginTop: mainCommentReply ? 0 : "10px",
              }}
              className="comment-input"
            >
              <BoltMentionInput
                placeholder={inputPlaceholder || "Create a post..."}
                initialEditorState={initialEditorState}
                resetCreator={resetCreator}
                onChangeText={(value: string) => setText(value)}
                onChangeDraftContent={setDraftContent}
              />
              <IconButton
                onClick={() => {
                  onSelectItem({ text, draftContent, giphy: selectedGiphy });
                  setResetCreator(Date.now());
                }}
                sx={{ p: 0, mb: "2px" }}
              >
                <SendIcon />
              </IconButton>
            </FlexRow>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};
