import React from "react";
import { CircularProgress, ModalProps, Typography } from "@mui/material";

import { useWithdraw } from "../../../hooks/WithdrawContext";
import { WithdrawModalType } from "../../../types";
import { BaseWithdrawModal } from "./BaseWithdrawModal";
import { FlexCol } from "../../FlexCol";

interface Props extends Partial<ModalProps> {}

export const WithdrawProcessingModal = ({ ...rest }: Props) => {
  const { values } = useWithdraw();

  return (
    <BaseWithdrawModal
      {...rest}
      showHeader={false}
      open={values.modal === WithdrawModalType.WITHDRAW_PROCESSING}
      onClose={() => {}}
    >
      <FlexCol alignItems="center" sx={{ py: 2 }}>
        <CircularProgress />
        <Typography sx={{ color: "#A3AED0", fontWeight: 600, mt: 2 }}>Processing Withdrawal</Typography>
      </FlexCol>
    </BaseWithdrawModal>
  );
};
