import { useMemo } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { BrokenImage } from "@mui/icons-material";

import { Formatter } from "../../../utils";
import { ItemContainer } from "./ItemContainer";
import { Title } from "./Title";
import { DetailText } from "./DetailText";
import { SubCategoryEntity, TrendItem } from "./types";

type TrendCategoryProps = Omit<TrendItem, "entity"> & {
  entity: SubCategoryEntity;
};

export const TrendCategory = ({ id, entity, views = 0, streams = 0, posts = 0 }: TrendCategoryProps) => {
  const details = useMemo(() => {
    let result = "";

    if (views) {
      result += `${Formatter.formatCount(views)} Views`;
    }

    if (streams) {
      result += `${result ? " • " : ""}${Formatter.formatCount(streams)} Streams`;
    }

    if (posts) {
      result += `${result ? " • " : ""}${Formatter.formatCount(posts)} Posts`;
    }

    return result;
  }, [views, streams, posts]);

  return (
    <ItemContainer>
      <Title title="Category" />

      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#282C46",
            px: 1,
            py: "3px",
            columnGap: 1,
            borderRadius: 1,
          }}
        >
          <Avatar
            src={entity.icon}
            sx={{
              width: 20,
              height: 20,
              borderRadius: 1,
              bgcolor: "rgb(17, 24, 33)",
            }}
          >
            <BrokenImage sx={{ color: "#FFFFFF40", fontSize: 16 }} />
          </Avatar>

          <Typography sx={{ fontSize: 14, color: "white", fontWeight: 600 }}>{entity.name}</Typography>
        </Box>
      </Box>

      <DetailText text={details} />
    </ItemContainer>
  );
};
