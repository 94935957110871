import {
  PropsWithChildren,
  RefObject,
  createContext,
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

type StepScrollContainerContextType = {
  isMounted: boolean;
  scrollRef: RefObject<HTMLDivElement>;
  //
  updateContext: (payload: Partial<Omit<StepScrollContainerContextType, "updateContext">>) => void;
};

const defaultStepScrollContainerContextValue: StepScrollContainerContextType = {
  isMounted: false,
  scrollRef: createRef<HTMLDivElement>(),
  updateContext: () => {},
};

export const StepScrollContainerContext = createContext<StepScrollContainerContextType>(
  defaultStepScrollContainerContextValue
);

type StepScrollContainerContextProviderProps = PropsWithChildren<{}>;

export const StepScrollContainerContextProvider = ({ children }: StepScrollContainerContextProviderProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [contextValue, setContextValue] = useState<StepScrollContainerContextType>(
    defaultStepScrollContainerContextValue
  );

  const updateContext: StepScrollContainerContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    if (scrollRef.current) {
      updateContext({ isMounted: true });
    }
  }, [scrollRef.current]);

  const memoizedValue = useMemo(() => {
    return { ...contextValue, scrollRef, updateContext };
  }, [contextValue]);

  return <StepScrollContainerContext.Provider value={memoizedValue}>{children}</StepScrollContainerContext.Provider>;
};

export const useStepScrollContainerContext = () => {
  const context = useContext(StepScrollContainerContext);

  if (!context) {
    throw new Error("useStepScrollContainerContext must be use inside PrivateLayoutProvider");
  }

  return context;
};
