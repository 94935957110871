import { useState } from "react";
import { Drawer } from "@mui/material";

import { ImmersivePanel } from "../../components";
import { usePrivateLayoutContext } from "../private-layout";
import { useImmersivePanelLayoutContext } from "./ImmersivePanelLayoutContext";

export const ImmersivePanelDrawer = () => {
  const { navbar__height } = usePrivateLayoutContext();

  const {
    chatProps,
    immersivePanel__open,
    immersivePanel__variant,
    immersivePanel__width,
    updateContext: updateImmersivePanelLayout,
  } = useImmersivePanelLayoutContext();

  const [flexDirection, setFlexDirection] = useState("column-reverse");

  return (
    <Drawer
      anchor="right"
      open={immersivePanel__open}
      onClose={() => {
        updateImmersivePanelLayout({ immersivePanel__open: false });
      }}
      variant={immersivePanel__variant}
      sx={{
        width: "100%",
        maxWidth: immersivePanel__width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth: { xs: "100%", sm: `${immersivePanel__width}px` },
          boxSizing: "border-box",
          pt: `${navbar__height}px`,
          flexDirection,
        },
      }}
      PaperProps={{
        id: chatProps.containerId,
      }}
    >
      <ImmersivePanel
        chatProps={chatProps}
        onChangeTab={(value) => {
          setFlexDirection(value === 0 ? "column-reverse" : "column");
        }}
      />
    </Drawer>
  );
};
