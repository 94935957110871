import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import BoltsReceivedPopover from "./BoltsReceivedPopover";
import { FlexRow } from "./FlexRow";
import { BasePost } from "../types";

type Props = {
  large?: boolean;
  showBackgroundColor?: boolean;
  boltCount?: number;
  bolts?: BasePost["bolts"];
};

const BoltsReceived = ({ large, showBackgroundColor, boltCount, bolts }: Props) => {
  const [boltsReceivedAnchor, setBoltsReceivedAnchor] = React.useState<HTMLButtonElement | null>(null);

  let iconSize = 15.6;
  let marginRight = 5.2;
  let fontSize = 11;

  if (large) {
    iconSize = 30;
    marginRight = 8.38;
    fontSize = 18.4;
  }

  if (!boltCount) return null;

  return (
    <FlexRow sx={{ alignItems: "center", alignSelf: "center" }}>
      <IconButton
        sx={{
          px: 1.2,
          py: 0.5625,
          background: showBackgroundColor ? "#3C436F" : "transparent",
          ":hover": showBackgroundColor
            ? undefined
            : {
              background: "rgba(255, 255, 255, 0.05)",
            },
          borderRadius: "8px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setBoltsReceivedAnchor(e.currentTarget);
        }}
      >
        <Typography sx={{ fontWeight: 300, fontSize, lineHeight: "100%", color: "rgba(255, 255, 255, 0.5)" }}>
          <span style={{ fontWeight: 600, display: "inline-block" }}>${boltCount}</span> Received
        </Typography>
      </IconButton>
      <BoltsReceivedPopover {...{ boltsReceivedAnchor, setBoltsReceivedAnchor, bolts }} />
    </FlexRow>
  );
};

export default BoltsReceived;
