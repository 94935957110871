import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { apiClient } from "../../../../config";
import { BloombergEpisodesPageContextType } from "./types";
import { defaultBloombergEpisodesPageContextValue } from "./consts";
import { BloombergEpisodesPageContext } from "./BloombergEpisodesPageContext";

type BloombergEpisodesPageProviderProps = PropsWithChildren<{}>;

export const BloombergEpisodesPageProvider = ({ children }: BloombergEpisodesPageProviderProps) => {
  const [contextValue, setContextValue] = useState<BloombergEpisodesPageContextType>(
    defaultBloombergEpisodesPageContextValue
  );

  const { categorySlug = "" } = useParams();

  const updateContext: BloombergEpisodesPageContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  const fetchData = useCallback(async () => {
    try {
      updateContext({ isLoadingData: true });

      const { data } = await apiClient.get(`/partner/bloomberg/${categorySlug}`);

      updateContext({ data });
    } catch (err) {
      console.error("Error at fetchHomeChannels: ", err);
    } finally {
      updateContext({ isLoadingData: false });
    }
  }, [categorySlug]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const memoizedValue = useMemo(
    () => ({
      ...contextValue,
      updateContext,
    }),
    [contextValue]
  );

  return (
    <BloombergEpisodesPageContext.Provider value={memoizedValue}>{children}</BloombergEpisodesPageContext.Provider>
  );
};
