import { all, fork, take } from "redux-saga/effects";
import * as actions from "./actions";

function* template() {
  while (true) {
    const { payload } = yield take(actions.SWITCH_THEME_ACTION);
  }
}

export default function* uiSaga() {
  yield all([fork(template)]);
}
