import React from "react";
import { Grid, Skeleton, SkeletonProps } from "@mui/material";
import { Formatter } from "../../../utils";

const MySkeleton = ({ variant = "text", sx, ...other }: SkeletonProps) => {
  return <Skeleton variant={variant} animation="wave" sx={{ opacity: 0.4, mt: 0, ...sx }} {...other} />;
};

export const VideosSkeleton = () => {
  return (
    <>
      {Formatter.nArray(4).map((index) => (
        <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={index}>
          <MySkeleton
            variant="rectangular"
            sx={{
              backgroundColor: "background.paper",
              width: "100%",
              height: { xs: 90, sm: 120, md: 140 },
              borderRadius: { xs: 2, md: 2.5 },
            }}
          />
          <MySkeleton sx={{ mt: 0.5 }} />
          <MySkeleton />
          <MySkeleton />
        </Grid>
      ))}
    </>
  );
};
