import { Avatar, Box, ButtonBase, Typography, alpha, useTheme } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";

import { OrderChip } from "./OrderChip";

export type SliderItemProps = {
  order?: number;
  thumbnail?: string;
  isLive?: boolean;
  isSelected?: boolean;
  onItem?: () => void;
};

export const SliderItem = ({
  order = 0,
  thumbnail = "",
  isLive = false,
  isSelected = false,
  onItem = () => {},
}: SliderItemProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", columnGap: 2 }}>
      <OrderChip order={order} isSelected={isSelected} />

      <ButtonBase
        sx={{
          borderRadius: "15px",
          overflow: "hidden",
          position: "relative",
          ...(isSelected
            ? {
                outline: `3px solid ${theme.palette.primary.main}`,
              }
            : {
                "&:hover": {
                  outline: `3px solid ${alpha("#fff", 0.2)}`,
                },
              }),
        }}
        onClick={onItem}
      >
        <Avatar
          src={thumbnail}
          sx={{
            width: { xs: "65vw", md: "25vw" },
            height: { xs: `calc(65vw / 1.8)`, md: `calc(25vw / 1.8)` },
            borderRadius: "15px",
            bgcolor: "rgb(35, 37, 51)",
            ".MuiAvatar-img": {
              scale: "1",
              transition: theme.transitions.create(["scale", "transform"]),
            },
            "&:hover": {
              ".MuiAvatar-img": {
                scale: "1.1",
              },
            },
          }}
        >
          <VideocamIcon sx={{ fontSize: 60, color: "#666" }} />
        </Avatar>

        {isLive && (
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 12,
              bgcolor: theme.palette.error.main,
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              columnGap: 0.5,
              px: 0.6,
              py: 0.2,
            }}
          >
            <Box sx={{ width: 5, height: 5, bgcolor: "#fff", borderRadius: 5 }} />

            <Typography sx={{ color: "#fff", fontWeight: 500, fontSize: 10 }}>LIVE</Typography>
          </Box>
        )}
      </ButtonBase>
    </Box>
  );
};
