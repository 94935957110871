import React from "react";
import { Button, Paper, IconButton, Typography } from "@mui/material";

import { FlexCol, FlexRow } from "../../components";
import { ReactComponent as BellIcon } from "../../assets/svgs/bell.svg";
import { ReactComponent as BinancePayIcon } from "../../assets/svgs/binancePay.svg";

export const CarouselCard = () => {
  return (
    <FlexRow sx={{ width: "100%" }}>
      <FlexCol sx={{ overflow: "hidden", position: "relative" }}>
        <video
          autoPlay
          loop
          muted
          preload="auto"
          webkit-playsinline="true"
          playsInline
          src="https://cdn.boltplus.tv/assets/binance.mp4"
          width="auto"
          height="294"
          style={{ objectFit: "cover" }}
        />

        <FlexCol component={Paper} sx={{ p: 2, borderRadius: 2.5, width: "100%" }}>
          <FlexRow>
            <FlexCol sx={{ mr: "auto" }}>
              <Typography fontWeight="700">
                Set up your
                <br />
                Binance Pay wallet
              </Typography>
            </FlexCol>
            <BinancePayIcon />
          </FlexRow>

          <Typography variant="caption" sx={{ fontSize: 10 }}>
            Use it on Bolt+ for virtual gifting and tipping!
          </Typography>

          <FlexRow sx={{ mt: 2, justifyContent: "space-between" }}>
            <Button
              variant="contained"
              sx={{ textTransform: "none", borderRadius: 2 }}
              onClick={() => window.open(`https://pay.binance.com`)}
            >
              <Typography variant="subtitle2">Learn More</Typography>
            </Button>
            <IconButton sx={{ bgcolor: "lightgray" }}>
              <BellIcon stroke="black" />
            </IconButton>
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </FlexRow>
  );
};
