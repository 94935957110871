import { Avatar, AvatarProps, Box, ButtonBase, Typography, alpha } from "@mui/material";

export type BrowseItemProps = {
  id: string;
  imageProps?: AvatarProps;
  title?: string;
  subTitle?: string;
  active?: boolean;
  onClick?: (id: string) => void;
};

export const BrowseItem = ({
  id,
  imageProps,
  title = "",
  subTitle = "",
  active = false,
  onClick = () => {},
}: BrowseItemProps) => {
  return (
    <Box sx={{ p: 0.75 }}>
      <ButtonBase
        onClick={() => onClick(id)}
        sx={{
          bgcolor: "rgb(35, 37, 51)",
          px: 4,
          py: 2.5,
          borderRadius: "10px",

          ...(active
            ? {
                outline: "3px solid white",
              }
            : {}),

          "&:hover": {
            ...(!active ? { outline: `3px solid ${alpha("#fff", 0.2)}` } : {}),
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2.5 }}>
          {imageProps && (
            <Avatar sx={{ bgcolor: "transparent", width: 25, height: 25, borderRadius: 0.5 }} {...imageProps} />
          )}

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {title && (
              <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>{title}</Typography>
            )}

            {subTitle && <Typography>{subTitle}</Typography>}
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
};
