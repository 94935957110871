import Axios from "axios";

import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { ALCHEMY_URL } from "./constant";
import { ERC20Balance } from "../../../types";

const web3 = createAlchemyWeb3(ALCHEMY_URL);

export const getBalance = async (walletAddress: string) => {
  try {
    const balance = await web3.eth.getBalance(walletAddress);

    return web3.utils.fromWei(balance);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("getBalance", error);
    throw error;
  }
};

export const getERC20Balances = async (walletAddress: string) => {
  try {
    const data = {
      jsonrpc: "2.0",
      method: "alchemy_getTokenBalances",
      params: [walletAddress, "DEFAULT_TOKENS"],
      id: 42,
    };

    const res: any = await Axios.post(ALCHEMY_URL, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.data.result.tokenBalances.length) {
      return [];
    }

    const balances = res.data.result.tokenBalances.map((item: ERC20Balance) => {
      return {
        ...item,
        tokenBalance: web3.utils.fromWei(item.tokenBalance),
      };
    });

    return balances;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("getERC20Balances", error);
    throw error;
  }
};

export const getNFTByContractAddress = async (walletAddress: string, contractAddress: string) => {
  return Axios.get(`${ALCHEMY_URL}/getNFTs/`, {
    params: {
      owner: walletAddress,
      "contractAddresses[]": contractAddress,
      withMetadata: false,
    },
  });
};
