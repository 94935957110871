import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SkipNext } from "@mui/icons-material";
import { Button } from "@mui/material";

import { useCurrentEpisode } from "./useCurrentEpisode";

export const NextBtn = () => {
  const navigate = useNavigate();

  const { nextEpisode, categorySlug } = useCurrentEpisode();

  const onClick = useCallback(() => {
    navigate(`/p/bloomberg/${categorySlug}/${nextEpisode?.slug}`);
  }, [categorySlug, nextEpisode?.slug]);

  return (
    <Button
      variant="contained"
      endIcon={<SkipNext />}
      sx={{
        bgcolor: "rgba(255, 255, 255, 0.10)",
        borderRadius: 100,

        "&:hover": { bgcolor: "rgba(255, 255, 255, 0.20)" },
      }}
      onClick={onClick}
    >
      Next
    </Button>
  );
};
