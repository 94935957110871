import React from "react";
import { Avatar, IconButton, Slider, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
// import { Howl, Howler } from 'howler';
// import Hls from 'hls.js'
import ReactPlayer from "react-player/lazy";
import PlayIcon from "@mui/icons-material/PlayArrowRounded";
import PauseIcon from "@mui/icons-material/PauseRounded";
import NextIcon from "@mui/icons-material/SkipNextRounded";
import VolumeNormalIcon from "@mui/icons-material/VolumeUpRounded";
import VolumeOffRoundedIcon from "@mui/icons-material/VolumeOffRounded";
import CloseIcon from "@mui/icons-material/Close";
// import OpenPlayerJS from 'openplayerjs';

import { FlexCol, FlexRow } from ".";
import { RadioStation } from "../types";

interface Props {
  item: RadioStation;
  onNext: (item?: RadioStation) => void;
  onClose: () => void;
}

const FloatingRadioPlayer = ({ item, onNext, onClose }: Props) => {
  const [play, setPlay] = React.useState(true);
  const [volume, setVolume] = React.useState(0.5);
  const [muted, setMuted] = React.useState(false);
  const [showVolumeSlider, setShowVolumeSlider] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const handlePlay = () => {
    setPlay(true);
  };

  const handlePause = () => {
    setPlay(false);
  };

  const renderControl = () => {
    if (loading) {
      return null;
    }

    if (!loading && play) {
      return (
        <IconButton onClick={handlePause}>
          <PauseIcon sx={{ width: 50, height: 50 }} />
        </IconButton>
      );
    }

    return (
      <IconButton onClick={handlePlay}>
        <PlayIcon sx={{ width: 50, height: 50 }} />
      </IconButton>
    );
  };

  return (
    <>
      <ReactPlayer
        stopOnUnmount
        className="radio-player"
        volume={volume}
        url={item.url}
        playing={play}
        muted={muted}
        playsinline
        onStart={() => setLoading(false)}
        config={{
          file: {
            // forceAudio: true,
            // forceHLS: true,
            attributes: { playsInline: true },
            hlsOptions: {
              liveSyncDurationCount: 10,
              initialLiveManifestSize: 10,
            },
          },
        }}
      />

      <FlexRow
        sx={{
          width: { xs: `calc(100vw - 40px)`, sm: 300, md: 442 },
          position: "fixed",
          bottom: { xs: 85, md: 40 },
          right: { xs: 20, md: 40 },
          ml: 3,
          zIndex: 10,
          borderRadius: 3,
          alignItems: "center",
          justifyContent: "space-between",
          backdropFilter: "blur(40px)",
          backgroundColor: "rgba(40, 44, 70, 0.5)",
          ":hover": {
            ".close-btn": { display: "inherit" },
          },
        }}
      >
        <FlexRow sx={{ alignItems: "center" }} className="items-center">
          <FlexCol sx={{ position: "relative" }}>
            <FlexRow sx={{ position: "absolute", inset: 0 }} className={play ? "radio-shadow" : ""} />
            <Avatar
              variant="rounded"
              src={item.logo}
              alt={item.title}
              sx={{ height: { xs: 69, md: 85 }, width: { xs: 69, md: 85 }, bgcolor: "black", borderRadius: 3 }}
            />
            <FlexRow sx={{ position: "absolute", inset: 0, alignItems: "center", justifyContent: "center" }}>
              {renderControl()}
            </FlexRow>
          </FlexCol>
          <FlexCol sx={{ ml: 4 }}>
            <Typography variant="h6" fontWeight="600" sx={{ fontSize: { xs: "15px", md: "19px" } }}>
              {item.title}
            </Typography>
          </FlexCol>
        </FlexRow>
        <FlexCol sx={{ pr: 2 }}>
          <FlexRow className="items-center">
            <FlexCol sx={{ position: "relative" }}>
              <IconButton
                onClick={() => setMuted(!muted)}
                onMouseOver={() => setShowVolumeSlider(true)}
                onMouseLeave={() => setShowVolumeSlider(false)}
              >
                {muted && <VolumeOffRoundedIcon fontSize="medium" />}
                {!muted && <VolumeNormalIcon fontSize="medium" />}
              </IconButton>
              <Slider
                orientation="vertical"
                value={volume * 100}
                min={0}
                max={100}
                sx={{
                  height: 100,
                  position: "absolute",
                  bottom: "100%",
                  left: { xs: 0, sm: 4 },
                  opacity: showVolumeSlider ? 1 : 0,
                  "& .MuiSlider-thumb": {
                    height: 14,
                    width: 14,
                    backgroundColor: "#fff",
                    border: "2px solid currentColor",
                    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                      boxShadow: "inherit",
                    },
                    "&:before": {
                      display: "none",
                    },
                  },
                  "& .MuiSlider-track": {
                    width: 3,
                  },
                }}
                onChange={(_, value) => setVolume((value as number) / 100)}
                onMouseOver={() => setShowVolumeSlider(true)}
                onMouseLeave={() => setShowVolumeSlider(false)}
              />
            </FlexCol>
            <IconButton onClick={() => onNext(item)}>
              <NextIcon />
            </IconButton>
          </FlexRow>
        </FlexCol>
        <IconButton
          className="close-btn"
          onClick={onClose}
          sx={{
            position: "absolute",
            display: { xs: "inherit", sm: "none" },
            top: -16,
            right: -16,
            p: 0.5,
            backgroundColor: "secondary.dark",
          }}
        >
          <CloseIcon />
        </IconButton>
      </FlexRow>
    </>
  );
};

export default React.memo(FloatingRadioPlayer);
