import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, ButtonBase, Typography, alpha, useTheme } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";

import { SubCategory, useSearchLandingContext } from "../search-landing";
import { usePrivateNavbarContext } from "../../navbar";
import { CollectionsSvg } from "./CollectionsSvg";

export const Collections = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { updateContext: updateNavbarContext } = usePrivateNavbarContext();

  const { subCategories } = useSearchLandingContext();

  const onCollectioItem = useCallback((item: SubCategory) => {
    if (item.partner && item.partner?.slug !== "boltplus") {
      navigate(`/p/${item.partner.slug}/${item.slug}`);
    } else {
      navigate(`/c/${item.slug}`);
    }

    updateNavbarContext({ activeDrawer: undefined });
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: 1.5 }}>
        <CollectionsSvg />

        <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Collections</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          width: "100%",
        }}
      >
        {subCategories.data.map((item, index) => (
          <ButtonBase
            key={`recommend-item-${index}`}
            sx={{
              borderRadius: "10px",
              overflow: "hidden",
              "&:hover": {
                outline: `3px solid ${alpha("#fff", 0.2)}`,
              },
            }}
            onClick={() => onCollectioItem(item)}
          >
            <Avatar
              src={item.thumbnails.portrait}
              sx={{
                width: 225,
                height: 300,
                borderRadius: "10px",
                bgcolor: "rgb(35, 37, 51)",
                ".MuiAvatar-img": {
                  scale: "1",
                  transition: theme.transitions.create(["scale", "transform"]),
                },
                "&:hover": {
                  ".MuiAvatar-img": {
                    scale: "1.1",
                  },
                },
              }}
            >
              <ImageIcon sx={{ fontSize: 60, color: "#666" }} />
            </Avatar>
          </ButtonBase>
        ))}
      </Box>

      {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          startIcon={<KeyboardArrowDownIcon />}
          sx={{ color: "rgba(255, 255, 255, 0.50)", px: 2, borderRadius: "10px" }}
        >
          Show more
        </Button>
      </Box> */}
    </Box>
  );
};
