import React from "react";
import { IconButton, useTheme } from "@mui/material";

import { usePrivateNavbarContext } from "../PrivateNavbarContext";
import { SearchSvg } from "./SearchSvg";

export const SearchButton = () => {
  const theme = useTheme();

  const { updateContext: updateNavbarContext } = usePrivateNavbarContext();

  return (
    <IconButton
      sx={{
        width: 36,
        height: 36,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: theme.palette.secondary.dark,

        "&:hover": {
          bgcolor: theme.palette.secondary.light,
        },
      }}
      onClick={() => {
        updateNavbarContext({
          activeDrawer: "search-result",
        });
      }}
    >
      <SearchSvg />
    </IconButton>
  );
};
