import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import { FlexCol, FeedCard, PostBar } from "../../components";
import { authSelector } from "../../store/auth/selector";
import { createStreamFeed, fetchHomeStreamFeed, likeStreamFeed } from "../../store/feed/actions";
import { streamFeedSelector } from "../../store/feed/selector";
import { EmptySkeleton } from "./EmptySkeleton";
import { RefreshToSeeNewPosts } from "./RefreshToSeeNewPosts";

interface Props {
  hidePostBar?: boolean;
}

const christmasPromotion = {
  photoUrl:
    "https://s3.ap-southeast-1.amazonaws.com/live.bolt.global/public/image/uploads/kucoinlogo_1659973658563.png",
  fullName: "KuCoin",
  text: "Merry Christmas! Bolt+ Offers You a $BOLT Prize Pool Worth $30,000!\r\nTo celebrate the upcoming Christmas, we will be launching a campaign to give away a BOLT prize pool worth $30,000 to qualified KuCoin users.\r\nCampaign Period: 10:00:00 on December 12, 2022 to 10:00:00 on December 19, 2022 (UTC)",
  image: "https://cdn.boltplus.tv/feed/stream/1670846021131-telegram-cloud-photo-size-5-6104893942651794550-y.jpg",
  firstName: "KuCoin",
  externalUrl: "https://www.kucoin.com/news/en-merry-christmas-bolt-offers-you-a-bolt-prize-pool-worth-30000usdt",
};

const footballPromotion = {
  photoUrl: "https://cdn.boltplus.tv/profile/avatar/1668598201039_boltplusicon.undefined",
  fullName: "Boltplus",
  text: "Post your best World Cup 2022 memes for your chance to win this week's #GetBolted prize of $2,000! The meme with the most combined likes and comments wins!",
  image: "https://cdn.boltplus.tv/feed/stream/1670948933866-streamyardcoverimageV2.jpg",
  firstName: "Boltplus",
};

export const StreamFeedList = ({ hidePostBar = false }: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const {
    items,
    loading,
    page,
    hasMore,
    action: { loading: creatingFeed },
  } = useSelector(streamFeedSelector);

  const [resetCreator, setResetCreator] = React.useState(0);

  const onSubmit = (data: { files?: File[]; text?: string; draftContent?: string; uploadId?: number }) => {
    dispatch(
      createStreamFeed({
        ...data,
        onSuccess: () => {
          setResetCreator(Date.now());
        },
      })
    );
  };

  React.useEffect(() => {
    dispatch(fetchHomeStreamFeed());
  }, [dispatch]);

  const onLike = (feedId: string, like: boolean) => {
    dispatch(likeStreamFeed({ feedId, like }));
  };

  return (
    <FlexCol sx={{ rowGap: 2 }}>
      {!hidePostBar && (
        <>
          <PostBar
            showAvatar
            loading={creatingFeed}
            avatarUrl={user?.photoUrl}
            resetCreator={resetCreator}
            placeHolder="Create a post.."
            onSubmit={onSubmit}
          />

          <hr
            style={{
              width: "100%",
              marginTop: 0,
              marginBottom: 0,
              borderLeft: 0,
              borderRight: 0,
              borderTop: 0,
              borderBottom: "0.25px solid rgba(255, 255, 255, 0.05)",
            }}
          />
        </>
      )}

      <RefreshToSeeNewPosts />

      <InfiniteScroll
        pageStart={1}
        loadMore={() => !loading && dispatch(fetchHomeStreamFeed({ page: page + 1 }))}
        hasMore={hasMore}
        useWindow
      >
        <FlexCol sx={{ rowGap: 2 }}>
          {items.map((item, index) => (
            <FeedCard key={index} item={item} />
          ))}
        </FlexCol>
      </InfiniteScroll>

      {loading && (
        <FlexCol sx={{ rowGap: 2, mt: items.length === 0 ? -2 : 0 }}>
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
        </FlexCol>
      )}
    </FlexCol>
  );
};
