import { all, fork, put, take } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* fetchStreamCategories() {
  while (true) {
    yield take(actions.FETCH_STREAM_CATEGORIES + START);
    try {
      const { data } = yield apiClient.get(`/categories/stream`);
      yield put({ type: actions.FETCH_STREAM_CATEGORIES + SUCCESS, payload: data || [] });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({ type: actions.FETCH_STREAM_CATEGORIES + FAIL, payload: BoltError(error) });
    }
  }
}

function* fetchStreamSubCategories() {
  while (true) {
    const { payload } = yield take(actions.FETCH_STREAM_SUB_CATEGORIES + START);
    try {
      const { data } = yield apiClient.get(`/subCategories?search=${payload.search || ""}`);
      yield put({
        type: actions.FETCH_STREAM_SUB_CATEGORIES + SUCCESS,
        payload: { ...payload, data },
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_STREAM_SUB_CATEGORIES + FAIL,
        payload: { ...payload, error },
      });
    }
  }
}

export default function* channelSaga() {
  yield all([fork(fetchStreamCategories), fork(fetchStreamSubCategories)]);
}
