import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { InView } from "react-intersection-observer";
import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

import { NFTFeed, REPORT_TYPE, StreamFeed } from "../types";
import { Formatter } from "../utils";
import { DefaultAvatar } from "./DefaultAvatar";
import { FlexRow } from "./FlexRow";
import TimeFromNow from "./TimeFromNow";
import { FlexCol } from "./FlexCol";
import { ReadMore } from "./ReadMore";
import { LinkPreview } from "./LinkPreview";
import ZoomedViewModal from "../pages/feed/ZoomedViewModal";
import FeedVideo from "./FeedVideo";
import { FeedContentTextBody } from "./FeedContentTextBody";
import { ConfirmModal, ReportModal } from "./modals";
import { userSelector } from "../store/auth/selector";
import { deleteStreamFeedAction } from "../store/feed/actions";
import { FeedGiphyContent } from "./FeedGiphyContent";
import { RewindLabel } from "./RewindLabel";
import { BoltAnalytics } from "../services";

const MENU_ITEM_HEIGHT = 48;

type Props = {
  item: StreamFeed | NFTFeed;
  home?: boolean;
  disabled?: boolean;
  disableExpandView?: boolean;
  rebolt?: boolean;
  excludeMedia?: boolean;
};

const FeedContentTop = ({ item, home, disabled, disableExpandView, rebolt, excludeMedia }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [zoomedViewOpen, setZoomedViewOpen] = useState(false);
  const [contentIndex, setContentIndex] = useState<number>(0);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();

  const open = Boolean(anchorEl);
  const options = React.useMemo(() => {
    return user?.id === item?.creator?.id ? ["Delete"] : ["Report"];
  }, [user, item]);

  useEffect(() => {
    const handler = () => setAnchorEl(undefined);
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action: string) => {
    if (action === "Delete") {
      setShowDeleteModal(true);
    } else if (action === "Report") {
      setShowReportModal(true);
    }
    setAnchorEl(undefined);
  };

  const handleMediaClick = useCallback(
    (e: React.MouseEvent<HTMLImageElement | HTMLDivElement, MouseEvent>, content: number) => {
      e.stopPropagation();
      setZoomedViewOpen(true);
      setContentIndex(content);

      if (item.rewind?.id || item.reboltFeed?.rewind?.id) {
        BoltAnalytics.watchRewind(item.rewind?.id || item.reboltFeed?.rewind?.id);
      }
    },
    []
  );

  const reboltFeed = item?.reboltFeed;

  let readMoreText = item?.text ? Formatter.urlify(item.text) : "";

  if (rebolt && reboltFeed?.text) {
    readMoreText = Formatter.urlify(reboltFeed.text);
  }

  let includedUrls = item?.text ? Formatter.parseUrls(item?.text) : [];

  if (rebolt && reboltFeed?.text) {
    includedUrls = Formatter.parseUrls(reboltFeed.text);
  }

  const shouldRenderUrlMeta = includedUrls.length > 0 && ((rebolt && !reboltFeed?.items.length) || !item?.items.length);

  const mediaItem = useCallback(
    (index: number) => {
      if (item) {
        let selectedMediaArray = item.items;
        if (item.reboltFeed?.items) {
          selectedMediaArray = item.reboltFeed.items;
        }

        if (selectedMediaArray.length > 1) {
          const videoItemIndex = selectedMediaArray.findIndex((e) => e.type === "video");
          if (videoItemIndex > 0) {
            const firstItem = selectedMediaArray[0];
            const videoItem = selectedMediaArray[videoItemIndex];
            selectedMediaArray[0] = videoItem;
            selectedMediaArray[videoItemIndex] = firstItem;
          }
        }

        if (selectedMediaArray.length && index < selectedMediaArray.length) {
          if (selectedMediaArray[index].type === "image") {
            return (
              <img
                src={selectedMediaArray[index].url}
                alt="post"
                style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "cover" }}
              />
            );
          }
          return (
            <FeedVideo
              {...{
                url: selectedMediaArray[index].url,
                thumbnail: selectedMediaArray[index].thumbnail,
                onClick: (e: any) => handleMediaClick(e, index),
                zoomedViewOpen,
              }}
            />
          );
        }
      }
      return null;
    },
    [item]
  );

  type InfoType = "url" | "username" | "firstName" | "fullName" | "draftContent" | "text";

  const getFeedData = (type: InfoType) => {
    if (type === "url") {
      return rebolt ? item?.reboltFeed?.creator?.photoUrl : item?.creator?.photoUrl;
    }

    if (type === "username") {
      return rebolt ? item?.reboltFeed?.creator?.username : item?.creator?.username;
    }

    if (type === "firstName") {
      return rebolt ? item?.reboltFeed?.creator?.firstName : item?.creator?.firstName;
    }

    if (type === "fullName") {
      return rebolt ? item?.reboltFeed?.creator?.fullName : item?.creator?.fullName;
    }

    if (type === "draftContent") {
      return rebolt ? item?.reboltFeed?.draftContent : item?.draftContent;
    }

    return rebolt ? item?.reboltFeed?.text : item?.text;
  };

  return (
    <Box
      onClick={
        rebolt && reboltFeed
          ? (e) => {
              e.stopPropagation();
              navigate(`/feed/${reboltFeed.id}`);
            }
          : undefined
      }
      sx={{ cursor: rebolt && reboltFeed ? "pointer" : undefined }}
    >
      <FlexRow
        sx={{
          alignItems: "center",
          pt: 2,
          pl: 3,
          pr: 1.25,
          mb: (rebolt && item?.reboltFeed?.text) || item?.text ? undefined : 2,
        }}
      >
        <Box
          component={Link}
          to={`/${(rebolt && item?.reboltFeed?.creator?.username) || item?.creator?.username}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          <Avatar
            src={getFeedData("url")}
            alt={getFeedData("firstName")}
            sx={{
              borderColor: "primary.main",
              borderWidth: 2,
              borderStyle: "solid",
              height: 34,
              width: 34,
            }}
            imgProps={{ referrerPolicy: "no-referrer" }}
          >
            <DefaultAvatar />
          </Avatar>
          <Typography variant="subtitle1" color="primary.contrastText" sx={{ ml: 1.5, fontWeight: 600 }}>
            {getFeedData("username")}
          </Typography>
          {!!(!rebolt && item?.rewind?.id) && <RewindLabel sx={{ ml: 1 }} />}
          {!!(rebolt && item?.reboltFeed?.rewind?.id) && <RewindLabel sx={{ ml: 1 }} />}
        </Box>
        <TimeFromNow sx={{ ml: "auto" }}>
          {moment((rebolt && item?.reboltFeed?.createdAt) || item?.createdAt).fromNow()}
        </TimeFromNow>
        {!rebolt && (
          <IconButton onClick={(e) => handleClick(e)} sx={{ p: 0.5, ml: 1.5 }}>
            <MoreVertIcon />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock
          PaperProps={{
            style: {
              maxHeight: MENU_ITEM_HEIGHT * 4.5,
              width: 100,
            },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleClose(option)} disabled={disabled}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </FlexRow>
      {((rebolt && !!item?.reboltFeed?.text?.trim()) || !!item?.text?.trim()) && (
        <FlexCol sx={{ my: 2, px: 3 }}>
          <ReadMore
            maxLine={5}
            active={
              (rebolt && item?.reboltFeed?.text?.length && item?.reboltFeed?.text?.length > 250) ||
              (!!item?.text?.length && item?.text?.length > 250)
            }
          >
            <FeedContentTextBody draftContent={getFeedData("draftContent")} text={getFeedData("text")} />
          </ReadMore>
        </FlexCol>
      )}
      {!!(item?.giphy || item?.reboltFeed?.giphy) && (
        <FeedGiphyContent data={item?.giphy! || item?.reboltFeed?.giphy!} />
      )}
      {!excludeMedia && ((rebolt && !!item?.reboltFeed?.items?.length) || !!item?.items?.length) && (
        <FlexRow
          sx={{
            gap: "3px",
            width: "100%",
            bgcolor: "background.paper",
            flexWrap: "wrap",
            height: item.reboltFeed?.items?.length! > 1 || item?.items?.length > 1 ? 187 : 377,
            ...((item?.rewind?.id || item?.reboltFeed?.rewind?.id) && { aspectRatio: "16/9", height: undefined }),
          }}
        >
          <Box
            sx={{ flex: 1, backgroundColor: "black", height: "100%", width: "calc(100% - 1.5px)" }}
            onClick={(e) => handleMediaClick(e, 0)}
          >
            {mediaItem(0)}
          </Box>
          {((item.reboltFeed?.items.length && item.reboltFeed?.items.length > 1) || item.items.length > 1) && (
            <FlexCol sx={{ height: "100%", width: "calc(50% - 1.5px)", gap: "3px" }}>
              <Box sx={{ flex: 1, height: "calc(50% - 1.5px)", width: "100%" }} onClick={(e) => handleMediaClick(e, 1)}>
                {mediaItem(1)}
              </Box>
              {((item.reboltFeed?.items.length && item.reboltFeed?.items.length > 2) || item.items.length > 2) && (
                <FlexRow sx={{ height: "calc(50% - 1.5px)", gap: "3px" }}>
                  <Box
                    sx={{ flex: 1, height: "100%", width: "calc(50% - 1.5px)" }}
                    onClick={(e) => handleMediaClick(e, 2)}
                  >
                    {mediaItem(2)}
                  </Box>
                  {((item.reboltFeed?.items.length && item.reboltFeed?.items.length > 3) || item.items.length > 3) && (
                    <Box sx={{ height: "100%", width: "calc(50% - 1.5px)" }} onClick={(e) => handleMediaClick(e, 3)}>
                      {mediaItem(3)}
                    </Box>
                  )}
                </FlexRow>
              )}
            </FlexCol>
          )}
        </FlexRow>
      )}
      {!excludeMedia && !item.giphy?.id && shouldRenderUrlMeta && (
        <FlexCol sx={{ overflow: "hidden", width: "100%", bgcolor: "background.paper" }}>
          <LinkPreview url={includedUrls[0]} />
        </FlexCol>
      )}
      {!excludeMedia && zoomedViewOpen && !disableExpandView && (
        <ZoomedViewModal
          {...{
            home,
            item,
            onClose: () => setZoomedViewOpen(false),
            contentIndex,
            setContentIndex,
          }}
        />
      )}
      <ConfirmModal
        open={showDeleteModal}
        title="Confirm"
        confirmTitle="Delete"
        description="Are you sure you want to delete this post?"
        onClose={(e?: any) => {
          if (e) e.stopPropagation();
          setShowDeleteModal(false);
        }}
        onConfirm={(e?: any) => {
          if (e) e.stopPropagation();
          dispatch(deleteStreamFeedAction(item.id));
          setShowDeleteModal(false);
        }}
      />
      <ReportModal
        reportType={REPORT_TYPE.STREAM_FEED}
        entityId={item.id}
        open={showReportModal}
        onClose={(e?: any) => {
          if (e) e.stopPropagation();
          setShowReportModal(false);
        }}
        onConfirm={(e?: any) => {
          if (e) e.stopPropagation();
          setShowReportModal(false);
        }}
      />
    </Box>
  );
};

export default FeedContentTop;
