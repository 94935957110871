import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FlexCol } from "../../components";
import { verifyTv } from "../../store/auth/actions";
import { useQuery } from "../../hooks/useQuery";

export const TvAuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();

  React.useEffect(() => {
    if (query.get("token") && typeof query.get("token") === "string") {
      dispatch(verifyTv(query.get("token")));
      navigate("/home");
    }
  }, []);

  return <FlexCol sx={{ margin: "auto", width: "100%", height: "100%" }}>Loading...</FlexCol>;
};
