import { useSearchLandingContext } from "../search-landing";
import { LiveItemProps } from "./LiveItem";

export const useLiveItems = () => {
  const { lives } = useSearchLandingContext();

  const liveItems: LiveItemProps[] = lives.map((item) => {
    return {
      id: item.boltId || "",
      imageProps: {
        src: item.stream_profile ? item.stream_profile.thumbnail : item.thumbnail,
      },
    };
  });

  return { liveItems };
};
