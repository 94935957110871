import { ChatContextProvider, ChatContextProviderProps } from "./ChatContext";
import { ChatUi } from "./ChatUi";

export type ChatProps = ChatContextProviderProps;

export const Chat = (props: ChatProps) => {
  return (
    <ChatContextProvider {...props}>
      <ChatUi />
    </ChatContextProvider>
  );
};
