import Axios from "axios";
import { COINGECKO_API } from "./constant";

export const getPriceByContractAddresses = async (platform: string, contractAddresses: Array<string>) => {
  try {
    const { data } = await Axios.get(
      `${COINGECKO_API}/simple/token_price/${platform}?contract_addresses=${contractAddresses}&vs_currencies=usd`
    );

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("getPriceByContractAddresses", error);
    throw error;
  }
};

export const getEthereumPrice = async () => {
  try {
    const res: any = await Axios.get("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd");

    return res.data.ethereum;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("getEthereumPrice", error);
    throw error;
  }
};
