import { Box, SxProps, Link, Skeleton, Typography } from "@mui/material";
import React from "react";
import { InView } from "react-intersection-observer";
import ReactPlayer from "react-player";

import { apiClient } from "../config";
import useExternalLinkStopPropagation from "../hooks/useExternalLinkStopPropagation";
import { UrlMeta } from "../types";
import { FlexCol } from "./FlexCol";
import { FlexRow } from "./FlexRow";

interface Props {
  url?: string;
  showMetaInfo?: boolean;
  style?: SxProps;
}

export const LinkPreview = ({ url, showMetaInfo = false, style }: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>();
  const [meta, setMeta] = React.useState<UrlMeta | undefined>();
  React.useEffect(() => {
    (async () => {
      if (!url) {
        return;
      }
      try {
        const { data } = await apiClient.post("/scraping/meta", { url });
        setMeta(data as UrlMeta);
      } catch (error) {
        setMeta({});
      }
      setLoading(false);
    })();
  }, [url]);

  useExternalLinkStopPropagation();

  if ((!url || (!loading && !meta?.image)) && !error) {
    return null;
  }

  return (
    <Box
      component={Link}
      href={url}
      target="_blank"
      sx={{
        width: "100%",
        height: undefined,
        aspectRatio: "506/284",
        // borderRadius: 2,
        overflow: "hidden",
        position: "relative",
      }}
      className="external-link"
    >
      {!!meta?.video && (
        <InView rootMargin="-40% 0px -40% 0px">
          {({ inView, ref }) => {
            return (
              <div ref={ref} style={{ width: "100%", height: "100%", backgroundColor: "black" }}>
                <ReactPlayer
                  playing={inView}
                  controls
                  playsinline
                  muted
                  url={meta?.video}
                  width="100%"
                  height="100%"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  pip
                />
              </div>
            );
          }}
        </InView>
      )}
      {!!meta?.image && !meta?.video && (
        <img src={meta?.image} alt="post" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
      )}
      {meta && showMetaInfo && (
        <FlexCol
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#000000aa",
            px: 2,
            py: 1,
            overflow: "hidden",
          }}
        >
          {!!meta?.title && (
            <Typography variant="subtitle2" fontWeight="600" color="white">
              {meta?.title}
            </Typography>
          )}
          {!!meta.description && (
            <Typography variant="caption" lineHeight={1.2} className="max-line-2" color="white">
              {meta?.description}
            </Typography>
          )}
        </FlexCol>
      )}
      {loading && <Skeleton variant="rectangular" animation="wave" height="100%" width="100%" sx={{ opacity: 0.4 }} />}
    </Box>
  );
};
