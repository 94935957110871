import { SearchResultDrawer } from "./SearchResultDrawer";
import { ProfileDrawer } from "./ProfileDrawer";
import { TrendsDrawer } from "./TrendsDrawer";

export const Drawers = () => {
  return (
    <>
      <SearchResultDrawer />

      <ProfileDrawer />

      <TrendsDrawer />
    </>
  );
};
