export enum PRODUCTS {
  BOLT_ADMIN = "BOLT_ADMIN",
  BOLT_PLUS = "BOLT_PLUS",
  BOLT_X = "BOLT_X",
  BOLT_TV = "BOLT_TV",
  BOLT_CMS = "BOLT_CMS",
  BOLT_TRACKING = "BOLT_TRACKING",
}

export enum EnvType {
  dev = "dev",
  testing = "testing",
  staging = "staging",
  prod = "prod",
}