import { Avatar, Box, ButtonBase, Typography, alpha, useTheme } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useNavigate } from "react-router-dom";

import { useStreamsContext } from "./StreamsContext";

export const LiveNow = () => {
  const theme = useTheme();

  const { liveNowItems } = useLiveNowItems();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1.5 }}>
        <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Live Now</Typography>

        <Box sx={{ width: 10, height: 10, bgcolor: theme.palette.error.main, borderRadius: "50%" }} />
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {liveNowItems.map((item, index) => (
          <LiveNowItem key={`live-now-item-${index}`} {...item} />
        ))}
      </Box>
    </Box>
  );
};

type LiveNowItemProps = {
  thumbnail?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
};

const LiveNowItem = ({
  thumbnail = "",
  avatar = "",
  firstName = "",
  lastName = "",
  userName = "",
}: LiveNowItemProps) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const name = `${firstName} ${lastName}`.trim();

  return (
    <ButtonBase
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative",
        bgcolor: "#1C2039",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: 300,
        "&:hover": {
          outline: `3px solid ${alpha("#fff", 0.2)}`,
        },
      }}
      onClick={() => {
        navigate(`/${userName || ""}`);
      }}
    >
      <Avatar
        src={thumbnail}
        sx={{ width: "100%", height: 212, borderRadius: "10px 10px 0 0", bgcolor: "rgb(35, 37, 51)" }}
      >
        <VideocamIcon sx={{ fontSize: 60, color: "#666" }} />
      </Avatar>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
          textAlign: "left",
          px: 2,
          py: 1,
          width: "100%",
        }}
      >
        <Avatar src={avatar} />

        <Box sx={{ overflow: "hidden" }}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "semibold",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </Typography>

          <Typography
            sx={{
              fontSize: 12,
              color: "#ffffff80",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {userName}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 12,
          bgcolor: theme.palette.error.main,
          borderRadius: 10,
          display: "flex",
          alignItems: "center",
          columnGap: 0.5,
          px: 0.6,
          py: 0.2,
        }}
      >
        <Box sx={{ width: 5, height: 5, bgcolor: "#fff", borderRadius: 5 }} />

        <Typography sx={{ color: "#fff", fontWeight: 500, fontSize: 10 }}>LIVE</Typography>
      </Box>
    </ButtonBase>
  );
};

const useLiveNowItems = () => {
  const { liveNows } = useStreamsContext();

  const liveNowItems: LiveNowItemProps[] = liveNows.map((item) => {
    return {
      thumbnail: item.streamThumbnail || "",
      avatar: item.photoUrl || "",
      firstName: item.firstName || "",
      lastName: item.lastName || "",
      userName: item.username || "",
    };
  });

  return { liveNowItems };
};
