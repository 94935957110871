import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { apiClient } from "../../../config";
import { TrendItem } from "./types";

type TrendListContextType = {
  data: TrendItem[];
  isLoading: boolean;
  //
  updateContext: (payload: Partial<Omit<TrendListContextType, "updateContext">>) => void;
};

const defaultTrendListContextValue: TrendListContextType = {
  data: [],
  isLoading: true,
  //
  updateContext: () => {},
};

export const TrendListContext = createContext<TrendListContextType>(defaultTrendListContextValue);

type TrendListProviderProps = PropsWithChildren<{}>;

export const TrendListProvider = ({ children }: TrendListProviderProps) => {
  const [contextValue, setContextValue] = useState<TrendListContextType>(defaultTrendListContextValue);

  const updateContext: TrendListContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  const fetchTrendList = useCallback(async () => {
    try {
      updateContext({ isLoading: true });

      const { data } = await apiClient.get("/discover/trends");

      updateContext({ data });
    } catch (err) {
      console.error("Error at fetchTrendList: ", err);
    } finally {
      updateContext({ isLoading: false });
    }
  }, []);

  useEffect(() => {
    fetchTrendList();
  }, [fetchTrendList]);

  const memoizedValue = useMemo(() => {
    return {
      ...contextValue,
      updateContext,
    };
  }, [contextValue]);

  return <TrendListContext.Provider value={memoizedValue}>{children}</TrendListContext.Provider>;
};

export const useTrendListContext = () => {
  const context = useContext(TrendListContext);

  return context;
};
