import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Box, BoxProps, Button, Grid, IconButton, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as DiscordIcon } from "../../assets/svgs/discord.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svgs/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/svgs/instagram.svg";
import { ReactComponent as EditIcon } from "../../assets/svgs/editIcon.svg";
import { DefaultAvatar } from "../DefaultAvatar";
import { FlexRow, FlexCol, ProfileMenuList } from "..";
import { ConfirmationModal, UserSettingsModal } from "../modals";
import { Channel, PROFILE_MENU_ITEM, PROFILE_MENU_TYPE, User } from "../../types";
import { Formatter } from "../../utils";
import { authSelector } from "../../store/auth/selector";
import { followUserAction, getUserProfileAction } from "../../store/profile/actions";
import { userProfileSelector } from "../../store/profile/selector";
import { changeChannelFollowAction, checkChannelFollowAction } from "../../store/channel/actions";
import { channelFollowSelector } from "../../store/channel/selector";

type SocialItemProps = {
  id?: string;
  link: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | any;
};

const SocialItem = ({ id, link, Icon }: SocialItemProps) => {
  if (id === "" || id === null || id === undefined) return null;
  return (
    <Grid item>
      <IconButton onClick={() => window.open(link)} aria-label={link}>
        <Icon style={{ width: 16, height: 16 }} />
      </IconButton>
    </Grid>
  );
};

type HeaderProps = {
  user?: User;
  channel?: Channel;
  currentTab: PROFILE_MENU_ITEM;
  enableChat?: boolean;
  isUser: boolean;
  openChatBox: () => void;
  openGiftBox: () => void;
  getEntity?: () => void;
  setCurrentTab: (tab: PROFILE_MENU_ITEM) => void;
} & BoxProps;

export const ProfileHeader = (props: HeaderProps) => {
  const {
    user,
    channel,
    currentTab,
    enableChat = true,
    isUser = false,
    openChatBox,
    openGiftBox,
    setCurrentTab = () => {},
    getEntity = () => {},
    sx,
  } = props;

  const dispatch = useDispatch();
  const { boltId: userId } = useParams();
  const { user: userAuth } = useSelector(authSelector);
  const { user: userProfile } = useSelector(userProfileSelector);
  const { loading, error, isFollow } = useSelector(channelFollowSelector);

  const isMyProfile = userAuth?.username === userId;
  const [unfollowModalVisible, setUnfollowModalVisible] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const discordId = user?.profile.discordId || channel?.discordId;
  const twitterId = user?.profile.twitterId || channel?.twitterId;
  const instagramId = user?.profile.instagramId || channel?.instagramId;
  const website = user?.profile.website || channel?.website;
  const websiteLink = `${website?.startsWith("http://") || website?.startsWith("https://") ? "" : "http://"}${website}`;
  const followerCount = Formatter.formatCount(userProfile?.followers || channel?.followers || 0);
  const followingCount = Formatter.formatCount(userProfile?.following || 0);
  const isFollowing = isFollow || userProfile?.followed;
  const isShowTweetsForChannel = false; // !isUser && !!channel?.categories.includes("6268a56c411eaadc0dd1b17a");

  const callChangeChannelFollow = useCallback((data: any) => dispatch(changeChannelFollowAction(data)), [dispatch]);
  const callCheckChannelFollow = useCallback((data: any) => dispatch(checkChannelFollowAction(data)), [dispatch]);
  const callGetUserProfileAction = useCallback((data: any) => dispatch(getUserProfileAction(data)), [dispatch]);

  useEffect(() => {
    if (channel?.channelId) callCheckChannelFollow({ channelId: channel?.channelId });
  }, [channel?.channelId]);

  useEffect(() => {
    if (user?.id) callGetUserProfileAction(user?.id);
    window.scrollTo({ top: 0 });
  }, [user?.id]);

  const postFollow = () => {
    if (isUser) dispatch(followUserAction({ follow: !userProfile?.followed, userId: user?.id }));
    else callChangeChannelFollow({ channelId: channel?.channelId, follow: !isFollowing });
    setTimeout(() => {
      setUnfollowModalVisible(false);
    }, 300);
    if (error === null) getEntity();
  };

  const handleClickFollow = () => {
    isFollowing ? setUnfollowModalVisible(true) : postFollow();
  };

  return (
    <Box sx={{ mt: 3, width: { sm: "auto", md: "93%", lg: "95%" }, mx: "auto", ...sx }}>
      <Box
        sx={{
          display: "flex",
          mx: { xs: 2, sm: 2, md: 0 },
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FlexRow>
          <Avatar
            src={user?.photoUrl || channel?.logo}
            alt={user?.username || channel?.title}
            sx={{
              borderColor: "primary.main",
              borderWidth: 2,
              borderStyle: "solid",
              height: { xl: 110, lg: 100, md: 90, sm: 73, xs: 55 },
              width: { xl: 110, lg: 100, md: 90, sm: 73, xs: 55 },
              objectFit: "cover",
            }}
          >
            <DefaultAvatar width="100%" height="100%" />
          </Avatar>
          <FlexRow
            sx={{
              ml: { lg: 2, md: 1.2 },
              justifyContent: "flex-start",
              alignItems: "center",
              width: "fit-content",
              display: "flex",
              flexFlow: "row wrap",
              gap: "12px 16px",
            }}
          >
            <FlexCol sx={{ justifyContent: "center" }}>
              <Typography fontWeight={600} sx={{ fontSize: { xs: 17, md: 20, lg: 23 }, ml: 1 }}>
                {user?.username || channel?.channelId}
              </Typography>
              {channel && (
                <Typography sx={{ fontSize: { xs: 12, md: 13, lg: 15 }, ml: 1, color: "gray" }}>
                  {channel?.title}
                </Typography>
              )}
              <Grid container spacing={{ xs: 0.1, sm: 0.3 }} sx={{ display: { xs: "none", sm: "flex" } }}>
                <SocialItem id={discordId} link={`https://discord.gg/${discordId}`} Icon={DiscordIcon} />
                <SocialItem id={twitterId} link={`https://twitter.com/${twitterId}`} Icon={TwitterIcon} />
                <SocialItem id={instagramId} link={`https://www.instagram.com/${instagramId}`} Icon={InstagramIcon} />
                <SocialItem id={website} link={websiteLink} Icon={LanguageOutlinedIcon} />
              </Grid>

              <Box sx={{ ml: 1, display: { xs: "flex", sm: "none" } }}>
                <Typography sx={{ fontSize: 12 }}>{followerCount}</Typography>
                <Typography sx={{ fontSize: 12, ml: 0.5, color: "gray" }}>Followers</Typography>
              </Box>
            </FlexCol>
            {isMyProfile && (
              <Button variant="contained" sx={{ height: 29, borderRadius: 15 }} onClick={() => setEditProfile(true)}>
                <EditIcon />
                <Typography sx={{ ml: 1, fontSize: 13 }}>Edit Profile</Typography>
              </Button>
            )}
          </FlexRow>
        </FlexRow>
        <FlexRow
          sx={{
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "max-content",
            display: "flex",
            flexFlow: "row wrap",
            gap: "12px 16px",
          }}
        >
          <Box
            sx={{
              px: { md: 2, lg: 3 },
              py: 1,
              borderRadius: 2,
              display: { xs: "none", md: "flex" },
              flexDirection: "row",
              bgcolor: "background.paper",
            }}
          >
            <Box sx={{ flexDirection: "column" }}>
              <Typography variant="body1" sx={{ textAlign: "center", fontWeight: 600 }}>
                {followerCount}
              </Typography>
              <Typography variant="caption">Followers</Typography>
            </Box>
            {isUser && (
              <Box sx={{ flexDirection: "column", ml: 2.5 }}>
                <Typography variant="body1" sx={{ textAlign: "center", fontWeight: 600 }}>
                  {followingCount}
                </Typography>
                <Typography variant="caption">Following</Typography>
              </Box>
            )}
          </Box>
          {!isMyProfile && (
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                height: 29,
                borderRadius: 29 / 2,
                background: isFollowing ? "#1F2234" : "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
              }}
              onClick={handleClickFollow}
              disabled={loading}
            >
              <Typography variant="subtitle1" sx={{ fontSize: { lg: 16, md: 14 } }}>
                {isFollowing ? "Followed" : "Follow"}
              </Typography>
              {!isFollowing && <AddOutlinedIcon sx={{ ml: 0.3, mr: -1 }} />}
            </Button>
          )}
        </FlexRow>
      </Box>

      <ProfileMenuList
        type={isUser ? PROFILE_MENU_TYPE.User : PROFILE_MENU_TYPE.Channel}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        // isShowTweets={isShowTweetsForChannel}
      />

      {unfollowModalVisible && (
        <ConfirmationModal
          modalVisible={unfollowModalVisible}
          title={`Unfollow ${isUser ? user?.username : channel?.title}?`}
          submitFn={postFollow}
          cancelFn={() => setUnfollowModalVisible(false)}
          submitLabel="Unfollow"
          cancelLabel="Discard"
        />
      )}
      {isMyProfile && <UserSettingsModal open={editProfile} onClose={() => setEditProfile(false)} />}
    </Box>
  );
};
