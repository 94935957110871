import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import LiveStreamPlayer from "../../components/LiveStreamPlayer";
import { apiClient } from "../../config";
import { BoltPlayer } from "../../components";

export const VideoWindowPopout = () => {
  const { boltId } = useParams();
  const [entity, setEntity] = useState<any>();
  const [isLive, setIsLive] = useState(false);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState<string>();
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    apiClient.get(`/entity/${boltId}`).then((data) => setEntity(data.data));
  }, []);

  useEffect(() => {
    if (entity) {
      switch (entity.type) {
        case "channel":
          setIsLive(entity.data.isLive);
          setUrl(entity.data.url);
          setTitle(entity.data.title);
          break;

        case "user":
          setIsLive(entity.data.streamProfile.isLive);
          setUrl(entity.data.streamProfile.url);
          setTitle(entity.data.streamProfile.title);
          setStartDate(entity.data?.streamSession?.createdAt);
          break;

        default:
          break;
      }
    }
  }, [entity]);

  if (!isLive || !url) {
    return null;
  }

  return entity.type === "channel" ? (
    <BoltPlayer url={url} title={title} isLive={isLive} isPopup />
  ) : (
    <LiveStreamPlayer url={url} isLive={isLive} title={title} startDate={moment(startDate).toDate()} isPopup />
  );
};
