import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";

type Props = {
  title: string;
  description: string;
  isFloatingPlayer?: boolean;
};

export const TitleBar = ({ title, description, isFloatingPlayer }: Props) => {
  const [isFloatingVideoEnabled, setIsFloatingVideoEnabled] = useState(false)
  const [titleFontSize, setTitleFontSize] = useState(20)

  useEffect(() => {
    if (isFloatingPlayer) {
      setIsFloatingVideoEnabled(isFloatingPlayer)
      setTitleFontSize(15)
    }
    else {
      setIsFloatingVideoEnabled(false)
      setTitleFontSize(20)
    }
  }, [isFloatingPlayer])

  return (
    <FlexRow>
      <FlexCol
        sx={{
          background: "linear-gradient(0deg, rgba(10, 12, 31, 0) 51.93%, #00000080)",
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          height: "50%",
        }}
      />
      <FlexCol sx={{ zIndex: 5, p: { xs: 1, sm: 2 } }}>
        <FlexRow alignItems="center">
          {isFloatingVideoEnabled && <Typography fontWeight="600" sx={{ mr: isFloatingPlayer ? 0.5 : 1, fontSize: { xs: 10, sm: titleFontSize } }}>Watching</Typography>}
          <Typography
            fontWeight="600"
            sx={{
              color: "white",
              ...isFloatingVideoEnabled && { width: "55%" },
              fontSize: { xs: isFloatingPlayer ? 12 : 18, sm: titleFontSize },
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            noWrap>
            {title}
          </Typography>
        </FlexRow>
        {!isFloatingPlayer && <Typography variant="subtitle2" fontWeight="600" sx={{ color: "white" }}>{description}</Typography>}
      </FlexCol>
    </FlexRow>
  );
};
