import React, { useState } from "react";
import { Box, Typography, IconButton, Stack, GlobalStyles, styled } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { FlexCol } from "../../components";
import BOLTPlus from '../../assets/pngs/carousel/BOLTPlus.png'
import Macbook1 from '../../assets/pngs/carousel/Macbook_1.png'
import Macbook2 from '../../assets/pngs/carousel/Macbook_2.png'
import Macbook3 from '../../assets/pngs/carousel/Macbook_3.png'

const CarouselLogoImage = styled("img")(({ theme }) => ({
  height: "auto",
  width: '120px !important',
  [theme.breakpoints.down("lg")]: { width: '120px !important' },
  [theme.breakpoints.down("md")]: { width: '20% !important' },
}));

const CarouselMac1Image = styled("img")(({ theme }) => ({
  height: "auto",
  width: '650px !important',
  paddingBottom: 48,
  [theme.breakpoints.down("lg")]: { width: '450px !important', paddingBottom: 40 },
  [theme.breakpoints.down("md")]: { width: '75% !important', paddingBottom: 30 }
}));

const CarouselMac2Image = styled("img")(({ theme }) => ({
  height: "auto",
  width: '770px !important',
  [theme.breakpoints.down("lg")]: { width: '550px !important' },
  [theme.breakpoints.down("md")]: { width: '90% !important' }
}));

const CarouselLabel = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  fontWeight: 600,
  marginTop: 24,
  [theme.breakpoints.down("lg")]: { fontSize: 32 },
  [theme.breakpoints.down("md")]: { fontSize: 24 }
}));

const CarouselItem = styled(Box)(({ theme }) => ({
  height: '100%',
  display: "flex",
  flexDirection: "column",
  justifyContent: 'center'
}));

export const OnboardingCarousel = () => {
  const [currrentIndex, setCurrentIndex] = useState(0);

  const onClickItem = (index: number, item: React.ReactNode) => { setCurrentIndex(index) };
  const onClickThumb = (index: number, item: React.ReactNode) => { setCurrentIndex(index) };

  const handleNext = () => {
    const temp = currrentIndex === 2 ? 0 : currrentIndex + 1;
    setCurrentIndex(temp)
  }

  return (
    <Box sx={{ width: "100%", height: '100vh' }}>
      <GlobalStyles styles={{ 'ul.control-dots': { display: "-webkit-box", bottom: '49px !important', left: '70px !important', margin: '0 !important' } }} />
      <Carousel
        selectedItem={currrentIndex}
        showArrows={false}
        infiniteLoop
        autoPlay
        interval={5000}
        transitionTime={800}
        swipeable
        emulateTouch
        showIndicators
        showThumbs={false}
        showStatus={false}
        width="100%"
        onChange={setCurrentIndex}
        onClickItem={onClickItem}
        onClickThumb={onClickThumb}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const defStyle = { bgcolor: "#ffffff33", borderRadius: 7, width: '8px', height: '8px', mr: "14px", display: "inline-flex", cursor: "pointer", verticalAlign: "middle" };
          const sxVal = isSelected ? { ...defStyle, bgcolor: "#A3AED0", width: '13px', height: '13px' } : { ...defStyle };
          return <FlexCol key={index} sx={sxVal} onClick={onClickHandler} onKeyDown={onClickHandler} />;
        }}
      >
        <CarouselItem sx={{ height: '100vh', pl: { lg: '60px', md: '30px', sm: '10px' } }} >
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <CarouselLogoImage src={BOLTPlus} />
            <CarouselMac1Image src={Macbook1} alt="channel" />
          </Stack>
          <CarouselLabel mt={{ md: 4, sm: 2 }} textAlign={{ md: 'start', sm: 'center' }} >Watch. Share. Earn</CarouselLabel>
        </CarouselItem>
        <CarouselItem sx={{ alignItems: 'center', pb: '64px' }} >
          <CarouselMac2Image src={Macbook2} />
          <CarouselLabel>Watch & Listen</CarouselLabel>
        </CarouselItem>
        <CarouselItem sx={{ alignItems: 'center', pb: '64px' }} >
          <CarouselMac2Image src={Macbook3} />
          <CarouselLabel>Share your moments</CarouselLabel>
        </CarouselItem>
      </Carousel>
      <IconButton aria-label="next" onClick={handleNext} sx={{ position: 'absolute', right: 40, bottom: 40, background: '#a3aed080', borderRadius: '15px', p: 1.5, zIndex: 2 }}>
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
};
