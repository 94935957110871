import { Button, Link } from "@mui/material";

import { DiscordLogoSvg } from "./DiscordLogoSvg";

export const JoinDiscordButton = () => {
  return (
    <Button
      component={Link}
      variant="contained"
      sx={{ position: "fixed", bottom: "2%", right: "calc(2% + 56px)", minHeight: 40, borderRadius: 40 }}
      startIcon={<DiscordLogoSvg />}
      href="https://discord.gg/boltos"
      target="_blank"
    >
      Join our Discord
    </Button>
  );
};
