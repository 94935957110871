import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

type PrivateNavbarContextType = {
  searchKeyword: string;
  activeDrawer: "search-result" | "profile" | "trends" | undefined;
  //
  updateContext: (payload: Partial<Omit<PrivateNavbarContextType, "updateContext">>) => void;
};

const defaultPrivateNavbarContextValue: PrivateNavbarContextType = {
  searchKeyword: "",
  activeDrawer: undefined,
  //
  updateContext: () => {},
};

export const PrivateNavbarContext = createContext<PrivateNavbarContextType>(defaultPrivateNavbarContextValue);

type PrivateNavbarContextProviderProps = PropsWithChildren<{}>;

export const PrivateNavbarContextProvider = ({ children }: PrivateNavbarContextProviderProps) => {
  const [contextValue, setContextValue] = useState<PrivateNavbarContextType>(defaultPrivateNavbarContextValue);

  const { pathname } = useLocation();

  const updateContext: PrivateNavbarContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    updateContext({ searchKeyword: "", activeDrawer: undefined });
  }, [pathname]);

  const memoizedValue = useMemo(() => {
    return { ...contextValue, updateContext };
  }, [contextValue]);

  return <PrivateNavbarContext.Provider value={memoizedValue}>{children}</PrivateNavbarContext.Provider>;
};

export const usePrivateNavbarContext = () => {
  const context = useContext(PrivateNavbarContext);

  return context;
};
