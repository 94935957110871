import React, { useState, useRef } from "react";
import { Box } from "@mui/material";

import { FlexRow } from "../FlexRow";
import { LoadingView } from "../LoadingView";
import PlayerControls from "./PlayerControls";
import StreamOffline from "../../assets/pngs/stream_offline.png";
import IvsPlayer from "./IvsPlayer";

type Props = {
  url?: string;
  title?: string;
  description?: string;
  isLive?: boolean;
  isPopup?: boolean;
  isSideChatBoxOpen?: boolean;
  isFloatingPlayer?: boolean;
  startDate?: Date;
  onError?: () => void;
  handleSideChatBoxClose?: () => void;
  closeFloatingPlayer?: () => void;
  backToProfile?: () => void;
  handlePopout?: () => void;
};

const LiveStreamPlayer = ({
  url,
  title,
  description,
  isLive = true,
  isPopup = false,
  isSideChatBoxOpen = true,
  isFloatingPlayer = false,
  startDate = new Date(),
  onError,
  handleSideChatBoxClose,
  closeFloatingPlayer,
  backToProfile,
  handlePopout,
}: Props) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(0.5);
  const [enablePip, setEnablePip] = useState(false);
  const [enableFullScreen, setEnableFullScreen] = useState(false);
  const [position, setPosition] = useState(0);
  const [qualities, setQualities] = useState<string[]>();
  const [quality, setQuality] = useState<string>();
  const [duration, setDuration] = useState(0);
  const [playingState, setPlayingState] = useState<{ playedSeconds: number; loadedSeconds: number }>({
    playedSeconds: 0,
    loadedSeconds: 0,
  });

  const toggleMute = () => {
    if (volume === 0) {
      setMuted(false);
      setVolume(0.5);
    } else {
      setMuted(!muted);
    }
  };

  return (
    <FlexRow
      component={Box}
      sx={{
        flex: 1,
        maxHeight: isPopup ? "100vh" : "90vh",
        minHeight: isPopup ? "100vh" : "100%",
        minWidth: "100%",
        height: "auto",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        bgcolor: "black",
        transition: "all 0.2s ease-out",
      }}
    >
      {url ? (
        <>
          <IvsPlayer
            ref={playerRef}
            style={{ transition: "all 0.8s ease" }}
            height="auto"
            width="100%"
            url={url}
            isLowLatency={isLive}
            muted={muted}
            playing={playing}
            playsinline
            volume={volume}
            quality={quality}
            enablePip={enablePip}
            position={position}
            enableFullScreen={enableFullScreen}
            onReady={() => setLoading(false)}
            onPlay={() => setPlaying(true)}
            onEnd={() => setPlaying(false)}
            onBuffer={() => setLoading(true)}
            onBufferEnd={() => setLoading(false)}
            onError={() => {
              setLoading(false);
              if (onError) onError();
            }}
            onProgress={(state) =>
              setPlayingState((previousState) => ({
                ...previousState,
                ...state,
              }))
            }
            onDisablePIP={() => setEnablePip(false)}
            onGetQualities={setQualities}
            onGetDuration={setDuration}
            onDisableFullScreen={() => setEnableFullScreen(false)}
          />
          <PlayerControls
            title={title!}
            description={description!}
            playing={playing}
            type={isLive ? "live" : "normal"}
            muted={muted as boolean}
            duration={duration}
            volume={volume}
            quality={quality}
            qualities={qualities}
            state={playingState}
            isSideChatBoxOpen={isSideChatBoxOpen}
            isPopup={isPopup}
            isFloatingPlayer={isFloatingPlayer}
            startDate={startDate}
            setPlaying={setPlaying}
            toggleMute={toggleMute}
            setMuted={setMuted}
            changeVolume={setVolume}
            seekTo={(position) => setPosition(position)}
            setQuality={setQuality}
            togglePip={() => setEnablePip(true)}
            handlePopout={handlePopout}
            handleSideChatBoxClose={handleSideChatBoxClose}
            closeFloatingPlayer={closeFloatingPlayer}
            enableFullScreen={() => setEnableFullScreen(true)}
            backToProfile={backToProfile}
          />
        </>
      ) : (
        <img
          src={StreamOffline}
          alt="Stream Offline"
          height="auto"
          width="100%"
          style={{ display: "flex", marginTop: -6 }}
        />
      )}
      <LoadingView visible={loading && url !== undefined} sx={{ position: "absolute", zIndex: 1 }} />
    </FlexRow>
  );
};

export default LiveStreamPlayer;
