import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ConnectWalletWrapper from "../../hooks/ConnectWalletWrapper";
import AudioPlayerWrapper from "../../hooks/AudioPlayerWrapper";
import { PrivateNavbar } from "../../components/navbar";
import { BottomNavigation } from "../../components";
import { EmailVerifyPendingForm } from "../../components/EmailVerifyPendingForm";
import { FloatingVideoPlayer } from "../../components/FloatingVideoPlayer";
import { authSelector, userSelector } from "../../store/auth/selector";
import { syncUserAction } from "../../store/auth/actions";
import { fetchNotificationUnreadCountAction } from "../../store/notification/actions";
import { fetchLiveStreamersAction } from "../../store/streamer/actions";
import { usePrivateLayoutContext } from "./context";
import { MenuDrawer } from "./MenuDrawer";

type PrivateLayoutUiProps = PropsWithChildren<{}>;

export const PrivateLayoutUi = ({ children }: PrivateLayoutUiProps) => {
  const theme = useTheme();

  const { navbar__height, leftMenubar__width, leftMenubar__visible } = usePrivateLayoutContext();

  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(userSelector);
  const { isLoggedIn, loading } = useSelector(authSelector);

  const syncUser = useCallback(() => dispatch(syncUserAction()), [dispatch]);
  const fetchNotificationUnreadCount = useCallback(() => dispatch(fetchNotificationUnreadCountAction()), [dispatch]);
  const fetchLiveStreamer = useCallback(() => dispatch(fetchLiveStreamersAction()), [dispatch]);

  useEffect(() => {
    fetchLiveStreamer();
    const timer = setInterval(() => fetchLiveStreamer(), 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (isLoggedIn && !loading) {
      syncUser();
      fetchNotificationUnreadCount();
    }
  }, [isLoggedIn, loading]);

  const isMainMenu =
    location.pathname === "/home" ||
    location.pathname.includes("/live/") ||
    location.pathname === "/notifications" ||
    location.pathname.includes("/feed/");

  return (
    <ConnectWalletWrapper>
      <AudioPlayerWrapper>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              height: navbar__height,
              zIndex: theme.zIndex.drawer + 30,
            }}
          >
            <PrivateNavbar />
          </Box>

          <Box
            sx={{
              position: "fixed",
              top: navbar__height,
              left: 0,
              bottom: 0,
              width: leftMenubar__width,
              zIndex: theme.zIndex.drawer + 20,
              display: { xs: "none", md: "flex" },
            }}
          >
            <MenuDrawer isOpen={leftMenubar__visible} />
          </Box>

          <Box sx={{ height: navbar__height }} />

          <Box sx={{ display: "flex", width: "100%" }}>
            <Box sx={{ width: leftMenubar__width, display: { xs: "none", md: "block" } }} />

            <Box sx={{ width: { xs: "100%", md: `calc(100% - ${leftMenubar__width}px)` }, position: "relative" }}>
              {!!user?.emailVerified && <Outlet />}

              {!user?.emailVerified && <EmailVerifyPendingForm email={user?.email!} />}
            </Box>
          </Box>

          <Box
            sx={{
              height: 65,
              display: { md: "none" },
            }}
          />

          {!!user?.emailVerified && <BottomNavigation />}

          {isMainMenu && <FloatingVideoPlayer />}
        </Box>
      </AudioPlayerWrapper>
    </ConnectWalletWrapper>
  );
};
