import React from "react";
import { Avatar, Skeleton } from "@mui/material";
import { FlexRow } from ".";

interface Props {
  url?: string;
  loading?: boolean;
}

export const NFTHeaderBackground = ({ url = "", loading }: Props) => {
  return (
    <FlexRow sx={{ height: "160px" }}>
      <Avatar
        variant="square"
        src={url}
        style={{
          objectFit: "cover",
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          flex: 1,
          height: "100%",
          width: "100%",
          overflow: "hidden",
          backgroundColor: "transparent",
        }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            opacity: 0.4,
            height: "100%",
            width: "100%",
          }}
        />
      </Avatar>
    </FlexRow>
  );
};
