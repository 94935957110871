import { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { motion, useScroll, useTransform } from "framer-motion";

import { useStepScrollContainerContext } from "../../components";
import { useDiscoverPageContext } from "./DiscoverPageContext";

type RowAnimationProps = PropsWithChildren<{
  rowNum?: number;
}>;

export const RowAnimation = ({ children, rowNum = 0 }: RowAnimationProps) => {
  const { scrollRef } = useStepScrollContainerContext();

  const { scrollYProgress } = useScroll({
    container: scrollRef,
  });

  const { collections, bottomOffset } = useDiscoverPageContext();

  const rows = collections.length;

  const scrollSteps = rows > 0 ? rows - 1 : 0;

  const scrollStep = scrollSteps > 0 ? 1 / scrollSteps : 0;

  const opacity = useTransform(
    scrollYProgress,
    [0, scrollStep * (rowNum + 0.15), scrollStep * (rowNum + 0.6), 1],
    [1, 1, 0, 0]
  );

  return (
    <Box>
      <motion.div style={{ opacity }}>
        {children}

        <Box
          sx={{
            scrollSnapAlign: "end",
            scrollSnapStop: "always",
            height: rowNum < collections.length - 1 ? bottomOffset : 0,
            mb: rowNum < collections.length - 1 ? `-${bottomOffset}px` : "0px",
          }}
        />
      </motion.div>
    </Box>
  );
};
