import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Fab, Grid, Typography } from "@mui/material";
import { useDisconnect } from "wagmi";

import { logOutAction } from "../../../../store/auth/actions";
import { userSelector } from "../../../../store/auth/selector";
import { ReactComponent as LogoutIcon } from "../../../../assets/svgs/logout.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/svgs/eyeIcon.svg";
import { ReactComponent as SettingIcon } from "../../../../assets/svgs/settingGradient.svg";
import { ReactComponent as EarningColorIcon } from "../../../../assets/svgs/earningColor.svg";
import { ReactComponent as AnalyticColorIcon } from "../../../../assets/svgs/users/analytic.svg";
import { Formatter } from "../../../../utils";
import { DefaultAvatar } from "../../../DefaultAvatar";
import { FlexCol } from "../../../FlexCol";
import { FlexRow } from "../../../FlexRow";
import { usePrivateNavbarContext } from "../PrivateNavbarContext";

export const ProfileDrawer = () => {
  const { activeDrawer, updateContext: updateNavbarContext } = usePrivateNavbarContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const { disconnect } = useDisconnect();

  return (
    <Drawer
      disableEnforceFocus
      anchor="right"
      open={activeDrawer === "profile"}
      onClose={() => {
        updateNavbarContext({
          activeDrawer: undefined,
        });
      }}
      PaperProps={{
        sx: {
          borderLeft: `1px solid rgba(255, 255, 255, 0.05)`,
        },
      }}
    >
      <FlexCol
        bgcolor="#1B1F3A"
        sx={{ borderWidth: 1, pt: (65 + 33) / 8, px: 33 / 8, height: "100%", width: 300, alignItems: "center" }}
      >
        <FlexRow sx={{ width: "100%" }}>
          <Avatar alt={user?.username} src={user?.photoUrl} sx={{ width: 63, height: 63, borderRadius: 11 / 4 }}>
            <DefaultAvatar />
          </Avatar>

          <FlexCol sx={{ justifyContent: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 600, ml: 4 }}>
              {user?.username}
            </Typography>
          </FlexCol>
        </FlexRow>

        <Grid container spacing={0} sx={{ mt: 2 }}>
          <Grid item xs={6} container>
            <Grid item xs={6}>
              <Typography variant="body2">{Formatter.formatCount(user?.followers || 0)}</Typography>
              <Typography variant="tiny">Followers</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2">{Formatter.formatCount(user?.following || 0)}</Typography>
              <Typography variant="tiny">Following</Typography>
            </Grid>
          </Grid>

          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ height: 24, borderRadius: 12 }}
              onClick={() => {
                navigate(`/${user?.username}`);
                updateNavbarContext({
                  activeDrawer: undefined,
                });
              }}
            >
              <EyeIcon />
              <Typography variant="tiny" sx={{ ml: 1, fontWeight: 600 }}>
                View Profile
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={{ my: 2 }} rowSpacing={1}>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={6}>
              <Fab
                variant="extended"
                color="secondary"
                sx={{
                  width: "100%",
                  height: "auto",
                  py: 1.5,
                  borderRadius: 2.5,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                }}
                href="/user/earnings"
                onClick={() => {
                  updateNavbarContext({
                    activeDrawer: undefined,
                  });
                }}
              >
                <EarningColorIcon style={{ height: 20 }} />
                <Typography variant="caption">Earnings</Typography>
              </Fab>
            </Grid>

            <Grid item xs={6}>
              <Fab
                variant="extended"
                color="secondary"
                sx={{
                  width: "100%",
                  height: "auto",
                  py: 1.5,
                  borderRadius: 2.5,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                }}
                href="/user/analytics"
                onClick={() => {
                  updateNavbarContext({
                    activeDrawer: undefined,
                  });
                }}
              >
                <AnalyticColorIcon style={{ height: 20 }} />
                <Typography variant="caption">Analytics</Typography>
              </Fab>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            {/* <Grid item xs={6}>
            <Fab
              variant="extended"
              color="secondary"
              sx={{
                width: "100%",
                height: "auto",
                py: 1.5,
                borderRadius: 2.5,
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <WalletIcon />
              <Typography variant="caption">Wallets</Typography>
            </Fab>
          </Grid> */}

            <Grid item xs={6}>
              <Fab
                variant="extended"
                color="secondary"
                sx={{
                  width: "100%",
                  height: "auto",
                  py: 1.5,
                  borderRadius: 2.5,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                }}
                onClick={() => {
                  updateNavbarContext({
                    activeDrawer: undefined,
                  });
                  navigate(`/settings/home`);
                }}
              >
                <SettingIcon style={{ height: 20 }} />
                <Typography variant="caption">Settings</Typography>
              </Fab>
            </Grid>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="secondary"
          sx={{ height: 24, borderRadius: 12 }}
          onClick={() => {
            updateNavbarContext({
              activeDrawer: undefined,
            });
            disconnect();
            dispatch(logOutAction());
          }}
        >
          <LogoutIcon />
          <Typography variant="caption" sx={{ ml: 1, fontWeight: 600 }}>
            Logout
          </Typography>
        </Button>
      </FlexCol>
    </Drawer>
  );
};
