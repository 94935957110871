import React from "react";
import { Tabs, TabsProps } from "@mui/material";

export const ChartTabs = ({ sx, children, ...other }: TabsProps) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    sx={{ mt: 4, mb: -0.1, width: 'fit-content', '& .MuiTab-root': { px: { xs: 1, sm: 2 } }, ...sx }}
    {...other}
  >
    {children}
  </Tabs>
);
