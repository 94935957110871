import axios from "axios";
import { Dispatch } from "redux";
import { apiClient } from "../config";

export const uploadFileOnS3 = async ({ url, fields, file, type = "IMAGE" }: any) => {
  const formData = new FormData();
  formData.append("key", fields.key);
  formData.append("Content-Type", type === "VIDEO" ? "video/*" : "image/*");
  formData.append("acl", "public-read");
  Object.entries(fields).forEach(([key, value]: [any, any]) => {
    if (key !== "key") {
      formData.append(key, value);
    }
  });
  formData.append("file", file);
  await axios({
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "http:localhost:3005",
      "Content-Type": "multipart/form-data",
    },
    method: "POST",
    url,
    data: formData,
  });

  return `${url}/${fields.key}`;
};

type FeedUploadType = {
  folder: "stream" | "nft";
  file: File;
  dispatch: Dispatch;
  reduxAction: string;
  uploadId?: number;
  newFileName?: string;
};

type CommonUploadType = {
  folder: string;
  file: File;
  dispatch: Dispatch;
  reduxAction: string;
  uploadId?: number;
  newFileName?: string;
};

type ProfileUploadType = {
  folder: "cover" | "avatar" | "thumbnail";
  file: File;
  dispatch: Dispatch;
  reduxAction: string;
  uploadId?: number;
  newFileName?: string;
};

export const uploadFeedMedia = async ({
  folder,
  file,
  dispatch,
  reduxAction,
  uploadId,
  newFileName,
}: FeedUploadType) => {
  const {
    data: { fields, url },
  }: any = await apiClient.post(`/upload/feed/`, {
    folder,
    type: file.type,
    name: newFileName || `${Date.now()}-${file.name.replace(/[^\w.]/g, "")}`,
  });

  const formData = new FormData();
  formData.append("key", fields.key);
  formData.append("Content-Type", file.type);
  formData.append("acl", "public-read");
  Object.entries(fields).forEach(([key, value]: [any, any]) => {
    if (key !== "key") {
      formData.append(key, value);
    }
  });
  formData.append("file", file);
  await axios.post(url, formData, {
    onUploadProgress: (event) => {
      dispatch({
        type: reduxAction,
        payload: {
          uploadId,
          progress: Math.floor((event.loaded / event.total) * 100),
        },
      });
    },
  });

  return `${url}/${fields.key}`;
};

export const uploadProfileImage = async ({
  folder,
  file,
  dispatch,
  reduxAction,
  uploadId,
  newFileName,
}: ProfileUploadType) => {
  const {
    data: { fields, url },
  }: any = await apiClient.post(`/upload/profile/`, {
    folder,
    type: file.type,
    name: newFileName || `${Date.now()}-${file.name.replace(/[^\w.]/g, "")}`,
  });

  const formData = new FormData();
  formData.append("key", fields.key);
  formData.append("Content-Type", file.type);
  formData.append("acl", "public-read");
  Object.entries(fields).forEach(([key, value]: [any, any]) => {
    if (key !== "key") {
      formData.append(key, value);
    }
  });
  formData.append("file", file);
  await axios.post(url, formData, {
    onUploadProgress: (event) => {
      dispatch({
        type: reduxAction,
        payload: {
          uploadId,
          progress: Math.floor((event.loaded / event.total) * 100),
        },
      });
    },
  });

  return `${url}/${fields.key}`;
};

export const uploadCommonFile = async ({
  folder,
  file,
  dispatch,
  reduxAction,
  uploadId,
  newFileName,
}: CommonUploadType) => {
  const {
    data: { fields, url },
  }: any = await apiClient.post(`/upload/common/`, {
    folder,
    type: file.type,
    name: newFileName || `${Date.now()}-${file.name.replace(/[^\w.]/g, "")}`,
  });

  const formData = new FormData();
  formData.append("key", fields.key);
  formData.append("Content-Type", file.type);
  formData.append("acl", "public-read");
  Object.entries(fields).forEach(([key, value]: [any, any]) => {
    if (key !== "key") {
      formData.append(key, value);
    }
  });
  formData.append("file", file);
  await axios.post(url, formData, {
    onUploadProgress: (event) => {
      dispatch({
        type: reduxAction,
        payload: {
          uploadId,
          progress: Math.floor((event.loaded / event.total) * 100),
        },
      });
    },
  });

  return `${url}/${fields.key}`;
};
