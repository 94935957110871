import { ReactionPackType, ReactionType } from "../types";

export const reactionsPack: ReactionPackType = {
  "👍": "👍",
  "❤️": "❤️",
  "😂": "😂",
  "😮": "😮",
  "😢": "😢",
  // addReaction: "addReaction",
};

export const reactionToRender = (value: ReactionType["type"]) => {
  switch (value) {
    case "LIKE":
      return reactionsPack["👍"];
    case "LOVE":
      return reactionsPack["❤️"];
    case "HAHA":
      return reactionsPack["😂"];
    case "SAD":
      return reactionsPack["😢"];
    case "WOW":
      return reactionsPack["😮"];
    default:
      return "";
  }
};

export const stringFromReaction = (value: string): ReactionType["type"] => {
  switch (value) {
    case reactionsPack["👍"]:
      return "LIKE";
    case reactionsPack["❤️"]:
      return "LOVE";
    case reactionsPack["😂"]:
      return "HAHA";
    case reactionsPack["😢"]:
      return "SAD";
    case reactionsPack["😮"]:
      return "WOW";
    default:
      return "LIKE";
  }
};
