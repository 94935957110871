import React from 'react';
import moment from "moment";
import { Grid, Skeleton } from '@mui/material';
import InfiniteScroll from "react-infinite-scroller";

import { FlexCol } from '../../../../components';
import { EarnigHistoryProps } from './EarnigHistoryProps';
import { NoHistory } from './NoHistory';
import { TableCell } from './TableCell';
import { StatusLabel } from './StatusLabel';
import { getPaymentMethod } from '../../../../utils';

export const HistoryWithdraw = ({ data, loading, hasMore, loadMore }: EarnigHistoryProps) => {

  return (
    <FlexCol sx={{ pb: 4 }}>
      <Grid item container sx={{ width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.05)', p: 1, mt: 3, borderRadius: 2 }}>
        <TableCell xs={2}>Date</TableCell>
        <TableCell xs={4}>Payment method</TableCell>
        <TableCell xs={3} sm={4}>Amount</TableCell>
        <TableCell xs={3} sm={2}>Status</TableCell>
      </Grid>

      {!loading && data.length === 0 && <NoHistory />}

      <InfiniteScroll pageStart={1} hasMore={hasMore} loadMore={loadMore} useWindow>
        {data.map((row, index) => (
          <Grid item container mt={1.5} key={index} alignItems="center">
            <TableCell xs={2} sx={{ fontSize: 12, color: '#efefef80' }}>{moment(row.createdAt).format("DD MMM, YYYY h:mm a")}</TableCell>
            <TableCell xs={4} sx={{ fontSize: 14, fontWeight: 600 }}>{getPaymentMethod(row.payoutMethod)}</TableCell>
            <TableCell xs={3} sm={4} sx={{ fontSize: 14, fontWeight: 400 }}>${row.amount}</TableCell>
            <TableCell xs={3} sm={2} sx={{ fontSize: 16, fontWeight: 600, display: 'flex' }}><StatusLabel status={row.status} /></TableCell>
          </Grid>
        ))}
        {loading && [...Array(10)].map((_, index) => (
          <Grid item container mt={1.5} key={index} alignItems="center">
            <TableCell xs={2}><Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: '80%' }} /></TableCell>
            <TableCell xs={4}><Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: '80%' }} /></TableCell>
            <TableCell xs={3}><Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: '80%' }} /></TableCell>
            <TableCell xs={3}><Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: '80%' }} /></TableCell>
          </Grid>
        ))}
      </InfiniteScroll>
    </FlexCol>
  )
};