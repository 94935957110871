import { createAction } from "redux-actions";
import { APPLE, EMAIL, GOOGLE, START, WALLET } from "../common";

export const INIT_AUTH = "INIT_AUTH";
export const FETCH_LOGIN = "FETCH_LOGIN";
export const FETCH_ONBOARDING = "FETCH_ONBOARDING";
export const FETCH_USER = "FETCH_USER";
export const SYNC_USER = "SYNC_USER";
export const LOG_OUT = "LOG_OUT";
export const FETCH_SIGNUP = "FETCH_SIGNUP";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_USER_WALLET = "UPDATE_USER_WALLET";
export const JOIN_WORLD_CUP_CAMPAIGN = "JOIN_WORLD_CUP_CAMPAIGN";
export const VERIFY_TV = "VERIFY_TV";

export const initAuthAction = createAction(INIT_AUTH);

export const fetchLoginGoogleAction = createAction(FETCH_LOGIN + GOOGLE + START);
export const fetchLoginAppleAction = createAction(FETCH_LOGIN + APPLE + START);
export const fetchLoginEmailAction = createAction(FETCH_LOGIN + EMAIL + START);
export const fetchLoginWalletAction = createAction(FETCH_LOGIN + WALLET + START);
export const fetchOnboardingAction = createAction(FETCH_ONBOARDING + START);
export const fetchUserAction = createAction(FETCH_USER + START);
export const syncUserAction = createAction(SYNC_USER + START);
export const updateProfileAction = createAction(UPDATE_PROFILE + START);

export const logOutAction = createAction(LOG_OUT);

export const fetchEmailSignUpAction = createAction(FETCH_SIGNUP + EMAIL + START);

export const joinWorldCupCampaignAction = createAction(JOIN_WORLD_CUP_CAMPAIGN + START);
export const verifyTv = createAction(VERIFY_TV);
