export enum BINANCE_TRANSACTION_STATUS {
  NOT_INITILIZED = "NOT_INITILIZED",
  INITIALIZED = "INITIALIZED",
  PAY_SUCCESS = "PAY_SUCCESS",
  PAY_CLOSED = "PAY_CLOSED",
}

export enum BINANCE_PAYOUT_STATUS {
  NOT_INITILIZED = "NOT_INITILIZED",
  ACCEPTED = "ACCEPTED",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
  PART_SUCCESS = "PART_SUCCESS",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
}
