import { ChatProps } from "../components/immersive-panel/chat";

export const handleVideoPopup = (id: string) => {
  setTimeout(() => {
    window.open(`/playerpopout/${id}`, "", "popup,width=993,height=550,left=100,top=120");
  }, 100);
};

export const handleChatPopup = ({ channelId, channelType, receiverName, receiverType }: ChatProps) => {
  setTimeout(() => {
    window.open(
      `/chatpopout?channelId=${channelId}&channelType=${channelType}&receiverName=${receiverName}&receiverType=${receiverType}`,
      "",
      "popup,width=450,height=640,left=300,top=50"
    );
  }, 100);
};
