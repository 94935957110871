import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box, BoxProps, Paper, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, PointElement, LineElement, Legend, ScriptableContext } from "chart.js";

import { ReactComponent as PeopleIcon } from "../../../assets/svgs/people.svg";
import { ReactComponent as HandIcon } from "../../../assets/svgs/users/hand.svg";

import { FlexRow } from "../../../components";
import { fetchAnalyticsFollowersAction, fetchAnalyticsFollowersStatistics } from "../../../store/users/actions";
import { userStreamSelector } from "../../../store/users/selector";
import { DateUnit, FilterType } from "../../../types";
import { OptionButton, StatusBox } from "./components";
import { Formatter, LineChartOptions } from "../../../utils";

ChartJS.register(PointElement, LineElement, Legend);

const AnalyticsFollowers = ({ sx, ...other }: BoxProps) => {
  const dispatch = useDispatch();
  const { loading, followers: { totalFollowers, newFollowers, statistics } } = useSelector(userStreamSelector);
  const [dayIndex, setDayIndex] = useState<FilterType>(FilterType.ALL);

  useEffect(() => {
    dispatch(fetchAnalyticsFollowersAction());
  }, [dispatch]);

  useEffect(() => {
    let startDate = 0;
    let dateUnit: DateUnit = "";
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    switch (dayIndex) {
      case FilterType.DAILY:
        dateUnit = "month";
        today.setDate(1);
        startDate = today.getTime();
        break;
      case FilterType.MONTHLY:
        dateUnit = "year";
        today.setDate(1);
        today.setMonth(0);
        startDate = today.getTime();
        break;
      default:
        break;
    }

    dispatch(fetchAnalyticsFollowersStatistics({ startDate, dateUnit }));
  }, [dispatch, dayIndex]);

  const graphData = {
    labels: statistics.map(({ date }) => moment(date).format(Formatter.getDateFormatType(dayIndex))),
    datasets: [
      {
        label: "Followers",
        data: statistics.map(({ count }) => count),
        fill: true,
        tension: 0.5,
        borderColor: (context: ScriptableContext<"line">) => {
          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "#3A09FD");
          gradient.addColorStop(1, "#3BA2EE");
          return gradient;
        },
        // pointBorderWidth: 0,
      },
    ],
  };

  return (
    <Box sx={{ pt: 3, pb: { xs: 10, sm: 3 }, ...sx }} {...other}>
      <Box
        sx={{
          flexWrap: "wrap",
          display: { xs: "block", sm: "flex" },
          flexDirection: "row",
          justifyContent: { xs: "center", sm: "start" },
        }}
      >
        <StatusBox Icon={PeopleIcon} label="Total Followers" value={totalFollowers} />
        <StatusBox Icon={HandIcon} label="New Followers" value={newFollowers} />
      </Box>

      <Box component={Paper} sx={{ mt: 4, p: { xs: 1.5, sm: 3 }, width: { md: "100%", lg: "85%", xl: "70%" } }}>
        <FlexRow sx={{ justifyContent: "space-between" }}>
          <Typography sx={{ color: "#A3AED0", fontSize: 16, fontWeight: 600 }}>Followers Graph</Typography>
          <FlexRow>
            <OptionButton
              onClick={() => setDayIndex(FilterType.DAILY)}
              isSelected={dayIndex === FilterType.DAILY}
              label={FilterType.DAILY}
            />
            <OptionButton
              onClick={() => setDayIndex(FilterType.MONTHLY)}
              isSelected={dayIndex === FilterType.MONTHLY}
              label={FilterType.MONTHLY}
            />
            <OptionButton
              onClick={() => setDayIndex(FilterType.ALL)}
              isSelected={dayIndex === FilterType.ALL}
              label={FilterType.ALL}
            />
          </FlexRow>
        </FlexRow>

        <Box sx={{ backgroundColor: "#282C46", mt: 1, px: 2, py: 0.5, borderRadius: 1, display: "table" }}>
          <Typography sx={{ color: "#A3AED0", fontSize: 12 }}>New Followers</Typography>
          <Typography sx={{ color: "#219653", fontSize: 18, fontWeight: 600 }}>
            {!loading && newFollowers > 0 ? "+" : ""}
            {loading ? 0 : newFollowers}
          </Typography>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Line options={LineChartOptions} data={graphData} height={30} width={90} />
        </Box>
      </Box>
    </Box >
  );
};

export default AnalyticsFollowers;
