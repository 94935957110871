import { handleActions } from "redux-actions";
import produce from "immer";

import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { LiveStreamer, TwitterFeed, TwitterUser, User, StreamVideo } from "../../types";

export interface StreamerState {
  loading: boolean;
  success: boolean;
  error: any;
  tweets: {
    [twitterId: string]: {
      loading?: boolean;
      data?: Array<TwitterFeed>;
      includes?: { users?: Array<TwitterUser> };
      meta?: { next_token?: string; result_count?: 20 };
    };
  };
  streamer: {
    loading: boolean;
    success: boolean;
    error: any;
    data?: User;
    ids: Array<string>;
  };
  records: {
    loading: boolean;
    success: boolean;
    error: any;
    data: StreamVideo[];
    page: number;
    total: number;
    hasMore: boolean;
  };
  liveStreamers: {
    loading: boolean;
    success: boolean;
    error: any;
    data: LiveStreamer[];
  };
  streamData: {
    url?: string;
    isLive: boolean;
    title?: string;
    boltId: string;
    isChannel?: boolean;
  };
}

const initialState: StreamerState = {
  loading: false,
  success: false,
  error: null,
  tweets: {},
  streamer: {
    loading: false,
    success: false,
    error: null,
    ids: [],
    data: undefined,
  },
  records: {
    loading: false,
    success: false,
    error: null,
    data: [],
    hasMore: false,
    page: 1,
    total: 0,
  },
  liveStreamers: {
    loading: false,
    success: false,
    error: null,
    data: [],
  },
  streamData: {
    isLive: false,
    boltId: "",
  },
};

const reducer = handleActions<StreamerState, any>(
  {
    // [actions.RESET_STREAMER]: () => initialState,
    [actions.RESET_STREAMER]: (state) =>
      produce(state, (draft) => {
        draft.loading = initialState.loading;
        draft.success = initialState.success;
        draft.error = initialState.error;
        draft.tweets = initialState.tweets;
        draft.streamer = initialState.streamer;
        draft.records = initialState.records;
        // draft.liveStreamers = initialState.liveStreamers;
      }),

    [actions.GET_STREAMER + START]: (state) =>
      produce(state, (draft) => {
        draft.streamer.loading = true;
        draft.streamer.success = false;
        draft.streamer.error = null;
      }),
    [actions.GET_STREAMER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.streamer.loading = false;
        draft.streamer.success = true;
        draft.streamer.data = payload;
      }),
    [actions.GET_STREAMER + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.streamer.loading = false;
        draft.streamer.success = false;
        draft.streamer.error = payload;
        draft.streamer.data = undefined;
      }),
    [actions.FETCH_STREAMER_TWEETS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = { ...state.tweets, [payload.twitterId]: { ...state.tweets[payload.twitterId], loading: true } };
      }),
    [actions.FETCH_STREAMER_TWEETS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = {
          ...state.tweets,
          [payload.twitterId]: {
            loading: false,
            data: payload?.meta?.next_token
              ? [...(state.tweets[payload.twitterId]?.data || []), ...(payload?.data || [])]
              : payload?.data,
            meta: payload?.meta,
            includes: payload?.includes,
          },
        };
      }),
    [actions.FETCH_STREAMER_TWEETS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = { ...state.tweets, [payload.twitterId]: { loading: false, data: [], meta: {} } };
      }),

    [actions.FETCH_STREAMER_RECORD + START]: (state) =>
      produce(state, (draft) => {
        draft.records.loading = true;
        draft.records.success = false;
        draft.records.error = null;
      }),
    [actions.FETCH_STREAMER_RECORD + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.records.loading = false;
        draft.records.success = true;
        draft.records.data = payload.page === 1 ? payload.data : [...state.records.data, ...payload.data];
        draft.records.page = payload.page;
        draft.records.total = payload.total;
        draft.records.hasMore = draft.records.data.length < payload.total;
      }),
    [actions.UPDATE_STREAMER_RECORD + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const index = state.records.data?.findIndex((item) => item.id === payload.id);
        if (index < 0) {
          return;
        }
        draft.records.data[index] = payload.data;
      }),
    [actions.DELETE_STREAMER_RECORD + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.records.data = state.records.data?.filter((item) => item.id !== payload.id);
        draft.records.total -= 1;
      }),
    [actions.FETCH_STREAMER_RECORD + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.records.loading = false;
        draft.records.success = false;
        draft.records.error = payload;
        draft.records.data = [];
      }),
    [actions.FETCH_LIVE_STREAMERS + START]: (state) =>
      produce(state, (draft) => {
        draft.liveStreamers.loading = true;
        draft.liveStreamers.success = false;
        draft.liveStreamers.error = null;
      }),
    [actions.FETCH_LIVE_STREAMERS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStreamers.loading = false;
        draft.liveStreamers.success = true;
        draft.liveStreamers.data = payload;
      }),
    [actions.FETCH_LIVE_STREAMERS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStreamers.loading = false;
        draft.liveStreamers.success = false;
        draft.liveStreamers.error = payload;
        draft.liveStreamers.data = [];
      }),
    [actions.SET_STREAM_DATA]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.streamData = {
          ...state.streamData,
          ...payload,
        };
      }),
  },
  initialState
);

export default reducer;
