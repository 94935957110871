import { ImmersivePanelLayout } from "../../../../layouts";
import { BloombergEpisodesPageProvider } from "../episodes-context";
import { BloombergEpisodePageUi } from "./BloombergEpisodePageUi";

export const BloombergEpisodePage = () => {
  return (
    <BloombergEpisodesPageProvider>
      <ImmersivePanelLayout>
        <BloombergEpisodePageUi />
      </ImmersivePanelLayout>
    </BloombergEpisodesPageProvider>
  );
};
