import { createAction } from "redux-actions";
import { START } from "../common";
import { GiphyObject } from "../../types";

export const FETCH_FEED_DETAILS = "FETCH_FEED_DETAILS";
export const FETCH_FEED_COMMENT = "FETCH_FEED_COMMENT";
export const FETCH_FEED_COMMENT_REPLY = "FETCH_FEED_COMMENT_REPLY";
export const STREAM_INDIVIDUAL_FEED_REACTION = "STREAM_INDIVIDUAL_FEED_REACTION";
export const STREAM_INDIVIDUAL_FEED_REACTION_REMOVE = "STREAM_INDIVIDUAL_FEED_REACTION_REMOVE";
export const STREAM_COMMENT_REACTION = "STREAM_COMMENT_REACTION";
export const STREAM_COMMENT_REACTION_REMOVE = "STREAM_COMMENT_REACTION_REMOVE";
export const STREAM_REPLY_REACTION = "STREAM_REPLY_REACTION";
export const STREAM_REPLY_REACTION_REMOVE = "STREAM_REPLY_REACTION_REMOVE";
export const FEED_DETAILS_LIKE = "FEED_DETAILS_LIKE";
export const FEED_COMMENT_LIKE = "FEED_COMMENT_LIKE";
export const FEED_COMMENT_ADD = "FEED_COMMENT_ADD";
export const FEED_COMMENT_ADD_REPLY = "FEED_COMMENT_ADD_REPLY";

export type FeedDetailsActionType = { streamFeed: string };

export type FeedCommentsActionType = { streamFeed: string; limit?: number; initialCommentsLoading?: boolean };

export type FeedCommentsReplyActionType = {
  streamFeed: string;
  streamFeedComment: string;
  limit?: number;
  index: number;
};

export type FeedDetailsLikeAction = {
  feedId: string;
  like: boolean;
};

export type FeedCommentLikeAction = {
  commentId: string;
  like: boolean;
  index: number;
  reply?: {
    index: number;
  };
};

export type FeedCommentAddAction = {
  feedId: string;
  commentText: string;
  draftContent?: string;
  giphy?: GiphyObject;
  limit?: number;
};

export type FeedCommentAddReplyAction = {
  index: number;
  feedId: string;
  commentId: string;
  replyText: string;
  draftContent?: string;
  limit?: number;
};

export const fetchFeedDetails = createAction<FeedDetailsActionType>(FETCH_FEED_DETAILS + START);
export const fetchFeedComments = createAction<FeedCommentsActionType>(FETCH_FEED_COMMENT + START);
export const fetchFeedCommentReply = createAction<FeedCommentsReplyActionType>(FETCH_FEED_COMMENT_REPLY + START);
export const addStreamIndividualFeedReaction = createAction(STREAM_INDIVIDUAL_FEED_REACTION + START);
export const removeStreamIndividualFeedReaction = createAction(STREAM_INDIVIDUAL_FEED_REACTION_REMOVE + START);
export const addStreamCommentReaction = createAction(STREAM_COMMENT_REACTION + START);
export const removeStreamCommentReaction = createAction(STREAM_COMMENT_REACTION_REMOVE + START);
export const addStreamReplyReaction = createAction(STREAM_REPLY_REACTION + START);
export const removeStreamReplyReaction = createAction(STREAM_REPLY_REACTION_REMOVE + START);
export const feedDetailsLike = createAction<FeedDetailsLikeAction>(FEED_DETAILS_LIKE + START);
export const feedCommentLike = createAction<FeedCommentLikeAction>(FEED_COMMENT_LIKE + START);
export const feedCommentAdd = createAction<FeedCommentAddAction>(FEED_COMMENT_ADD + START);
export const feedCommentAddReply = createAction<FeedCommentAddReplyAction>(FEED_COMMENT_ADD_REPLY + START);
