import { createSelector } from "reselect";
import { RootState } from "../../types/store";

const getChannelState = ({ channel }: RootState) => channel;

export const channelSelector = createSelector([getChannelState], (channel) => channel);

export const carouselSelector = createSelector([getChannelState], ({ carousels }) => carousels);

export const trendingChannelSelector = createSelector([getChannelState], ({ trending }) => trending);

export const categoryChannelSelector = (categoryKey: string) =>
  createSelector([getChannelState], ({ channels }) => {
    return channels[categoryKey]?.channels || [];
  });

export const channelTweetsSelector = (channelId?: string) => createSelector([getChannelState], ({ tweets }) => tweets[channelId || ""] || {});
export const channelFollowSelector = createSelector([getChannelState], ({ follow }) => follow);
