import { useMemo } from "react";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { BottomNavItem } from "./BottomNavItem";
import { ListenSvg } from "./ListenSvg";

export const ListenNavItem = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isActive = useMemo(() => {
    return (
      pathname === "/live/radio/home" || pathname === "/live/radio/trending" || pathname === "/live/radio/following"
    );
  }, [pathname]);

  const onClick = () => {
    navigate("/live/radio/home");
  };

  return (
    <BottomNavItem
      icon={<ListenSvg style={{ fontSize: 22, color: isActive ? theme.palette.primary.main : "white" }} />}
      label="Listen"
      onClick={onClick}
    />
  );
};
