import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, GlobalStyles, Grid, MenuItem, Select, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { EarningIcon, WithdrawIcon, DebitIcon } from "../../../assets/svgs/users";
import { FlexRow } from "../../../components";
import { EarningType, EarningHistory, WithdrawType } from "../../../types";
import { earningSelector } from "../../../store/users/selector";
import {
  fetchEarningTotalAction,
  fetchEarningListAction,
  fetchEarningWithdrawTotalAction,
  fetchEarningWithdrawListAction,
  fetchEarningBalanceTotalAction,
} from "../../../store/users/actions";
import { HistoryDebit, HistoryEarning, HistoryWithdraw, HistoryTabButton } from "./components";
import { useWithdraw } from "../../../hooks/WithdrawContext";

type Props = {
  tab: EarningType;
  isActive: boolean;
};

export const EarningTabItem = ({ tab, isActive }: Props) => {
  const { openForm, setValues } = useWithdraw();
  const dispatch = useDispatch();
  const { loading, data, totalEarnings, totalWithdraw, page, hasMore } = useSelector(earningSelector);

  const menuButtons = [
    { title: EarningHistory.EARNINGS, Icon: (props: React.SVGProps<SVGSVGElement>) => <EarningIcon {...props} /> },
    { title: EarningHistory.WITHDRAWAL, Icon: (props: React.SVGProps<SVGSVGElement>) => <WithdrawIcon {...props} /> },
    // { title: EarningHistory.DEBIT, Icon: (props: React.SVGProps<SVGSVGElement>) => <DebitIcon {...props} /> }
  ];

  const [filter, setFilter] = useState("all");
  const [currentHistory, setCurrentHistory] = useState<EarningHistory>(EarningHistory.EARNINGS);
  const [isOpen, setIsOpen] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0);

  const fetchEarningTotal = useCallback(() => dispatch(fetchEarningTotalAction()), [dispatch]);
  const fetchEarningList = useCallback((data: any) => dispatch(fetchEarningListAction(data)), [dispatch]);
  const fetchEarningWithdrawTotal = useCallback(() => dispatch(fetchEarningWithdrawTotalAction()), [dispatch]);
  const fetchEarningWithdrawList = useCallback(
    (data: any) => dispatch(fetchEarningWithdrawListAction(data)),
    [dispatch]
  );
  const fetchEarningBalanceTotal = useCallback(() => dispatch(fetchEarningBalanceTotalAction()), [dispatch]);

  const getPaymentMethod = () => {
    switch (tab) {
      case EarningType.BINANCE_PAY:
        return "binance";
      case EarningType.APPLE_GOOGLE_PAY:
        return "stripe";
      default:
        return "";
    }
  };

  useEffect(() => {
    // Change withdraw type on context regarding the tab changes
    // setValues({ type: WithdrawType.BINANCE })
  }, [tab, setValues]);

  useEffect(() => {
    const handler = () => setIsOpen(false);
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      switch (currentHistory) {
        case EarningHistory.EARNINGS:
          fetchEarningList({ page: 1, type: getPaymentMethod() });
          break;
        case EarningHistory.WITHDRAWAL:
          fetchEarningWithdrawList({ page: 1, type: getPaymentMethod() });
          break;
        case EarningHistory.DEBIT:
          break;
        default:
          break;
      }
    }
  }, [isActive, currentHistory]);

  useEffect(() => {
    if (isActive) {
      fetchEarningTotal();
      fetchEarningWithdrawTotal();
    }
  }, [isActive]);

  useEffect(() => {
    let temp = 0;
    switch (tab) {
      case EarningType.OVERVIEW:
        temp = totalEarnings.stripe + totalEarnings.binance - (totalWithdraw.stripe + totalWithdraw.binance);
        break;
      case EarningType.BINANCE_PAY:
        temp = totalEarnings.binance - totalWithdraw.binance;
        break;
      case EarningType.APPLE_GOOGLE_PAY:
        temp = totalEarnings.stripe - totalWithdraw.stripe;
        break;
      default:
        break;
    }
    setTotalBalance(temp);
  }, [totalEarnings, totalWithdraw]);

  const handleFilterChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value);
    setIsOpen(false);
  };

  const getTotalLabel = () => {
    switch (currentHistory) {
      case EarningHistory.EARNINGS:
        return "Total Earnings";
      case EarningHistory.WITHDRAWAL:
        return "Total Amount Withdrawn";
      case EarningHistory.DEBIT:
        return "Total Amount Debited";
      default:
        return "";
    }
  };

  const getHistoryTotalAmount = () => {
    let amount = 0;
    if (tab === EarningType.OVERVIEW) {
      if (currentHistory === EarningHistory.EARNINGS) {
        amount = totalEarnings.stripe + totalEarnings.binance;
      } else if (currentHistory === EarningHistory.WITHDRAWAL) {
        amount = totalWithdraw.stripe + totalWithdraw.binance;
      }
    } else if (tab === EarningType.BINANCE_PAY) {
      amount = currentHistory === EarningHistory.EARNINGS ? totalEarnings.binance : totalWithdraw.binance;
    } else if (tab === EarningType.APPLE_GOOGLE_PAY) {
      amount = currentHistory === EarningHistory.EARNINGS ? totalEarnings.stripe : totalWithdraw.stripe;
    }

    return amount.toFixed(2);
  };

  if (!isActive) return null;

  return (
    <Grid container rowSpacing={2} sx={{ pt: 5, pb: { xs: 10, sm: 3 } }}>
      <GlobalStyles styles={{ ".css-6nevci-MuiTabs-indicator, .css-1lkv59c": { display: "none !important" } }} />
      <Grid item xs={12} md={2} sx={{ justifyContent: "center", textAlign: { xs: "center", md: "start" } }}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, textAlign: "start", color: "#A3AED0" }}>
          Total Balance
        </Typography>
        <Typography sx={{ fontSize: { xs: 34, md: 45 }, fontWeight: 600, textAlign: { xs: "center", md: "start" } }}>
          ${totalBalance.toFixed(2)}
        </Typography>
        <Button
          variant="contained"
          sx={{ my: 2, width: { xs: 160, md: 120, lg: 160 }, height: 34, textTransform: "none", borderRadius: 4 }}
          onClick={openForm}
        >
          <Typography sx={{ fontWeight: 600, fontSize: 14 }}>Withdraw</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={10} lg={8}>
        <FlexRow sx={{ display: { xs: "none", sm: "flex" } }}>
          {menuButtons.map(({ title, Icon }, index) => (
            <HistoryTabButton
              key={index}
              title={title}
              Icon={Icon}
              isActive={currentHistory === title}
              onClick={() => setCurrentHistory(title)}
            />
          ))}
        </FlexRow>
        <FlexRow sx={{ display: { sm: "none" } }}>
          <table>
            <tbody>
              <tr>
                {menuButtons.map(({ title, Icon }, index) => (
                  <td key={index}>
                    <HistoryTabButton
                      title={title}
                      Icon={Icon}
                      isActive={currentHistory === title}
                      onClick={() => setCurrentHistory(title)}
                      isMobile
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </FlexRow>

        <FlexRow sx={{ mt: 4, alignItems: "center", justifyContent: "space-between" }}>
          <FlexRow sx={{ alignItems: "center" }}>
            <Typography sx={{ fontWeight: 600, fontSize: 14, color: "#A3AED0" }}>{getTotalLabel()}</Typography>
            <Typography sx={{ fontWeight: 600, fontSize: 24, ml: 2 }}>${getHistoryTotalAmount()}</Typography>
          </FlexRow>
          <FormControl
            sx={{
              ml: 1,
              minWidth: { xs: 80, sm: 110, md: 110, lg: 110, xl: 110 },
              display: "none",
              borderRadius: 2,
              backgroundColor: "#282C46",
              "& fieldset": { borderWidth: 0 },
              "& .MuiOutlinedInput-root": {
                "& .MuiSelect-select": { py: 0.5, textAlign: "center", fontSize: { xs: 12, sm: 14 } },
                "&.Mui-focused fieldset": { borderWidth: 0 },
              },
            }}
            size="small"
            color="secondary"
          >
            <Select
              open={isOpen}
              value={filter}
              onChange={handleFilterChange}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              displayEmpty={false}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{ disableScrollLock: true }}
            >
              <MenuItem value="all" sx={{ fontSize: { xs: 12, sm: 14 }, minHeight: { xs: 25, sm: 35 } }}>
                All
              </MenuItem>
              <MenuItem value="succeeded" sx={{ fontSize: { xs: 12, sm: 14 }, minHeight: { xs: 25, sm: 35 } }}>
                Succeeded
              </MenuItem>
              <MenuItem value="pending" sx={{ fontSize: { xs: 12, sm: 14 }, minHeight: { xs: 25, sm: 35 } }}>
                Pending
              </MenuItem>
              <MenuItem value="cancelled" sx={{ fontSize: { xs: 12, sm: 14 }, minHeight: { xs: 25, sm: 35 } }}>
                Cancelled
              </MenuItem>
            </Select>
          </FormControl>
        </FlexRow>

        {currentHistory === EarningHistory.EARNINGS && (
          <HistoryEarning
            data={data}
            loading={loading}
            hasMore={hasMore}
            loadMore={() => !loading && fetchEarningList({ page: page + 1, type: getPaymentMethod() })}
          />
        )}
        {currentHistory === EarningHistory.WITHDRAWAL && (
          <HistoryWithdraw
            data={data}
            loading={loading}
            hasMore={hasMore}
            loadMore={() => !loading && fetchEarningWithdrawList({ page: page + 1, type: getPaymentMethod() })}
          />
        )}
        {currentHistory === EarningHistory.DEBIT && (
          <HistoryDebit
            data={data}
            loading={loading}
            hasMore={hasMore}
            loadMore={() => !loading && fetchEarningWithdrawList({ page: page + 1, type: getPaymentMethod() })}
          />
        )}
      </Grid>
    </Grid>
  );
};
