import { Box, ButtonBase, ModalProps } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { NFTFeed, StreamFeed } from "../../types";
import { FlexRow } from "../../components/FlexRow";
import { BaseModal } from "../../components/modals/BaseModal";
import { FeedContentContainer } from "./FeedContentContainer";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";
import { ReactComponent as LeftIcon } from "../../assets/svgs/arrowLeft.svg";
import { ReactComponent as RightIcon } from "../../assets/svgs/arrowRight.svg";
import { FlexCol } from "../../components";
import ReactionButtons from "../../components/ReactionButtons";
import BoltsReceived from "../../components/BoltsReceived";
import PrevNextButton from "../../components/PrevNextButton";
import { feedDetailsSelector } from "../../store/feedDetails/selector";
import { fetchFeedDetails } from "../../store/feedDetails/actions";
import FeedSkeleton from "./FeedSkeleton";

interface Props extends Partial<ModalProps> {
  item?: StreamFeed | NFTFeed;
  home?: boolean;
  contentIndex: number;
  setContentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const ZoomedViewModal = ({ item, home, contentIndex, setContentIndex, onClose, ...rest }: Props) => {
  const dispatch = useDispatch();

  const feedDetails = useSelector(feedDetailsSelector);

  useEffect(() => {
    if (home && item) dispatch(fetchFeedDetails({ streamFeed: item.id }));
  }, [dispatch, home, item]);

  const handleOnClose = useCallback((e: any, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick" && onClose) {
      onClose(e, reason);
    }
  }, []);

  const handleOnClick = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  const handleCloseButtonClick = useCallback((e: any) => {
    e.stopPropagation();
    if (onClose) onClose(e, "escapeKeyDown");
  }, []);

  const handlePrevClick = useCallback(() => {
    setContentIndex(contentIndex - 1);
  }, [contentIndex, setContentIndex]);

  const handleNextClick = useCallback(() => {
    setContentIndex(contentIndex + 1);
  }, [contentIndex, setContentIndex]);

  let selectedMediaArray = item?.items || [];

  if (item?.reboltFeed?.items) {
    selectedMediaArray = item.reboltFeed.items;
  }

  return (
    <BaseModal {...rest} open onClose={handleOnClose} onClick={handleOnClick}>
      <Box sx={{ height: "100%", width: "100%" }}>
        <FlexRow sx={{ height: "100%", width: "100%" }}>
          <FlexCol
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              pl: { xs: "none", md: "30px" },
              pr: { lg: 2.5, xs: "none", md: "30px" },
              gap: 3.5,
            }}
          >
            <ButtonBase
              sx={{
                padding: 0.75,
                cursor: "pointer",
                // marginLeft: { xl: 4.625, md: 3.375, sm: 2.125, xs: 1.25 },
                width: "fit",
                alignSelf: "flex-start",
              }}
              onClick={handleCloseButtonClick}
            >
              <CloseIcon style={{ height: 20, width: 20 }} />
            </ButtonBase>
            <Box
              sx={{
                height: "74%",
                width: "100%",
                // backgroundColor: "black",
                borderRadius: 1.25,
                overflow: "hidden",
                position: "relative",
              }}
            >
              {selectedMediaArray[contentIndex].type === "image" && (
                <img
                  src={selectedMediaArray[contentIndex].url}
                  alt="feed"
                  style={{ height: "100%", width: "100%", objectFit: "contain" }}
                />
              )}
              {selectedMediaArray[contentIndex].type === "video" && (
                <ReactPlayer
                  playing
                  playsinline
                  muted={false}
                  controls
                  url={selectedMediaArray[contentIndex].url}
                  width="100%"
                  height="100%"
                  style={{ display: "flex", objectFit: "contain" }}
                  pip
                />
              )}
              {contentIndex > 0 && (
                <PrevNextButton
                  sx={{
                    position: "absolute",
                    top: "50%",
                    transform: "translate(0, -50%)",
                    left: 20,
                  }}
                  onClick={handlePrevClick}
                >
                  <LeftIcon style={{ marginRight: 3, height: 24 }} />
                </PrevNextButton>
              )}
              {contentIndex < selectedMediaArray.length - 1 && (
                <PrevNextButton
                  sx={{
                    position: "absolute",
                    top: "50%",
                    transform: "translate(0, -50%)",
                    right: 20,
                  }}
                  onClick={handleNextClick}
                >
                  <RightIcon style={{ marginLeft: 3, height: 24 }} />
                </PrevNextButton>
              )}
            </Box>
            <FlexRow
              sx={{ px: 1.875, py: 1.25, justifyContent: "space-between", flexDirection: { xs: "column", sm: "row" } }}
            >
              <ReactionButtons
                {...{
                  large: true,
                  reactions: item?.reboltFeed?.reactions || item?.reactions,
                  reactionCount: item?.reboltFeed?.reactionCount || item?.reactionCount,
                  streamFeedId: item?.reboltFeed?.id || item?.id,
                  replyCount: item?.reboltFeed?.commentCount || item?.commentCount,
                  reboltCount: item?.reboltFeed?.reboltCount || item?.reboltCount,
                }}
              />
              <BoltsReceived
                {...{
                  large: true,
                  boltCount: item?.reboltFeed?.boltCount || item?.boltCount,
                  bolts: item?.reboltFeed?.bolts || item?.bolts,
                }}
              />
            </FlexRow>
          </FlexCol>
          <Box sx={{ width: "454px", display: { lg: "block", xs: "none" }, margin: "25px 30px 25px 0" }}>
            {feedDetails.loading && <FeedSkeleton />}
            {!feedDetails.loading && (
              <FeedContentContainer
                {...{ item: home ? feedDetails : item, excludeMedia: true, disableExpandView: true }}
              />
            )}
          </Box>
        </FlexRow>
      </Box>
    </BaseModal>
  );
};

export default React.memo(ZoomedViewModal);
