import { useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import { orderBy } from "lodash";

import { TrendCategory } from "./TrendCategory";
import { TrendTag } from "./TrendTag";
import { TrendChannel } from "./TrendChannel";
import { TrendStreamer } from "./TrendStreamer";
import { useTrendListContext } from "./TrendListContext";

export const TrendListUi = () => {
  const { isLoading, data: trends } = useTrendListContext();

  const categories = useMemo(() => {
    return orderBy(
      (trends || []).filter((item) => item.type === "sub_category"),
      ["order"],
      ["asc"]
    ).slice(0, 5);
  }, [trends]);

  const tags = useMemo(() => {
    return orderBy(
      (trends || []).filter((item) => item.type === "tag"),
      ["order"],
      ["asc"]
    ).slice(0, 5);
  }, [trends]);

  const channels = useMemo(() => {
    return orderBy(
      (trends || []).filter((item) => item.type === "channel"),
      ["order"],
      ["asc"]
    ).slice(0, 5);
  }, [trends]);

  const users = useMemo(() => {
    return orderBy(
      (trends || []).filter((item) => item.type === "user"),
      ["order"],
      ["asc"]
    ).slice(0, 5);
  }, [trends]);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <div>
      {categories.length > 0 &&
        categories.map((item, index) => <TrendCategory key={`trend-sub-category-${index}`} {...item} />)}

      {tags.length > 0 && tags.map((item, index) => <TrendTag key={`trend-tag-${index}`} {...item} />)}

      {channels.length > 0 && channels.map((item, index) => <TrendChannel key={`trend-channel-${index}`} {...item} />)}

      {users.length > 0 && users.map((item, index) => <TrendStreamer key={`trend-user-${index}`} {...item} />)}
    </div>
  );
};
