import { all, fork, put, select, take } from "redux-saga/effects";
import { Dispatch } from "redux";
import { fetchBalance } from "@wagmi/core";
import * as actions from "./actions";
import * as authActions from "../auth/actions";
import { FAIL, START, SUCCESS } from "../common";

import { getEthereumPrice } from "../../services/crypto/coingecko";
import { Ethereum } from "../../services/crypto/ethereum";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";

const fetchEthBalances = async (address: any, dispatch: Dispatch) => {
  const balances = await fetchBalance({ address });
  const ethToken = await getEthereumPrice();

  const formatedBalance = {
    value: balances.formatted,
    symbol: balances.symbol,
    price: ethToken.usd,
  };

  dispatch({ type: actions.UPDATE_BALANCES, payload: { address, balance: formatedBalance } });
};

function* initWallet(dispatch: Dispatch): any {
  while (true) {
    const { payload } = yield take(actions.INIT_WALLET + START);
    try {
      const { data } = yield apiClient.post(`/users/connectWallet`, {
        walletAddress: payload.walletAddress,
        wallet: payload.connectedTo,
      });

      const formatedWallets = data.providers.map((item: any) => {
        fetchEthBalances(item.walletAddress, dispatch);

        return item;
      });

      yield put({
        type: authActions.UPDATE_USER_WALLET + SUCCESS,
        payload: data,
      });

      // update account
      yield put({ type: actions.CONNECT_ACCOUNT, payload: { accounts: formatedWallets } });

      // fetch eth balance and erc20 balances
      // const erc20Balance = yield Ethereum.getERC20Balances(payload.accounts[0]);
      // const ethBalance = yield Ethereum.getBalance(payload.accounts[0]);

      // insert eth balance and erc20 balances
      // yield put({
      //   type: actions.UPDATE_BALANCES,
      //   payload: {
      //     ethBalance: {
      //       [BLOCKCHAIN_TYPE.ethereum]: { contractAddress: BLOCKCHAIN_TYPE.ethereum, tokenBalance: ethBalance },
      //     },
      //     erc20Balance,
      //   },
      // });

      // get contract address ids from balance then fetch tokens base on balance contract addresses
      // const contractAddressIds = erc20Balance.map((item: ERC20Balance) => item.contractAddress);
      // const erc20Tokens = yield getPriceByContractAddresses("ethereum", contractAddressIds);
      // const ethToken = yield getEthereumPrice();

      // yield put({
      //   type: actions.UPDATE_TOKENS,
      //   payload: { ethToken: { [BLOCKCHAIN_TYPE.ethereum]: ethToken }, erc20Tokens },
      // });

      yield put({
        type: actions.INIT_WALLET + SUCCESS,
      });
    } catch (error: any) {
      const errorData = BoltError(error);
      if (errorData?.status === 409) {
        ToastService.showErrorMessage(errorData.message);
      }
      yield put({ type: actions.INIT_WALLET + FAIL, payload: errorData });
    }
  }
}

function* fetchNft(): any {
  while (true) {
    const { payload } = yield take(actions.FETCH_NFT + START);
    try {
      const { nftCreator, wallet } = yield select();

      const contractAddress = nftCreator?.nftCreator?.data[payload]?.contractAddress;
      const walletAddress = wallet.accounts[0];
      // const walletAddress = '0xC6Ef03CAd320d952F1F4BD47f6b0f834dCD4eF14'
      // const contractAddress = '0x1dfe7ca09e99d10835bf73044a23b73fc20623df'

      const { data } = yield Ethereum.getNFTByContractAddress(walletAddress, contractAddress);

      yield put({
        type: actions.FETCH_NFT + SUCCESS,
        payload: data.ownedNfts,
      });
    } catch (error: any) {
      yield put({ type: actions.FETCH_NFT + FAIL, payload: error.message });
    }
  }
}

function* disconnectWallet(): any {
  while (true) {
    const { payload } = yield take(actions.DISCONNECT_ACCOUNT + START);
    try {
      yield apiClient.post(`/users/disconnectWallet`, {
        walletAddress: payload.walletAddress,
        wallet: payload.connectedTo,
      });

      yield put({
        type: actions.DISCONNECT_ACCOUNT + SUCCESS,
        payload: { address: payload.walletAddress },
      });
    } catch (error: any) {
      yield put({ type: actions.DISCONNECT_ACCOUNT + FAIL, payload: error.message });
    }
  }
}

export default function* walletConnectSaga(dispatch: Dispatch) {
  yield all([fork(initWallet, dispatch), fork(fetchNft), fork(disconnectWallet)]);
}
