import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";

import { NAV_BAR_HEIGHT } from "../../constants";
import { OrderChip } from "./OrderChip";
import { useDiscoverPageContext } from "./DiscoverPageContext";

export const Content = () => {
  const navigate = useNavigate();

  const { selectedItem, bottomOffset } = useDiscoverPageContext();

  const onPlay = useCallback(() => {
    navigate(`/${selectedItem.slug}`);
  }, [selectedItem]);

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: { xs: 2, md: 3 },
        px: 4,
        py: 4,
        mt: {
          xs: `calc((100vh - ${NAV_BAR_HEIGHT}px - 24px - 8px - (65vw / 1.8) - 8px -  16px - ${bottomOffset}px - 296px - 65px) / 2)`,
          md: `calc((100vh - ${NAV_BAR_HEIGHT}px - 24px - 8px - (25vw / 1.7) - 8px -  16px - ${bottomOffset}px - 364px) / 2)`,
        },
        mb: {
          xs: `calc((100vh - ${NAV_BAR_HEIGHT}px - 24px - 8px - (65vw / 1.8) - 8px -  16px - ${bottomOffset}px - 296px - 65px) / 2)`,
          md: `calc((100vh - ${NAV_BAR_HEIGHT}px - 24px - 8px - (25vw / 1.7) - 8px -  16px - ${bottomOffset}px - 364px) / 2)`,
        },
        height: { xs: 296, md: 364 },
        zIndex: 2,
      }}
    >
      <Box>
        <Box
          component="img"
          src={selectedItem.logo}
          sx={{
            height: { xs: 48, md: 85 },
            width: "auto",
            objectFit: "contain",
            borderRadius: 1,
          }}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1.5 }}>
        <OrderChip order={selectedItem.column} />

        <Typography sx={{ color: "#8F9095" }}>in {selectedItem.collectionName}</Typography>
      </Box>

      <Box sx={{ maxWidth: 428 }}>
        <Typography
          sx={{
            fontWeight: 500,
            lineHeight: "28px",
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {selectedItem.shortDescription || selectedItem.description}
        </Typography>
      </Box>

      <Box>
        <Button
          variant="contained"
          startIcon={<PlayArrow />}
          sx={{ borderRadius: 10, fontSize: { xs: 16, md: 20 }, width: { xs: 120, md: 160 } }}
          onClick={onPlay}
        >
          Play
        </Button>
      </Box>
    </Box>
  );
};
