import React, { useState } from "react";
import { GlobalStyles, Typography } from "@mui/material";

import { ReactComponent as EarningColorIcon } from "../../../assets/svgs/earningColor.svg";
import { CustomTab, CustomTabs, FlexCol, FlexRow, ScrollToTopButton } from "../../../components";
import { NAV_BAR_HEIGHT } from "../../../constants";
import { EarningType } from "../../../types";
import { EarningTabItem } from "./EarningTabItem";
import { WithdrawContextProvider } from "../../../hooks/WithdrawContext";

export const EarningsPage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <WithdrawContextProvider>
      <FlexCol
        sx={{
          minWidth: "100%",
          display: "flex",
          py: { xs: 2, sm: 3, md: 4, lg: 5 },
          px: { xs: 2, sm: 3, md: 5, lg: 7 },
        }}
      >
        <GlobalStyles styles={{ ".css-6nevci-MuiTabs-indicator": { display: "none !important" } }} />
        <FlexRow sx={{ alignItems: "center" }}>
          <EarningColorIcon width={30} height={30} />
          <Typography sx={{ fontSize: { xs: 20, sm: 24 }, ml: 2.5 }} fontWeight="600">
            Earnings
          </Typography>
        </FlexRow>

        <CustomTabs value={tabIndex} onChange={handleChange}>
          {Object.values(EarningType).map((item, index) => (
            <CustomTab label={item} key={index} />
          ))}
        </CustomTabs>

        {Object.values(EarningType).map((item, index) => (
          <EarningTabItem key={index} tab={item} isActive={tabIndex === index} />
        ))}
        <ScrollToTopButton />
      </FlexCol>
    </WithdrawContextProvider>
  );
};
