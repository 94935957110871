import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";

import { ReactComponent as BrowseIcon } from "../../../assets/svgs/browse.svg";
import { useSearchLandingContext } from "../search-landing";
import { BrowseItem } from "./BrowseItem";
import { useBrowseItems } from "./useBrowseItems";

export const BrowseSlider = () => {
  const { browseItems } = useBrowseItems();

  const { selectedCategoryId, updateContext: updateSearchLandingContext } = useSearchLandingContext();

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [AutoScroll({ speed: 1, startDelay: 100, stopOnFocusIn: false })]
  );
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    if (isPlaying) {
      autoScroll.play();
    } else {
      autoScroll.stop();
    }
  }, [emblaApi, isPlaying]);

  const onMouseEnter = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (selectedCategoryId && browseItems.findIndex((item) => item.id === selectedCategoryId) >= 0) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [selectedCategoryId]);

  const onBrowseItem = useCallback(
    (id: string) => {
      if (selectedCategoryId === id) {
        updateSearchLandingContext({ selectedCategoryId: "" });
      } else {
        updateSearchLandingContext({ selectedCategoryId: id });
      }
    },
    [selectedCategoryId]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        rowGap: 2.5,
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1.5 }}>
        <BrowseIcon />

        <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Browse</Typography>
      </Box>

      <Box sx={{ position: "relative", maxWidth: "100%" }}>
        <Box
          ref={emblaRef}
          sx={{ overflow: "hidden", maxWidth: "100%" }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Box sx={{ display: "flex" }}>
            {browseItems.map((item, index) => (
              <Box key={`browse-item-${index}`}>
                <BrowseItem {...item} active={selectedCategoryId === item.id} onClick={onBrowseItem} />
              </Box>
            ))}
          </Box>
        </Box>

        {/* <Box
          sx={{
            position: "absolute",
            top: 0,
            zIndex: 10,
            height: "100%",
            width: "10%",
            background: "linear-gradient(90deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.5) 39%, rgba(255,255,255,0) 100%)",
            pointerEvents: "none",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10,
            height: "100%",
            width: "10%",
            background:
              "linear-gradient(270deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.5) 39%, rgba(255,255,255,0) 100%)",
            pointerEvents: "none",
          }}
        /> */}
      </Box>
    </Box>
  );
};
