import React from "react";
import { Gif } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Box, BoxProps, Skeleton } from "@mui/material";

import { GiphyObject } from "../types";

const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY!);

interface Props extends BoxProps {
  data: GiphyObject;
  height?: number;
  width?: number;
}

export const FeedGiphyContent = ({ data: giphyData, width = 400, height, ...rest }: Props) => {
  const boxRef = React.useRef<HTMLDivElement>();
  const [data, setData] = React.useState<any>();
  React.useEffect(() => {
    // setWidth(boxRef?.current?.getBoundingClientRect().width as number);
    if (giphyData?.id) {
      (async () => {
        const { data } = await gf.gif(giphyData.id);
        setData(data);
      })();
    }
    return () => {
      setData(undefined);
    };
  }, [giphyData?.id]);

  if (!data) {
    return <Skeleton width="100%" height={300} sx={{ transform: "none" }} />;
  }

  return (
    <Box
      {...rest}
      sx={{ display: "flex", justifyContent: "center", overflow: "hidden", backgroundColor: "black", ...rest?.sx }}
      ref={boxRef}
    >
      <Gif
        gif={data}
        width={width}
        height={height}
        backgroundColor="transparent"
        className="giphy-content"
        noLink
        hideAttribution
      />
    </Box>
  );
};
