import { createAction } from "redux-actions";
import { START } from "../common";

export const FETCH_HOME_STREAM_FEED = "FETCH_HOME_STREAM_FEED";
export const FETCH_HOME_NFT_FEED = "FETCH_HOME_NFT_FEED";
export const CREATE_STREAM_FEED = "CREATE_STREAM_FEED";
export const STREAM_FEED_REACTION = "STREAM_FEED_REACTION";
export const STREAM_FEED_REACTION_REMOVE = "STREAM_FEED_REACTION_REMOVE";
export const LIKE_STREAM_FEED = "LIKE_STREAM_FEED";
export const LIKE_NFT_FEED = "LIKE_NFT_FEED";
export const DELETE_STREAM_FEED = "DELETE_STREAM_FEED";
export const DELETE_NFT_FEED = "DELETE_NFT_FEED";
export const FETCH_USER_STREAM_FEED = "FETCH_USER_STREAM_FEED";
export const STREAM_LIST_COMMENT_REACTION = "STREAM_LIST_COMMENT_REACTION";
export const STREAM_LIST_COMMENT_REACTION_REMOVE = "STREAM_LIST_COMMENT_REACTION_REMOVE";
export const STREAM_LIST_REPLY_REACTION = "STREAM_LIST_REPLY_REACTION";
export const STREAM_LIST_REPLY_REACTION_REMOVE = "STREAM_LIST_REPLY_REACTION_REMOVE";
export const FEED_LIST_COMMENT_ADD = "FEED_LIST_COMMENT_ADD";
export const FEED_LIST_COMMENT_ADD_REPLY = "FEED_LIST_COMMENT_ADD_REPLY";
export const FETCH_FEED_LIST_COMMENT_REPLY = "FETCH_FEED_LIST_COMMENT_REPLY";
export const CREATE_FEED_IMPRESSION = "CREATE_FEED_IMPRESSION";

export const FETCH_NFT_FEED = "FETCH_NFT_FEED";
export const CREATE_NFT_FEED = "CREATE_NFT_FEED";

export const fetchHomeStreamFeed = createAction(FETCH_HOME_STREAM_FEED + START);
export const fetchHomeNFTFeed = createAction(FETCH_HOME_NFT_FEED + START);
export const createStreamFeed = createAction(CREATE_STREAM_FEED + START);
export const addStreamFeedReaction = createAction(STREAM_FEED_REACTION + START);
export const removeStreamFeedReaction = createAction(STREAM_FEED_REACTION_REMOVE + START);
export const fetchFeedListCommentReply = createAction(FETCH_FEED_LIST_COMMENT_REPLY + START);
export const addStreamListFeedCommentReaction = createAction(STREAM_LIST_COMMENT_REACTION + START);
export const removeStreamListFeedCommentReaction = createAction(STREAM_LIST_COMMENT_REACTION_REMOVE + START);
export const addStreamListFeedReplyReaction = createAction(STREAM_LIST_REPLY_REACTION + START);
export const removeStreamListFeedReplyReaction = createAction(STREAM_LIST_REPLY_REACTION_REMOVE + START);
export const likeStreamFeed = createAction(LIKE_STREAM_FEED + START);
export const likeNFTFeed = createAction(LIKE_NFT_FEED + START);
export const fetchNftFeedAction = createAction(FETCH_NFT_FEED + START);
export const createNftFeedAction = createAction(CREATE_NFT_FEED + START);
export const deleteStreamFeedAction = createAction(DELETE_STREAM_FEED + START);
export const deleteNFTFeedAction = createAction(DELETE_NFT_FEED + START);
export const fetchUserStreamFeed = createAction(FETCH_USER_STREAM_FEED + START);
export const feedListCommentAdd = createAction(FEED_LIST_COMMENT_ADD + START);
export const feedListCommentAddReply = createAction(FEED_LIST_COMMENT_ADD_REPLY + START);
export const createFeedImpression = createAction(CREATE_FEED_IMPRESSION);
