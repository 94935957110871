import { SearchLandingContextProvider } from "./SearchLandingContext";
import { SearchLandingUi } from "./SearchLandingUi";

export const SearchLanding = () => {
  return (
    <SearchLandingContextProvider>
      <SearchLandingUi />
    </SearchLandingContextProvider>
  );
};
