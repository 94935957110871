import { createAction } from "redux-actions";
import { START } from "../common";

export const INIT_STREAM_CATEGORIES = "INIT_STREAM_CATEGORIES";
export const FETCH_STREAM_CATEGORIES = "FETCH_STREAM_CATEGORIES";
export const FETCH_STREAM_SUB_CATEGORIES = "FETCH_STREAM_SUB_CATEGORIES";

export const initStreamCategoriesAction = createAction(INIT_STREAM_CATEGORIES);
export const fetchStreamCategoriesAction = createAction(FETCH_STREAM_CATEGORIES + START);
export const fetchStreamSubCategoriesAction = createAction(FETCH_STREAM_SUB_CATEGORIES + START);
