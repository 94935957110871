import { useMemo } from "react";
import { Box } from "@mui/material";

import { useBloombergEpisodesPageContext } from "../episodes-context";
import { EpisodeItem, EpisodeItemProps } from "./EpisodeItem";

export const Episodes = () => {
  const { data } = useBloombergEpisodesPageContext();

  const items: EpisodeItemProps[] = useMemo(() => {
    return (data?.channels || []).map((item, index) => {
      return {
        id: item.id,
        imageProps: {
          src: item.thumbnail,
        },
        title: item.title,
        description: item.description,
        order: index + 1,
        slug: item.slug,
      };
    });
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 1,
        px: { xs: 2, md: 5, lg: 3, xl: 5 },
        pt: { lg: 5 },
        pb: { xs: 8, md: 0 },
        ml: { lg: "auto" },
      }}
    >
      {items.map((item, index) => (
        <Box
          key={`episode-items-slider-${index}`}
          sx={{
            scrollSnapAlign: "start",
            scrollSnapStop: "always",
            pt: 6,
            pb: index < items.length - 1 ? 0 : 6,
            mb: index < items.length - 1 ? -6 : 0,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <EpisodeItem {...item} />
        </Box>
      ))}
    </Box>
  );
};
