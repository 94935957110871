import { Box, IconButton, IconButtonProps } from "@mui/material";
import React from "react";

type Props = IconButtonProps;

const PrevNextButton = ({ sx, children, ...rest }: Props) => {
  return (
    <IconButton
      sx={{
        borderRadius: "50%",
        padding: 2,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(2.5px)",
        height: 65,
        width: 65,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </IconButton>
  );
};

export default PrevNextButton;
