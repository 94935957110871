import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";
import { Carousel, Channel, TwitterFeed, TwitterUser } from "../../types";

export interface ChannelState {
  channel: {
    loading: boolean;
    success: boolean;
    error: any;
    data?: Channel;
  };
  channels: { [symbol: string]: { categoryId: string; title: string; icon: string; channels: Channel[] } };
  trending: {
    loading: boolean;
    items: Channel[];
    error: any;
  };
  following: {
    loading: boolean;
    items: Channel[];
    error: any;
  };
  loading: boolean;
  error: any;
  carousels: { loading: boolean; items: Carousel[]; error: any };
  tweets: {
    [channelId: string]: {
      loading?: boolean;
      data?: Array<TwitterFeed>;
      includes?: { users?: Array<TwitterUser> };
      meta?: { next_token?: string; result_count?: 20 };
    };
  };
  follow: {
    loading: boolean;
    success: boolean;
    isFollow: boolean;
    error: any;
  };
}

const initialState: ChannelState = {
  channels: {},
  trending: {
    loading: false,
    items: [],
    error: null,
  },
  following: {
    loading: false,
    items: [],
    error: null,
  },
  loading: false,
  error: null,
  carousels: {
    loading: false,
    items: [],
    error: null,
  },
  tweets: {},
  channel: {
    loading: false,
    success: false,
    error: null,
    data: undefined,
  },
  follow: {
    loading: false,
    success: false,
    isFollow: false,
    error: null,
  }
};

const reducer = handleActions<ChannelState, any>(
  {
    [actions.INIT_CHANNEL]: (state) =>
      produce(state, (draft) => {
        draft.channel = initialState.channel;
      }),
    [actions.FETCH_CHANNEL + START]: (state) =>
      produce(state, (draft) => {
        draft.channel.loading = true;
        draft.channel.success = false;
        draft.channel.error = null;
      }),
    [actions.FETCH_CHANNEL + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.channel.loading = false;
        draft.channel.success = true;
        draft.channel.data = payload;
      }),
    [actions.FETCH_CHANNEL + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.channel.loading = false;
        draft.channel.success = false;
        draft.channel.error = payload;
        draft.channel.data = undefined;
      }),
    [actions.FETCH_HOME_CAROUSEL + START]: (state) =>
      produce(state, (draft) => {
        draft.carousels.loading = true;
        draft.carousels.error = null;
      }),
    [actions.FETCH_HOME_CAROUSEL + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.carousels.loading = false;
        draft.carousels.items = payload;
        draft.carousels.error = null;
      }),
    [actions.FETCH_HOME_CAROUSEL + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.carousels.loading = false;
        draft.carousels.error = payload;
      }),
    [actions.FETCH_HOME_CHANNELS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      }),
    [actions.FETCH_HOME_CHANNELS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.channels = payload;
        draft.error = null;
      }),
    [actions.FETCH_HOME_CHANNELS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.FETCH_CHANNEL_TWEETS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = { ...state.tweets, [payload.channelId]: { ...state.tweets[payload.channelId], loading: true } };
      }),
    [actions.FETCH_CHANNEL_TWEETS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = {
          ...state.tweets,
          [payload.channelId]: {
            loading: false,
            data: payload?.meta?.next_token
              ? [...(state.tweets[payload.channelId]?.data || []), ...(payload?.data || [])]
              : payload?.data,
            meta: payload?.meta,
            includes: payload?.includes,
          },
        };
      }),
    [actions.FETCH_CHANNEL_TWEETS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = { ...state.tweets, [payload.channelId]: { loading: false, data: [], meta: {} } };
      }),

    [actions.CHECK_CHANNEL_FOLLOW + START]: (state) =>
      produce(state, (draft) => {
        draft.follow.loading = true;
        draft.follow.error = null;
      }),
    [actions.CHECK_CHANNEL_FOLLOW + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.follow.loading = false;
        draft.follow.isFollow = payload;
        draft.follow.error = null;
      }),
    [actions.CHECK_CHANNEL_FOLLOW + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.follow.loading = false;
        draft.follow.error = payload;
      }),


    [actions.CHANGE_CHANNEL_FOLLOW + START]: (state) =>
      produce(state, (draft) => {
        draft.follow.loading = true;
        draft.follow.error = null;
      }),
    [actions.CHANGE_CHANNEL_FOLLOW + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.follow.loading = false;
        draft.follow.isFollow = !draft.follow.isFollow;
        draft.follow.error = null;
      }),
    [actions.CHANGE_CHANNEL_FOLLOW + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.follow.loading = false;
        draft.follow.error = payload;
      }),
  },
  initialState
);

export default reducer;
