import React from "react";
import { Tabs, TabsProps } from "@mui/material";


export const CustomTabs = ({ sx, children, ...other }: TabsProps) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    sx={{ mt: 2, mb: 1, borderBottom: '1px solid #ffffff4d', width: 'fit-content', ...sx }}
    {...other}
  >
    {children}
  </Tabs>
);
