import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Button, IconButton, Paper, Popover, Typography } from "@mui/material";

import { ReactComponent as WarningIcon } from "../../../assets/svgs/warningWhite.svg";
import {
  BINANCE_TRANSACTION_STATUS,
  MessageSender,
  RECEIVER_TYPE,
  REPORT_TYPE,
  STRIPE_TRANSACTION_STATUS,
} from "../../../types";
import { apiClient } from "../../../config";
import { updateBinancePayStatus, updateStripePayStatus } from "../../../store/payment/actions";
import { ReportModal } from "../../modals";
import GiftBoltsModal from "../../GiftBoltsModal";
import { DefaultAvatar } from "../../DefaultAvatar";
import { FlexCol } from "../../FlexCol";
import { FlexRow } from "../../FlexRow";

type Props = {
  sender: MessageSender | null;
  anchorElPopover: HTMLElement | null;
  setAnchorElPopover: (data: any) => void;
};

export const SenderPopover = ({ sender, anchorElPopover, setAnchorElPopover }: Props) => {
  const dispatch = useDispatch();
  const [showReportModal, setShowReportModal] = useState(false);
  const [showGiftBox, setShowGiftBox] = useState(false);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    setUser(null);
    if (sender) {
      apiClient.get(`/users/${sender.username}`).then((data) => setUser(data.data));
    }
  }, [sender]);

  const handleGiftPopup = (flag: boolean) => {
    setAnchorElPopover(null);
    setShowGiftBox(flag);
    dispatch(updateBinancePayStatus(BINANCE_TRANSACTION_STATUS.NOT_INITILIZED));
    dispatch(updateStripePayStatus(STRIPE_TRANSACTION_STATUS.NOT_INITILIZED));
  };

  const handleReportPopup = () => {
    setShowReportModal(true);
    setAnchorElPopover(null);
  };

  return (
    <>
      <Popover
        open={Boolean(anchorElPopover)}
        anchorEl={anchorElPopover}
        onClose={() => setAnchorElPopover(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        disableScrollLock
      >
        <FlexCol component={Paper} sx={{ py: 1, px: 2, borderRadius: 2, backgroundColor: "#1C2039" }}>
          <FlexRow>
            <Avatar
              src={sender?.photoUrl}
              alt={sender?.fullName}
              sx={{
                borderColor: "#6535E9",
                borderWidth: 1.5,
                borderStyle: "solid",
                height: 54,
                width: 54,
                alignSelf: "center",
              }}
            >
              <DefaultAvatar />
            </Avatar>
            <FlexCol sx={{ ml: 1, flex: 1 }}>
              <FlexRow sx={{ alignItems: "center" }}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: 600, cursor: "pointer" }}
                  onClick={() => window.open(`/${sender?.username}`, "_blank")}
                >
                  {sender?.fullName}
                </Typography>
                {/* <VerifiedIcon style={{ marginLeft: 5 }} /> */}
              </FlexRow>
              <FlexRow sx={{ alignItems: "center", mt: 0.5 }}>
                <Typography sx={{ fontSize: 12 }}>@{sender?.username}</Typography>
                {user && user?.followed && (
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: "#FFFFFF80",
                      backgroundColor: "#33364D",
                      textTransform: "uppercase",
                      ml: 1,
                      p: 0.7,
                      py: 0.2,
                      borderRadius: 1,
                    }}
                  >
                    Followed
                  </Typography>
                )}
              </FlexRow>
            </FlexCol>
          </FlexRow>

          <FlexRow sx={{ alignItems: "center", mt: 1.5 }}>
            <Button
              variant="contained"
              sx={{ borderRadius: 20, background: "#33364D", height: 35 }}
              // endIcon={<Bolts style={{ width: 20, height: 20 }} />}
              onClick={() => handleGiftPopup(true)}
            >
              Donate
            </Button>
            <IconButton
              aria-label="report"
              sx={{ backgroundColor: "#33364D", height: 35, width: 35, borderRadius: 20, ml: 1 }}
              onClick={() => handleReportPopup()}
            >
              <WarningIcon style={{ width: 15, height: 15 }} />
            </IconButton>
          </FlexRow>
        </FlexCol>
      </Popover>

      <GiftBoltsModal
        open={showGiftBox}
        onClose={() => handleGiftPopup(false)}
        receiverId={sender?.id || ""}
        receiverName={sender?.username || ""}
        receiverType={RECEIVER_TYPE.USER}
      />

      <ReportModal
        reportType={REPORT_TYPE.USER}
        entityId={sender?.id}
        open={showReportModal}
        onClose={(e?: any) => {
          if (e) e.stopPropagation();
          setShowReportModal(false);
        }}
        onConfirm={(e?: any) => {
          if (e) e.stopPropagation();
          setShowReportModal(false);
        }}
      />
    </>
  );
};
