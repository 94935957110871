const sessionKey = "bolt-plus-session";
const registerSessionKey = "bolt-plus-session-register";
const connectionKey = "connection-state";

export const setAuthToken = (token: string): void => {
  localStorage.setItem(sessionKey, token);
};

export const getAuthToken = (): string => {
  return localStorage.getItem(sessionKey) || "";
};

export const removeAuthToken = (): void => {
  localStorage.removeItem(sessionKey);
};

export const setRegisterAuthToken = (token: string): void => {
  localStorage.setItem(registerSessionKey, token);
};

export const getRegisterAuthToken = (): string => {
  return localStorage.getItem(registerSessionKey) || "";
};

export const removeRegisterAuthToken = (): void => {
  localStorage.removeItem(registerSessionKey);
};

export const setWalletConnectionState = (dApps: string, blockchain: string = "ETHEREUM") => {
  localStorage.setItem(connectionKey, JSON.stringify({ blockchain, dApps }));
};

export const getWalletConnectionState = () => {
  const data = localStorage.getItem(connectionKey);

  if (!data) {
    return "";
  }

  return JSON.parse(data);
};

export const removeWalletConnectionState = () => {
  localStorage.removeItem(connectionKey);
};
