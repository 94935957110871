import { Avatar, Box, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";

import { usePrivateLayoutContext } from "../../layouts";
import { useCollectionPageContext } from "./context";

export const Content = () => {
  const { navbar__height } = usePrivateLayoutContext();

  const { bottomOffset, itemHeight } = useCollectionPageContext();

  const { avatar, title } = useMainInfo();

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: { xs: 2, md: 3 },
        px: { xs: 4, md: 6, xl: 8 },
        py: { xs: 0, md: 4 },
        mt: {
          xs: `calc((100vh - ${navbar__height}px - 32px - 140px - 32px - 40px - ${itemHeight}px - ${bottomOffset}px - 64px) / 2)`,
          md: `calc((100vh - ${navbar__height}px - 32px - 206px - 24px - 36px - 32px - 64px - 40px - ${itemHeight}px - ${bottomOffset}px) / 2)`,
        },
        mb: {
          xs: `calc((100vh - ${navbar__height}px - 32px - 140px - 32px - 40px - ${itemHeight}px - ${bottomOffset}px - 64px) / 2)`,
          md: `calc((100vh - ${navbar__height}px - 32px - 206px - 24px - 36px - 32px - 64px - 40px - ${itemHeight}px - ${bottomOffset}px) / 2)`,
        },
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "column" },
          alignItems: { xs: "center", md: "start" },
          columnGap: { xs: 3, md: 3 },
          rowGap: { xs: 2, md: 3 },
          py: 4,
        }}
      >
        <Box>
          <Avatar
            src={avatar}
            sx={{
              height: { xs: 140, md: 206 },
              width: { xs: 140 / 1.3, md: 206 / 1.3 },
              objectFit: "cover",
              borderRadius: "10px",
              bgcolor: "rgb(35, 37, 51)",
            }}
          >
            <Image sx={{ fontSize: { xs: 48, md: 60 }, color: "#666" }} />
          </Avatar>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            columnGap: 4,
            rowGap: 1,
          }}
        >
          <Typography sx={{ fontSize: { xs: 20, md: 24 }, fontWeight: 600 }}>{title}</Typography>

          {/* <Button
            variant="contained"
            sx={{ borderRadius: 100, height: 34 }}
            startIcon={
              <Box sx={{ width: 20, height: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Add sx={{ fontSize: 20 }} />
              </Box>
            }
          >
            Follow
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

const useMainInfo = () => {
  const { mainInfo } = useCollectionPageContext();

  const avatar = mainInfo?.thumbnails?.portrait || mainInfo?.thumbnails?.landscape || "";
  const title = mainInfo?.name || "";

  return { avatar, title };
};
