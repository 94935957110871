import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  ModalProps,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { FlexCol } from "../../FlexCol";
import { useWithdraw } from "../../../hooks/WithdrawContext";
import { WithdrawModalType } from "../../../types";
import { FlexRow } from "../../FlexRow";
import { ReactComponent as BinancePayIcon } from "../../../assets/svgs/binancePay.svg";
import { BaseWithdrawModal } from "./BaseWithdrawModal";

interface Props extends Partial<ModalProps> {}

export const WithdrawConfirmModal = ({ ...rest }: Props) => {
  const { values, setValues, confirmWithdraw, quoteBinanceAmount } = useWithdraw();

  useEffect(() => {
    if (values.modal !== WithdrawModalType.WITHDRAW_CONFIRM) {
      return;
    }
    if (values.withdrawCurrency === "USDT") {
      setValues({ convertedWithdrawAmount: values.withdrawAmount, errors: { message: "", invalidCode: false } });
    } else {
      quoteBinanceAmount({ currency: values.withdrawCurrency, amount: values.withdrawAmount });
      // calculate
    }
  }, [values.withdrawCurrency, values.modal]);

  return (
    <BaseWithdrawModal
      {...rest}
      loading={!!values.loadings?.quoting}
      open={values.modal === WithdrawModalType.WITHDRAW_CONFIRM}
      onClose={() => setValues({ ...values, modal: undefined })}
    >
      <FlexCol sx={{ mt: 2 }}>
        <FlexCol alignItems="center">
          <Typography variant="caption" component="span" fontWeight={600}>
            <Typography fontWeight={600} variant="h3" component="span">
              {values.convertedWithdrawAmount}{" "}
            </Typography>
            {values.withdrawCurrency}
          </Typography>
        </FlexCol>
        <FlexCol alignItems="center">
          <Typography variant="caption" component="span" sx={{ color: "#ffffff80" }}>
            To{" "}
            <Typography fontWeight={600} variant="inherit" component="span" sx={{ color: "white" }}>
              {values.binanceInfo.value}
            </Typography>
          </Typography>
        </FlexCol>
        <FlexCol sx={{ mt: 2 }}>
          <FormControl>
            <FormLabel sx={{ mb: 1 }}>
              <Typography variant="caption">Select Binance Currency</Typography>
            </FormLabel>
            <RadioGroup
              value={values.withdrawCurrency}
              onChange={(e) => setValues({ withdrawCurrency: e.target.value as any })}
            >
              <FormControlLabel
                value="USDT"
                control={<Radio size="small" />}
                label="USDT"
                componentsProps={{ typography: { fontSize: 14 } }}
              />
              {/* <FormControlLabel
                value="BNB"
                control={<Radio size="small" />}
                label="BNB"
                componentsProps={{ typography: { fontSize: 14 } }}
              />
              <FormControlLabel
                value="BTC"
                control={<Radio size="small" />}
                label="BTC"
                componentsProps={{ typography: { fontSize: 14 } }}
              /> */}
            </RadioGroup>
          </FormControl>
        </FlexCol>
        <Typography variant="tiny" color="error" sx={{ mt: 1 }}>
          {values?.errors?.message || ""}
        </Typography>
        <FlexCol alignItems="center" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            disabled={values.withdrawAmount === 0 || !!values?.errors?.message}
            sx={{ borderRadius: 40, width: "60%", fontWeight: 600, fontSize: 12 }}
            onClick={confirmWithdraw}
          >
            Withdraw
          </Button>
        </FlexCol>
        <FlexCol sx={{ mt: 1 }}>
          <Typography variant="tiny">
            Starting from June 30, 2024, Binance will comply with new MiCA stablecoin regulations. Please review the{" "}
            <Link
              target="_blank"
              rel="noreferrer"
              sx={{ color: "#ffffff", fontWeight: "600" }}
              href="https://www.binance.com/en/support/announcement/mica-stablecoin-rules-implementation-announcement-884c621e335540e0add5fdce31597121"
            >
              official announcement
            </Link>{" "}
            for more details.
          </Typography>
        </FlexCol>
        <FlexRow justifyContent="center" alignItems="center" sx={{ mt: 2, mb: 1 }}>
          <Typography
            sx={{
              color: "#A3AED0",
              fontWeight: 600,
              fontSize: 7.27,
              lineHeight: "10.91px",
              mr: 1,
              letterSpacing: "4px",
            }}
          >
            POWERED BY
          </Typography>
          <BinancePayIcon />
        </FlexRow>
      </FlexCol>
    </BaseWithdrawModal>
  );
};
