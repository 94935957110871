import React from "react";
import { Button, ButtonGroup, Typography, useTheme } from "@mui/material";
import { ReactComponent as BankIcon } from "../../assets/svgs/bank.svg";
import { ReactComponent as BinanceIcon } from "../../assets/svgs/binanceWithdraw.svg";
import { WithdrawTabBank } from "./WithdrawTabBank";
import { WithdrawTabBinance } from "./WithdrawTabBinance";
import { FlexCol } from "../FlexCol";

const menu = [
  {
    id: "toBinance",
    label: "To Binance",
    icon: <BinanceIcon width={18} />,
    disabled: false,
  },
  {
    id: "toBank",
    label: "To Bank",
    icon: <BankIcon width={18} height={18} />,
    disabled: true,
  },
];

export const WithdrawForm = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = React.useState(0);

  const getTabBg = (index: number) => {
    let color;

    if (theme.palette.mode === "dark") {
      color = tabIndex === index ? "#3C436F" : "#13162C";
    } else {
      color = tabIndex === index ? "primary.light" : "secondary.light";
    }

    return color;
  };

  return (
    <FlexCol>
      <ButtonGroup variant="contained" sx={{ boxShadow: "none", mb: "12px" }}>
        {menu.map(({ label, id, icon, disabled }, index) => (
          <Button
            key={id}
            sx={{
              borderRadius: "5px",
              my: 1,
              bgcolor: getTabBg(index),
              borderColor: "transparent !important",
              width: "50%",
              ":hover": { bgcolor: theme.palette.mode === "dark" ? "secondary.light" : "primary.light" },
            }}
            onClick={() => setTabIndex(index)}
            disabled={disabled}
          >
            {icon}
            <Typography variant="caption" sx={{ fontWeight: 400, ml: 1, fontSize: 12 }}>
              {label}
            </Typography>
          </Button>
        ))}
      </ButtonGroup>
      <FlexCol>
        {tabIndex === 0 && <WithdrawTabBinance />}
        {tabIndex === 1 && <WithdrawTabBank />}
      </FlexCol>
    </FlexCol>
  );
};
