import { TrendListProvider } from "./TrendListContext";
import { TrendListUi } from "./TrendListUi";

export const TrendList = () => {
  return (
    <TrendListProvider>
      <TrendListUi />
    </TrendListProvider>
  );
};
