import React from "react";
import { AppBar, Toolbar } from "@mui/material";

import { Logo } from "../../logo";

export const PublicNavbar = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        height: 65,
        px: { xs: 0, md: 3 },
        pt: { xs: 0.5, sm: 0 },
        backgroundColor: "transparent",
        zIndex: (theme) => theme.zIndex.drawer + 10,
        backgroundImage: "none",
        boxShadow: "none",
        border: "1px solid rgba(255, 255, 255, 0.05)",
      }}
    >
      <Toolbar>
        <Logo />
      </Toolbar>
    </AppBar>
  );
};
