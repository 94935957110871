import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import { LIVELAYOUT_SIDEBAR_WIDTH, NAV_BAR_HEIGHT } from "../../constants";
import { FlexRow, LiveNavigationBar } from "../../components";

export const LiveLayout = () => {
  return (
    <FlexRow>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          width: LIVELAYOUT_SIDEBAR_WIDTH,
          bgcolor: "#1E2034",
          height: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
          position: "fixed",
          top: NAV_BAR_HEIGHT,
          overflowY: "auto",
        }}
      >
        <LiveNavigationBar />
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "block" },
          width: LIVELAYOUT_SIDEBAR_WIDTH,
        }}
      />

      <Box
        sx={{
          width: { xs: "100%", md: `calc(100% - ${LIVELAYOUT_SIDEBAR_WIDTH}px)` },
        }}
      >
        <Outlet />
      </Box>
    </FlexRow>
  );
};
