import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  ModalProps,
  IconButton,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { debounce } from "lodash";

import { DefaultAvatar } from "../DefaultAvatar";
import { LoadingView } from "../LoadingView";
import { NFTHeaderBackground } from "../NFTHeaderBackground";
import { apiClient } from "../../config";
import { UpdateProfileDto } from "../../dtos";
import { User } from "../../types";
import { ToastService } from "../../services";
import { authSelector } from "../../store/auth/selector";
import { updateProfileAction } from "../../store/auth/actions";
import { ReactComponent as DiscordIcon } from "../../assets/svgs/discord.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svgs/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/svgs/instagram.svg";
import { ReactComponent as CameraPlusIcon } from "../../assets/svgs/cameraPlus.svg";
import { BaseModal } from "./BaseModal";
import { ConfirmationModal } from "./ConfirmationModal";

const resolver = classValidatorResolver(UpdateProfileDto, {
  skipNullProperties: true,
  skipMissingProperties: true,
  skipUndefinedProperties: true,
});

interface Props extends Partial<ModalProps> {
  onClose?: () => void;
}

export const UserSettingsModal = ({ onClose = () => {}, ...rest }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isDuplicatedUsername, setIsDuplicatedUsername] = useState(false);
  const [isInValidTwitterID, setIsInValidTwitterID] = useState(false);
  const [imgChanges, setImgChanges] = useState(false);
  const [saveChangesPopup, setSaveChangesPopup] = useState(false);
  const {
    user,
    profileUpdate: { loading, error },
  } = useSelector(authSelector);
  const [avatarImage, setAvatarImage] = useState<string | File>(user?.photoUrl || "");
  const [coverImg, setCoverImg] = useState<string | File>(user?.profile?.coverImage || "");
  const coverImageInputRef: any = useRef(null);
  const photoInputRef: any = useRef(null);

  const defaultValues = {
    username: user?.username,
    firstName: user?.firstName,
    lastName: user?.lastName,
    discordId: user?.profile?.discordId,
    twitterId: user?.profile?.twitterId,
    instagramId: user?.profile?.instagramId,
    website: user?.profile?.website,
    bio: user?.profile?.bio,
    photoUrl: user?.photoUrl,
    coverImage: user?.profile?.coverImage,
  };

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<UpdateProfileDto>({
    resolver,
    defaultValues,
  });

  const onSubmit = useCallback(
    (data: UpdateProfileDto) => {
      setErrorMessage("");
      dispatch(
        updateProfileAction({
          data,
          onSuccess: (data: User) => {
            ToastService.showSuccessMessage("Successfully updated your profile.");
            navigate(`/${data?.username}`);
            onClose();
          },
        })
      );
      setSaveChangesPopup(false);
    },
    [user?.username]
  );

  const checkUserName = useMemo(
    () =>
      debounce(async (username: string) => {
        try {
          const { data } = await apiClient.post("/auth/userNameCheck", { username: username?.trim() });
          setIsDuplicatedUsername(!!data.exists);
        } catch (error) {
          setIsDuplicatedUsername(false);
        }
      }, 500),
    []
  );

  const checkTwitterID = useMemo(
    () =>
      debounce(async (twitterId: string) => {
        try {
          const { data } = await apiClient.get(`twitter/${twitterId}/exists`);
          setIsInValidTwitterID(!data.exists);
        } catch (error) {
          setIsInValidTwitterID(false);
        }
      }, 500),
    []
  );

  useEffect(() => {
    if (rest.open) {
      reset(defaultValues);
      setAvatarImage((user?.photoUrl as string) || "");
      setCoverImg((user?.profile.coverImage as string) || "");
      setImgChanges(false);
    }
  }, [rest.open]);

  useEffect(() => {
    const username = watch("username");
    if (username?.length > 5) {
      checkUserName(username);
    } else {
      setIsDuplicatedUsername(false);
    }
  }, [watch("username")]);

  useEffect(() => {
    const twitterId = watch("twitterId");
    if (twitterId?.length > 3) {
      checkTwitterID(twitterId);
    } else {
      setIsInValidTwitterID(false);
    }
  }, [watch("twitterId")]);

  const getDataWithImg = (data: UpdateProfileDto) => {
    const dataObj = { ...data };
    if (avatarImage !== user?.photoUrl) {
      dataObj.photoUrl = avatarImage;
    }
    if (coverImg !== user?.profile.coverImage) {
      dataObj.coverImage = coverImg;
    }
    return dataObj;
  };

  const handleCloseModal = () => {
    if (isDirty || imgChanges) {
      setSaveChangesPopup(true);
    } else {
      onClose();
    }
  };

  const cancelChanges = () => {
    reset(defaultValues);
    setAvatarImage((user?.photoUrl as string) || "");
    setCoverImg((user?.profile.coverImage as string) || "");
    setImgChanges(false);
    setSaveChangesPopup(false);
    onClose();
  };

  const inputProps = {
    style: {
      backgroundColor: "#282C46",
      border: "0.5px solid rgba(255, 255, 255, 0.15)",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "21px",
      height: "20px",
      padding: "7px",
      paddingLeft: "13px",
      paddingRight: "40px",
      borderRadius: "6px",
    },
  };

  return (
    <>
      <BaseModal {...rest} open={!!rest.open} onClose={handleCloseModal}>
        <Box
          component={Paper}
          className="-webkit-chatbox-scrollbar chatbox-scrollbar"
          sx={{
            borderRadius: 4,
            maxHeight: "100%",
            position: "relative",
            flexDirection: "column",
            px: 2,
            pt: 2,
            pb: 4,
            width: 468,
            height: "90%",
            overflowY: "scroll",
          }}
        >
          <Box
            component="form"
            sx={{ px: 4, pb: 2 }}
            onSubmit={handleSubmit((data) => {
              if (!isDuplicatedUsername && !isInValidTwitterID) {
                onSubmit(getDataWithImg(data));
              }
            })}
            noValidate
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Box sx={{ flexDirection: "row", alignItems: "center" }}>
                <IconButton onClick={handleCloseModal} sx={{ ml: -4, p: 0.5, mb: "4px" }}>
                  <CloseIcon sx={{ cursor: "pointer", opacity: 0.5, width: { xs: 25, md: 40 } }} />
                </IconButton>
                <Typography
                  sx={{
                    color: "#A3AED0",
                    fontWeight: 600,
                    fontSize: { xs: 18, md: 20 },
                    lineHeight: "30px",
                    display: "inline-flex",
                    mt: "10px",
                  }}
                  align="center"
                >
                  Edit Profile
                </Typography>
              </Box>
              <Button
                variant="contained"
                type="submit"
                sx={{ textTransform: "none", height: 34, px: 5, borderRadius: 29 / 2 }}
                disabled={loading || (!isDirty && !imgChanges)}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: 14, lineHeight: "18.55px" }}>
                  Save
                </Typography>
              </Button>
            </Grid>
            <Grid item sx={{ width: "100%", position: "absolute", left: 0, right: 0, top: 80 }}>
              <NFTHeaderBackground
                url={typeof coverImg === "object" ? URL.createObjectURL(coverImg) : coverImg}
                loading={false}
              />
              <input
                type="file"
                ref={coverImageInputRef}
                onChange={(e) => {
                  if (e.target.files) {
                    setCoverImg(e.target.files[0]);
                    setImgChanges(true);
                  }
                }}
                style={{ display: "none" }}
                accept="image/jpg, image/png, image/jpeg"
              />
              <IconButton
                onClick={() => coverImageInputRef?.current.click()}
                sx={{
                  right: 15,
                  bottom: 15,
                  position: "absolute",
                  cursor: "pointer",
                  backgroundColor: "rgba(19, 22, 44, 0.5)",
                  backdropFilter: "blur(35px)",
                  "&:hover": { backgroundColor: "rgba(19, 22, 44, 0.5)", opacity: 0.85 },
                }}
              >
                <CameraPlusIcon />
              </IconButton>
            </Grid>
            <Grid item xl="auto" lg="auto" md="auto" sm={3} xs={3} sx={{ position: "absolute", top: 180 }}>
              <Avatar
                src={typeof avatarImage === "object" ? URL.createObjectURL(avatarImage) : avatarImage}
                alt={user?.fullName}
                sx={{
                  borderColor: "primary.main",
                  borderWidth: 2,
                  borderStyle: "solid",
                  height: { xl: 110, lg: 110, md: 110, sm: 73, xs: 73 },
                  width: { xl: 110, lg: 110, md: 110, sm: 73, xs: 73 },
                  objectFit: "cover",
                }}
              >
                <DefaultAvatar width="100%" height="100%" />
              </Avatar>
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files) {
                    setAvatarImage(e.target.files[0]);
                    setImgChanges(true);
                  }
                }}
                ref={photoInputRef}
                style={{ display: "none" }}
                accept="image/jpg, image/png, image/jpeg"
              />
              <IconButton
                onClick={() => photoInputRef?.current.click()}
                sx={{
                  right: { xs: 20, md: 40 },
                  bottom: { xs: 20, md: 40 },
                  position: "absolute",
                  cursor: "pointer",
                  backgroundColor: "rgba(19, 22, 44, 0.5)",
                  backdropFilter: "blur(35px)",
                  "&:hover": { backgroundColor: "rgba(19, 22, 44, 0.5)", opacity: 0.85 },
                }}
              >
                <CameraPlusIcon />
              </IconButton>
            </Grid>
            <Grid item height={230} />
            <Grid item sx={{ mt: 2, mb: 1, display: "flex", gap: 1 }}>
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                First Name
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="firstName"
                required
                fullWidth
                placeholder="First Name *"
                autoFocus
                inputProps={inputProps}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                {...register("firstName")}
              />
            </Grid>
            <Grid item sx={{ mt: 2, mb: 1, display: "flex", gap: 1 }}>
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                Last Name
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="lastName"
                required
                fullWidth
                placeholder="Last Name *"
                inputProps={inputProps}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                {...register("lastName")}
              />
            </Grid>
            <Grid item sx={{ mt: 2, mb: 1, display: "flex", gap: 1 }}>
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                Username
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="username"
                required
                fullWidth
                placeholder="Username *"
                inputProps={inputProps}
                error={!!errors.username || isDuplicatedUsername}
                helperText={
                  !!errors.username || isDuplicatedUsername
                    ? errors.username?.message || "Username already exists."
                    : ""
                }
                {...register("username")}
              />
            </Grid>
            <Grid item sx={{ mt: 2, mb: 1 }}>
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>Bio</Typography>
            </Grid>
            <Grid item>
              <TextareaAutosize
                minRows={3}
                style={{
                  width: "100%",
                  outline: "none",
                  fontFamily: "Poppins",
                  color: "white",
                  resize: "none",
                  backgroundColor: "#282C46",
                  border: "0.5px solid rgba(255, 255, 255, 0.15)",
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "21px",
                  padding: "12px",
                  paddingLeft: "13px",
                  paddingRight: "40px",
                  borderRadius: "6px",
                  borderWidth: 0,
                }}
                id="bio"
                {...register("bio")}
              />
            </Grid>
            <Grid item sx={{ mt: 2, mb: 1, display: "flex", gap: 1 }}>
              <DiscordIcon width={16} height={16} />
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                Discord
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="discordId"
                fullWidth
                inputProps={inputProps}
                error={!!errors.discordId}
                helperText={errors.discordId?.message}
                {...register("discordId")}
              />
            </Grid>
            <Grid item sx={{ mt: 2, mb: 1, display: "flex", gap: 1 }}>
              <TwitterIcon width={16} height={16} />
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                Twitter
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="twitterId"
                fullWidth
                inputProps={inputProps}
                error={!!errors.twitterId || isInValidTwitterID}
                helperText={
                  !!errors.twitterId || isInValidTwitterID ? errors.twitterId?.message || "Can't find Twitter ID" : ""
                }
                {...register("twitterId")}
              />
            </Grid>
            <Grid item sx={{ mt: 2, mb: 1, display: "flex", gap: 1 }}>
              <InstagramIcon width={16} height={16} />
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                Instagram
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="instagramId"
                fullWidth
                inputProps={inputProps}
                error={!!errors.instagramId}
                helperText={errors.instagramId?.message}
                {...register("instagramId")}
              />
            </Grid>
            <Grid item sx={{ mt: 2, mb: 1, display: "flex", gap: 1 }}>
              <LanguageOutlinedIcon sx={{ width: 16, height: 16 }} />
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                Website
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="website"
                fullWidth
                inputProps={inputProps}
                error={!!errors.website}
                helperText={errors.website?.message}
                {...register("website")}
              />
            </Grid>
            {(errorMessage || error?.message) && (
              <Alert variant="outlined" severity="error" sx={{ my: 2 }}>
                {errorMessage || error?.message}
              </Alert>
            )}
          </Box>
          <LoadingView visible={loading} />
        </Box>
      </BaseModal>
      {saveChangesPopup && (
        <ConfirmationModal
          modalVisible={saveChangesPopup}
          title="Exit without saving?"
          submitFn={handleSubmit((data) => onSubmit(getDataWithImg(data)))}
          cancelFn={cancelChanges}
          submitLabel="Save changes"
          cancelLabel="Discard"
          posting={loading}
        />
      )}
    </>
  );
};
