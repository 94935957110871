import { Box, CircularProgress } from "@mui/material";

import { usePrivateLayoutContext } from "../layouts";

export const PageLoader = () => {
  const { navbar__height, leftMenubar__width } = usePrivateLayoutContext();

  return (
    <Box
      sx={{
        position: "fixed",
        top: navbar__height,
        left: { xs: 0, md: leftMenubar__width },
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
