import { SVGProps } from "react";

export const CollectionsSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none" viewBox="0 0 20 18" {...props}>
      <path
        fill="#351F7D"
        d="M2.844 3.68a.586.586 0 00.586.586h12.89a.586.586 0 000-1.172H3.43a.586.586 0 00-.586.586zm2.344-2.344a.586.586 0 00.585.586h8.204a.586.586 0 000-1.172H5.773a.586.586 0 00-.585.586z"
      ></path>
      <path
        fill="#6334E4"
        d="M.5 15.398a1.758 1.758 0 001.758 1.758h15.234a1.757 1.757 0 001.758-1.758V7.195a1.758 1.758 0 00-1.758-1.758H2.258A1.758 1.758 0 00.5 7.196v8.203z"
      ></path>
    </svg>
  );
};
