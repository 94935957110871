
export enum FilterType {
  DAILY = "daily",
  MONTHLY = "monthly",
  ALL = "all"
};

export enum ChartType {
  REACTIONS = "reactions",
  IMPRESSIONS = "impressions",
  COMMENTS = "comments",
  REBOLTS = "rebolts"
};

export enum EarningType {
  OVERVIEW = "Overview",
  BINANCE_PAY = "Binance Pay",
  APPLE_GOOGLE_PAY = "Apple and Google Pay",
  // BOLTX = "BoltX",
  // METAMAST = "Metamast",
  // WALLET_CONNECT = "Walletconnect",
}

export enum EarningHistory {
  EARNINGS = "Earnings History",
  WITHDRAWAL = "Withdrawal History",
  DEBIT = "Debit History"
};

export enum PAYOUT_TRANSACTION_STATUS {
  REQUESTED = "requested",
  SUCCEEDED = "succeeded",
  PENDING = "pending",
  FAILED = "failed",
  BLOCKED = "blocked",
}

export enum TRANSACTION_STATUS {
  INITIALIZED = "initialized",
  SUCCEEDED = "succeeded",
  PENDING = "pending",
  FAILED = "failed",
  CANCELED = "canceled",
  BLOCKED = "blocked",
}