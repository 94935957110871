import React from "react";
import { Avatar, Box, Button, Fab, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { isMobile } from "react-device-detect";
import Carousel from "nuka-carousel";

import { FlexCol, FlexRow, LoadingView } from "../../components";
import { useFlashback } from "./FlashbackContext";
import { User } from "../../types";
import { DefaultAvatar } from "../../components/DefaultAvatar";
import { PulseChart } from "./PulseChart";
import { ShareIcon } from "../../assets/icons/ShareIcon";
import { ToastService } from "../../services";
import { ShareLinkModal } from "../../components/modals";

export const CarouselComponent = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [link, setLink] = React.useState("");
  const [showShareModal, setShowShareModal] = React.useState(false);

  const {
    flashbacks,
    values: { loading },
    getCardInfo,
  } = useFlashback();
  return (
    <FlexRow height={1} sx={{ justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ width: { xs: "320px", md: "650px" }, position: "relative" }}>
        <FlexRow justifyContent="space-between" sx={{ mt: -2 }}>
          {flashbacks.map((_, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: 4,
                width: `${100 / flashbacks.length / 1.5}%`,
                height: 3,
                mb: 1,
                backgroundColor: currentIndex === index ? "white" : "secondary.dark",
              }}
            />
          ))}
        </FlexRow>
        <Carousel
          renderCenterLeftControls={({ previousSlide, previousDisabled }) =>
            isMobile || previousDisabled ? null : (
              <Fab
                disabled={previousDisabled}
                sx={{ left: -150 }}
                color="secondary"
                size="small"
                onClick={previousSlide}
              >
                <KeyboardArrowLeftIcon style={{ fontSize: 30 }} />
              </Fab>
            )
          }
          renderCenterRightControls={({ nextSlide, nextDisabled }) =>
            isMobile || nextDisabled ? null : (
              <Fab sx={{ right: -150 }} color="secondary" size="small" onClick={nextSlide}>
                <KeyboardArrowRightIcon style={{ fontSize: 30 }} />
              </Fab>
            )
          }
          renderBottomCenterControls={null}
          afterSlide={setCurrentIndex}
        >
          {flashbacks.map((item, index) => (
            <Box key={index} sx={{ width: { sm: "100%", md: "650px", userSelect: "none", position: "relative" } }}>
              <img src={item.image} alt="img" width="100%" />
              <FlexRow
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {!!getCardInfo(item.id)?.value && item.id !== "pulse_score" && item.id !== "final_share_info" && (
                  <Typography color="black" sx={{ ...item?.sxProps }}>
                    {getCardInfo(item.id)?.value}
                  </Typography>
                )}
                {item.id === "pulse_score" && (
                  <Box sx={{ ...item.sxProps, position: "relative" }}>
                    <PulseChart
                      percent={Number(getCardInfo(item.id)?.value)}
                      size={isMobile ? 130 : 270}
                      thick={isMobile ? 8 : 16}
                    />
                    <FlexRow
                      sx={{
                        position: "absolute",
                        top: isMobile ? 35 : 60,
                        left: isMobile ? 36 : 64,
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        color="black"
                        sx={{ fontFamily: "WorkSans", fontSize: isMobile ? 32 : 80, lineHeight: 0.8 }}
                      >
                        {getCardInfo(item.id)?.value}
                      </Typography>
                      <Typography
                        color="white"
                        sx={{ fontFamily: "WorkSans", fontSize: isMobile ? 12 : 30, lineHeight: 1 }}
                      >
                        / 100
                      </Typography>
                    </FlexRow>
                  </Box>
                )}
                {getCardInfo(item.id)?.data?.length! > 0 && (
                  <FlexCol sx={{ ...item.sxProps }}>
                    {getCardInfo(item.id)?.data?.map((user: User, index) => (
                      <FlexRow key={index} sx={{ alignItems: "center", mt: isMobile ? 1.5 : 2.7 }}>
                        <Typography
                          component="span"
                          sx={{ fontSize: isMobile ? 8 : 16, fontWeight: 200, color: "#010101", fontStyle: "italic" }}
                        >
                          #{index + 1}
                        </Typography>
                        <Avatar
                          src={user.photoUrl}
                          alt={user.username}
                          sx={{
                            height: isMobile ? 20 : 44,
                            width: isMobile ? 20 : 44,
                            ml: isMobile ? 0.5 : 2,
                          }}
                        >
                          <DefaultAvatar />
                        </Avatar>
                        <Typography
                          variant="caption"
                          sx={{ fontSize: isMobile ? 12 : 22, ml: isMobile ? 0.5 : 1.5, fontFamily: "WorkSans" }}
                        >
                          {user.username}
                        </Typography>
                      </FlexRow>
                    ))}
                  </FlexCol>
                )}
                {item.id === "final_share_info" && (
                  <FlexRow sx={{ ...item.sxProps }}>
                    <Button
                      color="darker"
                      variant="contained"
                      sx={{
                        bgcolor: "white",
                        color: "black",
                        borderRadius: 40,
                        fontWeight: 600,
                        fontSize: 16,
                        width: 120,
                      }}
                      endIcon={<ShareIcon />}
                      onClick={() => {
                        setShowShareModal(true);
                        setLink(getCardInfo(item.id).value);
                      }}
                    >
                      Share
                    </Button>
                  </FlexRow>
                )}
              </FlexRow>
            </Box>
          ))}
        </Carousel>
      </Box>
      <ShareLinkModal link={link} open={showShareModal} onClose={() => setShowShareModal(false)} />
      <LoadingView visible={loading} />
    </FlexRow>
  );
};
