import axios from "axios";

export const fetchRates = async () => {
  try {
    const { data } = await axios.get(
      "https://freecurrencyapi.net/api/v2/latest?apikey=389f84a0-57f9-11ec-bc1b-55eff95b7659"
    );
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};
