import { createAction } from "redux-actions";
import { START } from "../common";

export const INIT_SEARCH = "INIT_SEARCH";
export const SET_SEARCH_INPUT = "SET_SEARCH_INPUT";
export const UPDATE_SEARCH_TYPE = "UPDATE_SEARCH_TYPE";
export const FETCH_SEARCH = "FETCH_SEARCH";

export const initSearchAction = createAction(INIT_SEARCH);
export const setSearchInputAction = createAction(SET_SEARCH_INPUT);
export const updateSearchTypeAction = createAction(UPDATE_SEARCH_TYPE);
export const fetchSearchAction = createAction(FETCH_SEARCH + START);
