export enum PollEntityType {
  STREAM_FEED = "stream_feed",
  NFT_FEED = "nft_feed",
  LIVE_STREAM = "stream_message",
  LIVE_CHANNEL = "channel_message",
  NFT_MESSAGE = "nft_message",
}

export enum PollTime {
  "5m" = "5m",
  "10m" = "10m",
  "20m" = "20m",
  "30m" = "30m",
  "60m" = "60m",
}

export enum PollStatusEvent {
  START = "start",
  STOP = "stop",
  VOTE = "vote",
}
