import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, IconButton, useTheme } from "@mui/material";

import { ReactComponent as NotificationIcon } from "../../../../assets/svgs/menu/notification.svg";
import { notificationUnreadCountSelector } from "../../../../store/notification/selector";

export const NotificationsButton = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const notificationCount = useSelector(notificationUnreadCountSelector);

  return (
    <Badge
      badgeContent={notificationCount}
      color="error"
      sx={{
        ".MuiBadge-badge": { top: 5 },
      }}
    >
      <IconButton
        sx={{
          width: 36,
          height: 36,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: theme.palette.secondary.dark,

          "&:hover": {
            bgcolor: theme.palette.secondary.light,
          },
        }}
        onClick={() => navigate("/notifications")}
      >
        <NotificationIcon />
      </IconButton>
    </Badge>
  );
};
