import { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { Formatter } from "../../../utils";
import { ItemContainer } from "./ItemContainer";
import { Title } from "./Title";
import { DetailText } from "./DetailText";
import { TagEntity, TrendItem } from "./types";

type TrendTagProps = Omit<TrendItem, "entity"> & {
  entity: TagEntity;
};

export const TrendTag = ({ id, entity, views = 0, streams = 0, posts = 0 }: TrendTagProps) => {
  const details = useMemo(() => {
    let result = "";

    if (views) {
      result += `${Formatter.formatCount(views)} Views`;
    }

    if (streams) {
      result += `${result ? " • " : ""}${Formatter.formatCount(streams)} Streams`;
    }

    if (posts) {
      result += `${result ? " • " : ""}${Formatter.formatCount(posts)} Posts`;
    }

    return result;
  }, [views, streams, posts]);

  return (
    <ItemContainer>
      <Title title="Tag" />

      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#282C46",
            pl: 1,
            pr: 1.5,
            py: "3px",
            columnGap: 0.8,
            borderRadius: 20,
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M4.80321 12.5977L7.43927 2.59766M8.75729 12.5977L11.3933 2.59766M4.1442 5.72266H13.3704M2.82617 9.47266H12.0524"
              stroke="white"
              strokeOpacity="0.25"
              strokeWidth="1.19845"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Typography sx={{ fontSize: 14, color: "white", fontWeight: 600 }}>{entity.name}</Typography>
        </Box>
      </Box>

      <DetailText text={details} />
    </ItemContainer>
  );
};
