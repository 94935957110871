import React from "react";
import styled from "@emotion/styled";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { FlexCol, FlexRow } from ".";

import { ReactComponent as HomeIcon } from "../assets/svgs/menu/home.svg";
import { ReactComponent as HomeStrokeIcon } from "../assets/svgs/menu/home_stroke.svg";
import { ReactComponent as TrendingIcon } from "../assets/svgs/menu/trending.svg";
import { ReactComponent as FollowingIcon } from "../assets/svgs/menu/following.svg";
import { channelSelector } from "../store/channel/selector";

const radioMenu = [
  { label: "Home", icon: <HomeIcon />, path: "home" },
  { label: "Trending", icon: <TrendingIcon />, path: "trending" },
  { label: "Following", icon: <FollowingIcon />, path: "following" },
];

const StyledList = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    background: "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    background: "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
  },
});

export const LiveNavigationBar = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const { tab = "tv", category = "home" } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { channels } = useSelector(channelSelector);

  const tvMenu = React.useMemo(
    () => [
      { label: "Home", icon: <HomeStrokeIcon />, path: "home" },
      ...Object.keys(channels).map((key) => ({
        label: key,
        path: channels[key]?.title?.split(" ").join("-"),
        icon: channels[key]?.icon,
      })),
    ],
    [channels]
  );

  React.useEffect(() => {
    if (tab === "tv") {
      const activeTVCategoryIndex = tvMenu.findIndex((item) => item.path === category) || 0;
      setSelectedIndex(activeTVCategoryIndex);
    }
  }, [tab, category]);

  const menu = tab === "tv" ? tvMenu : radioMenu;

  const handleCategorySelection = (index: number, path: string) => {
    // to be decided
    navigate(path);
    setSelectedIndex(index);
  };

  return (
    <FlexCol
      sx={{
        pt: { xs: 2, md: 1 },
        position: "relative",
        zIndex: "1",
      }}
    >
      <StyledList sx={{ display: { xs: "none", sm: "inline" } }}>
        {menu.map(({ label, path, icon }, index) => (
          <ListItem
            key={index}
            sx={{
              "::active": {
                bgcolor: "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
                background: "#6535E9",
              },
              display: "block",
              width: "100%",
              py: 0.5,
            }}
          >
            <ListItemButton
              selected={selectedIndex === index}
              onClick={() => handleCategorySelection(index, path)}
              sx={{
                borderRadius: "5px",
                justifyContent: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1.5,
                  justifyContent: "center",
                  width: "18px",
                  height: "18px",
                }}
              >
                {typeof icon === "string" ? <img src={icon} alt="" /> : icon}
              </ListItemIcon>

              <ListItemText
                primary={label}
                sx={{
                  opacity: 1,
                  fontWeight: selectedIndex === index ? 700 : 600,
                  span: { fontSize: 14 },
                  letterSpacing: "-0.004em",
                  color: selectedIndex === index ? "#FFFFFF" : "rgba(255, 255, 255, 0.85)",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </StyledList>

      <FlexRow
        sx={{
          width: "100%",
          overflowX: "auto",
          p: 1,
          display: { md: "none" },
        }}
      >
        <table style={{ paddingRight: 16 }}>
          <tbody>
            <tr>
              {menu.map(({ label, icon, path }, index) => (
                <td key={index}>
                  <Button
                    variant={category === path ? "contained" : "text"}
                    sx={{
                      justifyContent: { md: "flex-start" },
                      px: { xs: 3, md: 0 },
                      py: { xs: 0.5, md: 0.75 },
                      pl: { md: "20%" },
                      mr: { xs: 1, md: 0 },
                      borderRadius: { xs: "8px", md: "4px" },
                      "&.MuiButton-textPrimary": {
                        backgroundColor: { xs: "rgba(28, 32, 57, 0.5)", md: "transparent" },
                      },
                    }}
                    onClick={() => navigate(`/live/${tab}/${path}`)}
                  >
                    <FlexRow sx={{ flexShrink: 0 }}>
                      {/* {icon} */}
                      {/* {tab === "tv"
                        ?
                        <Avatar sx={{ backgroundColor: "transparent", p: 0 }}>{icon}</Avatar>
                        : icon
                      } */}
                    </FlexRow>
                    <Typography
                      sx={{ ml: 2, width: "max-content" }}
                      fontWeight="600"
                      color={category === path ? "white" : "primary.contrastText"}
                    >
                      {label}
                    </Typography>
                  </Button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </FlexRow>
    </FlexCol>
  );
};
