import { isMobile } from "react-device-detect";
import Flashback1 from "./FlashBack 1.png";
import Flashback2 from "./FlashBack 2.png";
import Flashback3 from "./FlashBack 3.png";
import Flashback4 from "./FlashBack 4.png";
import Flashback5 from "./FlashBack 5.png";
import Flashback6 from "./FlashBack 6.png";
import Flashback7 from "./FlashBack 7.png";
import Flashback8 from "./FlashBack 8.png";
import Flashback9 from "./FlashBack 9.png";
import Flashback10 from "./FlashBack 10.png";
import Flashback11 from "./FlashBack 11.png";
import Flashback12 from "./FlashBack 12.png";
import Flashback13 from "./FlashBack 13.png";
import Flashback14 from "./FlashBack 14.png";
import Flashback15 from "./FlashBack 15.png";
import Flashback16 from "./FlashBack 16.png";
import Flashback17 from "./FlashBack 17.png";
import Flashback18 from "./FlashBack 18.png";
import Flashback19 from "./FlashBack 19.png";
import Flashback20 from "./FlashBack 20.png";
import Flashback21 from "./FlashBack 21.png";
import Flashback22 from "./FlashBack 22.png";

export const flashbacks = [
  { image: Flashback1, isStreamer: false, id: "welcome_info" },
  { image: Flashback2, isStreamer: false, id: "intro_info" },
  {
    image: Flashback3,
    isStreamer: true,
    id: "total_mins",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "153px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "310px" },
  },
  {
    image: Flashback4,
    isStreamer: true,
    id: "total_days",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "145px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "290px" },
  },
  {
    image: Flashback5,
    isStreamer: true,
    id: "most_views",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "153px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "304px" },
  },
  // { image: Flashback6, isStreamer: true, id: "most_chats" },
  { image: Flashback7, isStreamer: true, id: "virtual_stadium_info" },
  {
    image: Flashback8,
    isStreamer: false,
    id: "total_chats",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "138px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "270px" },
  },
  { image: Flashback9, isStreamer: false, id: "chatty_info" },
  {
    image: Flashback10,
    isStreamer: true,
    id: "total_stream_impressions",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "147px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "290px" },
  },
  { image: Flashback11, isStreamer: true, id: "digital_wildfire_info" },
  {
    image: Flashback12,
    isStreamer: true,
    id: "total_unique_visitors",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "126px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "250px" },
  },
  {
    image: Flashback13,
    isStreamer: true,
    id: "top_users",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "102px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "200px" },
  },
  {
    image: Flashback14,
    isStreamer: true,
    id: "max_duration",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "144px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "285px" },
  },
  { image: Flashback15, isStreamer: true, id: "live_content_info" },
  // { image: Flashback16, isStreamer: false, id: "fellow_creators" },
  { image: Flashback17, isStreamer: false, id: "dream_team_info" },
  {
    image: Flashback18,
    isStreamer: false,
    id: "earning",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "140px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "280px" },
  },
  { image: Flashback19, isStreamer: false, id: "savvy_info" },
  {
    image: Flashback20,
    isStreamer: true,
    id: "pulse_score",
    sxProps: isMobile
      ? { fontSize: 57, fontFamily: "WorkSans", ml: "20px", mt: "112px" }
      : { fontSize: 120, fontFamily: "WorkSans", ml: "40px", mt: "220px" },
  },
  { image: Flashback21, isStreamer: false, id: "next_bring_info" },
  {
    image: Flashback22,
    isStreamer: false,
    id: "final_share_info",
    sxProps: isMobile ? { ml: "103px", mt: "212px" } : { ml: "260px", mt: "450px" },
  },
];
