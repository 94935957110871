import { useCallback, useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { FastRewind } from "@mui/icons-material";

import { CollectionItemProps, CollectionItems } from "./collection-items";
import { useCollectionPageContext } from "./context";

export const Rewinds = () => {
  const theme = useTheme();

  const { items, isLoadingMore, hideShowMore, onShowMore } = useRewindItems();

  return (
    <CollectionItems
      title={
        <Box sx={{ height: 40 }}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 1.5 }}>
            <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Rewinds</Typography>

            <FastRewind sx={{ color: theme.palette.success.light }} />
          </Box>
        </Box>
      }
      itemBadge={
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 12,
            bgcolor: theme.palette.success.main,
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            columnGap: 0.5,
            px: 0.6,
            py: 0.2,
          }}
        >
          <FastRewind sx={{ fontSize: 12 }} />

          <Typography sx={{ color: "#fff", fontWeight: 500, fontSize: 10 }}>REWIND</Typography>
        </Box>
      }
      items={items}
      isLoadingMore={isLoadingMore}
      hideShowMore={hideShowMore}
      onShowMore={onShowMore}
    />
  );
};

const useRewindItems = () => {
  const { rewinds, isLoadingRewinds, getCollectionRewinds } = useCollectionPageContext();

  const items: CollectionItemProps[] = rewinds.data.map((item) => {
    return {
      thumbnail: item.thumbnail,
      avatar: item.user.photoUrl,
      title: item.title,
      description: item.user.fullName,
      slug: item.user.username,
    };
  });

  const hideShowMore = useMemo(() => {
    return rewinds.data.length === rewinds.total;
  }, [rewinds.data.length, rewinds.total]);

  const onShowMore = useCallback(() => {
    getCollectionRewinds({ page: rewinds.page + 1, moreData: true });
  }, [getCollectionRewinds, rewinds.page]);

  return { items, isLoadingMore: isLoadingRewinds, hideShowMore, onShowMore };
};
