import React, { SVGProps } from 'react';

export const WithdrawIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.6031 5.6525C13.6662 5.50019 13.6827 5.33258 13.6505 5.1709C13.6183 5.00921 13.5389 4.8607 13.4223 4.74417L9.2556 0.577499L8.07727 1.75583L10.8214 4.5H0.333104V6.16667H12.8331C12.9979 6.16671 13.159 6.11788 13.2961 6.02636C13.4331 5.93484 13.54 5.80474 13.6031 5.6525ZM0.396438 8.3475C0.333351 8.49981 0.316858 8.66741 0.349047 8.8291C0.381236 8.99079 0.460661 9.13929 0.577271 9.25583L4.74394 13.4225L5.92227 12.2442L3.1781 9.5H13.6664V7.83333H1.16644C1.00161 7.83318 0.840443 7.88196 0.703365 7.9735C0.566287 8.06503 0.459466 8.1952 0.396438 8.3475Z"
        fill={props.color || "#A3AED0"}
      />
    </svg>
  );
}
