import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";

export interface UiState {
  theme: "dark" | "light";
  fileUpload: { [symbol: number]: number };
}

const initialState: UiState = {
  theme: "dark",
  fileUpload: {},
};

const reducer = handleActions<UiState, any>(
  {
    [actions.SWITCH_THEME_ACTION]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.theme = payload;
      }),
    [actions.FILE_UPLOAD_PROGRESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.fileUpload = {
          ...state.fileUpload,
          [payload.uploadId]: payload.progress,
        };
      }),
  },
  initialState
);

export default reducer;
