import { all, fork, put, take } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* fetchNotifications() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_NOTIFICATIONS + START);
    const { page = 1 } = payload;
    try {
      const { data } = yield apiClient.get(`notifications?limit=20&page=${page}`);
      yield put({
        type: actions.FETCH_NOTIFICATIONS + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_NOTIFICATIONS + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchNotificationUnreadCount() {
  while (true) {
    yield take(actions.FETCH_NOTIFICATION_UNREAD_COUNT + START);
    try {
      const { data } = yield apiClient.get("notifications/unreadCount");
      yield put({
        type: actions.FETCH_NOTIFICATION_UNREAD_COUNT + SUCCESS,
        payload: data?.totalCount || 0,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_NOTIFICATION_UNREAD_COUNT + FAIL,
        payload: error,
      });
    }
  }
}

function* markAllReadNotification() {
  while (true) {
    yield take(actions.MARK_ALL_READ_NOTIFICATION + START);
    try {
      yield apiClient.post("notifications/markAsReadAll");
      yield put({
        type: actions.MARK_ALL_READ_NOTIFICATION + SUCCESS,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.MARK_ALL_READ_NOTIFICATION + FAIL,
        payload: error,
      });
    }
  }
}

function* markAsReadNotifications() {
  while (true) {
    const { payload = {} } = yield take(actions.MARK_AS_READ_NOTIFICATION + START);
    const { id, read } = payload;
    if (!id) {
      continue;
    }
    try {
      yield apiClient.put(`notifications/${read ? "markAsRead" : "markAsUnRead"}/${id}`);
      yield put({
        type: actions.MARK_AS_READ_NOTIFICATION + SUCCESS,
        payload,
      });
    } catch (error) {
      yield put({
        type: actions.MARK_AS_READ_NOTIFICATION + FAIL,
        payload: error,
      });
    }
  }
}

export default function* notificationSaga() {
  yield all([
    fork(fetchNotifications),
    fork(markAsReadNotifications),
    fork(fetchNotificationUnreadCount),
    fork(markAllReadNotification),
  ]);
}
