import { PropsWithChildren } from "react";

import { PrivateLayoutUi } from "./PrivateLayoutUi";
import { PrivateLayoutProvider } from "./context";

type PrivateLayoutProps = PropsWithChildren<{}>;

export const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  return (
    <PrivateLayoutProvider>
      <PrivateLayoutUi>{children}</PrivateLayoutUi>
    </PrivateLayoutProvider>
  );
};
