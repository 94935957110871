import * as React from "react";
import { SVGProps } from "react";

export const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 18C2.45 18 1.979 17.804 1.587 17.412C1.195 17.02 0.999333 16.5493 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.804 17.021 14.412 17.413C14.02 17.805 13.5493 18.0007 13 18H3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
      fill={props.fill || "white"}
    />
  </svg>
);
