import React from "react";
import { useParams } from "react-router-dom";
import { LiveRadioPage } from "./LiveRadioPage";
import { LiveTVPage } from "./LiveTVPage";

export const LivePage = () => {
  const { tab = "tv" } = useParams();
  if (tab === "radio") {
    return <LiveRadioPage />;
  }
  return <LiveTVPage />;
};
