import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { EditorState } from "draft-js";

import {
  BINANCE_TRANSACTION_STATUS,
  GiphyObject,
  MESSAGE_TYPE,
  MessageSender,
  STRIPE_TRANSACTION_STATUS,
} from "../../../types";
import { sendMessageChannelCommunity } from "../../../store/socket/actions";
import { updateBinancePayStatus, updateStripePayStatus } from "../../../store/payment/actions";
import { useChatContext } from "./ChatContext";
import { useTypingIndicator } from "./useTypingIndicator";

type ChatState = {
  message: string;
  draftContent: string;
  editorState: EditorState;
  resetCreator: number;
  msgSender: MessageSender | null;
  showGiphyModal: boolean;
  showGiftBox: boolean;
  anchorElPopover: HTMLElement | null;
};

const defaultChatState: ChatState = {
  message: "",
  draftContent: "",
  editorState: EditorState.createEmpty(),
  resetCreator: 0,
  msgSender: null,
  showGiphyModal: false,
  showGiftBox: false,
  anchorElPopover: null,
};

export const useChatUi = () => {
  const dispatch = useDispatch();

  const { channelId, channelType } = useChatContext();

  const [
    { editorState, message, draftContent, resetCreator, msgSender, showGiphyModal, showGiftBox, anchorElPopover },
    setChatState,
  ] = useState<ChatState>(defaultChatState);

  useTypingIndicator({ message, channelId, channelType });

  const updateChatState = (payload: Partial<ChatState>) => {
    setChatState((prev) => ({ ...prev, ...payload }));
  };

  const sendMessage = useCallback(() => {
    if (message && message.trim()) {
      dispatch(
        sendMessageChannelCommunity({
          channelId,
          channelType,
          message,
          draftContent,
          type: MESSAGE_TYPE.TEXT,
        })
      );

      updateChatState({
        message: "",
        draftContent: "",
        editorState: EditorState.createEmpty(),
        resetCreator: Date.now(),
      });
    }
  }, [channelId, channelType, message, draftContent]);

  const sendGiphy = useCallback(
    (payload: { text: string; draftContent: string; giphy: GiphyObject }) => {
      dispatch(
        sendMessageChannelCommunity({
          channelId,
          channelType,
          message: payload.text || "",
          draftContent: payload.draftContent,
          giphy: payload?.giphy,
          type: MESSAGE_TYPE.GIPHY,
        })
      );

      updateChatState({
        message: "",
        draftContent: "",
        editorState: EditorState.createEmpty(),
        resetCreator: Date.now(),
        showGiphyModal: false,
      });
    },
    [channelId, channelType]
  );

  const onDonate = useCallback((showGiftBox: boolean) => {
    updateChatState({
      showGiftBox,
    });

    dispatch(updateBinancePayStatus(BINANCE_TRANSACTION_STATUS.NOT_INITILIZED));
    dispatch(updateStripePayStatus(STRIPE_TRANSACTION_STATUS.NOT_INITILIZED));
  }, []);

  return {
    editorState,
    resetCreator,
    showGiphyModal,
    msgSender,
    showGiftBox,
    anchorElPopover,
    //
    sendMessage,
    sendGiphy,
    onDonate,
    updateChatState,
  };
};
