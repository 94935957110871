import { PropsWithChildren, useMemo } from "react";
import { Box, useTheme } from "@mui/material";

import { useImmersivePanelLayoutContext } from "./ImmersivePanelLayoutContext";

type MainProps = PropsWithChildren<{}>;

export const Main = ({ children }: MainProps) => {
  const theme = useTheme();

  const { immersivePanel__variant, immersivePanel__open, immersivePanel__width } = useImmersivePanelLayoutContext();

  const isTempporary = useMemo(() => immersivePanel__variant === "temporary", [immersivePanel__variant]);

  return (
    <Box
      sx={{
        width: "1px",
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: isTempporary ? 0 : `-${immersivePanel__width}px`,
        ...(immersivePanel__open && {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginRight: 0,
        }),
      }}
    >
      {children}
    </Box>
  );
};
