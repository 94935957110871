import { apiClient } from "../config";
import { DIRECT_TO_TYPE, RECEIVER_TYPE } from "../types";

type DataOptions = {
  currency: string;
  amount: number;
  receiverType: RECEIVER_TYPE;
  receiver: string;
  directTo?: string;
  directToType?: DIRECT_TO_TYPE;
};

export const createStripePayOrder = async (data: DataOptions) => {
  return apiClient.post("/v2/payments/stripe/createOrder", data);
};
