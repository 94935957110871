import { forwardRef, useEffect, useMemo, useState } from "react";
import { Box, IconButton, InputBase, SxProps, alpha, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "lodash";

import { usePrivateNavbarContext } from "../PrivateNavbarContext";
import { SearchSvg } from "./SearchSvg";

type SearchInputProps = {
  containerSx?: SxProps;
  focusedSx?: SxProps;
};

export const SearchInput = forwardRef(({ containerSx: _containerSx = {}, focusedSx }: SearchInputProps, ref) => {
  const theme = useTheme();

  const { searchKeyword, updateContext } = usePrivateNavbarContext();

  const [isFocused, setIsFocused] = useState(false);

  const containerSx = useMemo(() => {
    if (!focusedSx) {
      return _containerSx;
    }

    if (isFocused) {
      return focusedSx;
    }

    if (searchKeyword.length > 0) {
      return focusedSx;
    }

    return _containerSx;
  }, [isFocused, searchKeyword, _containerSx]);

  const handleSearch = (value: string) => {
    updateContext({ searchKeyword: value });
  };

  const debouncedHandlder = useMemo(() => debounce(handleSearch, 500), []);

  useEffect(() => {
    debouncedHandlder(searchKeyword);
  }, [searchKeyword]);

  return (
    <Box
      sx={{
        bgcolor: alpha(theme.palette.common.white, 0.1),
        borderRadius: "10px",
        height: 36,
        display: "flex",
        alignItems: "center",
        columnGap: 1,
        px: 1,
        transition: theme.transitions.create("width"),
        ...containerSx,
      }}
    >
      <SearchSvg color="#ffffff80" width={20} height={20} />

      <InputBase
        inputRef={ref}
        sx={{ flex: 1, fontSize: 14 }}
        placeholder="Search..."
        value={searchKeyword}
        onChange={(e) => {
          updateContext({ searchKeyword: e.target.value });
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            updateContext({ searchKeyword: "" });
          }
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      />

      {searchKeyword && (
        <IconButton
          sx={{
            width: 24,
            height: 24,
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            updateContext({ searchKeyword: "" });
          }}
        >
          <CloseIcon sx={{ fontSize: 16 }} />
        </IconButton>
      )}
    </Box>
  );
});
