import { SxProps, Typography } from "@mui/material";

import { FlexCol } from "./FlexCol";

type GeoBlockedProps = {
  sx?: SxProps;
  message: string;
};

export const GeoBlocked = ({ sx = {}, message }: GeoBlockedProps) => {
  return (
    <FlexCol
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
        alignItems: "center",
        py: 6,
        ...sx,
      }}
    >
      <Typography variant="h5">{message}</Typography>
    </FlexCol>
  );
};
