import React from "react";
import { Typography } from "@mui/material";

import { FlexCol, FlexRow } from "../../components";
import { ReactComponent as VoteIcon } from "../../assets/svgs/studio/vote.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/svgs/verified.svg";

export const StudioActivity = () => {

  return (
    <FlexCol sx={{ width: "100%", height: "100%" }}>
      <FlexRow sx={{ bgcolor: "#1E2034", py: 1, px: 2.5 }}>
        <FlexRow sx={{ alignItems: "center" }}>
          <FlexCol sx={{ ml: 1 }}>
            <Typography sx={{ fontWeight: 600, fontSize: 16 }}>$145</Typography>
            <Typography sx={{ color: "#A3AED0", fontSize: 11 }}> received</Typography>
          </FlexCol>
        </FlexRow>

        <FlexRow sx={{ alignItems: "center", ml: 2 }}>
          <VoteIcon style={{ width: 30, height: 30 }} />
          <FlexCol sx={{ ml: 1 }}>
            <Typography sx={{ fontWeight: 600, fontSize: 16 }}>145</Typography>
            <Typography sx={{ color: "#A3AED0", fontSize: 11 }}>Votes casted</Typography>
          </FlexCol>
        </FlexRow>
      </FlexRow>
      <FlexCol sx={{ flexGrow: 1, alignItems: "center" }} height='50px'>
        <FlexCol className="-webkit-chatbox-scrollbar" sx={{ mt: 1, pb: 0.5, width: "100%", minHeight: 50, gap: 0.5, flexGrow: 1, overflowY: 'auto' }}>
          {[...Array(10)].map((_, index) => {
            if (index % 2 === 0) {
              return (<FlexRow sx={{ bgcolor: "#1E2034", py: 1.5, px: 1.5, alignItems: "center" }} key={index}>
                <VoteIcon style={{ width: 20, height: 20, marginLeft: 2, marginRight: 2 }} />
                <Typography sx={{ fontWeight: 600, fontSize: 16, color: "#EB5757", ml: 1 }}>Pixel Warrios</Typography>
                <VerifiedIcon style={{ marginLeft: 8, marginRight: 8 }} />
                <Typography sx={{ color: "#A3AED0", fontSize: 12 }}>started a Poll</Typography>
              </FlexRow>
              );
            }

            return (
              <FlexRow sx={{ bgcolor: "#1E2034", py: 1.5, px: 1.5, alignItems: "center" }} key={index}>
                <Typography sx={{ fontWeight: 600, fontSize: 16, color: "#EB5757", ml: 1 }}>Pixel Warrios</Typography>
                <VerifiedIcon style={{ marginLeft: 8, marginRight: 8 }} />
                <Typography sx={{ color: "#A3AED0", fontSize: 12 }}>gifted <b style={{ color: 'white' }}>$10</b></Typography>
                <Typography sx={{ color: "#A3AED0", fontSize: 12 }}>to <b style={{ color: 'white' }}>Ladyxtel</b></Typography>
              </FlexRow>
            );
          })}
        </FlexCol>
      </FlexCol>
    </FlexCol>
  );
};