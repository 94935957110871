import { createAction } from "redux-actions";
import { FAIL, START } from "../common";

export const FETCH_EXCHANGE_RATE = "FETCH_EXCHANGE_RATE";
export const CREATE_BINANCE_PAY_ORDER = "CREATE_BINANCE_PAY_ORDER";
export const CREATE_BINANCE_PAYOUT_REQUEST = "CREATE_BINANCE_PAYOUT_REQUEST";
export const UPDATE_BINANCE_PAYOUT_REQUEST = "UPDATE_BINANCE_PAYOUT_REQUEST";
export const FETCH_BINANCE_AVAILABLE_BALANCE = "FETCH_BINANCE_AVAILABLE_BALANCE";
export const UPDATE_BINANCE_PAY_STATUS = "UPDATE_BINANCE_PAY_STATUS";
export const CREATE_STRIPE_PAY_ORDER = "CREATE_STRIPE_PAY_ORDER";
export const UPDATE_STRIPE_PAY_STATUS = "UPDATE_STRIPE_PAY_STATUS";
export const UPDATE_PAY_STATUS = "UPDATE_PAY_STATUS";

export const fetchExchangeRates = createAction(FETCH_EXCHANGE_RATE + START);
export const createBinancePayOrder = createAction(CREATE_BINANCE_PAY_ORDER + START);
export const updateBinancePayoutRequest = createAction(UPDATE_BINANCE_PAYOUT_REQUEST);
export const createBinancePayoutRequest = createAction(CREATE_BINANCE_PAYOUT_REQUEST + START);
export const fetchBinanceAvailableBalance = createAction(FETCH_BINANCE_AVAILABLE_BALANCE + START);
export const updateBinancePayStatus = createAction(UPDATE_BINANCE_PAY_STATUS);
export const createStripePayOrder = createAction(CREATE_STRIPE_PAY_ORDER + START);
export const updateStripePayStatus = createAction(UPDATE_STRIPE_PAY_STATUS);
export const updatePayStatus = createAction(UPDATE_PAY_STATUS);
