import { Button, ButtonProps } from "@mui/material";
import React from "react";

type Props = {} & ButtonProps;

const LoadMoreButton = ({ sx, ...rest }: Props) => {
  return (
    <Button
      sx={{
        alignSelf: "center",
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: 20,
        color: "white",
        fontWeight: 400,
        fontSize: 10,
        lineHeight: "100%",
        padding: "7px 10px",
        ":hover": {
          background: "rgba(255, 255, 255, 0.15)",
        },
        ...sx,
      }}
      {...rest}
    >
      Load More
    </Button>
  );
};

export default LoadMoreButton;
