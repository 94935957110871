import { createSelector } from "reselect";
import { RootState } from "../../types/store";

const getNotificationState = ({ notification }: RootState) => notification;

export const notificationSelector = createSelector([getNotificationState], (notification) => notification);
export const notificationUnreadCountSelector = createSelector(
  [notificationSelector],
  ({ unreadCount }) => unreadCount || 0
);
