import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";
import { NFTFeed, StreamFeed } from "../../types";

export interface FeedState {
  stream: {
    items: StreamFeed[];
    loading: boolean;
    hasMore: boolean;
    loadingMore: boolean;
    page: number;
    total: number;
    action: {
      loading: boolean;
    };
  };
  nft: {
    items: NFTFeed[];
    loading: boolean;
    hasMore: boolean;
    loadingMore: boolean;
    page: number;
    total: number;
    action: {
      loading: boolean;
    };
  };
  userStreams: {
    [symbol: string]: {
      items: StreamFeed[];
      loading: boolean;
      hasMore: boolean;
      loadingMore: boolean;
      page: number;
      total: number;
      action: {
        loading: boolean;
      };
    };
  };
}

const initialState: FeedState = {
  stream: {
    items: [],
    loading: false,
    hasMore: false,
    loadingMore: false,
    page: 1,
    total: 0,
    action: {
      loading: false,
    },
  },
  nft: {
    items: [],
    loading: false,
    hasMore: false,
    loadingMore: false,
    page: 1,
    total: 0,
    action: {
      loading: false,
    },
  },
  userStreams: {},
};

const reducer = handleActions<FeedState, any>(
  {
    [actions.FETCH_HOME_STREAM_FEED + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stream.loading = true;
      }),
    [actions.FETCH_HOME_STREAM_FEED + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stream.loading = false;
        draft.stream.items = payload.page === 1 ? payload.data : [...state.stream.items, ...payload.data];
        draft.stream.page = payload.page;
        draft.stream.total = payload.total;
        draft.stream.hasMore = draft.stream.items.length < payload.total;
      }),
    [actions.FETCH_HOME_STREAM_FEED + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stream.loading = false;
        draft.stream.hasMore = false;
      }),
    [actions.CREATE_STREAM_FEED + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stream.action.loading = true;
      }),
    [actions.CREATE_STREAM_FEED + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stream.action.loading = false;
        if (!payload?.processing) {
          draft.stream.items = [{ ...payload }, ...state.stream.items];
        }
      }),
    [actions.CREATE_STREAM_FEED + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stream.action.loading = false;
      }),
    [actions.STREAM_FEED_REACTION + START]: (state, { payload }) =>
      produce(state, (draft) => {
        const { streamFeedId, reaction, streamerId } = payload;
        draft.stream.items.forEach((item) => {
          if (item.id === streamFeedId) {
            item.reactionCount += 1;
            const reactionIndex = item.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
            if (reactionIndex !== -1) {
              item.reactions[reactionIndex].count += 1;
              item.reactions[reactionIndex].reacted = true;
            } else {
              item.reactions = [
                ...item.reactions,
                {
                  type: reaction,
                  count: 1,
                  reacted: true,
                },
              ];
            }
          } else if (item.reboltFeed && item.reboltFeed.id === streamFeedId) {
            // reboltFeed update
            item.reboltFeed.reactionCount += 1;
            const reactionIndex = item.reboltFeed.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
            if (reactionIndex !== -1) {
              item.reboltFeed.reactions[reactionIndex].count += 1;
              item.reboltFeed.reactions[reactionIndex].reacted = true;
            } else {
              item.reboltFeed.reactions = [
                ...item.reboltFeed.reactions,
                {
                  type: reaction,
                  count: 1,
                  reacted: true,
                },
              ];
            }
          }
        });

        if (streamerId !== "") {
          draft.userStreams[streamerId].items.forEach((item) => {
            if (item.id === streamFeedId) {
              item.reactionCount += 1;
              const reactionIndex = item.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
              if (reactionIndex !== -1) {
                item.reactions[reactionIndex].count += 1;
                item.reactions[reactionIndex].reacted = true;
              } else {
                item.reactions = [
                  ...item.reactions,
                  {
                    type: reaction,
                    count: 1,
                    reacted: true,
                  },
                ];
              }
            } else if (item.reboltFeed && item.reboltFeed.id === streamFeedId) {
              // reboltFeed update
              item.reboltFeed.reactionCount += 1;
              const reactionIndex = item.reboltFeed.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
              if (reactionIndex !== -1) {
                item.reboltFeed.reactions[reactionIndex].count += 1;
                item.reboltFeed.reactions[reactionIndex].reacted = true;
              } else {
                item.reboltFeed.reactions = [
                  ...item.reboltFeed.reactions,
                  {
                    type: reaction,
                    count: 1,
                    reacted: true,
                  },
                ];
              }
            }
          });
        }
      }),
    [actions.STREAM_FEED_REACTION_REMOVE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        const { streamFeedId, reaction, streamerId } = payload;
        draft.stream.items.forEach((item) => {
          if (item.id === streamFeedId) {
            item.reactionCount -= 1;
            const reactionIndex = item.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
            if (reactionIndex !== -1) {
              item.reactions[reactionIndex].count -= 1;
              item.reactions[reactionIndex].reacted = false;
            }
          } else if (item.reboltFeed && item.reboltFeed.id === streamFeedId) {
            // reboltFeed update
            item.reboltFeed.reactionCount -= 1;
            const reactionIndex = item.reboltFeed.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
            if (reactionIndex !== -1) {
              item.reboltFeed.reactions[reactionIndex].count -= 1;
              item.reboltFeed.reactions[reactionIndex].reacted = false;
            }
          }
        });

        if (streamerId !== "") {
          draft.userStreams[streamerId].items.forEach((item) => {
            if (item.id === streamFeedId) {
              item.reactionCount -= 1;
              const reactionIndex = item.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
              if (reactionIndex !== -1) {
                item.reactions[reactionIndex].count -= 1;
                item.reactions[reactionIndex].reacted = false;
              }
            } else if (item.reboltFeed && item.reboltFeed.id === streamFeedId) {
              // reboltFeed update
              item.reboltFeed.reactionCount -= 1;
              const reactionIndex = item.reboltFeed.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
              if (reactionIndex !== -1) {
                item.reboltFeed.reactions[reactionIndex].count -= 1;
                item.reboltFeed.reactions[reactionIndex].reacted = false;
              }
            }
          });
        }
      }),
    [actions.FETCH_HOME_NFT_FEED + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.loading = true;
      }),
    [actions.FETCH_HOME_NFT_FEED + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.loading = false;
        draft.nft.items = payload.page === 1 ? payload.data : [...state.nft.items, ...payload.data];
        draft.nft.page = payload.page;
        draft.nft.total = payload.total;
        draft.nft.hasMore = draft.nft.items.length < payload.total;
      }),
    [actions.FETCH_HOME_NFT_FEED + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.loading = false;
        draft.nft.hasMore = false;
      }),
    [actions.FETCH_NFT_FEED + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.loading = true;
      }),
    [actions.FETCH_NFT_FEED + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.loading = false;
        draft.nft.items = payload.data;
        draft.nft.page = payload.page;
        draft.nft.total = payload.total;
      }),
    [actions.FETCH_NFT_FEED + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.loading = false;
      }),
    [actions.CREATE_NFT_FEED + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.action.loading = true;
      }),
    [actions.CREATE_NFT_FEED + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.action.loading = false;
        if (!payload?.processing) {
          draft.nft.items = [payload, ...state.nft.items];
        }
      }),
    [actions.CREATE_NFT_FEED + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.action.loading = false;
      }),
    [actions.DELETE_STREAM_FEED + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stream.items = state.stream.items.filter(({ id }) => id !== payload);
      }),
    [actions.DELETE_NFT_FEED + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nft.items = state.nft.items.filter(({ id }) => id !== payload);
      }),
    [actions.FETCH_USER_STREAM_FEED + START]: (state, { payload }) =>
      produce(state, (draft) => {
        if (!payload.userId) {
          return;
        }
        draft.userStreams = {
          ...state.userStreams,
          [payload.userId]: { ...state.userStreams[payload.userId], loading: true },
        };
      }),
    [actions.FETCH_USER_STREAM_FEED + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (!payload.userId) {
          return;
        }
        const currentLength =
          (state.userStreams[payload.userId] &&
            state.userStreams[payload.userId].items &&
            state.userStreams[payload.userId].items.length) ||
          0;
        draft.userStreams = {
          ...state.userStreams,
          [payload.userId]: {
            ...state.userStreams[payload.userId],
            loading: false,
            items: payload.page === 1 ? payload.data : [...state.userStreams[payload.userId].items, ...payload.data],
            page: payload.page,
            total: payload.total,
            hasMore: currentLength + payload.data.length < payload.total,
          },
        };
      }),
    [actions.FETCH_USER_STREAM_FEED + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.userStreams = {
          ...state.userStreams,
          [payload.userId]: {
            loading: false,
            hasMore: false,
          },
        };
      }),
    // feed comment replies
    [actions.FETCH_FEED_LIST_COMMENT_REPLY + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const { streamFeedId, index, data } = payload;
        const feedIndex = state.stream.items.findIndex((item) => item.id === streamFeedId);
        const { comments } = draft.stream.items[feedIndex];
        if (comments) {
          comments[index].replies = data;
        }
      }),
    // [actions.STREAM_LIST_COMMENT_REACTION + START]: (state, { payload }) =>
    //   produce(state, (draft) => {
    //     const { streamFeedId, streamFeedCommentId, reaction } = payload;
    //     const feedIndex = state.stream.items.findIndex((item) => item.id === streamFeedId);
    //     const commentIndex = state.stream.items[feedIndex].comments.findIndex(({ id }) => id === streamFeedCommentId);
    //     const reactionIndex = state.stream.items[feedIndex].comments[commentIndex].reactions.findIndex(
    //       ({ type }) => type === reaction
    //     );
    //     if (reactionIndex !== -1) {
    //       draft.stream.items[feedIndex].comments[commentIndex].reactions[reactionIndex].count += 1;
    //       draft.stream.items[feedIndex].comments[commentIndex].reactions[reactionIndex].reacted = true;
    //     } else {
    //       draft.stream.items[feedIndex].comments[commentIndex].reactions = [
    //         ...draft.stream.items[feedIndex].comments[commentIndex].reactions,
    //         { count: 1, reacted: true, type: reaction },
    //       ];
    //     }
    //   }),
    // [actions.STREAM_LIST_COMMENT_REACTION_REMOVE + START]: (state, { payload }) =>
    //   produce(state, (draft) => {
    //     const { streamFeedId, streamFeedCommentId, reaction } = payload;
    //     const feedIndex = state.stream.items.findIndex((item) => item.id === streamFeedId);
    //     const commentIndex = state.stream.items[feedIndex].comments.findIndex(({ id }) => id === streamFeedCommentId);
    //     const reactionIndex = state.stream.items[feedIndex].comments[commentIndex].reactions.findIndex(
    //       ({ type }) => type === reaction
    //     );
    //     if (reactionIndex !== -1) {
    //       draft.stream.items[feedIndex].comments[commentIndex].reactions[reactionIndex].count -= 1;
    //       draft.stream.items[feedIndex].comments[commentIndex].reactions[reactionIndex].reacted = false;
    //     }
    //   }),
    // [actions.STREAM_LIST_REPLY_REACTION + START]: (state, { payload }) =>
    //   produce(state, (draft) => {
    //     const { streamFeedId, streamFeedCommentId, replyId, reaction } = payload;
    //     const feedIndex = state.stream.items.findIndex((item) => item.id === streamFeedId);
    //     const commentIndex = state.stream.items[feedIndex].comments.findIndex(({ id }) => id === streamFeedCommentId);
    //     const replyIndex = state.stream.items[feedIndex].comments[commentIndex].replies.findIndex(
    //       ({ id }) => id === replyId
    //     );
    //     const reactionIndex = state.stream.items[feedIndex].comments[commentIndex].replies[
    //       replyIndex
    //     ].reactions.findIndex(({ type }) => type === reaction);
    //     if (reactionIndex !== -1) {
    //       draft.stream.items[feedIndex].comments[commentIndex].replies[replyIndex].reactions[reactionIndex].count += 1;
    //       draft.stream.items[feedIndex].comments[commentIndex].replies[replyIndex].reactions[reactionIndex].reacted =
    //         true;
    //     } else {
    //       draft.stream.items[feedIndex].comments[commentIndex].replies[replyIndex].reactions = [
    //         ...draft.stream.items[feedIndex].comments[commentIndex].replies[replyIndex].reactions,
    //         { count: 1, reacted: true, type: reaction },
    //       ];
    //     }
    //   }),
    // [actions.STREAM_LIST_REPLY_REACTION_REMOVE + START]: (state, { payload }) =>
    //   produce(state, (draft) => {
    //     const { streamFeedId, streamFeedCommentId, replyId, reaction } = payload;
    //     const feedIndex = state.stream.items.findIndex((item) => item.id === streamFeedId);
    //     const commentIndex = state.stream.items[feedIndex].comments.findIndex(({ id }) => id === streamFeedCommentId);
    //     const replyIndex = state.stream.items[feedIndex].comments[commentIndex].replies.findIndex(
    //       ({ id }) => id === replyId
    //     );
    //     const reactionIndex = state.stream.items[feedIndex].comments[commentIndex].replies[
    //       replyIndex
    //     ].reactions.findIndex(({ type }) => type === reaction);
    //     if (reactionIndex !== -1) {
    //       draft.stream.items[feedIndex].comments[commentIndex].replies[replyIndex].reactions[reactionIndex].count -= 1;
    //       draft.stream.items[feedIndex].comments[commentIndex].replies[replyIndex].reactions[reactionIndex].reacted =
    //         false;
    //     }
    //   }),
    [actions.FEED_LIST_COMMENT_ADD + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const { feedId } = payload;
        const feedIndex = state.stream.items.findIndex((item) => item.id === feedId);
        draft.stream.items[feedIndex].commentCount += 1;
      }),
    // [actions.FEED_LIST_COMMENT_ADD_REPLY + SUCCESS]: (state, { payload }) =>
    //   produce(state, (draft) => {
    //     const { streamFeedId, index } = payload;
    //     const feedIndex = state.stream.items.findIndex((item) => item.id === streamFeedId);
    //     draft.stream.items[feedIndex].comments[index].repliesCount += 1;
    //   }),
  },
  initialState
);

export default reducer;
