import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";
import { Notification } from "../../types";

export interface NotificationState {
  items: Notification[];
  loading: boolean;
  hasMore: boolean;
  loadingMore: boolean;
  page: number;
  total: number;
  action: {
    loading: boolean;
  };
  unreadCount: number;
}

const initialState: NotificationState = {
  items: [],
  loading: false,
  hasMore: false,
  loadingMore: false,
  page: 1,
  total: 0,
  action: {
    loading: false,
  },
  unreadCount: 0,
};

const reducer = handleActions<NotificationState, any>(
  {
    [actions.FETCH_NOTIFICATION_UNREAD_COUNT + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.unreadCount = payload;
      }),
    [actions.FETCH_NOTIFICATIONS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = true;
      }),
    [actions.FETCH_NOTIFICATIONS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.items = payload.page === 1 ? payload.data : [...state.items, ...payload.data];
        draft.page = payload.page;
        draft.total = payload.total;
        draft.hasMore = draft.items.length < payload.total;
      }),
    [actions.FETCH_NOTIFICATIONS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.hasMore = false;
      }),
    [actions.MARK_AS_READ_NOTIFICATION + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const index = draft.items?.findIndex((item) => item.id === payload?.id);
        if (index > -1) {
          draft.items[index].read = !!payload?.read;
          draft.unreadCount = state.unreadCount > 0 ? state.unreadCount - 1 : 0;
        }
      }),
    [actions.MARK_ALL_READ_NOTIFICATION + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.unreadCount = 0;
        draft.items = state.items.map((item) => ({ ...item, read: true }));
      }),
  },
  initialState
);

export default reducer;
