import { createSelector } from "reselect";
import { RootState } from "../../types/store";

const walletState = ({ wallet }: RootState) => wallet;

export const walletSelector = createSelector([walletState], wallet => wallet);
export const balancesSelector = createSelector([walletState], ({ balances }) => balances)

export const totalBalanceSelector = createSelector([walletState], ({ balances, tokens }) => {
  return Object.keys(balances).reduce((acc, item) => {
    return acc + (balances[item].tokenBalance * (tokens[item] && +tokens[item].usd ? +tokens[item].usd : 0))
  }, 0)
})