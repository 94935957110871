import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { FlexCol } from "./FlexCol";
import { FlexRow } from "./FlexRow";

export const EntitySkeleton = () => {
  const navigate = useNavigate();
  return (
    <Container sx={{ pb: 4, pt: 0 }} disableGutters maxWidth={false}>
      <FlexCol sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <FlexRow sx={{ flexDirection: { xs: "column", sm: "row", width: "100%" } }}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            component="div"
            sx={{ opacity: 0.4, borderRadius: 1, width: "100%", height: { xs: 200, sm: "inherit" } }}
          />
          <FlexCol
            sx={{
              width: { xs: "100%", sm: 750 },
              ml: { xs: 0, sm: 0.4 },
              mt: { xs: 2, sm: 0 },
              display: { xl: "flex", lg: "flex", md: "flex", sm: "none", xs: "none" },
            }}
          >
            <FlexCol
              sx={{
                borderRadius: 1,
                overflow: 'hidden',
                width: "100%",
                height: "100%",
                p: 1,
                position: "relative",
                flex: 1,
                maxHeight: { md: 490, lg: '70vh' },
                minHeight: { md: 490, lg: '70vh' },
                zIndex: 0,
              }}
            >
              {new Array(10).fill('').map((_, index) => (
                <FlexRow sx={{ alignItems: "center", flex: 1, mb: { sm: 2, lg: 3, xl: 1.8 } }} key={index}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                      opacity: 0.4,
                      borderRadius: 110,
                      height: 32,
                      width: 38,
                    }}
                  />
                  <Typography variant="caption" sx={{ color: "gray", width: '100%', mx: 2 }}>
                    <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: '100%' }} />
                  </Typography>
                </FlexRow>
              ))}
            </FlexCol>
          </FlexCol>
        </FlexRow>
      </FlexCol>
      <FlexCol sx={{ pt: { xs: 3 }, pb: 4, maxWidth: 1920 }}>
        <Grid container sx={{ px: 4, mt: 4 }}>
          <Grid item container xs={12} sm={4}>
            <FlexRow sx={{ alignItems: "center", flex: 1 }}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  opacity: 0.4,
                  borderRadius: 110,
                  height: 110,
                  width: 110,
                }}
              />
              <FlexCol sx={{ ml: 4, flexGrow: 1, justifyContent: "space-between", height: "100%" }}>
                <Typography variant="h3" fontWeight={600}>
                  <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4 }} />
                </Typography>
                <Typography variant="caption" sx={{ color: "gray" }}>
                  <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30 }} />
                </Typography>
                <FlexRow>
                  <Skeleton variant="circular" animation="wave" sx={{ opacity: 0.4, width: 24, height: 24, mr: 0.5 }} />
                  <Skeleton variant="circular" animation="wave" sx={{ opacity: 0.4, width: 24, height: 24, mr: 0.5 }} />
                  <Skeleton variant="circular" animation="wave" sx={{ opacity: 0.4, width: 24, height: 24, mr: 0.5 }} />
                  <Skeleton variant="circular" animation="wave" sx={{ opacity: 0.4, width: 24, height: 24, mr: 0.5 }} />
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </Grid>
          <Grid item container xs={12} sm={8} sx={{ alignItems: "center", mt: { xs: 4, sm: 0 } }}>
            <Grid item xs={6} sm={6} md={8} sx={{ justifyContent: "flex-end", display: "flex" }}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  opacity: 0.4,
                  borderRadius: 2,
                  width: 200,
                  height: 60,
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              sx={{
                justifyContent: "flex-end",
                display: { xl: "flex", lg: "flex", md: "flex", sm: "none", xs: "none" },
              }}
            >
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ width: 120, height: 30, borderRadius: 20, opacity: 0.4 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 8, justifyContent: "center", flexDirection: "row" }}>
          <Grid item xs={12} sm={8} md={5}>
            <Stack direction="row" sx={{}}>
              <Skeleton variant="circular" animation="wave" width={40} height={40} sx={{ opacity: 0.4, flexShrink: 0 }} />
              <Typography variant="h4" sx={{ opacity: 0.4, width: "30%", ml: 1 }}>
                <Skeleton variant="text" animation="wave" />
              </Typography>
              <Typography variant="h4" sx={{ opacity: 0.4, width: "10%", ml: "auto" }}>
                <Skeleton variant="text" animation="wave" />
              </Typography>
            </Stack>
            <Typography variant="h4">
              <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4 }} />
            </Typography>
            <Skeleton variant="rectangular" animation="wave" height={200} sx={{ opacity: 0.4, borderRadius: 2 }} />
            <Typography variant="h4" sx={{ opacity: 0.4, width: "100%" }}>
              <Skeleton variant="text" animation="wave" />
            </Typography>
          </Grid>
        </Grid>
      </FlexCol>
    </Container>
  );
};
