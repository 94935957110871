import { PropsWithChildren, createContext, useContext, useMemo, useState } from "react";

export const homeCards = {
  liveOnBolt: "live-on-bolt",
  trends: "trends",
  binancePay: "binance-pay",
};

type HomePageContextType = {
  activeCard: string | false;
  //
  showLiveOnBoltInCardsColumn: boolean;
  updateContext: (payload: Partial<Omit<HomePageContextType, "updateContext">>) => void;
};

const defaultHomePageContextValue: HomePageContextType = {
  activeCard: homeCards.liveOnBolt,
  //
  showLiveOnBoltInCardsColumn: false,
  //
  updateContext: () => {},
};

export const HomePageContext = createContext<HomePageContextType>(defaultHomePageContextValue);

type HomePageContextProviderProps = PropsWithChildren<{
  showLiveOnBoltInCardsColumn?: boolean;
}>;

export const HomePageContextProvider = ({
  children,
  showLiveOnBoltInCardsColumn = false,
}: HomePageContextProviderProps) => {
  const [contextValue, setContextValue] = useState<HomePageContextType>({
    ...defaultHomePageContextValue,
    showLiveOnBoltInCardsColumn,
  });

  const updateContext: HomePageContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  const memoizedValue = useMemo(() => {
    return {
      ...contextValue,
      //
      updateContext,
    };
  }, [contextValue]);

  return <HomePageContext.Provider value={memoizedValue}>{children}</HomePageContext.Provider>;
};

export const useHomePageContext = () => {
  const context = useContext(HomePageContext);

  return context;
};
