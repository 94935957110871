import React from "react";
import { SearchInput } from "../../components/navbar/private-navbar/search/SearchInput";
import { SearchResult } from "./SearchResult";

const SearchPage = () => {
  // return <SearchResult />;
  return null;
};

export default SearchPage;
