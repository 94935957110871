import { useMemo } from "react";
import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { socketSelector } from "../../../store/socket/selector";
import { authSelector } from "../../../store/auth/selector";

const maxAvatarCount = 5;

type TyperItemProps = {
  avatar: string;
  username: string;
  fullname: string;
};

export const TyperIndicator = () => {
  const { typers = [] } = useTyperItems();

  if (typers.length === 0) {
    return null;
  }

  if (typers.length === 1) {
    return (
      <Box sx={{ px: 1, display: "flex", alignItems: "center", gap: 0.5 }}>
        <Avatar
          sx={{
            width: 20,
            height: 20,
            border: "2px solid #0A0B19",
            boxSizing: "content-box",
          }}
          src={typers[0].avatar}
          alt={typers[0].fullname}
        />

        <Typography
          sx={{
            fontSize: 12,
            color: "#ffffff80",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {typers[0].username} is typing...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ px: 1, display: "flex", alignItems: "center", gap: 0.5 }}>
      <AvatarGroup
        max={maxAvatarCount}
        sx={{
          ".MuiAvatarGroup-avatar": { fontSize: 16 },
          ".MuiAvatar-root": {
            ml: -0.5,
          },
        }}
      >
        {typers.slice(0, maxAvatarCount).map((item) => (
          <Avatar sx={{ width: 20, height: 20 }} src={item.avatar} alt={item.fullname} />
        ))}
      </AvatarGroup>

      {typers.length > maxAvatarCount && (
        <Typography sx={{ fontSize: 12, color: "#ffffff80" }}>+{typers.length - maxAvatarCount}</Typography>
      )}

      <Typography sx={{ fontSize: 12, color: "#ffffff80" }}>are typing...</Typography>
    </Box>
  );
};

const useTyperItems = () => {
  const { user: { id: currentUserId = "" } = {} } = useSelector(authSelector);

  const {
    typers: { items },
  } = useSelector(socketSelector);

  const typers: TyperItemProps[] = useMemo(() => {
    return (
      items
        // .filter((item) => item.id !== currentUserId)
        .map((item) => {
          const avatar = item.photoUrl || "";
          const username = item.username || "";
          const fullname = item.fullName || "";

          return {
            avatar,
            username,
            fullname,
          };
        })
    );
  }, [currentUserId, items]);

  return { typers };
};
