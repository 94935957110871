import React, { useState } from "react";
import CommentContent from "./CommentContent";
import { FlexCol } from "../../components";
import ReplyContainer from "./ReplyContainer";
import { CommentType, ReplyType } from "../../types";

type Props = {
  feedId: string;
  comment?: CommentType;
  reply?: ReplyType;
  index: number;
  parentCommentId?: string;
  loadingCommentIndex: number;
  home?: boolean;
  rebolt?: boolean;
};

const Comment = ({ feedId, comment, reply, index, parentCommentId, loadingCommentIndex, home, rebolt }: Props) => {
  const [showReplyBox, setShowReplyBox] = useState(false);

  return (
    <FlexCol
      sx={{
        background: "#1C2039",
        borderRadius: reply ? 0 : 2.5,
        overflow: "hidden",
        cursor: "default",
        boxShadow: reply ? undefined : "0px 0px 20px rgba(0, 0, 0, 0.25)",
        borderTop: reply && index !== 0 ? "0.5px solid rgba(255, 255, 255, 0.05)" : undefined,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <CommentContent {...{ feedId, comment, reply, showReplyBox, setShowReplyBox, parentCommentId, home, rebolt }} />
      {/* <ReplyContainer
        {...{ feedId, index, comment, reply, showReplyBox, showReplies, setShowReplies, loadingCommentIndex, home }}
      /> */}
    </FlexCol>
  );
};

export default Comment;
