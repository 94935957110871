import React, { useEffect, useRef } from "react";
import { ANALYTICS_TRACK_TYPE } from "../types";
import { BoltAnalytics } from "../services";

interface Props {
  entityId: string;
  type: ANALYTICS_TRACK_TYPE;
  userId?: string;
  enabled?: boolean;
}

const TRACK_INTERVAL = 5 * 60 * 1000; // 5 mins

export const useAnalyticsTracker = ({ entityId, type, userId, enabled = true }: Props) => {
  // Set up interval to call the postAnalytics function
  useEffect(() => {
    BoltAnalytics.trackViews({ entityId, type, userId, enabled });
    const id = setInterval(() => BoltAnalytics.trackViews({ entityId, type, userId, enabled }), TRACK_INTERVAL);
    return () => clearInterval(id); // Clean up interval on unmount
  }, [entityId, type, userId, enabled]);

  return null;
};
