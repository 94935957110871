import { ButtonBase, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { numberOfItemsToLoad } from "../helpers/numberOfItemsToLoad";
import Comment from "../pages/feed/Comment";
import CommentSkeleton from "../pages/feed/CommentSkeleton";
import { fetchFeedComments } from "../store/feedDetails/actions";
import { NFTFeed, StreamFeed } from "../types";
import { FlexCol } from "./FlexCol";
import LoadMoreButton from "./LoadMoreButton";
import { ReactComponent as DownIcon } from "../assets/svgs/chevronDown.svg";

type Props = {
  item: StreamFeed | NFTFeed;
  initialLoad?: boolean;
  loadingComments?: boolean;
  loadingCommentIndex?: number;
};

const CommentContainer = ({ item, initialLoad, loadingComments, loadingCommentIndex = -1 }: Props) => {
  const dispatch = useDispatch();

  const [sortAnchor, setSortAnchor] = useState<HTMLButtonElement | null>(null);

  const [orderBy, setOrderBy] = useState("Recent");

  const handleMenuClose = useCallback((e?: any) => {
    if (e) e.stopPropagation();
    setSortAnchor(null);
  }, []);

  const getComments = useCallback(
    (limit?: number, initialCommentsLoading?: boolean) => {
      if (item.reboltFeed) {
        dispatch(
          fetchFeedComments({
            streamFeed: item.reboltFeed.id,
            limit,
            initialCommentsLoading,
          })
        );
        return;
      }
      dispatch(
        fetchFeedComments({
          streamFeed: item.id,
          limit,
          initialCommentsLoading,
        })
      );
    },
    [item, dispatch]
  );

  useEffect(() => {
    if (item.id) getComments(undefined, true);
  }, []);

  const handleLoadMore = useCallback(() => {
    const limit = item.reboltFeed
      ? numberOfItemsToLoad(item.reboltFeed.comments?.length || 0, item.reboltFeed.commentCount)
      : numberOfItemsToLoad(item.comments?.length || 0, item.commentCount);
    getComments(limit);
  }, [getComments, item]);

  const memoizedComments = useMemo(() => {
    if (item.reboltFeed) {
      return item.reboltFeed.comments?.map((comment, index) => (
        <Comment
          key={comment.id}
          {...{ feedId: item.reboltFeed!.id, index, comment, loadingCommentIndex, rebolt: true }}
        />
      ));
    }
    return item?.comments?.map((comment, index) => (
      <Comment
        key={comment.id}
        {...{ feedId: item.id, index, comment, loadingCommentIndex, rebolt: !!item.reboltFeed }}
      />
    ));
  }, [item]);

  if (item?.reboltFeed && item?.reboltFeed?.commentCount === 0) return null;

  if (!item?.reboltFeed && item?.commentCount === 0) return null;

  return (
    <FlexCol sx={{ px: 2.25, py: 1.875, gap: 1.25, borderTop: "1px solid rgba(255, 255, 255, 0.05)" }}>
      <ButtonBase
        sx={{
          padding: "4px 5px",
          alignSelf: "flex-start",
          fontWeight: 600,
          fontSize: 10,
          lineHeight: "100%",
          color: "white",
          display: "flex",
          alignItems: "flex-start",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setSortAnchor(e.currentTarget);
        }}
      >
        {orderBy} <DownIcon style={{ width: 8, marginLeft: 7 }} />
      </ButtonBase>
      <Menu anchorEl={sortAnchor} open={!!sortAnchor} onClose={handleMenuClose} onClick={(e) => e.stopPropagation()}>
        <MenuItem
          onClick={() => {
            setOrderBy("Recent");
            handleMenuClose();
          }}
        >
          Recent
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOrderBy("Popular");
            handleMenuClose();
          }}
        >
          Popular
        </MenuItem>
      </Menu>
      {!initialLoad && memoizedComments}
      {(loadingComments || initialLoad) && (
        <>
          <CommentSkeleton />
          <CommentSkeleton />
          <CommentSkeleton />
        </>
      )}
      {((item?.reboltFeed?.comments && item?.reboltFeed?.commentCount > item?.reboltFeed?.comments.length) ||
        (!item?.reboltFeed && item?.comments && item?.commentCount > item?.comments.length)) && (
        <LoadMoreButton onClick={handleLoadMore} />
      )}
    </FlexCol>
  );
};

export default CommentContainer;
