export enum BoltProvider {
  GOOGLE = "google.com",
  APPLE = "apple.com",
  WALLET = "wallet",
}

export enum BoltWallet {
  METAMASK = "METAMASK",
  WALLET_CONNECT = "WALLET_CONNECT",
  BOLTX = "BOLTX",
  OKX_WALLET = "OKX_WALLET",
}
