import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import PlayIcon from "@mui/icons-material/PlayArrowSharp";
import { IconButton } from "@mui/material";
import { FlexCol } from "../FlexCol";

type Props = {
  url?: string;
  onClick: (e: any) => void;
};

export const AnalyticsFeedVideo = ({ url = "", onClick = () => { } }: Props) => {
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    playerRef.current?.seekTo(1);
  }, [playerRef.current]);

  return (
    <FlexCol sx={{ height: "100%", width: "100%", position: "relative" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <ReactPlayer
          ref={playerRef}
          muted
          url={url}
          width="100%"
          height="100%"
          style={{ display: "flex", backgroundColor: "black", objectFit: "cover" }}
          onReady={(player) => { playerRef.current?.seekTo(1); }}
        />
      </div>

      <FlexCol sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <IconButton
          sx={{
            borderRadius: 30,
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: '#00000060'
          }}
          onClick={onClick}
        >
          <PlayIcon sx={{ width: 35, height: 35 }} />
        </IconButton>
      </FlexCol>
    </FlexCol>
  );
};
