import React, { useEffect } from "react";
import { Box, Container, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { debounce, orderBy, unionBy } from "lodash";

import { BoltPlayer, FlexCol, FlexRow } from "../../../components";
import { fetchHomeChannels } from "../../../store/channel/actions";
import { channelSelector } from "../../../store/channel/selector";
import { Channel, NftCreator } from "../../../types";
import { NftCreatorCard } from "./NftCreatorCard";
import { ChannelRow } from "./ChannelRow";

const ITEM_HEIGHT = 120;

export const TvCategoryTabContent = () => {
  const dispatch = useDispatch();
  const { category } = useParams();
  const navigate = useNavigate();
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const mobileTouchDeltaY = React.useRef<number>();
  const { channels, loading } = useSelector(channelSelector);
  const data = React.useMemo(() => {
    if (category === "home") {
      return orderBy(
        unionBy(
          Object.values(channels).reduce((a: Channel[], b) => a.concat(b.channels), []),
          "id"
        ),
        ["order", "createdAt", "title"],
        ["asc", "desc", "asc"]
      );
    }
    return channels[category?.split("-").join(" ")!]?.channels || [];
  }, [category, channels]);
  const [activeChannel, setActiveChannel] = React.useState<Channel>();

  React.useEffect(() => {
    setActiveChannel(data ? data[0] : undefined);
    scrollRef.current?.scrollTo({ top: 0 });
  }, [data]);

  const checkDirection = React.useCallback(
    debounce(
      (event: WheelEvent) => {
        if (event.deltaY > 0) {
          scrollRef.current?.scrollBy({ top: ITEM_HEIGHT, behavior: "smooth" });
        } else if (event.deltaY < 0) {
          scrollRef.current?.scrollBy({ top: -ITEM_HEIGHT, behavior: "smooth" });
        }
      },
      50,
      { leading: true, trailing: false }
    ),
    []
  );

  const scrollListener = React.useCallback((e: Event) => {
    e.preventDefault();
    const event = e as WheelEvent;
    checkDirection(event);
  }, []);

  const touchStartListener = React.useCallback((e: Event) => {
    e.preventDefault();
    const event = e as TouchEvent;
    mobileTouchDeltaY.current = event.touches[0].pageY;
  }, []);
  const touchMoveListener = React.useCallback((e: Event) => {
    e.preventDefault();
    const event = e as TouchEvent;
    const newEvent = event as Event;
    checkDirection({ ...newEvent, deltaY: mobileTouchDeltaY.current! - event.touches[0].pageY } as WheelEvent);
  }, []);

  React.useEffect(() => {
    const element = document.getElementById("scroll-element");
    element?.addEventListener("mousewheel", scrollListener);
    element?.addEventListener("touchstart", touchStartListener, false);
    element?.addEventListener("touchmove", touchMoveListener, false);
    return () => {
      element?.removeEventListener("mousewheel", scrollListener);
      element?.removeEventListener("touchstart", touchStartListener);
      element?.removeEventListener("touchmove", touchMoveListener);
    };
  }, []);

  useEffect(() => {
    if (!data?.length) {
      dispatch(fetchHomeChannels());
    }
  }, [dispatch, data]);

  const navigateChannel = (channel: Channel | NftCreator) => {
    if (!channel.slug) {
      navigate(`/${(channel as Channel).channelId}`);
    } else {
      navigate(`/${(channel as NftCreator).nftId}`);
    }
  };

  const handleItemClick = React.useCallback((active: boolean, index: number, channel: Channel) => {
    if (active) {
      navigateChannel(channel);
    } else {
      scrollRef.current?.scrollTo({ top: index * ITEM_HEIGHT, behavior: "smooth" });
    }
  }, []);

  const debouncedChannelChange = React.useCallback(
    debounce((channel: Channel) => setActiveChannel(channel), 500, { leading: false, trailing: true }),
    []
  );

  return (
    <>
      <Box sx={{ position: "absolute", inset: 0 }}>
        <BoltPlayer url={activeChannel?.url} isMuted controls={false} isLive={activeChannel?.isLive} />
      </Box>
      <Box
        sx={{
          position: "fixed",
          width: "330px",
          top: 0,
          bottom: 0,
          background: "linear-gradient(73.23deg, rgba(101, 53, 233, 0.5) 0%, rgba(101, 53, 233, 0) 50.13%);",
        }}
      />
      <Container
        ref={scrollRef}
        component="div"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          flex: 1,
          width: "100%",
          position: "absolute",
          inset: 0,
          overflow: "hidden",
          scrollSnapType: "y mandatory",
          zIndex: 1,
        }}
        id="scroll-element"
        disableGutters
        maxWidth={false}
      >
        <FlexCol
          sx={{
            mb: 2,
            width: "100%",
            paddingTop: `calc(100vh - ${ITEM_HEIGHT * 2}px)`,
            paddingBottom: ITEM_HEIGHT,
          }}
        >
          {data.map((channel, index) =>
            !channel.slug ? (
              <ChannelRow
                key={index}
                channel={channel}
                onClick={(active) => handleItemClick(active, index, channel)}
                style={{
                  height: ITEM_HEIGHT,
                  scrollSnapStop: "always",
                  scrollSnapAlign: "end",
                  scrollMarginBottom: ITEM_HEIGHT * 0.75,
                }}
                setActiveChannel={(channel) => {
                  debouncedChannelChange(channel);
                }}
              />
            ) : (
              <NftCreatorCard
                key={index}
                style={{ mr: { xs: 2, lg: 4 }, mb: { xs: 2, lg: 4 } }}
                channel={channel}
                enableClick
                onClick={() => navigateChannel(channel)}
              />
            )
          )}
          {loading &&
            Array(30)
              .fill(0)
              .map((_, index) => (
                <FlexRow key={index} sx={{ alignItems: "center" }}>
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    animation="wave"
                    sx={{
                      opacity: 0.6,
                      borderRadius: "100%",
                      width: { xs: 92, md: 112 },
                      height: { xs: 92, md: 112 },
                      ml: 4,
                      mb: 4,
                    }}
                  />
                  <FlexCol sx={{ ml: 2 }}>
                    <Skeleton variant="text" sx={{ fontSize: { xs: 14, sm: 34, width: 200 } }} />
                    <Skeleton variant="text" sx={{ fontSize: { xs: 14, sm: 16, width: 50 } }} />
                  </FlexCol>
                </FlexRow>
              ))}
        </FlexCol>
      </Container>
    </>
  );
};
