import { Box, SxProps, Link, Skeleton, Typography } from "@mui/material";
import React from "react";
import { InView } from "react-intersection-observer";
import ReactPlayer from "react-player";

import { apiClient } from "../config";
import useExternalLinkStopPropagation from "../hooks/useExternalLinkStopPropagation";
import { UrlMeta } from "../types";
import { FlexCol } from "./FlexCol";
import { FlexRow } from "./FlexRow";

interface Props {
  url?: string;
  style?: SxProps;
}

export const MiniLinkPreview = ({ url, style }: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>();
  const [meta, setMeta] = React.useState<UrlMeta | undefined>();
  React.useEffect(() => {
    (async () => {
      if (!url) {
        return;
      }
      try {
        const { data } = await apiClient.post("/scraping/meta", { url });
        setMeta(data as UrlMeta);
      } catch (error) {
        setMeta({});
      }
      setLoading(false);
    })();
  }, [url]);

  useExternalLinkStopPropagation();

  const domain = React.useMemo(() => {
    if (!meta?.url) {
      return "";
    }
    return (new URL(meta?.url)).hostname;
  }, [meta?.url]);

  if ((!url || (!loading && !meta?.image)) && !error) {
    return null;
  }

  return (
    <Box
      component={Link}
      href={url}
      target="_blank"
      sx={{
        width: "100%",
        height: 80,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
        borderRadius: 2,
        position: "relative",
        bgcolor: "background.paper",
        my: 1,
        ...style,
      }}
      className="external-link"
    >
      {!!meta?.video && (
        <InView rootMargin="-40% 0px -40% 0px">
          {({ inView, ref }) => {
            return (
              <div
                ref={ref}
                style={{
                  width: undefined,
                  height: "100%",
                  aspectRatio: "1",
                  borderRadius: 8,
                  overflow: "hidden",
                  backgroundColor: "black",
                }}
              >
                <ReactPlayer
                  playing={inView}
                  controls
                  playsinline
                  muted
                  url={meta?.video}
                  width="100%"
                  height="100%"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  pip
                />
              </div>
            );
          }}
        </InView>
      )}
      {!!meta?.image && !meta?.video && (
        <img
          src={meta?.image}
          alt="post"
          style={{
            width: undefined,
            height: "100%",
            aspectRatio: "1",
            borderRadius: 8,
            overflow: "hidden",
            objectFit: "cover",
          }}
        />
      )}
      {!!meta && (
        <FlexCol
          sx={{
            px: 2,
            py: 1,
            overflow: "hidden",
            flex: 1,
          }}
        >
          {!!meta?.title && (
            <Typography
              variant="subtitle2"
              fontWeight="600"
              color="white"
              sx={{ display: "-webkit-box", overflow: "hidden", WebkitBoxOrient: "vertical", WebkitLineClamp: 2, flex: 1 }}
            >
              {meta?.title}
            </Typography>
          )}
          {!!domain && (
            <Typography variant="caption" className="max-line-2" color="#ffffff80" sx={{ mt: 0.5 }}>
              {domain}
            </Typography>
          )}
        </FlexCol>
      )}
      {loading && <Skeleton variant="rectangular" animation="wave" height={80} sx={{ opacity: 0.1, flex: 1 }} />}
    </Box>
  );
};
