import React from "react";
import { Fab, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FlexRow } from "./FlexRow";
import { CustomOption } from "../types";

type Props = {};

const defaultCountryFilter: CustomOption[] = [{ label: "Global", value: "all" }];
const defaultChannelFilters: CustomOption[] = [{ label: "All Channels", value: "all" }];

export const RadioFilter = (props: Props) => {
  const theme = useTheme();
  const [selectedCountry, setSelectedCountry] = React.useState(defaultCountryFilter[0]);
  const [selectedChannel, setSelectedChannel] = React.useState(defaultChannelFilters[0]);
  const [anchorElCountry, setAnchorElCountry] = React.useState<HTMLButtonElement>();
  const [anchorElChannel, setAnchorElChannel] = React.useState<HTMLButtonElement>();
  const openCountry = Boolean(anchorElCountry);
  const openChannel = Boolean(anchorElChannel);
  const handleClickCountry = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElCountry(event.currentTarget);
  };
  const handleClickChannel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElChannel(event.currentTarget);
  };
  const handleCloseCountry = (item: CustomOption) => {
    setSelectedCountry(item);
    setAnchorElCountry(undefined);
  };
  const handleCloseChannel = (item: CustomOption) => {
    setSelectedChannel(item);
    setAnchorElChannel(undefined);
  };

  return (
    <FlexRow sx={{ justifyContent: { xs: "center", md: "flex-end" }, alignItems: "center", flex: 1 }}>
      <Fab
        sx={{
          textTransform: "none",
          height: 30,
          minWidth: 140,
          pr: 1,
          bgcolor: "background.paper",
        }}
        color="secondary"
        variant="extended"
        onClick={handleClickCountry}
      >
        <Typography variant="caption" sx={{ px: 2, color: theme.palette.mode === "dark" ? "white" : "black" }}>
          {selectedCountry.label}
        </Typography>
        <KeyboardArrowDownIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
      </Fab>
      <Menu anchorEl={anchorElCountry} open={openCountry} onClose={() => handleCloseCountry(selectedCountry)}>
        {defaultCountryFilter.map(({ label, value }) => (
          <MenuItem
            sx={{ minWidth: 140 }}
            selected={selectedCountry.value === value}
            key={value}
            onClick={() => handleCloseCountry({ label, value })}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
      <Fab
        sx={{
          textTransform: "none",
          height: 30,
          minWidth: 140,
          pr: 1,
          bgcolor: "background.paper",
          ml: 8,
        }}
        color="secondary"
        variant="extended"
        onClick={handleClickChannel}
      >
        <Typography variant="caption" sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
          {selectedChannel.label}
        </Typography>
        <KeyboardArrowDownIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
      </Fab>
      <Menu anchorEl={anchorElChannel} open={openChannel} onClose={() => handleCloseChannel(selectedChannel)}>
        {defaultChannelFilters.map(({ label, value }) => (
          <MenuItem
            sx={{ minWidth: 140 }}
            selected={selectedChannel.value === value}
            key={value}
            onClick={() => handleCloseChannel({ label, value })}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </FlexRow>
  );
};
