import React, { useEffect, useState } from "react";
import { Fab, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomOption } from "../types";

type Props = {
  mode: CustomOption,
  setMode: (mode: CustomOption) => void
};

export const defaultSorts: CustomOption[] = [
  { label: "Recent", value: "recent" },
  { label: "Lasted", value: "lasted" },
];

export const VideoSortFilter = (props: Props) => {
  const { mode, setMode } = props;
  const theme = useTheme();
  const [selected, setSelected] = useState(mode);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);

  useEffect(() => {
    const handler = () => setAnchorEl(undefined);
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item: CustomOption) => {
    setSelected(item);
    setMode(item);
    setAnchorEl(undefined);
  };

  return (
    <>
      <Fab
        sx={{
          textTransform: "none",
          height: 30,
          bgcolor: "background.paper",
          zIndex: 0,
          borderRadius: 1.5,
          paddingX: 1
        }}
        color="secondary"
        variant="extended"
        onClick={handleClick}
      >
        <Typography variant="caption" sx={{ px: 1, color: theme.palette.mode === "dark" ? "white" : "black" }}>{selected.label}</Typography>
        <KeyboardArrowDownIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
      </Fab>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose(selected)} disableScrollLock>
        {defaultSorts.map(({ label, value }) => (
          <MenuItem selected={selected.value === value} key={value} onClick={() => handleClose({ label, value })}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
