import { Box, Typography } from "@mui/material";

import { Streams } from "../streams";
import { usePrivateNavbarContext } from "../../navbar";

export const SearchResult = () => {
  const { searchKeyword } = usePrivateNavbarContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: { xs: 2.5, md: 3.5 }, py: { xs: 0, md: 2.5 } }}>
      <Typography sx={{ fontSize: 14, color: "#A3AED0", lineHeight: 1 }}>
        Showing results for “<span style={{ color: "#fff" }}>{searchKeyword}</span>“
      </Typography>

      <Streams />
    </Box>
  );
};
