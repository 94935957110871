import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import lottie from "lottie-web";
import { SxProps } from "@mui/material";
import loadingAnimation from "../assets/animations/loadingAnimation.json";

type Props = { size?: number | string; sx?: SxProps };

const CustomCircularProgress = ({ size = 48, sx }: Props) => {
  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      const animation = lottie.loadAnimation({
        container: boxRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
      });
      return () => animation.destroy();
    }
    return () => {};
  }, []);

  return <Box ref={boxRef} sx={{ height: size, width: size, ...sx }} />;
};

export default CustomCircularProgress;
