import React from "react";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useForm } from "react-hook-form";

import { FlexCol } from "./FlexCol";
import { RequestResetPasswordDto } from "../dtos";
import { LoadingView } from "./LoadingView";
import { apiClient } from "../config";
import { BoltError, ToastService } from "../services";

const resolver = classValidatorResolver(RequestResetPasswordDto);

export const RequestResetPasswordForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RequestResetPasswordDto>({ resolver, defaultValues: { email: "" } });

  const onSubmit = async (data: RequestResetPasswordDto) => {
    setErrorMessage("");
    setLoading(true);
    try {
      await apiClient.post("/auth/sendResetPasswordEmail", data);
      setErrorMessage("");
      reset();
      ToastService.showSuccessMessage("We've sent verification link to your email!.");
    } catch (error) {
      setErrorMessage(BoltError(error).message);
    }
    setLoading(false);
  };

  return (
    <FlexCol sx={{ alignItems: "center", height: "100%", justifyContent: "center" }}>
      <Typography variant="h4">Reset Password</Typography>
      <Typography variant="caption" color="primary.light" sx={{ my: 3 }}>
        Enter the email address associated with your account and we'll send you a link to reset password.
      </Typography>
      <Box component="form" sx={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          autoComplete="email"
          autoFocus
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register("email")}
        />
        {errorMessage && (
          <Alert variant="outlined" severity="error" sx={{ my: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <FlexCol sx={{ alignItems: "center", justifyContent: "center", mt: 4 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, px: 8, textTransform: "none", borderRadius: 50 }}
          >
            <Typography variant="subtitle1" color="white" fontWeight="600">
              Continue
            </Typography>
          </Button>
        </FlexCol>
      </Box>
      <LoadingView visible={loading} />
    </FlexCol>
  );
};
