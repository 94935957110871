import React from "react";
import { Box, BoxProps } from "@mui/material";
import { isMobile } from "react-device-detect";


type RoundIconProps = { Icon: React.ElementType; } & BoxProps;

export const RoundIcon = ({ Icon }: RoundIconProps) => {
  return (
    <Box sx={{
      backgroundColor: '#282C46',
      height: { xs: 50, sm: 56 },
      width: { xs: 50, sm: 56 },
      borderRadius: 28,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    }}>
      <Icon style={{ height: isMobile ? 20 : 25, width: isMobile ? 20 : 25 }} />
    </Box>
  );
};
