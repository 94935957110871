import { useMemo } from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";

import { Formatter } from "../../../utils";
import { ItemContainer } from "./ItemContainer";
import { Title } from "./Title";
import { DetailText } from "./DetailText";
import { StreamerEntity, TrendItem } from "./types";

export type TrendStreamerProps = Omit<TrendItem, "entity"> & {
  entity: StreamerEntity;
};

export const TrendStreamer = ({ id, entity, views = 0, comments = 0, posts = 0 }: TrendStreamerProps) => {
  const navigate = useNavigate();

  const details = useMemo(() => {
    let result = "";

    if (views) {
      result += `${Formatter.formatCount(views)} Views`;
    }

    if (comments) {
      result += `${result ? " • " : ""}${Formatter.formatCount(comments)} Comments`;
    }

    if (posts) {
      result += `${result ? " • " : ""}${Formatter.formatCount(posts)} Posts`;
    }

    return result;
  }, [views, comments, posts]);

  return (
    <ItemContainer>
      <Title title="Streamer" />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: "3px",
            columnGap: 1,
          }}
        >
          <Avatar
            src={entity.photoUrl}
            sx={{
              width: 30,
              height: 30,
              bgcolor: "transparent",
            }}
          />

          <Typography sx={{ fontSize: 14, color: "white", fontWeight: 600 }}>{entity.fullName}</Typography>
        </Box>

        <Button
          variant="contained"
          sx={{
            ml: "auto",
            fontSize: 12,
            py: 0.3,
            px: 1.5,
            minWidth: "unset",
            bgcolor: "white",
            color: "#333333",
            borderRadius: 10,

            "&:hover": {
              color: "white",
            },
          }}
          endIcon={<ArrowOutwardIcon sx={{ width: 13, height: 13 }} />}
          onClick={() => {
            navigate(`/${entity.username}`);
          }}
        >
          Watch now
        </Button>
      </Box>

      <DetailText text={details} />
    </ItemContainer>
  );
};
