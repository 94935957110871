import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarProps, Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { Image, SmartDisplay } from "@mui/icons-material";

import { useBloombergEpisodesPageContext } from "../episodes-context";

export type EpisodeItemProps = {
  id: string;
  imageProps?: AvatarProps;
  title?: string;
  description?: string;
  duration?: string;
  order?: number;
  slug?: string;
};

export const EpisodeItem = ({
  imageProps = {},
  title = "",
  description = "",
  duration = "",
  order = 1,
  slug = "",
}: EpisodeItemProps) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { data } = useBloombergEpisodesPageContext();

  const onItem = useCallback(() => {
    navigate(`/p/bloomberg/${data?.slug}/${slug}`);
  }, []);

  return (
    <ButtonBase
      sx={{
        textAlign: "left",
        borderRadius: 1.25,
        transition: theme.transitions.create(["background-color"]),
        width: "100%",
        maxWidth: { lg: 640, xl: 1024 },

        "&:hover": {
          bgcolor: "#444553",
        },
      }}
      onClick={onItem}
    >
      <Box sx={{ p: 2.5 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row", lg: "column", xl: "row" },
            alignItems: "center",
            gap: 3,
          }}
        >
          <Avatar
            sx={{
              width: { xs: "100%", md: 260, lg: "100%", xl: 260 },
              height: { xs: 200, md: 150, lg: 200, xl: 150 },
              objectFit: "cover",
              borderRadius: 1.5,
            }}
            {...imageProps}
          >
            <Image sx={{ fontSize: 48 }} />
          </Avatar>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25, pr: { xs: 3, lg: 0, xl: 3 } }}>
            <Typography
              sx={{
                fontSize: 20,
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {title}
            </Typography>

            <Typography
              color="text.secondary"
              sx={{
                fontSize: 14,
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {description}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <SmartDisplay sx={{ color: "#ffffff80" }} />

              <Typography color="text.secondary" sx={{ fontSize: 14 }}>
                {duration}
              </Typography>

              <Typography color="text.secondary" sx={{ fontSize: 14 }}>
                •
              </Typography>

              <Typography color="text.secondary" sx={{ fontSize: 14 }}>
                Episode {order}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
};
