import { PrivateLayoutContextType } from "./types";

export const defaultPrivateLayoutContextValue: PrivateLayoutContextType = {
  navbar__visible: true,
  navbar__height: 64,
  //
  leftMenubar__visible: false,
  leftMenubar__width: 64,
  //
  bottomMenubar__visible: true,
  //
  updatePrivateLayout: () => {},
};
