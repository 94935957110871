import { Dispatch } from "redux";
import { all, fork, put, take } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, NftFeedService, S3, ToastService } from "../../services";
import { uploadFeedMedia } from "../../services/s3";
import { FAIL, START, SUCCESS } from "../common";
import { FILE_UPLOAD_PROGRESS } from "../ui/actions";
import * as actions from "./actions";
import { GiphyObject } from "../../types";

function* fetchStreamFeed() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_HOME_STREAM_FEED + START);
    const { page = 1 } = payload;
    try {
      const { data } = yield apiClient.get(`v2/feed/stream?limit=20&page=${page}`);
      yield put({
        type: actions.FETCH_HOME_STREAM_FEED + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_HOME_STREAM_FEED + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchNftFeed() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_HOME_NFT_FEED + START);
    const { page = 1 } = payload;
    try {
      const { data } = yield apiClient.get(`/feed/nftCommunity?limit=20&page=${page}`);
      yield put({
        type: actions.FETCH_HOME_NFT_FEED + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_HOME_NFT_FEED + FAIL,
        payload: error,
      });
    }
  }
}

function* createStreamFeed(dispatch: Dispatch): Generator<any, any, any> {
  while (true) {
    const { payload } = yield take(actions.CREATE_STREAM_FEED + START);
    const { files, text, draftContent, giphy, uploadId } = payload as {
      files?: File[];
      text?: string;
      giphy?: GiphyObject;
      draftContent: string;
      uploadId?: number;
    };
    if (!files?.length && !text && !giphy) {
      continue;
    }
    try {
      const mediaData: { type: string; url: string; caption: string; order: number }[] = [];
      if (files?.length) {
        for (let index = 0; index < files?.length; index += 1) {
          const url = yield uploadFeedMedia({
            folder: "stream",
            file: files[index],
            dispatch,
            reduxAction: FILE_UPLOAD_PROGRESS,
            uploadId,
          });
          yield put({
            type: FILE_UPLOAD_PROGRESS,
            payload: {
              uploadId,
              progress: 0.01,
            },
          });
          let fileType = "image";
          if (!files[index].type.includes("image")) {
            fileType = "video";
          }
          mediaData.push({
            type: fileType,
            url,
            caption: "",
            order: index,
          });
        }
      }
      const { data } = yield apiClient.post("/v2/feed/stream", {
        text,
        draftContent,
        giphy,
        items: mediaData,
      });
      yield put({
        type: actions.CREATE_STREAM_FEED + SUCCESS,
        payload: data,
      });
      if (payload.onSuccess) {
        payload.onSuccess();
      }
      if (data.processing) {
        ToastService.showSuccessMessage(
          "We are processing your video, we will notify when it’s available through email."
        );
      } else {
        ToastService.showSuccessMessage("Successfully posted.");
      }
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.CREATE_STREAM_FEED + FAIL,
        payload: error,
      });
    }
  }
}

function* addStreamFeedReaction() {
  while (true) {
    const { payload } = yield take(actions.STREAM_FEED_REACTION + START);
    const { streamFeedId, reaction, streamerId } = payload;
    try {
      yield apiClient.post(`/v2/feed/stream/${streamFeedId}/reaction`, { type: reaction });
      yield put({
        type: actions.STREAM_FEED_REACTION + SUCCESS,
        payload: { streamerId },
      });
    } catch (error) {
      yield put({
        type: actions.STREAM_FEED_REACTION + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* removeStreamFeedReaction() {
  while (true) {
    const { payload } = yield take(actions.STREAM_FEED_REACTION_REMOVE + START);
    const { streamFeedId, reaction, streamerId } = payload;
    try {
      yield apiClient.delete(`/v2/feed/stream/${streamFeedId}/reaction`, { data: { type: reaction } });
      yield put({
        type: actions.STREAM_FEED_REACTION_REMOVE + SUCCESS,
        payload: { streamerId },
      });
    } catch (error) {
      yield put({
        type: actions.STREAM_FEED_REACTION_REMOVE + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* fetchNftCommunityFeed(): any {
  while (true) {
    const { payload } = yield take(actions.FETCH_NFT_FEED + START);
    try {
      const { data } = yield NftFeedService.fetchNftCommunityFeed(payload);

      yield put({
        type: actions.FETCH_NFT_FEED + SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      yield put({
        type: actions.FETCH_NFT_FEED + FAIL,
        payload: error.response.data,
      });
    }
  }
}
function* createNftCommunityPost(dispatch: Dispatch): any {
  while (true) {
    const { payload } = yield take(actions.CREATE_NFT_FEED + START);

    const { file, text, uploadId, nftCreatorId } = payload as {
      file?: File;
      text?: string;
      uploadId?: number;
      nftCreatorId: string;
    };

    if (!file && !text) {
      continue;
    }
    try {
      let mediaUrl: string | undefined;
      if (file) {
        mediaUrl = yield S3.uploadFeedMedia({
          folder: "nft",
          file,
          dispatch,
          reduxAction: FILE_UPLOAD_PROGRESS,
          uploadId,
        });
      }

      const mediaData = file?.type.includes("image") ? { image: mediaUrl } : { video: mediaUrl };

      const { data } = yield NftFeedService.createNftCommunityPost(nftCreatorId, {
        text,
        ...mediaData,
      });

      yield put({
        type: actions.CREATE_NFT_FEED + SUCCESS,
        payload: data,
      });
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } catch (error: any) {
      yield put({
        type: actions.CREATE_NFT_FEED + FAIL,
        payload: error.response.data,
      });
    }
  }
}

function* deleteStreamFeed() {
  while (true) {
    const { payload } = yield take(actions.DELETE_STREAM_FEED + START);
    try {
      yield apiClient.delete(`/v2/feed/stream/${payload}`);
      yield put({
        type: actions.DELETE_STREAM_FEED + SUCCESS,
        payload,
      });
    } catch (error) {
      yield put({
        type: actions.DELETE_STREAM_FEED + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* deleteNFTFeed() {
  while (true) {
    const { payload } = yield take(actions.DELETE_NFT_FEED + START);
    try {
      yield apiClient.delete(`/feed/nftCommunity/${payload}`);
      yield put({
        type: actions.DELETE_NFT_FEED + SUCCESS,
        payload,
      });
    } catch (error) {
      yield put({
        type: actions.DELETE_NFT_FEED + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* fetchUserStreamFeed() {
  while (true) {
    const { payload } = yield take(actions.FETCH_USER_STREAM_FEED + START);
    const { page = 1, userId } = payload;
    try {
      const { data } = yield apiClient.get(`/v2/users/${userId}/feeds?limit=20&page=${page}`);
      yield put({
        type: actions.FETCH_USER_STREAM_FEED + SUCCESS,
        payload: { ...data, userId },
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_USER_STREAM_FEED + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchFeedListCommentReply() {
  while (true) {
    const { payload = {} } = yield take(actions.FETCH_FEED_LIST_COMMENT_REPLY + START);
    const { streamFeedId = "", streamFeedComment = "", limit = 3, index } = payload;
    try {
      const { data } = yield apiClient.get(
        `/v2/feed/stream/comment/${streamFeedId}/reply/${streamFeedComment}?limit=${limit}`
      );
      yield put({
        type: actions.FETCH_FEED_LIST_COMMENT_REPLY + SUCCESS,
        payload: { streamFeedId, data, index },
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_FEED_LIST_COMMENT_REPLY + FAIL,
        payload: { error, index },
      });
    }
  }
}

function* addStreamListCommentReaction() {
  while (true) {
    const { payload } = yield take(actions.STREAM_LIST_COMMENT_REACTION + START);
    const { streamFeedCommentId, reaction } = payload;
    try {
      yield apiClient.post(`/v2/feed/stream/comment/${streamFeedCommentId}/reaction`, { type: reaction });
      yield put({
        type: actions.STREAM_LIST_COMMENT_REACTION + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: actions.STREAM_LIST_COMMENT_REACTION + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* removeStreamListCommentReaction() {
  while (true) {
    const { payload } = yield take(actions.STREAM_LIST_COMMENT_REACTION_REMOVE + START);
    const { streamFeedCommentId, reaction } = payload;
    try {
      yield apiClient.delete(`/v2/feed/stream/comment/${streamFeedCommentId}/reaction`, { data: { type: reaction } });
      yield put({
        type: actions.STREAM_LIST_COMMENT_REACTION_REMOVE + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: actions.STREAM_LIST_COMMENT_REACTION_REMOVE + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* addStreamListReplyReaction() {
  while (true) {
    const { payload } = yield take(actions.STREAM_LIST_REPLY_REACTION + START);
    const { replyId, reaction } = payload;
    try {
      yield apiClient.post(`/v2/feed/stream/comment/${replyId}/reaction`, { type: reaction });
      yield put({
        type: actions.STREAM_LIST_REPLY_REACTION + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: actions.STREAM_LIST_REPLY_REACTION + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* removeStreamListReplyReaction() {
  while (true) {
    const { payload } = yield take(actions.STREAM_LIST_REPLY_REACTION_REMOVE + START);
    const { replyId, reaction } = payload;
    try {
      yield apiClient.delete(`/v2/feed/stream/comment/${replyId}/reaction`, { data: { type: reaction } });
      yield put({
        type: actions.STREAM_LIST_REPLY_REACTION_REMOVE + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: actions.STREAM_LIST_REPLY_REACTION_REMOVE + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* feedListCommentAdd() {
  while (true) {
    const { payload } = yield take(actions.FEED_LIST_COMMENT_ADD + START);
    const { feedId, commentText, draftContent, limit = 3 } = payload;
    try {
      const { data } = yield apiClient.post(
        `/v2/feed/stream/comment/${feedId}/`,
        { text: commentText, draftContent },
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      // yield put({
      //   type: FETCH_FEED_COMMENT + START,
      //   payload: { streamFeed: feedId, limit } as FeedCommentsActionType,
      // });
      yield put({
        type: actions.FEED_LIST_COMMENT_ADD + SUCCESS,
        payload: { feedId, data },
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FEED_LIST_COMMENT_ADD + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* feedListCommentAddReply() {
  while (true) {
    const { payload } = yield take(actions.FEED_LIST_COMMENT_ADD_REPLY + START);
    const { feedId, commentId, draftContent, replyText, limit = 3, index } = payload;
    try {
      const params = new URLSearchParams();
      params.append("text", replyText);
      params.append("draftContent", draftContent);
      const { data } = yield apiClient.post(`/v2/feed/stream/comment/${feedId}/reply/${commentId}`, params, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      yield put({
        type: actions.FETCH_FEED_LIST_COMMENT_REPLY + START,
        payload: { streamFeedId: feedId, streamFeedComment: commentId, limit, index },
      });
      yield put({
        type: actions.FEED_LIST_COMMENT_ADD_REPLY + SUCCESS,
        payload: { streamFeedId: feedId, index, data },
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      // yield put({
      //   type: FEED_DETAILS_LIKE + FAIL,
      //   payload: { request: payload, error },
      // });
    }
  }
}

function* createFeedImpression() {
  while (true) {
    const { payload } = yield take(actions.CREATE_FEED_IMPRESSION);
    const { type, item } = payload;
    try {
      yield apiClient.post("/trackings/feed/impression", { type, item });
    } catch (error) {
      console.error("Tracking error: ", error);
    }
  }
}

export default function* feedSaga(dispatch: Dispatch) {
  yield all([
    fork(fetchStreamFeed),
    fork(fetchNftFeed),
    fork(createStreamFeed, dispatch),
    fork(addStreamFeedReaction),
    fork(removeStreamFeedReaction),
    fork(fetchNftCommunityFeed),
    fork(createNftCommunityPost, dispatch),
    fork(deleteStreamFeed),
    fork(deleteNFTFeed),
    fork(fetchUserStreamFeed),
    fork(fetchFeedListCommentReply),
    fork(addStreamListCommentReaction),
    fork(removeStreamListCommentReaction),
    fork(addStreamListReplyReaction),
    fork(removeStreamListReplyReaction),
    fork(feedListCommentAdd),
    fork(feedListCommentAddReply),
    fork(createFeedImpression),
  ]);
}
