import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, Grid, Avatar, Box } from "@mui/material";

import { ReactComponent as GiftIcon } from "../../../assets/svgs/giftAlt.svg";
import { FlexRow } from "../../FlexRow";
import { FeedContentTextBody } from "../../FeedContentTextBody";
import { Message, MESSAGE_TYPE, MessageSender } from "../../../types";
import { channelSelector } from "../../../store/channel/selector";
import { FeedGiphyContent } from "../../FeedGiphyContent";

type Props = {
  item?: Message;
  handleClickProfile?: (event: HTMLDivElement | null, sender: MessageSender) => void;
};

export const DisplayChatMessage = ({ item, handleClickProfile = () => {}, ...rest }: Props) => {
  const refAvatar = useRef<HTMLDivElement>(null);
  const { boltId } = useParams();
  const {
    channel: { data },
  } = useSelector(channelSelector);

  const stringToColor = (string: string) => {
    if (!string) return null;
    let hash = 0;
    /* eslint-disable no-bitwise */
    for (let i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";
    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const handleUserPopover = () => {
    // if (!isMyProfileOrOwn)
    handleClickProfile(refAvatar?.current, item?.sender!);
  };

  switch (item?.type) {
    case MESSAGE_TYPE.TEXT:
    case MESSAGE_TYPE.GIPHY:
    case MESSAGE_TYPE.ASSIGN_MODERATOR:
    case MESSAGE_TYPE.REMOVE_MODERATOR:
      return (
        <FlexRow sx={{ alignItems: "flex-start", mb: 1 }}>
          <Avatar
            ref={refAvatar}
            src={item.sender ? item.sender.photoUrl : data?.logo}
            sx={{
              width: 30,
              height: 30,
              bgcolor: stringToColor(item.sender?.username),
              cursor: "pointer",
            }}
            onClick={handleUserPopover}
          >
            {item.sender?.firstName?.charAt(0).concat(item.sender?.lastName ? item.sender?.lastName?.charAt(0) : "")}
          </Avatar>
          <Box sx={{ ml: 1.5, mt: 0.7, flex: 1 }} className="chat-box">
            <FeedContentTextBody
              draftContent={item.draftContent}
              fontSize={12}
              text={item.message}
              chatUserName={item?.sender?.username}
              handleUserPopover={handleUserPopover}
            />
            {!!item.giphy && (
              <FeedGiphyContent
                data={item.giphy}
                width={300}
                sx={{ mt: 1, backgroundColor: "transparent", justifyContent: "flex-start" }}
              />
            )}
          </Box>
        </FlexRow>
      );
    case MESSAGE_TYPE.NOTICE:
      return (
        <Typography variant="caption" display="inline-block" component="span" {...rest}>
          {item?.message}
        </Typography>
      );
    case MESSAGE_TYPE.GIFT:
      return (
        <Grid container spacing={1} alignItems="center" {...rest}>
          <Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <GiftIcon width={16} height={16} />
          </Grid>
          <Grid item>
            <Typography variant="caption" sx={{}}>
              {item?.message}
            </Typography>
          </Grid>
        </Grid>
      );
    case MESSAGE_TYPE.TIP:
      return (
        <Grid container spacing={1} alignItems="center" sx={{ mb: 1 }} {...rest}>
          <Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Avatar
              ref={refAvatar}
              src={item.sender ? item.sender.photoUrl : data?.logo}
              sx={{
                width: 30,
                height: 30,
                bgcolor: stringToColor(item.sender?.username),
                cursor: "pointer",
              }}
              onClick={handleUserPopover}
            >
              {item.sender?.firstName.charAt(0).concat(item.sender?.lastName ? item.sender?.lastName.charAt(0) : "")}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
              sx={{ mx: 0.5, fontWeight: 600, cursor: "pointer" }}
              onClick={handleUserPopover}
            >
              {item?.sender.username}
            </Typography>
            <Typography variant="caption" sx={{ color: "#ffffffa0" }}>
              sent
            </Typography>
            {/* <Bolts style={{ width: 18, height: 18, marginLeft: 6, marginRight: 6, verticalAlign: "middle" }} /> */}
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
              {` $${item.amount || 0}`}{" "}
            </Typography>
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};
