import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Paper, Typography, Box, ModalProps, Fab } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { QRCode } from "react-qrcode-logo";

import { FlexCol, FlexRow } from ".";
import { ReactComponent as BinancePayIcon } from "../assets/svgs/binancePay.svg";
import { ReactComponent as ApplePayIcon } from "../assets/svgs/applePay.svg";
import { ReactComponent as GooglePayIcon } from "../assets/svgs/googlePay.svg";
import { ReactComponent as BinanceIcon } from "../assets/svgs/binance.svg";
import BinanceLogoIcon from "../assets/svgs/binanceWithdraw.svg";
import { SuccessAnimation } from "./Success";
import { ErrorAnimation } from "./Error";

import { createBinancePayOrder, updateBinancePayStatus, updateStripePayStatus } from "../store/payment/actions";
import { paymentSelector } from "../store/payment/selector";
import { BINANCE_TRANSACTION_STATUS, STRIPE_TRANSACTION_STATUS, RECEIVER_TYPE, DIRECT_TO_TYPE } from "../types";
import { useStripePaymentButtons } from "../hooks/useStripePaymentButtons";
import { BaseModal } from "./modals/BaseModal";

const enum PAY_OPTIONS {
  BINANCE = "BINANCE",
  BOLTX = "BOLTX",
  STRIPE = "STRIPE",
}

interface Props extends Partial<ModalProps> {
  open: boolean;
  onClose: () => void;
  receiverName: string;
  receiverId: string;
  receiverType: RECEIVER_TYPE;
  directTo?: string;
  directToType?: DIRECT_TO_TYPE;
}

const GiftBoltsModal = ({
  open,
  onClose,
  receiverName,
  receiverId,
  receiverType,
  directTo,
  directToType,
  ...rest
}: Props) => {
  const dispatch = useDispatch();
  const { binancePayOrder, binancePayStatus, stripePayStatus } = useSelector(paymentSelector);
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const [selectedBolt, setSelectedBolt] = useState(0);
  const [qrCode, setQrCode] = useState("");
  const [qrContent, setQrContent] = useState("");
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [deeplink, setDeeplink] = useState("");
  const bolts = [1, 5, 10, 25, 50, 100];

  const {
    paymentRequest,
    stripeAvailableButtons: stripeAvailable,
    setStripeAvailableButtons,
  } = useStripePaymentButtons({
    amount: selectedBolt,
    country: "US",
    currency: "usd",
    label: "Donate",
    receiverId,
    receiverType,
    directTo,
    directToType,
  });

  useEffect(() => {
    if (binancePayOrder) {
      setCheckoutUrl(binancePayOrder.data?.checkoutUrl);
      setQrCode(binancePayOrder.data?.qrcodeLink);
      setQrContent(binancePayOrder.data?.qrContent);
      setDeeplink(binancePayOrder.data?.deeplink);
    } else {
      setCheckoutUrl("");
      setQrCode("");
      setQrContent("");
      setDeeplink("");
    }
  }, [binancePayOrder]);

  const payWith = async (option: PAY_OPTIONS) => {
    if (selectedBolt > 0) {
      switch (option) {
        case PAY_OPTIONS.BINANCE:
          dispatch(
            createBinancePayOrder({
              env: { terminalType: "WAP" },
              merchantTradeNo: `Tip${new Date().getTime()}`,
              orderAmount: selectedBolt,
              currency: "USDT",
              goods: {
                goodsType: "02",
                goodsCategory: "Z000",
                referenceGoodsId: `${new Date().getTime()}`,
                goodsName: "Tip",
              },
              receiverType,
              receiver: receiverId,
              directTo,
              directToType,
            })
          );
          break;
        case PAY_OPTIONS.BOLTX:
          break;
        case PAY_OPTIONS.STRIPE:
          paymentRequest?.show();
          break;
        default:
          break;
      }
    }
  };

  return (
    <BaseModal {...rest} open={!!open} onClose={onClose} onClick={(e) => e.stopPropagation()}>
      <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <FlexCol
          sx={{
            minHeight: 300,
            minWidth: { xs: 0, sm: 468 },
            height: !qrCode ? { xs: "max-content", md: "max-content" } : { lg: "80%", xl: "max-content" },
            width: { xs: "95%", sm: "auto" },
            borderRadius: { xs: 4, md: 4 },
            borderTopLeftRadius: { xs: 15, md: "none" },
            borderTopRightRadius: { xs: 15, md: "none" },
            overflowY: "auto",
            left: { xs: "3%", md: "3%" },
            bottom: 0,
            zIndex: 103,
            transition: "all ease-in-out 0.9s",
            bgcolor: { xs: "#1E2034", md: "#1C2039" },
          }}
        >
          <FlexCol sx={{ bgcolor: { xs: "#1E2034", md: "#1C2039" }, p: 3, height: "100%" }}>
            <FlexRow
              sx={{
                justifyContent:
                  showPaymentOption &&
                  binancePayStatus !== BINANCE_TRANSACTION_STATUS.PAY_SUCCESS &&
                  stripePayStatus !== STRIPE_TRANSACTION_STATUS.PAY_SUCCESS
                    ? "space-between"
                    : "flex-end",
              }}
            >
              {showPaymentOption &&
                binancePayStatus !== BINANCE_TRANSACTION_STATUS.PAY_SUCCESS &&
                stripePayStatus !== STRIPE_TRANSACTION_STATUS.PAY_SUCCESS && (
                  <IconButton onClick={() => setShowPaymentOption(false)}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                )}
              <IconButton
                onClick={() => {
                  setShowPaymentOption(false);
                  setSelectedBolt(0);
                  setQrCode("");
                  setCheckoutUrl("");
                  setDeeplink("");
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </FlexRow>
            <FlexRow sx={{ justifyContent: "center", alignItems: "center", textAlign: "center", mb: 2 }}>
              <Typography component="span">
                <Typography>
                  Support{" "}
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {receiverName}
                  </Typography>
                </Typography>
              </Typography>
            </FlexRow>
            <Typography
              variant="caption"
              sx={{
                my: 0.5,
                mx: "auto",
                display: "inline-block",
                color: "#A3AED0",
              }}
            >
              Donate to your favourite creators directly
            </Typography>
            {selectedBolt > 0 && (
              <FlexRow sx={{ flex: 1, alignItems: "center", justifyContent: "center", pt: { xs: 0, md: 2 } }}>
                <Typography sx={{ fontSize: { xs: 25, sm: 33 } }} fontWeight={600}>
                  ${selectedBolt}
                </Typography>
                {/* display icon below for mobile screen alone */}
                {/* <FlexRow
                  sx={{
                    display: { xs: "flex", md: "none" },
                    width: { xs: 50, sm: 75 },
                    height: { xs: 50, sm: 75 },
                    ml: { xs: 0, md: -1.5 },
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Bolts style={{ width: "100%" }} />
                </FlexRow> */}
                {/* display icon above for mobile screen alone */}

                {/* display icon below for desktop screen alone */}
                {/* <FlexRow
                  sx={{ display: { xs: "none", md: "flex" }, ml: 1.2 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Bolts style={{ width: 50, height: 50 }} />
                </FlexRow> */}
                {/* display icon above for desktop screen alone */}
              </FlexRow>
            )}
            {(binancePayStatus === BINANCE_TRANSACTION_STATUS.INITIALIZED ||
              (binancePayStatus === BINANCE_TRANSACTION_STATUS.NOT_INITILIZED &&
                stripePayStatus === STRIPE_TRANSACTION_STATUS.INITIALIZED) ||
              stripePayStatus === STRIPE_TRANSACTION_STATUS.NOT_INITILIZED) && (
              <FlexCol sx={{ alignItems: "center", flex: 1 }}>
                {!showPaymentOption && !qrCode && (
                  <FlexCol
                    sx={{
                      flex: 1,
                      alignItems: "center",
                      justifyContent: { xs: "start", md: "center" },
                      width: { xs: "90%", lg: "80%", xl: "70%" },
                      maxWidth: 370,
                      mt: 2,
                    }}
                  >
                    <FlexRow sx={{ justifyContent: "center", flexWrap: "wrap", gap: 0.75 }}>
                      {bolts.map((bolt: number) => {
                        return (
                          <Button
                            key={bolt}
                            sx={{
                              flex: 0.32,
                              justifyContent: "space-around",
                              px: "10px",
                              minWidth: 90,
                              background: bolt === selectedBolt ? "#A3AED0" : "#282C46",
                              transition: "background 0.5s ease-in-out",
                              "&:hover": { background: bolt === selectedBolt ? "#A3AED0" : "#282C46" },
                            }}
                            onClick={() => {
                              setSelectedBolt(bolt);
                              setStripeAvailableButtons(undefined);
                            }}
                            // endIcon={<Bolts />}
                          >
                            <FlexRow sx={{ alignItems: "center" }}>
                              <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                color={bolt === selectedBolt ? "secondary.main" : "white"}
                                sx={{ transition: "color 0.6s ease-in-out" }}
                              >
                                ${Number(bolt)}
                              </Typography>
                            </FlexRow>
                          </Button>
                        );
                      })}
                    </FlexRow>
                    {selectedBolt > 0 && (
                      <Button
                        onClick={() => setShowPaymentOption(true)}
                        sx={{
                          mx: "auto",
                          display: "block",
                          height: 36,
                          width: "99%",
                          bgcolor: "primary.main",
                          borderRadius: 30,
                          mt: 2,
                          mb: { xs: 2, md: 0 },
                          "&:hover": { bgcolor: "primary.main" },
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ color: "white" }}>
                          Donate
                        </Typography>
                      </Button>
                    )}
                  </FlexCol>
                )}
                {showPaymentOption && (
                  <FlexCol sx={{ width: "100%", flex: 1 }}>
                    {!qrCode && (
                      <FlexCol sx={{ alignItems: "center", width: "80%", mx: "auto" }}>
                        <Typography variant="subtitle2" sx={{ alignSelf: "start", color: "rgba(255, 255, 255, 0.35)" }}>
                          Pay with
                        </Typography>
                        <Button
                          sx={{
                            height: 40,
                            width: "100%",
                            my: 1,
                            borderRadius: 1,
                            bgcolor: "secondary.light",
                            "&:hover": { bgcolor: "secondary.light" },
                          }}
                          color="warning"
                          onClick={() => payWith(PAY_OPTIONS.BINANCE)}
                        >
                          <Typography variant="caption" fontWeight="600" color="black">
                            <BinancePayIcon />
                          </Typography>
                        </Button>
                        {(stripeAvailable?.applePay || stripeAvailable?.googlePay) && (
                          <Button
                            sx={{
                              height: 40,
                              width: "100%",
                              my: 1,
                              borderRadius: 1,
                              bgcolor: "secondary.light",
                              "&:hover": { bgcolor: "secondary.light" },
                            }}
                            color="warning"
                            onClick={() => payWith(PAY_OPTIONS.STRIPE)}
                          >
                            <Typography variant="caption" fontWeight="600" color="black">
                              {!!stripeAvailable.applePay && <ApplePayIcon />}
                              {!!stripeAvailable.googlePay && <GooglePayIcon />}
                            </Typography>
                          </Button>
                        )}
                      </FlexCol>
                    )}
                    {qrCode && (
                      <FlexCol sx={{ alignItems: "center" }}>
                        <FlexCol>
                          <BinancePayIcon />
                        </FlexCol>
                        <Typography variant="tiny" sx={{ my: 1, color: "#FFFFFF80" }}>
                          Scan this QR code with{" "}
                          <Typography
                            component="a"
                            variant="inherit"
                            sx={{ textDecoration: "underline" }}
                            color="#F0B90B"
                          >
                            Binance app
                          </Typography>{" "}
                          to pay
                        </Typography>
                        <FlexCol sx={{ borderRadius: 3.5, overflow: "hidden" }}>
                          <QRCode
                            value={qrContent}
                            logoImage={BinanceLogoIcon}
                            removeQrCodeBehindLogo
                            style={{ width: 90, height: 90, border: "solid red 6px" }}
                          />
                        </FlexCol>
                        <Typography variant="tiny" sx={{ fontWeight: "600", color: "gray", mt: 1 }}>
                          or
                        </Typography>
                        <Button variant="text" onClick={() => window.open(checkoutUrl)}>
                          <Typography variant="tiny" sx={{ textDecoration: "underline", color: "#1976d2" }}>
                            Pay on web browser
                          </Typography>
                        </Button>

                        <Fab
                          variant="extended"
                          sx={{ height: 30, width: "80%", mt: 1, mb: 3 }}
                          color="warning"
                          onClick={() => window.open(deeplink)}
                        >
                          <Typography variant="caption" fontWeight="600" color="black">
                            Open in
                          </Typography>
                          <BinanceIcon />
                        </Fab>
                      </FlexCol>
                    )}
                  </FlexCol>
                )}
              </FlexCol>
            )}
            {binancePayStatus === BINANCE_TRANSACTION_STATUS.PAY_SUCCESS ||
              (stripePayStatus === STRIPE_TRANSACTION_STATUS.PAY_SUCCESS && (
                <FlexCol sx={{ alignItems: "center", flex: 1 }}>
                  <FlexCol sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                    <Typography sx={{ fontWeight: 600, mb: 4 }}>Gift Successful!</Typography>
                    <SuccessAnimation />
                  </FlexCol>
                  <FlexCol
                    component={Paper}
                    elevation={4}
                    sx={{ p: 2, borderRadius: 4, width: "100%", alignItems: "center" }}
                  >
                    {selectedBolt > 0 && (
                      <FlexRow sx={{ flex: 1, alignItems: "center", justifyContent: "center", pt: { xs: 0, md: 2 } }}>
                        <Typography sx={{ fontSize: { xs: 25, sm: 33 } }} fontWeight={600}>
                          ${selectedBolt}
                        </Typography>
                        {/* display icon below for mobile screen alone */}
                        {/* <FlexRow
                          sx={{
                            display: { xs: "flex", md: "none" },
                            width: { xs: 50, sm: 75 },
                            height: { xs: 50, sm: 75 },
                            ml: { xs: 0, md: -1.5 },
                          }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Bolts style={{ width: "100%" }} />
                        </FlexRow> */}
                        {/* display icon above for mobile screen alone */}

                        {/* display icon below for desktop screen alone */}
                        {/* <FlexRow
                          sx={{ display: { xs: "none", md: "flex" }, ml: 1.2 }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Bolts style={{ width: 50, height: 50 }} />
                        </FlexRow> */}
                        {/* display icon above for desktop screen alone */}
                      </FlexRow>
                    )}
                    <FlexCol component={Paper} elevation={4} sx={{ p: 2, borderRadius: 4, width: "100%" }}>
                      <FlexRow sx={{ justifyContent: "space-between", alignItems: "center", mt: 1.5 }}>
                        <Typography variant="tiny" sx={{ color: "lightgray" }}>
                          Gift Receiver
                        </Typography>
                        <Typography variant="caption">@{receiverName}</Typography>
                      </FlexRow>
                      <FlexRow sx={{ justifyContent: "space-between", alignItems: "center", mt: 1.5 }}>
                        <Typography variant="caption">Gift amount</Typography>
                        <Typography variant="subtitle2" sx={{ ml: "auto" }}>
                          {selectedBolt} Bolts
                        </Typography>
                      </FlexRow>
                      <Typography variant="tiny" sx={{ mt: 5, alignSelf: "center" }}>
                        Paid via
                      </Typography>
                      <FlexRow sx={{ justifyContent: "center", mt: 2 }}>
                        {binancePayStatus === BINANCE_TRANSACTION_STATUS.PAY_SUCCESS && <BinancePayIcon />}
                        {stripePayStatus === STRIPE_TRANSACTION_STATUS.PAY_SUCCESS && (
                          <>
                            {!!stripeAvailable?.applePay && <ApplePayIcon />}
                            {!!stripeAvailable?.googlePay && <GooglePayIcon />}
                          </>
                        )}
                      </FlexRow>
                    </FlexCol>
                  </FlexCol>
                </FlexCol>
              ))}
            {binancePayStatus === BINANCE_TRANSACTION_STATUS.PAY_CLOSED ||
              (stripePayStatus === STRIPE_TRANSACTION_STATUS.PAY_CLOSED && (
                <FlexCol sx={{ flex: 1, alignItems: "center" }}>
                  <FlexCol sx={{ flex: 1, justifyContent: "center", alignItems: "center", my: 2 }}>
                    <Typography sx={{ fontWeight: 600, mb: 2 }}>Gift not completed!</Typography>
                    <ErrorAnimation />
                  </FlexCol>
                  <Button
                    variant="contained"
                    sx={{ width: "60%", borderRadius: 100 }}
                    onClick={() => {
                      dispatch(updateBinancePayStatus(BINANCE_TRANSACTION_STATUS.NOT_INITILIZED));
                      dispatch(updateStripePayStatus(STRIPE_TRANSACTION_STATUS.NOT_INITILIZED));
                    }}
                  >
                    <Typography variant="subtitle2" fontWeight="600">
                      Retry
                    </Typography>
                  </Button>
                </FlexCol>
              ))}
          </FlexCol>
        </FlexCol>
      </Box>
    </BaseModal>
  );
};

export default GiftBoltsModal;
