import { Box, CircularProgress } from "@mui/material";

import { BrowseSlider } from "../browse";
// import { Recommend } from "../recommend";
import { LiveNow } from "../live-now";
import { Collections } from "../collections";
import { useSearchLandingContext } from "./SearchLandingContext";

export const SearchLandingUi = () => {
  const {
    categories,
    isLoadingCategories,
    selectedCategoryId,
    subCategories,
    isLoadingSubCategories,
    lives,
    isLoadingLives,
  } = useSearchLandingContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 5, py: 2.5 }}>
      {!isLoadingCategories && categories.length > 0 && <BrowseSlider />}

      {selectedCategoryId && isLoadingSubCategories && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: 180 }}>
          <CircularProgress />
        </Box>
      )}

      {!isLoadingSubCategories && subCategories.data.length > 0 && <Collections />}

      {/* <Recommend /> */}

      {!isLoadingLives && lives.length > 0 && <LiveNow />}

      {(isLoadingCategories || isLoadingLives) && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: 180 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
