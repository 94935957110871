import React, { useMemo, useState, useRef } from "react";
import { Avatar, Link, Popover, Typography } from "@mui/material";
import { extractHashtagsWithIndices } from "@draft-js-plugins/hashtag";
import { extractLinks } from "@draft-js-plugins/linkify";
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromRaw,
  Editor,
  EditorState,
  Modifier,
} from "draft-js";

import { Formatter } from "../utils";
import { FlexCol } from "./FlexCol";
import { FlexRow } from "./FlexRow";

const findMentionEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === "mention";
  }, callback);
};

const findChatUserNameEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === "chat-user-name";
  }, callback);
};

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  extractLinks(contentBlock.getText())?.forEach((value) => {
    callback(value.index, value.lastIndex);
    return true;
  });
};

const findHashtagEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  extractHashtagsWithIndices(contentBlock.getText())?.forEach((value) => {
    callback(value.indices[0], value.indices[1]);
    return true;
  });
};

const UserLink = (props: any) => {
  const data = props.contentState.getEntity(props.entityKey).getData();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget || anchorEl);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        ref={linkRef}
        component="a"
        rel="noreferrer nofollow"
        className="mention"
        href={`${window.location.origin}/${data?.mention?.username}`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {props.children}
      </Link>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handlePopoverClose}
        disableScrollLock
      >
        <FlexRow
          sx={{ backgroundColor: "secondary.dark", p: 1.5, py: 1, pointerEvents: "auto", cursor: "pointer" }}
          onMouseEnter={() => handlePopoverOpen({} as any)}
          onMouseLeave={handlePopoverClose}
          onClick={(e) => {
            e.stopPropagation();
            linkRef.current?.click();
          }}
        >
          <Avatar src={data?.mention?.avatar} sx={{ width: 35, height: 35 }} />
          <FlexCol sx={{ ml: 1 }}>
            <Typography sx={{ fontWeight: 600, fontSize: 14, mb: -0.5 }}>
              {data?.mention?.fullName || data?.mention?.name}
            </Typography>
            <Typography variant="caption">@{data?.mention?.username}</Typography>
          </FlexCol>
        </FlexRow>
      </Popover>
    </>
  );
};

const GeneralLink = ({ decoratedText, children }: any) => {
  return (
    <Link
      rel="noreferrer nofollow"
      className="mention"
      sx={{ span: { fontWeight: 400, fontSize: "inherit" } }}
      href={decoratedText.includes("https://") ? decoratedText : `https://${decoratedText}`}
      target="_blank"
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Link>
  );
};

const ChatUserName = (props: any) => {
  const data = props.contentState.getEntity(props.entityKey).getData();
  const isMineOwn = data?.isMineOwn;
  const handleAction = data?.handleAction;

  const handleClick = (event: any) => {
    event.preventDefault();
    if (!isMineOwn && handleAction) handleAction(event);
  };

  return (
    <span
      className="chat-user-name"
      style={{ color: "#ffffff80", fontWeight: 600, cursor: isMineOwn ? "default" : "pointer" }}
      onClick={handleClick}
      aria-hidden="true"
    >
      {props.children}
    </span>
  );
};

const HashtagComponent = ({ children }: any) => {
  return (
    <span className="hashtag" style={{ color: "#1d9bf0", fontWeight: 600 }}>
      {children}
    </span>
  );
};

const insertUserName = (
  editorState: EditorState,
  chatUserName: String,
  isMyProfileOrOwn: boolean,
  handleUserPopover?: () => void
) => {
  if (!chatUserName) return editorState;

  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const entityKey = currentContent
    .createEntity("chat-user-name", "IMMUTABLE", {
      handleAction: handleUserPopover,
      isMineOwn: isMyProfileOrOwn,
    })
    .getLastCreatedEntityKey();
  const textWithEntity = Modifier.insertText(currentContent, selection, `${chatUserName}  `, undefined, entityKey);

  return EditorState.push(editorState, textWithEntity, "insert-fragment");
};

const decorator = new CompositeDecorator([
  {
    strategy: findChatUserNameEntities,
    component: ChatUserName,
  },
  {
    strategy: findLinkEntities,
    component: GeneralLink,
  },
  {
    strategy: findHashtagEntities,
    component: HashtagComponent,
  },
  {
    strategy: findMentionEntities,
    component: UserLink,
  },
]);

interface Props {
  draftContent?: string;
  text?: string;
  fontSize?: number;
  chatUserName?: string; // only use this for live chat component
  isMyProfileOrOwn?: boolean;
  handleUserPopover?: () => void;
}

export const FeedContentTextBody = ({
  draftContent = "",
  fontSize = 16,
  text = "",
  chatUserName = "",
  isMyProfileOrOwn = false,
  handleUserPopover = () => {},
}: Props) => {
  const readMoreText = useMemo(() => (text ? Formatter.urlify(text) : ""), [text]);

  const convertToEditorState = (editorContent: string) => {
    try {
      const content = convertFromRaw(JSON.parse(editorContent));
      const editorState = EditorState.createWithContent(content, decorator);

      const newState = insertUserName(editorState, chatUserName, isMyProfileOrOwn, handleUserPopover);
      return EditorState.createWithContent(newState.getCurrentContent(), decorator);
    } catch (_) {
      const editorState = EditorState.createWithContent(ContentState.createFromText(text), decorator);
      return insertUserName(editorState, chatUserName, isMyProfileOrOwn, handleUserPopover);
    }
  };

  if (draftContent || chatUserName) {
    return (
      <FlexCol
        sx={{
          display: "block",
          ".mention": { color: "#1d9bf0", fontWeight: 600, cursor: "pointer", textDecoration: "none" },
        }}
      >
        <Editor editorState={convertToEditorState(draftContent)} readOnly onChange={() => {}} />
      </FlexCol>
    );
  }

  // support old plain text posts
  return (
    <Typography
      sx={{
        flex: 1,
        ".mention": { color: "#1d9bf0", fontWeight: 600, cursor: "pointer" },
        a: { color: "#1d9bf0", wordBreak: "break-all" },
        wordBreak: "break-word",
        whiteSpace: "pre-line",
        fontSize,
      }}
      dangerouslySetInnerHTML={{
        __html: readMoreText,
      }}
    />
  );
};
