import React, { useEffect, useState } from "react";
import { Box, Divider, IconButton, Paper, SxProps, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { FlexRow } from "../FlexRow";
import { ReactComponent as LinkIcon } from "../../assets/svgs/studio/linkWhite.svg";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";

type WindowProps = {
  title?: string;
  visible: boolean;
  Icon?: React.ElementType; // React.FunctionComponent<React.SVGProps<SVGSVGElement>> | | React.SVGProps<SVGSVGElement>
  link?: string;
  disabledClose?: boolean;
  onClose?: () => void;
  endItem?: React.ReactNode;
  sx?: SxProps;
  children?: React.ReactNode;
}

export const Window = (props: WindowProps) => {
  const { title, visible, Icon, link, disabledClose, onClose = () => { }, endItem, sx, children } = props;

  const delayTime = 100;
  const [display, setDisplay] = useState<"block" | "none">("block");
  const navigate = useNavigate();

  useEffect(() => {
    if (visible) {
      setTimeout(() => setDisplay("block"), 10);
    } else {
      setTimeout(() => setDisplay("none"), delayTime);
    }
  }, [visible]);

  const gotoLinkpage = () => {
    // navigate(`/${link}`);
    window.open(`${link}`, "_blank")
  };

  return (
    <Box
      component={Paper}
      sx={{
        width: "100%",
        height: "100%",
        minHeight: 100,
        display: visible ? "flex" : "none",
        flexDirection: "column",
        // display,
        // visibility: visible ? "visible" : "hidden",
        // opacity: visible ? 1 : 0,
        // width: visible ? "100%" : "0%",
        // transition: `all ${delayTime}ms ease-in-out`,
        ...sx
      }}>
      <FlexRow sx={{ alignItems: 'center', p: 0.5, pl: 1.6, pr: 1, justifyContent: 'space-between', height: 38, minHeight: 38, maxHeight: 38 }}>
        <FlexRow sx={{ alignItems: 'center' }} >
          {Icon && <Icon style={{ width: 16, height: 16 }} />}
          <Typography sx={{ ml: 1.5, fontWeight: 600, maxWidth: 200 }} noWrap>{title}</Typography>
          {link && <IconButton size="medium" sx={{ ml: 1 }} onClick={gotoLinkpage}><LinkIcon /></IconButton>}
        </FlexRow>
        <FlexRow sx={{ alignItems: "center" }}>
          {endItem}
          {!disabledClose && <IconButton size="small" onClick={onClose} sx={{ ml: endItem ? 1 : 0 }}><CloseIcon style={{ width: 12, height: 12 }} /></IconButton>}
        </FlexRow>
      </FlexRow>
      <Divider />
      {children}
    </Box>
  );
};