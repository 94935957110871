import { SVGProps } from "react";

export const DiscoverSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m13-3l-3.693.739a2 2 0 00-1.568 1.568L9 15l3.693-.739a2 2 0 001.569-1.568z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
