import React from "react";
import { FlexCol, PasswordResetForm, RequestResetPasswordForm } from "../../components";
import { useQuery } from "../../hooks/useQuery";

export const ResetPasswordPage = () => {
  const query = useQuery();
  const hasToken = query.get("token") && query.get("email");

  return (
    <FlexCol sx={{ margin: "auto", width: "100%", height: "100%" }}>
      {!hasToken && <RequestResetPasswordForm />}
      {hasToken && <PasswordResetForm />}
    </FlexCol>
  );
};
