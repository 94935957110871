import React, { useEffect } from "react";
import { Box, Fab, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ResponsiveContainer, StackedCarousel } from "react-stacked-center-carousel";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import { FlexRow } from "../FlexRow";
import Slide from "./Slide";
import { useLiveCarousel } from "./useLiveCarousel";

type LiveCarouselProps = {
  showTitle?: boolean;
  slideWidth?: number;
  slideHeight?: number;
};

export const LiveCarousel = ({ showTitle = false, slideWidth: _slideWidth, slideHeight }: LiveCarouselProps) => {
  const theme = useTheme();

  const ref = React.useRef<StackedCarousel>();
  const { data, loading } = useLiveCarousel();
  const navigate = useNavigate();

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      if (ref.current) {
        ref.current.goNext();
      }
    }, 10000);

    return () => {
      clearInterval(autoplayInterval);
    };
  }, []);

  const navigateToChannel = (boltId: string) => {
    navigate(`/${boltId}`);
  };

  const isXxl = useMediaQuery("(min-width:1920px)");

  const defaultSlideWidth = isMobile ? 292 : 300;

  const slideWidth = _slideWidth || defaultSlideWidth;

  const maxVisibleSlide = isXxl ? 9 : 5;
  const customScales = isXxl ? [1, 0.85, 0.7, 0.6, 0.5, 0.4] : [1, 0.85, 0.7, 0.45];

  if (loading || data.length < 1) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", position: "relative", pb: 2 }}>
      {showTitle && (
        <FlexRow
          sx={{
            justifyContent: "center",
            alignItems: "center",
            columnGap: 1,
            pt: { xs: 2.5, sm: 3 },
            pb: { xs: 2, sm: 1 },
          }}
        >
          <Box sx={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ width: 10, height: 10, bgcolor: theme.palette.error.main, borderRadius: "50%" }} />
          </Box>

          <Typography variant="h6" sx={{ fontFamily: "NFUltra-Regular", fontSize: { xs: 15 } }}>
            LIVE ON BOLT
          </Typography>
        </FlexRow>
      )}

      <ResponsiveContainer
        carouselRef={ref}
        render={(width, carouselRef) => {
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={(props) => (
                <Slide {...props} slideHeight={slideHeight} onClickCenterItem={(boltId) => navigateToChannel(boltId)} />
              )}
              slideWidth={slideWidth}
              carouselWidth={width}
              data={data}
              fadeDistance={0}
              maxVisibleSlide={maxVisibleSlide}
              customScales={customScales}
              transitionTime={450}
            />
          );
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: 0,
          zIndex: 10,
          height: "100%",
          width: "30%",
          background: "linear-gradient(90deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.5) 39%, rgba(255,255,255,0) 100%)",
          pointerEvents: "none",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 10,
          height: "100%",
          width: "30%",
          background: "linear-gradient(270deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.5) 39%, rgba(255,255,255,0) 100%)",
          pointerEvents: "none",
        }}
      />
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Fab
          sx={{
            position: "absolute",
            top: "50%",
            left: { xs: 12, md: 16, lg: 20, xl: 20 },
            backgroundColor: "#ffffff20",
            transform: "translateY(-50%)",
          }}
          color="secondary"
          size="small"
          onClick={() => ref.current?.goBack()}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: 30 }} />
        </Fab>
        <Fab
          sx={{
            position: "absolute",
            top: "50%",
            right: { xs: 12, md: 16, lg: 20, xl: 20 },
            backgroundColor: "#ffffff20",
            transform: "translateY(-50%)",
          }}
          color="secondary"
          size="small"
          onClick={() => ref.current?.goNext()}
        >
          <KeyboardArrowRightIcon style={{ fontSize: 30 }} />
        </Fab>
      </Box>
    </Box>
  );
};
