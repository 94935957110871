import { BoltWallet } from "./provider";

export enum USER_GENDER {
  MALE = "male",
  FEMALE = "female",
  NON_BINARY = "non-binary",
}

export interface SocialProvider {
  providerId: string; // google | apple | wallet
  rawId?: string;
  email?: string;
  displayName?: string;
  photoUrl?: string;
  walletAddress?: string;
  wallet?: BoltWallet; // METAMASK | WALLET_CONNECT | BOLTX
}

export interface UserMention {
  username: string;
  name?: string;
}
export interface User {
  id: string;
  email: string;
  fullName: string;
  username?: string;
  firstName: string;
  lastName: string;
  photoUrl?: string;
  emailVerified?: boolean;
  agreedTos: boolean;
  agreedPolicy: boolean;
  otpEnabled?: boolean;
  secureWithdraw?: boolean;
  profile: {
    name?: string;
    bio: string;
    comment: string;
    createdAt: string;
    id: string;
    interests: string[];
    language: string;
    gender: USER_GENDER;
    discordId?: string;
    twitterId?: string;
    instagramId?: string;
    website?: string;
    coverImage?: string;
    updatedAt: string;
    user: string;
    username: string;
  };
  followers?: number;
  following?: number;
  followed?: boolean;
  streamProfile?: any;
  activities?: any[];
  settings?: any;
  providers?: Array<SocialProvider>;
  impressions?: number;
  country?: string;
  streamSession: { createdAt: Date };
  subscriptionUrl?: string;
}

export enum SEARCH_ORDER {
  NAME = "name",
  // EMAIL = "email",
  // PHONE = "phone",
}

export interface UserSettings {
  // user: string,
  // id: string,
  emailFeedComment: boolean;
  emailFeedPost: boolean;
  emailFeedTags: boolean;
  emailStreamingLive: boolean;
}

export interface LiveStreamer {
  username: string;
  boltId: string;
  photoUrl: string;
  fullName: string;
}
