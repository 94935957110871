import { useCallback, useMemo, useState } from "react";
import { Avatar, Box, Button, ButtonBase, CircularProgress, Typography, alpha } from "@mui/material";
import { Add, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { apiClient } from "../../../config";
import { ToastService } from "../../../services";
import { useStreamsContext } from "./StreamsContext";

export const Users = () => {
  const { users } = useStreamsContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
      <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Users</Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: { xs: 1, md: 2 } }}>
        {users.map((item, index) => (
          <UserItem key={`profile-item-${index}`} {...item} />
        ))}
      </Box>
    </Box>
  );
};

type UserItemProps = {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  photoUrl?: string;
};

const UserItem = ({ id, firstName = "", lastName = "", username = "", photoUrl = "" }: UserItemProps) => {
  const navigate = useNavigate();

  const name = useMemo(() => {
    return `${firstName} ${lastName}`.trim();
  }, [firstName, lastName]);

  const [isFollowing, setIsFollowing] = useState(false);

  const onFollow = useCallback(async () => {
    try {
      setIsFollowing(true);

      await apiClient.post(`/users/follow/${id}`, {
        follow: true,
      });
    } catch (err) {
      console.error("Error at following: ", err);

      ToastService.showErrorMessage("Failed to follow the user.");
    } finally {
      setIsFollowing(false);
    }
  }, []);

  return (
    <ButtonBase
      component="div"
      sx={{
        bgcolor: "#1C2039",
        borderRadius: "15px",
        "&:hover": {
          outline: `3px solid ${alpha("#fff", 0.2)}`,
        },
        width: { xs: "100%", md: "40%", lg: "30%", xl: "22%" },
      }}
      onClick={() => {
        navigate(`/${username}`);
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: { xs: 1.5, md: 2 },
          columnGap: { xs: 1.5, md: 2 },
          width: "100%",
        }}
      >
        <Avatar
          sx={{ width: { xs: 64, md: 75 }, height: { xs: 64, md: 75 }, bgcolor: "#353A5B", color: "#1C2039" }}
          src={photoUrl}
        >
          <Person sx={{ fontSize: 56 }} />
        </Avatar>

        <Box sx={{ display: "flex", flex: 1, flexDirection: "column", rowGap: { xs: 0, md: 0.5 }, overflow: "hidden" }}>
          <Typography
            sx={{
              fontWeight: 600,
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {name}
          </Typography>

          <Typography
            sx={{
              fontSize: 12,
              color: alpha("#fff", 0.5),
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            @{username}
          </Typography>
        </Box>

        <Button
          variant="contained"
          sx={{ borderRadius: 100, height: 34, ml: "auto" }}
          endIcon={
            <Box sx={{ width: 20, height: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
              {isFollowing ? <CircularProgress size={15} sx={{ color: "#fff" }} /> : <Add sx={{ fontSize: 20 }} />}
            </Box>
          }
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={onFollow}
          disabled={isFollowing}
        >
          Follow
        </Button>
      </Box>
    </ButtonBase>
  );
};
