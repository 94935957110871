import { GiphyObject } from "./feed";

export enum BIZ_STATUS {
  INITIALIZED = "INITIALIZED",
  PAY_SUCCESS = "PAY_SUCCESS",
  PAY_CLOSED = "PAY_CLOSED",
}

export type MessageSender = {
  id: string;
  fullName: string;
  username: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
};

export type Message = {
  id?: string;
  createdAt?: string;
  message: string;
  draftContent?: string;
  giphy?: GiphyObject;
  type: string;
  sender: MessageSender;
  receiver?: any;
  prepayId?: string;
  item?: string;
  amount?: string;
  status?: BIZ_STATUS;
};

export enum MESSAGE_TYPE {
  TEXT = "text",
  IMAGE = "image",
  GIPHY = "giphy",
  VIDEO = "video",
  AUDIO = "audio",
  GIFT = "gift",
  TIP = "tip",
  NOTICE = "notice",
  POLL = "poll",
  ASSIGN_MODERATOR = "assignModerator",
  REMOVE_MODERATOR = "removeModerator",
  BANNED = "banned",
  UNBANNED = "unbanned",
}

export enum NOTIFICATION_TYPE {
  MESSAGE = "message",
  GIFT = "gift",
  TIP = "tip",
  NOTICE = "notice",
  VIEWER = "viewer",
  STREAM = "stream",
  POLL = "poll",
  ERROR = "error",
  NEW_FEED_AVAILABLE = "new_feed_available",
  SUSPEND_ACCOUNT = "suspend_account",
  USER_TYPING = "user_typing"
}

export enum RECEIVER_TYPE {
  CHANNEL = "channel",
  NFT_PROFILE = "nft_profile",
  USER = "user",
}

export enum CHANNEL_TYPE {
  NFT = "nft",
  CHANNEL = "channel",
  STREAM = "stream",
}

export enum SOCKET_NOTIFY_TYPE {
  POLL_START = "poll_start",
  POLL_STOP = "poll_stop",
  TRANSACTION = "transaction",
  STREAM_DETAILS = "stream_details",
  MODERATORS = "moderators",
}

export enum SOCKET_ROOM {
  NOTIFICATION = "notification",
  TV = "tv",
}
