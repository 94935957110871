import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { apiClient } from "../../../config";

type Partner = {
  name: string;
  slug: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  id: string;
};

export type SubCategory = {
  name: string;
  slug: string;
  thumbnails: {
    landscape: string;
    portrait: string;
  };
  icon: string;
  status: string;
  category: {
    name: string;
    id: string;
  };
  tags: any[];
  createdAt: Date;
  updatedAt: Date;
  id: string;
  partner?: Partner;
};

type SearchLandingContextType = {
  categories: {
    name: string;
    slug: string;
    description: string;
    thumbnails: {
      landscape: string;
      portrait: string;
    };
    icon: string;
    order: number;
    status: string;
    tags: any[];
    createdAt: Date;
    updatedAt: Date;
    id: string;
  }[];
  isLoadingCategories: boolean;
  selectedCategoryId: string;
  //
  subCategories: {
    data: SubCategory[];
    page: number;
    limit: number;
    total: number;
  };
  isLoadingSubCategories: boolean;
  //
  lives: {
    _id: string;
    username: string;
    boltId: string;
    photoUrl: string;
    thumbnail: string;
    fullName: string;
    followers: number;
    stream_profile?: {
      tags: string[];
      categories: any[];
      url: string;
      isLive: boolean;
      protocol: string;
      enableTip: boolean;
      languages: string[];
      restrictedCountries: any[];
      availableCountries: any[];
      thumbnail: string;
      title: string;
    };
  }[];
  isLoadingLives: boolean;
  //
  updateContext: (payload: Partial<Omit<SearchLandingContextType, "updateContext">>) => void;
};

const defaultSearchLandingContextValue: SearchLandingContextType = {
  categories: [],
  isLoadingCategories: true,
  selectedCategoryId: "",
  //
  subCategories: {
    data: [],
    page: 1,
    limit: 0,
    total: 0,
  },
  isLoadingSubCategories: true,
  //
  lives: [],
  isLoadingLives: true,
  //
  updateContext: () => {},
};

export const SearchLandingContext = createContext<SearchLandingContextType>(defaultSearchLandingContextValue);

type SearchLandingContextProviderProps = PropsWithChildren<{}>;

export const SearchLandingContextProvider = ({ children }: SearchLandingContextProviderProps) => {
  const [contextValue, setContextValue] = useState<SearchLandingContextType>(defaultSearchLandingContextValue);

  const updateContext: SearchLandingContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  const fetchCategories = useCallback(async () => {
    try {
      updateContext({ isLoadingCategories: true });

      const { data } = await apiClient.get("/categories");

      updateContext({ categories: data });
    } catch (err) {
      console.error("Error at fetchCategories: ", err);
    } finally {
      updateContext({ isLoadingCategories: false });
    }
  }, []);

  const fetchLives = useCallback(async () => {
    try {
      updateContext({ isLoadingLives: true });

      const { data } = await apiClient.get("/v2/live");

      updateContext({ lives: data });
    } catch (err) {
      console.error("Error at fetchLives: ", err);
    } finally {
      updateContext({ isLoadingLives: false });
    }
  }, []);

  const fetchSubCategories = useCallback(async () => {
    if (contextValue.selectedCategoryId) {
      try {
        updateContext({ isLoadingSubCategories: true });

        const { data } = await apiClient.get(`/subCategories/?top_category=${contextValue.selectedCategoryId}`);

        updateContext({ subCategories: data });
      } catch (err) {
        console.error("Error at fetchSubCategories: ", err);
      } finally {
        updateContext({ isLoadingSubCategories: false });
      }
    } else {
      updateContext({
        isLoadingSubCategories: false,
        subCategories: defaultSearchLandingContextValue.subCategories,
      });
    }
  }, [contextValue.selectedCategoryId]);

  useEffect(() => {
    fetchCategories();
    fetchLives();
  }, []);

  useEffect(() => {
    fetchSubCategories();
  }, [fetchSubCategories]);

  const memoizedValue = useMemo(() => {
    return { ...contextValue, updateContext };
  }, [contextValue]);

  return <SearchLandingContext.Provider value={memoizedValue}>{children}</SearchLandingContext.Provider>;
};

export const useSearchLandingContext = () => {
  const context = useContext(SearchLandingContext);

  return context;
};
