import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FloatingRadioPlayer } from "../components";
import { playNextRadioStation } from "../store/radio/actions";
import { selectedRadioSelector } from "../store/radio/selector";
import { RadioStation } from "../types";

interface Props {
  children: React.ReactNode;
}

interface ContextInterface {
  setRadio: any;
}

export const AudioPlayerContext = React.createContext<ContextInterface | undefined>(undefined);

const AudioPlayerWrapper = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [radio, setRadio] = React.useState<RadioStation>();
  const selectedRadio = useSelector(selectedRadioSelector);
  useEffect(() => {
    if (selectedRadio) {
      setRadio(selectedRadio);
    }
  }, [selectedRadio]);

  const radioObject = React.useMemo(
    () => ({
      setRadio,
    }),
    []
  );
  return (
    <AudioPlayerContext.Provider value={radioObject}>
      {children}
      {radio ? (
        <FloatingRadioPlayer
          item={radio}
          onNext={(item) => {
            dispatch(playNextRadioStation(item?.id));
          }}
          onClose={() => setRadio(undefined)}
        />
      ) : null}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerWrapper;
