import { Avatar, AvatarProps, Box, ButtonBase, Typography, alpha } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { usePrivateNavbarContext } from "../../navbar";
import { useStreamsContext } from "./StreamsContext";

export const Collections = () => {
  const { collections } = useCollections();

  const navigate = useNavigate();

  const { updateContext: updateNavbarContext } = usePrivateNavbarContext();

  const onCollectioItem = useCallback((item: Category) => {
    if (item.partner && item.partner?.slug !== "boltplus") {
      navigate(`/p/${item.partner.slug}/${item.slug}`);
    } else {
      navigate(`/c/${item.slug}`);
    }

    updateNavbarContext({ activeDrawer: undefined });
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
      <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Collections</Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {collections.map((item, index) => (
          <CollectionItem key={`collection-item-${index}`} {...item} onCollectioItem={onCollectioItem} />
        ))}
      </Box>
    </Box>
  );
};

const useCollections = () => {
  const { categories } = useStreamsContext();

  const collections: CollectionItemProps[] = categories.data.map((item) => {
    return {
      ...item,
      imageProps: {
        src: item.thumbnails.portrait,
      },
    };
  });

  return { collections };
};

interface CollectionItemProps extends Category {
  imageProps?: AvatarProps;
  onCollectioItem?: (item: Category) => void;
}

type Category = {
  slug: string;
  partner: {
    name: string;
    slug: string;
  };
};

const CollectionItem = ({ imageProps = {}, onCollectioItem, ...rest }: CollectionItemProps) => {
  return (
    <ButtonBase
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative",
        "&:hover": {
          outline: `3px solid ${alpha("#fff", 0.2)}`,
        },
      }}
      onClick={() => onCollectioItem?.({ slug: rest.slug, partner: rest.partner })}
    >
      <Avatar
        sx={{
          width: { xs: 160, md: 225 },
          height: { xs: 160 * 1.3, md: 225 * 1.3 },
          borderRadius: "10px",
          bgcolor: "rgb(35, 37, 51)",
        }}
        {...imageProps}
      >
        <ImageIcon sx={{ fontSize: 60, color: "#666" }} />
      </Avatar>
    </ButtonBase>
  );
};
